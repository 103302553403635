import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import DateTime from '../../../components/common/DateTime';
import ItemOptions from '../../../components/common/ItemOptions';
import Avatar from './Avatar';
import { doSaveUser, doDeleteUser } from '../UsersActions';
import { getUserRoleLabel } from '../UsersConsts';

import I18n from '../../../locales/I18n';
import EditUser from './EditUser';
import { openOverlay } from '../../../UIActions';




class User extends React.Component {

	editForm = event => {
		event.preventDefault();
		this.props.getEditForm(this.props.user, 'details');
	}


	editPasswordForm = event => {
		event.preventDefault();
		this.props.getEditForm(this.props.user, 'password');
	}



	onDelete = event => {
		event.preventDefault();

		if( window.confirm( I18n.t('Are you sure?')) )
			this.props.onDelete({ id: this.props.user.id });
	};

	onToggleActive = event => {
		event.preventDefault();

		const formData = new FormData();
		formData.append('id', this.props.user.id);
		formData.append('active', this.props.user.active ? 0 : 1);
		this.props.onSave(formData);
	}


	getItemOptions = () => {

		const options = [
			{
				label: I18n.t('Edit'),
				action: this.editForm,
				icon: 'edit'
			},
			{
				label: I18n.t('Change password'),
				action: this.editPasswordForm,
				icon: 'lock'
			}
		];

		if( ! this.props.isSelf ) {
			options.push({
				label: this.props.user.active ? I18n.t('Disable') : I18n.t('Enable'),
				action: this.onToggleActive,
				icon: this.props.user.active ? 'star-empty' : 'star'
			});

			options.push({
				label: I18n.t('Delete'),
				action: this.onDelete,
				icon: 'trash'
			});
		}
		return options;
	}


	getUserRoleLabel = () => {
		switch( this.props.user.user_role.label ) {
			case 'SU':
				return I18n.t('SuperMario');

			case 'MANAGER':
				return I18n.t('Admin/Manager');

			case 'ACCOUTANT':
				return I18n.t('Accountant');

			case 'ACCOUNT_MANAGER':
				return I18n.t('Account Manager');

			case 'EMPLOYEE':
			default:
				return I18n.t('Employee');
		}
	}


	render() {
		const { user, isSelf } = this.props;
		const selfInfo = isSelf ? ` (${ I18n.t('You') })` : '';

		return (
			<li className="data-list-item">
				<div className={`item user`}>
					<div className="item-user-picture">
						<Avatar key={ `avatar-user${user.id}` } user={ user } />
					</div>
					<div className="item-main-col">
						<div className="item-title">
							<button className="link-like" onClick={ this.editForm }>{user.fullname}{ selfInfo }</button>
						</div>
						<div className="item-info">{user.email}</div>
					</div>

					<div className="item-col-date">
						<DateTime date={user.created_at} format="dd mmm yyyy" />
					</div>

					<div className="item-col-category">{ getUserRoleLabel(user.user_role.label) }</div>

					<ItemOptions options={ this.getItemOptions() } />
				</div>
			</li>
		)
	}
};

User.propTypes = {
	user: PropTypes.object.isRequired,
	isSelf: PropTypes.bool.isRequired,
	userRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
	getEditForm: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};


const mapStateToProps = (state, ownProps) => {
	const { user, userRoles } = state.data || { user: {}, userRoles: [] };
	const isSelf = user.id === ownProps.user.id;
	return { isSelf, userRoles }
}


const mapDispatchToProps = (dispatch) => {
	return {
		getEditForm: (user, type) => {
			const title = I18n.t('Edit user');
			const module = <EditUser user={user} type={ type } />;
			dispatch(openOverlay( title, module ));
		},

		onSave: (user) => {
			dispatch(doSaveUser(user))
		},

		onDelete: (user) => {
			dispatch(doDeleteUser(user));
		}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(User);
