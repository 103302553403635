//import { dispatch } from 'react-redux';
import fetch from 'isomorphic-fetch';
import config from './config';
import getStore from './ExpressoStore';
import { USER_AUTH_ERROR } from './AppEvents';
import I18n from './locales/I18n';
//import Storage from './Storage';

const rootEndpoint = config.app.domain + config.app.path;
//console.log(rootEndpoint);



const fetchApi = (url, params = {}) => {
	if(! params.mode )
		params.mode = 'cors';

	if(! params.redirect)
		params.redirect = 'follow';

	if(! params.credentials)
		params.credentials = 'include';

	if(! params.method )
		params.method = 'GET';


	const headersObj = {
		'Accept': params.type || 'application/json',
	};

	/*
	 * Workaround bug @url https://stackoverflow.com/a/50691997
	 */
	const method = params.method.toUpperCase();
	if( method === 'PUT' || method === 'PATCH' ) {
		params.body.append('_method', method);
		params.method = 'POST';
	}

	params.headers = new Headers(headersObj);

	const time = (new Date()).getTime();
	const sep = url.indexOf('?') > -1 ? '&t=' : '?t=';
	const request = new Request(url + sep + time, params);

	return fetch(request).then( response => {
			if( response.status === 401 ) {
				const store = getStore();
				store.dispatch({ type: USER_AUTH_ERROR });
				return response.json();
			}

			if (response.status >= 400) {
				const promiseError = response.json();

				promiseError.then(json => {
					if(! json.errorMessage )
						return;

					if(json.errorMessage === 'USER_AUTH_ERROR') {
						const store = getStore();
						store.dispatch({ type: USER_AUTH_ERROR });
					}

					json.status = response.status;

					//throw new Error(json.errorMessage);

					return json;
				});
				return promiseError;
			}

			const contentType = response.headers.get('Content-Type');
			if(! contentType ) {
				return response.text();
			}

			if( contentType.indexOf('application/json') > -1 ) {
				const promiseSuccess = response.json();
				promiseSuccess.then(json => {
					json.status = response.status;
					json.isSuccess = json.status >= 200 && json.status <= 200;
					return json;
				});
				return promiseSuccess;
			}

			if( contentType.indexOf('image/') > -1 )
				return response.blob();

			return response.text();

		}).catch( error => {
			console.log(error);
			//const store = getStore();
			//store.dispatch({ type: USER_AUTH_ERROR });
		});
}

/**
 * Convert an object into FormData
 *
 * @param  {object} obj source object
 * @return {FormData}
 */
const formDataFromObject = (obj) => {
	if(obj instanceof FormData)
		return obj;

	let formData = new FormData();

	for(let i in obj) {
		if( i !== 0 ) {
			if( typeof(obj[i]) === 'object' ) {
				formData.append( `${i}[]`, JSON.stringify(obj[i]) );
			}
			else
				formData.append(i, obj[i]);
		}
	}

	return formData;
}




export const signIn = (data) => {
	const params = {
		method: 'POST',
		body: data
	};
	return fetchApi(`${rootEndpoint}/user/login.json`, params);
};

export const signOut = () => {
	return fetchApi(`${rootEndpoint}/user/logout.json`);
};

// Get all user data
export const getData = (year) => {
	return fetchApi(`${rootEndpoint}/${year}/data.json`);
};

export const synchronizeData = (lastEventId, year) => {
	return fetchApi(`${rootEndpoint}/${year}/${lastEventId}/sync.json`);
};


export const isLoggedIn = () => {
	return fetchApi(`${rootEndpoint}/user/session-active.json`);
}


/*
 * Jobs
 */
export const saveJob = data => {
	const isNew = ! data.get('id');

	const params = {
		method: isNew ? 'POST' : 'PUT',
		body: formDataFromObject(data),
	};

	const url = isNew ? `job/add.json` : `job/${data.get('id')}/edit.json`;
	return fetchApi(`${rootEndpoint}/${url}`, params);
};

export const deleteJob = job => {
	const params = {
		method: 'DELETE'
	};
	return fetchApi(`${rootEndpoint}/job/${job.id}/delete.json`, params);
};

export const invoiceJobs = data => {
	const params = {
		method: 'POST',
		body: data,
	};
	return fetchApi(`${rootEndpoint}/jobs/invoice.json`, params);
};

export const saveJobUsers = (job_id, data) => {
	const params = {
		method: 'PATCH',
		body: formDataFromObject(data),
	};
	return fetchApi(`${rootEndpoint}/job/${job_id}/assign.json`, params);
};

export const saveJobOwner = (job_id, data) => {
	const params = {
		method: 'PATCH',
		body: formDataFromObject(data),
	};
	return fetchApi(`${rootEndpoint}/job/${job_id}/set-owner.json`, params);
}






/*
 * Work Sessions
 */
export const jobSessionAdd = (job_id) => {
	const params = {
		method: 'POST',
		body: formDataFromObject({ job_id }),
	};
	return fetchApi(`${rootEndpoint}/job-session/add.json`, params);
};


export const jobSessionSave = (id, data) => {
	const params = {
		method: 'PUT',
		body: data
	};
	return fetchApi(`${rootEndpoint}/job-session/${id}/edit.json`, params);
}

export const jobSessionComplete = (id) => {
	const params = {
		method: 'PATCH',
		body: formDataFromObject({ id: id }),
	};
	return fetchApi(`${rootEndpoint}/job-session/${id}/complete.json`, params);
};

export const jobSessionDelete = (id) => {
	const params = {
		method: 'DELETE',
		body: formDataFromObject({ id: id }),
	};
	return fetchApi(`${rootEndpoint}/job-session/${id}/delete.json`, params);
};







/*
 * Clients
 */
export const saveClient = (data) => {
	const isNew = ! data.get('id');

	const params = {
		method: isNew ? 'POST' : 'PUT',
		body: data,
	};

	const url = isNew ? `client/add.json` : `client/${data.get('id')}/edit.json`;
	return fetchApi(`${rootEndpoint}/${url}`, params);
};

export const saveClientUsers = (id, data) => {
	const params = {
		method: 'PATCH',
		body: formDataFromObject(data),
	};
	return fetchApi(`${rootEndpoint}/client/${id}/assign.json`, params);
};

/*
export const deleteClient = (id) => {
	const params = {
		method: 'POST',
		body: id,
	};
	return fetchApi(`${rootEndpoint}/client/delete.json`, params);
};
*/


/*
 * BankAccounts
 */
export const searchBankAccount = (term) => {
	return fetchApi(`${rootEndpoint}/bank-accounts/search.json?term=${term}`);
}


export const saveBankAccount = (data) => {
	const isNew = ! data.get('id');

	const params = {
		method: isNew ? 'POST' : 'PUT',
		body: data,
	};

	const url = isNew ? `bank-account/add.json` : `bank-account/${data.get('id')}/edit.json`;
	return fetchApi(`${rootEndpoint}/${url}`, params);
};


export const deleteBankAccount = (account_id) => {
	const params = {
		method: 'DELETE'
	};
	return fetchApi(`${rootEndpoint}/bank-account/${account_id}/delete.json`, params);
}


export const makeDefaultBankAccount = (account_id) => {
	const data = new FormData();
	data.append('id', account_id);

	const params = {
		method: 'PATCH',
		body: data,
	};
	return fetchApi(`${rootEndpoint}/bank-account/${account_id}/make-default.json`, params);
}










/*
 * Invoices
 * Data typeof FormData
 */
export const saveInvoice = (data) => {
	const isNew = ! data.get('id');

	const params = {
		method: isNew ? 'POST' : 'PUT',
		body: data
	};

	const url = isNew ? `invoice/add.json` : `invoice/${data.get('id')}/edit.json`;
	return fetchApi(`${rootEndpoint}/${url}`, params);
};

export const sendInvoice = (invoice) => {
	const data = new FormData();
	data.append('id', invoice.id);

	const params = {
		method: 'POST',
		body: data,
	};
	return fetchApi(`${rootEndpoint}/invoice/${invoice.id}/email.json`, params);
};

export const deleteInvoice = (invoice) => {
	const params = {
		method: 'DELETE'
	};
	return fetchApi(`${rootEndpoint}/invoice/${invoice.id}/delete.json`, params);
};


export const createJobFromQuote = (id, data) => {
	const params = {
		method: 'POST',
		body: data
	};
	return fetchApi(`${rootEndpoint}/quote/${id}/convert.json`, params);
}

export const createCreditNoteFromInvoice = invoiceId => {
	const formData = new FormData();
	formData.append('id', invoiceId);
	const params = {
		method: 'POST',
		body: formData
	};
	return fetchApi(`${rootEndpoint}/invoice/${invoiceId}/make-credit-note.json`, params);
}







/*
 * Expenses
 */
export const saveExpense = (data) => {
	const isNew = ! data.get('id');

	const params = {
		method: isNew ? 'POST' : 'PUT',
		body: data,
	};

	const url = isNew ? `expense/add.json` : `expense/${data.get('id')}/edit.json`;
	return fetchApi(`${rootEndpoint}/${url}`, params);
};

export const deleteExpense = (id) => {
	const formData = new FormData();
	formData.append('id', id);
	const params = {
		method: 'DELETE',
		body: formData,
	};
	return fetchApi(`${rootEndpoint}/expense/${id}/delete.json`, params);
};

export const sendExpenseFile = (data) => {
	const params = {
		method: 'PATCH',
		body: data, /* FormData */
	};
	return fetchApi(`${rootEndpoint}/expense/attachment/add.json`, params);
};

export const deleteExpenseFile = (id) => {
	const data = new FormData();
	data.append('id', id);
	const params = {
		method: 'PATCH',
		body: data, /* FormData */
	};
	return fetchApi(`${rootEndpoint}/expense/${id}/attachment/delete.json`, params);
};








/*
 * Transactions
 */
export const saveTransaction = (data) => {
	const isNew = ! data.get('id');

	const params = {
		method: isNew ? 'POST' : 'PUT',
		body: data,
	};

	const url = isNew ? `transaction/add.json` : `transaction/${data.get('id')}/edit.json`;
	return fetchApi(`${rootEndpoint}/${url}`, params);
};

export const validateTransaction = (data) => {
	const params = {
		method: 'PATCH',
		body: data,
	};

	const id = data.get('id');
	return fetchApi(`${rootEndpoint}/transaction/${id}/validate.json`, params);
};

export const deleteTransaction = (id) => {
	const params = {
		method: 'DELETE',
		body: id,
	};
	return fetchApi(`${rootEndpoint}/transaction/${id}/delete.json`, params);
};

export const importCoda = (data) => {
	// Coda import
	const params = {
		method: 'POST',
		body: data,
	};
	return fetchApi(`${rootEndpoint}/transactions/import.json`, params);
};








/*
 * TaxRates (not yet working...)
 */
export const saveTaxRate = (data) => {
	const isNew = ! data.get('id');

	const params = {
		method: isNew ? 'POST' : 'PUT',
		body: data,
	};

	const url = isNew ? `tax-rate/add.json` : `tax-rate/${data.get('id')}/edit.json`;
	return fetchApi(`${rootEndpoint}/${url}`, params);
};

export const deleteTaxRate = (id) => {
	const data = new FormData();
	data.append('id', id);
	const params = {
		method: 'POST',
		body: data,
	};
	return fetchApi(`${rootEndpoint}/tax-rate/delete.json`, params);
};




/*
 * Users
 */
 export const saveUser = (data) => {
	const isNew = ! data.get('id');

 	const params = {
 		method: isNew ? 'POST' : 'PUT',
 		body: data,
 	};

	const url = isNew ? `user/add.json` : `user/${data.get('id')}/edit.json`;
 	return fetchApi(`${rootEndpoint}/${url}`, params);
 };

 export const deleteUser = (user) => {
 	const params = {
 		method: 'DELETE'
 	};
 	return fetchApi(`${rootEndpoint}/user/${user.id}/delete.json`, params);
 };




/*
 * Settings
 */

export const getSettings = (data) => {
 	const params = {};
	return fetchApi(`${rootEndpoint}/settings.json`, params);
};


export const saveSettings = (data) => {
	//const isNew = ! data.get('id');
	const params = {
		method: 'PUT',
		body: data,
	};
	return fetchApi(`${rootEndpoint}/settings/save.json`, params);
};






/*
 * Locale
 */
export const getLanguageFile = locale => {
	return fetchApi(`/locales/${config.locale}/app.json`, { type: 'text/plain' });
};


export const getCountry = term => {
	return fetchApi(`${rootEndpoint}/countries/find.json?s=${term}`);
};


export const getCompanyByVatID = (apiKey, vatID) => {
	// remove space or dots or dash
	const vatNumber = vatID.replace(/\s|\.|-/g, '').toUpperCase();

	// validate format of VAT ID (2 letters + a lot of numbers)
	if(! vatNumber.match(/^[A-Z]{2}[0-9]+$/)) {
		alert( I18n.t('VAT Number format was not recognized') );
		return;
	}

	return fetchApi(`${rootEndpoint}/clients/vat-search.json?vat_id=${vatID}`);
}
