import {
	OVERLAY_OPEN,
	OVERLAY_CLOSE,
	DOCVIEWER_OPEN,
	DOCVIEWER_CLOSE,
	FLASH_MESSAGE_HIDE,
	DO_SEARCH
} from './AppEvents';

export const overlayClassName = 'show-right-overlay';


export function addOverlayBodyClass() {
	return document.body.classList.add(overlayClassName);
}

export function removeOverlayBodyClass() {
	return document.body.classList.remove(overlayClassName);
}

export function openOverlay(title, module, onClose) {
	return {
		type: OVERLAY_OPEN,
		title,
		module,
		onClose
	}
}

export function closeOverlay() {
	return {
		type: OVERLAY_CLOSE
	}
}



export function hideFlashMessage() {
	return {
		type: FLASH_MESSAGE_HIDE
	}
}



export function openDocumentViewer(url, options) {
	return {
		type: DOCVIEWER_OPEN,
		url,
		options
	}
}

export function closeDocumentViewer() {
	return {
		type: DOCVIEWER_CLOSE
	}
}


export function doSearch(terms) {
	return {
		type: DO_SEARCH,
		terms: terms ? terms : false
	}
}
