import React from 'react';
import PropTypes from 'prop-types';

class Avatar extends React.Component {

	render() {
		const user = this.props.user;
		const size = this.props.size ? this.props.size : 'medium';
		const title = this.props.title ? this.props.title : user.fullname;

		let avatarUrl = false;
		switch(size) {
			case 'tiny':
			case 'small':
				avatarUrl = user.small_avatar_url;
				break;

			case 'medium':
				avatarUrl = user.medium_avatar_url;
				break;

			case 'large':
				avatarUrl = user.large_avatar_url;
				break;

			default:
				avatarUrl = false;
		}

		if( avatarUrl )
			return <img src={ avatarUrl} alt={ title } title={ title } className={ `avatar avatar-img avatar-${size}` } />;

		return <span className={ `avatar avatar-letter avatar-${size}` } data-letter={ user.fname.slice(0, 1) } title={ title } />;

	}
};

Avatar.propTypes = {
	user: PropTypes.object.isRequired,
	size: PropTypes.string,
	title: PropTypes.string
};

export default Avatar;
