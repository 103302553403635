import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectBox from '../../../components/forms/SelectBox';
import I18n from '../../../locales/I18n';
import { closeOverlay, removeOverlayBodyClass } from '../../../UIActions';
import { doSaveSettings } from '../SettingsActions';
import {getSettings } from '../../../api';
import { SETTINGS_SAVED } from '../../../AppEvents';


const EditSettings = ({ companySettings, lastAction, isSaving = false, onSave }) => {

	const [ options, setOptions ] = useState(null);

	useEffect(() => {
		getSettings().then(data => setOptions(data));
	}, []);

	useEffect(() => {
		if( lastAction.type === SETTINGS_SAVED && lastAction.success ) {
			removeOverlayBodyClass();

			const timer = setTimeout(() => {
				this.props.doClose();
				clearTimeout(timer);
			}, 500);
		}
	}, [lastAction]);


	const _onSave = event => {
		event.preventDefault();
		if( isSaving ) {
			return;
		}
		const settingsData = new FormData(document.getElementById('settings-edit'));
		onSave(settingsData);
	};

	const _onChangeLocale = event => {
		event.preventDefault();
		document.getElementById('locale-short').value = event.target.value.slice(0,2);
	};

	const _onChangeVoid = event => {
		event.preventDefault();
	};

	const parseOptions = () => {
		const locales = Object.keys(options.locales).map(key => {
			return { value: key, label: options.locales[key] };
		});

		const currencies = Object.keys(options.currencies).map(key => {
			return { value: key, label: options.currencies[key] };
		});

		const dateFormats = Object.keys(options.dateFormats).map(key => {
			return { value: key, label: options.dateFormats[key] };
		});

		const timeFormats = Object.keys(options.timeFormats).map(key => {
			return { value: key, label: options.timeFormats[key] };
		});

		const templates = Object.keys(options.templates).map(key => {
			return { value: key, label: options.templates[key] };
		});

		return { locales, currencies, dateFormats, timeFormats, templates };
	}

	if(! options ) {
		return (<div className="message-empty">{ I18n.t('Please wait while loading...') }</div>)
	}

	const { locales, currencies, dateFormats, timeFormats, templates } = parseOptions();


	const renderSubmit = () => {
		if( isSaving ) {
			return ( <button type="submit" className="is-saving">{ I18n.t('Saving...') }</button> )
		}
		return ( <button type="submit">{ I18n.t('Save') }</button> )
	}

	return (
		<div className="settings form">
			<form method="post" acceptCharset="utf-8" id="settings-edit" onSubmit={ _onSave }>
				<fieldset>
					<div className="fields-group">
						<div className="input select">
							<label htmlFor="locale">{ I18n.t('Language') }</label>
							<SelectBox
								id="locale"
								name="locale"
								options={ locales }
								value={ companySettings.locale }
								onChange={ _onChangeLocale }
							/>
							<input
								type="hidden"
								name="locale-short"
								maxLength="2"
								id="locale_short"
								value={ companySettings.locale_short }
								readOnly
							/>
						</div>

						<div className="input select">
							<label htmlFor="currency">{ I18n.t('Currency') }</label>
							<SelectBox
								id="currency"
								name="currency"
								options={ currencies }
								value={ companySettings.currency }
								onChange={ _onChangeVoid }
							/>
						</div>
					</div>


					<div className="fields-group">
						<div className="input select">
							<label htmlFor="date-format">{ I18n.t('Date format') }</label>
							<SelectBox
								id="date-format"
								name="date_format"
								options={ dateFormats }
								value={ companySettings.date_format }
								onChange={ _onChangeVoid }
							/>
						</div>

						<div className="input select">
							<label htmlFor="time-format">{ I18n.t('Time format') }</label>
							<SelectBox
								id="time-format"
								name="time_format"
								options={ timeFormats }
								value={ companySettings.time_format }
								onChange={ _onChangeVoid }
							/>
						</div>
					</div>
				</fieldset>


				<fieldset>
					<div className="input select">
						<label htmlFor="invoice-template">{ I18n.t('Invoice template') }</label>
						<SelectBox
							id="invoice-template"
							name="invoice_template"
							options={ templates }
							value={ companySettings.invoice_template }
							onChange={ _onChangeVoid }
						/>
					</div>

					<div className="input select">
						<label htmlFor="quote-template">{ I18n.t('Quote template') }</label>
						<SelectBox
							id="quote-template"
							name="quote_template"
							options={ templates }
							value={ companySettings.quote_template }
							onChange={ _onChangeVoid }
						/>
					</div>

					<div className="input select">
						<label htmlFor="credit-note-template">{ I18n.t('Credit note template') }</label>
						<SelectBox
							id="credit-note-template"
							name="credit_note_template"
							options={ templates }
							value={ companySettings.credit_note_template }
							onChange={_onChangeVoid }
						/>
					</div>
				</fieldset>

				<fieldset>
					<div className="input select">
						<label htmlFor="apilayer-access-key">{ I18n.t('ApiLayer Access Key') }</label>
						<input
							type="text"
							id="apilayer-access-key"
							name="apilayer_access_key"
							defaultValue={ companySettings.apilayer_access_key }
						/>
					</div>
				</fieldset>

				<div className="form-controls">
					<input type="hidden" name="id" value={companySettings.id} readOnly />
					{ renderSubmit() }
				</div>
			</form>
		</div>
	);
}


EditSettings.propTypes = {
	user: PropTypes.object.isRequired,
	lastAction: PropTypes.object.isRequired,
	companySettings: PropTypes.object.isRequired,
	isSaving: PropTypes.bool.isRequired
};



const mapStateToProps = (state, ownProps) => {
	const { user, companySettings, lastAction, isSaving } = state.data || { user: {}, companySettings: [], lastAction: {}, isSaving: false };
	return { user, lastAction, companySettings, isSaving }
}


const mapDispatchToProps = (dispatch) => {
	return {
		onSave: (settings) => {
			dispatch(doSaveSettings(settings));
		},

		doClose: () => {
			dispatch(closeOverlay());
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSettings);
