import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group'

export default class Loader extends React.Component {
	render() {
		return (
            <TransitionGroup>
    			<CSSTransition
    				component="div"
    				classNames="loader"
                    timeout={ 100000 }
    			>
    				<div className="ui-react-pending-request" />
    			</CSSTransition>
            </TransitionGroup>
		)
	}
}
