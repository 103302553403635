import React from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';

class DateTime extends React.Component {


	format = (fmt) => {
		dateFormat.i18n = {
			dayNames: [
				"Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam",
				"Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Dimanche"
			],
			monthNames: [
				"jan", "fév", "mars", "avr", "mai", "juin", "juil", "aout", "sept", "oct", "nov", "déc",
				"janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"
			]
		};

		const _date = new Date(this.props.date);
		return dateFormat(_date, fmt);
	}


	render() {
		if( ! this.props.date )
			return null;

		return (
			<time dateTime={this.format('isoDateTime')}>
				{this.format(this.props.format)}
			</time>
		);
	};
}

DateTime.propTypes = {
	date: PropTypes.string.isRequired,
	format: PropTypes.string.isRequired
};



export default DateTime;
