import React from 'react';
import PropTypes from 'prop-types';


const Glyphicon = ({ icon, className = null, ...attrs } ) => {
	const classes = ['icon', 'glyphicon'];
	classes.push(`glyphicon-${icon}`);

	if(className) {
		classes.push(className);
	}
	const tagName = attrs.tagName || 'span';

	delete attrs.className;
	delete attrs.tagName;

	const GlyphTag = `${tagName}`;

	return (
		<GlyphTag className={ classes.join(' ') } {...attrs} />
	);
}

Glyphicon.propTypes = {
	icon: PropTypes.string.isRequired
};


export default Glyphicon;
