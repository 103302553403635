import escapeStringForRegex from '../../../libs/EscapeStringForRegex';

const matchTerms = (terms, client) => {
	if(terms === false)
		return true; // no current search

	const regExp = new RegExp('\\W'+ escapeStringForRegex(terms), 'gi');
	const searchInTerms = [' ',client.name, client.short_name, ' '];
	return searchInTerms.join(' ').match(regExp) !== null;
}


const getVisibleClients = (state, clientsTypeKey, onlyActive) => {
	if( state.clients.length === 0 )
		return [];

	const clients = [];
	state.clients.forEach(c => {
		const isActive = onlyActive ? c.active : true;

		if( c[clientsTypeKey] && (! c.is_self) && isActive && matchTerms(state.searchTerms, c) )
			clients.push(c);
	});

	return clients;
};

export default getVisibleClients;
