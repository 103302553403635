import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DateSelect from '../../../components/forms/DateSelect';
import SelectBox from '../../../components/forms/SelectBox';
import I18n from '../../../locales/I18n';
import { closeOverlay, removeOverlayBodyClass } from '../../../UIActions';
import { doSaveTransaction } from '../TransactionsActions';
import { TransactionCategories, TransactionTypes, transactionsTypesWithDoc } from '../TransactionConsts';

import { TRANSACTION_SAVED } from '../../../AppEvents';

class EditTransaction extends React.Component {

	constructor(props) {
		super(props);

		this.changedCategory = false;
		this.changedDocument = false;

		this.types = {
			IN: I18n.t('Income'),
			OUT:I18n.t('Outcome')
		};

		const amount = props.transaction.amount ? props.transaction.amount : null;
		let type = null;
		if( amount )
			type = parseFloat(props.transaction.amount) > 0 ? 'IN' : 'OUT';


		const category = props.transaction.category ? props.transaction.category : null;
		this.state = {
			category,
			type,
			amount,
			account_id: null,
			client_id: null,
			manualAccount: false,
			document_id: null,
			bankaccount_id: null,
			bankaccount_name: null,
			bankaccount_iban: null,
			bankaccount_bic: null,
		};
	}



	componentDidUpdate() {
		const { lastAction } = this.props;

		if( lastAction.type === TRANSACTION_SAVED && lastAction.success ) {
			removeOverlayBodyClass();

			const timer = setTimeout(() => {
				this.props.doClose();
				clearTimeout(timer);
			}, 500);
		}
	}


	/*
	  * When user saves transaction ...
	 */
	save = event => {
		event.preventDefault();

		if( this.props.isSaving )
			return;

		if(! this.isValid() )
			return false;

		const transaction = new FormData(document.getElementById('transaction-edit'));
		this.props.onSave(transaction);
	}


	isValid = () => {
		// category && amount should not be null
		const { type, category, amount } = this.state;
		if( ! type || ! category )
			return false;

		if( isNaN(amount) )
			return false;

		return true;
	}




	callChangeCategory = () => {
		if( this.changedCategory || this.props.transaction.id )
			return;

		const timer = setInterval( () => {
			this.changeCategory();
			clearInterval(timer);
		}, 300);
	}


	changeCategory = () => {
		this.changedCategory = true;
		this.changedDocument = false;
		this.setState({ category: document.getElementById('category-selector').value });
	}



	callChangeDocument = () => {
		if( this.changedCategory || this.props.transaction.id )
			return;

		const timer = setInterval( () => {
			this.changeDocument();
			clearInterval(timer);
		}, 300);
	}




	changeDocument = () => {
		//event.preventDefault();
		this.changedDocument = true;

		const documentId = parseInt(document.getElementById('transaction-document').value, 10);
		const clientIdField = document.getElementById('transaction-client-id');

		if(! documentId) {
			clientIdField.value = '';
			this.setState({ client_id: null, document_id: null });
			return;
		}

		const { type, category } = this.state;
		const documents = (type === 'IN' && category !== 'CREDIT_NOTE') || (type === 'OUT' && category === 'CREDIT_NOTE') ? this.props.invoices : this.props.expenses;
		const doc = documents.find(d => d.id === documentId );
		if( doc ) {
			clientIdField.value = doc.client_id;
			this.setState({ client_id: doc.client_id, manualAccount: false, document_id: documentId });
			return;
		}

		clientIdField.value = '';
		this.setState({ client_id: null, document_id: null });
		return;
	}


	changeAmount = event => {
		event.preventDefault();
		const amount = parseFloat(event.target.value);
		if(amount && isNaN(amount)) {
			alert(I18n.t('Please enter a number'));
			return;
		}

		const type = amount > 0 ? 'IN' : 'OUT';

		if( this.state.type !== type ) {
			this.changedCategory = false;
			document.getElementById('type').value = type;
			this.setState({ type: type });
		}

		if( this.state.amount !== amount ) {
			this.setState({ amount });
		}
	}






	invoicesList = type => {
		const transaction = this.props.transaction;
		const invoices = this.props.invoices.filter(inv => {
			const isOpen = transaction.document_id === inv.id || inv.status !== 'CLOSED';
			const isAmountSameOrBigger = parseFloat(inv.amount_with_taxes.toFixed(2)) * 100 >= parseFloat(this.state.amount.toFixed(2)) * 100;
			return inv.type === type && isOpen && isAmountSameOrBigger;
		});

		const list = [];

		invoices.forEach(inv => {
			list.push({
				label: inv.iid,
				value: inv.id
			});
		});

		return list;
	}



	expensesList = type => {
		const transaction = this.props.transaction;

		const expenses = this.props.expenses.filter(exp => {
			const isOpen = transaction.document_id === exp.id || exp.status !== 'CLOSED';
			const isSameAmount = (exp.amount_with_taxes * -1).toString() === this.state.amount.toString();
			return exp.type === type && isSameAmount && isOpen;
		});
		const list = [];

		expenses.forEach(exp => {
			list.push({
				label: exp.label,
				value: exp.id
			});
		});

		return list;
	}





	renderCategories = () => {
		const { categories } = this.props;
		const { type, category } = this.state;

		if(! type)
			return null;

		const options = Object
			.keys(categories[type])
			.map( key => {
				return {
					label: categories[type][key],
					value: key
				}
			});

		if( options.length === 1 )
			return <input type="hidden" name="category-selector" value={ options[0] } readOnly />

		return (
			<div className="input select">
				<label htmlFor="category-selector">{ I18n.t('Category') }</label>
				<SelectBox name="category" id="category-selector" value={ category === null ? '' : category } options={ options } onChange={ this.changeCategory } />
				{ this.callChangeCategory() }
			</div>
		);
	}



	hasDocument = category => {
		return transactionsTypesWithDoc.indexOf(category) > -1;
	}




	renderDocumentSelect = () => {

		//const { transaction } = this.props;
		const { category, type, document_id } = this.state;

		if( category === TransactionCategories.REFERENCED_TRANSACTION ) {
			let references = this.getReferencedTransactions();
			if( references.length === 0 ) {
				return '';
			}
			return (
				<div className="input select">
					<label htmlFor={`transaction-${transaction.id}-referenced-transaction`}>{ I18n.t('Ref.') }</label>
					<SelectBox name="referenced_transaction_id" id={`transaction-${transaction.id}-referenced-transaction`} value={ this.state.referenced_transaction_id || '' } options={ references } onChange={ this.changeReferencedTransaction } />
				</div>
			);
		}

		if( this.hasDocument(category) ) {
			let documentType = category;
			if( category === 'OUTCOME' || category === 'INCOME') {
				documentType = 'INVOICE';
			}

			let documents =
				(type === 'IN' && category !== 'CREDIT_NOTE') || (type === 'OUT' && category === 'CREDIT_NOTE') ?
				this.invoicesList(documentType) :
				this.expensesList(documentType);

			if(! documents.length) {
				documents = [{ value: '', label: I18n.t('No document matches') }];
			}

			return (
				<div className="input select">
					<label htmlFor="transaction-document">{ I18n.t('Document') }</label>
					<SelectBox
						name="document_id"
						id="transaction-document"
						value={document_id || ''}
						options={ documents }
						onChange={ this.changeDocument }
					/>
					{ this.callChangeDocument() }
				</div>
			);
		}

		return '';
	}




	getReferencedTransactions = () => {
		const transaction = this.state;
		if( this.props.referenced_transactions.length === 0 ) {
			return [];
		}
		const types =
			transaction.type === TransactionTypes.IN ?
			[TransactionCategories.INCOME, TransactionCategories.INVEST] :
			[TransactionCategories.OUTCOME, TransactionCategories.TAX];

		const references = this.props.referenced_transactions.filter(ref => {
			// if amount is provided in transaction, reduce to
			const amount = transaction.amount < 0 ? transaction.amount * -1 : transaction.amount;
			const isAmount = ref.amount ? amount === ref.amount : true;
			return types.indexOf(ref.type) > -1 && isAmount;
		});

		if( references.length === 0 ) {
			return [];
		}

		const list = [];
		references.forEach(ref => {
			list.push({
				label: ref.name,
				value: ref.id
			});
		});

		return list;
	}



	useManualAccountForm = event => {
		event.preventDefault();
		this.setState({ manualAccount: true });
	}

	handleChangeOwnBankAccount = event => {
		const id = parseInt(event.target.value);
		const account = this.props.bankAccounts.find(a => id === a.id);
		if( account ) {
			this.setState({
				bankaccount_id: account.id,
				bankaccount_name: account.account_name,
				bankaccount_iban: account.iban,
				bankaccount_bic: account.bic,
			});
		}
	}

	renderTransactionAccountForm = () => {
		const { transaction } = this.props;
		const { category, type } = this.state;
		if(! type || ! category) {
			return null;
		}

		const client = this.state.client_id ? this.props.clients.find(c => c.id === this.state.client_id) : false;

		if( category === TransactionCategories.OWN_TRANSFER ) {

			let ownAccounts = [{ value: null, label: I18n.t('Choose') }];
			this.props.bankAccounts.forEach(account => {
				ownAccounts.push({
					label: account.formatted_iban,
					value: account.id
				});
			});

			return (
				<fieldset id="transaction-account">
					<div className="input text">
						<label htmlFor="label">{ I18n.t('Paid by') }</label>
						<SelectBox
							options={ ownAccounts }
							name="client_bank_account_id"
							value={ this.state.bankaccount_id }
							onChange={ this.handleChangeOwnBankAccount }
							required="required"
						/>
					</div>

					<input type="hidden" name="bankaccount_name" value={ this.state.bankaccount_name } />
					<input type="hidden" name="bankaccount_iban" value={ this.state.bankaccount_iban } />
					<input type="hidden" name="bankaccount_bic" value={ this.state.bankaccount_bic } />
				</fieldset>
			);
		}

		if( client !== false && this.state.manualAccount === false ) {

			const listAccounts = [];
			client.bank_accounts.forEach(account => {
				listAccounts.push({
					label: account.formatted_iban,
					value: account.id
				});
			});

			return (
				<fieldset id="transaction-account">
					<div className="input text">
						<label htmlFor="label">{ I18n.t('Existing account') }</label>
						<SelectBox options={ listAccounts } name="client_bank_account_id" />

						<button className="link-like" onClick={ this.useManualAccountForm }>{ I18n.t('Manual input')}</button>
					</div>
				</fieldset>
			);
		}


		return (
			<fieldset id="transaction-account">
				<div className="input text">
					<label htmlFor="bankaccount-name">{ I18n.t('Bank Account') }</label>
					<input type="text" name="bankaccount_name" maxLength="40" id="bankaccount-name" defaultValue={transaction.bankaccount_name} />
				</div>

				<div className="input text">
					<label htmlFor="bankaccount-iban">{ I18n.t('IBAN') }</label>
					<input type="text" name="bankaccount_iban" maxLength="40" id="bankaccount-iban" defaultValue={transaction.bankaccount_iban} />
				</div>

				<div className="input text">
					<label htmlFor="bankaccount-bic">{ I18n.t('BIC') }</label>
					<input type="text" name="bankaccount_bic" maxLength="40" id="bankaccount-bic" defaultValue={transaction.bankaccount_bic} />
				</div>
			</fieldset>
		);
	}


	renderTransactionDetailsForm = () => {
		const { transaction } = this.props;
		const { category, type } = this.state;
		if(! type || ! category)
			return null;

		return (
			<fieldset id="transaction-details">
				<div className="input text">
					<label htmlFor="details">{ I18n.t('Message') }</label>
					<textarea name="message" id="details" defaultValue={transaction.message} />
				</div>

				<div className="input text">
					<label htmlFor="structured-message">{ I18n.t('Structured message') }</label>
					<input type="text" name="structured_message" maxLength="40" id="structured-message" defaultValue={transaction.structured_message || ''} />
				</div>
			</fieldset>
		)
	}


	handleBankAccountChange = event => {
		this.setState({
			account_id: parseInt(event.target.value, 10),
		});
	}


	renderSubmit = () => {
		if( ! this.isValid() )
			return null;

		if( this.props.isSaving )
			return <button type="submit" className="is-saving">{ I18n.t('Saving...') }</button>

		return <button type="submit">{ I18n.t('Save') }</button>
	}


	render() {
		const { transaction, settings, bankAccounts } = this.props;

		let companyAccountSelect = '';
		if(! transaction.bank_account_id ) {
			const companyBankAccounts = bankAccounts.map( a => {
				return {
					value: a.id,
					label: `${a.bank} ${a.formatted_iban}`
				}
			});

			companyAccountSelect = (
				<div className="input text">
					<label htmlFor="bank-account-id">{ I18n.t('Paid on') }</label>
					<SelectBox
						id="bank-account-id"
						options={ companyBankAccounts }
						value={ this.state.account_id }
						onChange={ this.handleBankAccountChange }
						name="bank_account_id"
					/>
				</div>
			);
		}
		else {
			const companyBankAccount = bankAccounts.find(a => parseInt(a.id, 10) === parseInt(transaction.bank_account_id, 10));

			companyAccountSelect = (
				<div className="input text">
					<span className="label-like">{ I18n.t('Paid on') }</span>
					<span className="input-like">{ `${companyBankAccount.bank} ${companyBankAccount.formatted_iban}` }</span>
				</div>
			);
		}

		return (
			<div className="transaction form">
				<form method="post" acceptCharset="utf-8" id="transaction-edit" onSubmit={this.save}>
					<fieldset>
						<div className="input date required">
							<label>{ I18n.t('Date') }</label>
							<DateSelect name="date_memo" defaultValue={transaction.date_memo} />
						</div>

						<div className="input number">
							<label htmlFor="amount">{ I18n.t('Amount') }</label>
							<input
								type="number"
								className="small"
								name="amount"
								step="any"
								id="transaction-amount"
								style={{ width: '120px' }}
								defaultValue={transaction.amount}
								onBlur={ this.changeAmount }
								required
							/>
							{ settings.currency_info.iso }
						</div>

						{ companyAccountSelect }

						{ this.renderCategories() }

						{ this.renderDocumentSelect() }
					</fieldset>

					{ this.renderTransactionAccountForm() }

					{ this.renderTransactionDetailsForm() }

					<div className="form-controls">
						<input type="hidden" name="id" value={transaction.id} readOnly />
						<input type="hidden" name="client_id" id="transaction-client-id" value={this.state.client_id || ''} readOnly />
						<input type="hidden" name="type" id="type" value={this.state.type || ''} readOnly />
						{ this.renderSubmit() }
					</div>
				</form>
			</div>
		);
	}
};

EditTransaction.propTypes = {
	bankAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
	transaction: PropTypes.object.isRequired,
	categories: PropTypes.object.isRequired,
	expenses: PropTypes.arrayOf(PropTypes.object).isRequired,
	invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
	clients: PropTypes.arrayOf(PropTypes.object).isRequired,
	settings: PropTypes.object.isRequired,
	lastAction: PropTypes.object.isRequired,
	isSaving: PropTypes.bool.isRequired
};



const mapStateToProps = (state, ownProps) => {
	const {
		company,
		transactions,
		transaction_categories,
		expenses,
		invoices,
		clients,
		lastAction,
		companySettings,
		isSaving,
		referenced_transactions
	} = state.data || { 
		company: {},
		transactions: [],
		clients: [],
		expenses: [],
		invoice: [],
		transaction_categories: {},
		lastAction: {},
		companySettings: {},
		isSaving: false,
		referenced_transactions: [],
	};
	const transaction = ownProps.transaction.id ? transactions.find(tr => tr.id === ownProps.transaction.id ) : ownProps.transaction;
	const bankAccounts = company.bank_accounts || [];
	return {
		bankAccounts,
		transaction,
		categories: transaction_categories,
		referenced_transactions,
		expenses,
		invoices,
		clients,
		settings: companySettings,
		lastAction,
		isSaving,
	};
}


const mapDispatchToProps = (dispatch) => {
	return {
		onSave: (transaction) => {
			dispatch(doSaveTransaction(transaction));
		},

		doClose: () => {
			dispatch(closeOverlay());
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTransaction);
