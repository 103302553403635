import {
	LANGUAGE_PACK_GET,
	LANGUAGE_PACK_GOT,
} from '../AppEvents';

const initialState = {
	isFetching: false,
	didInvalidate: true,
	lastUpdated: null,
	locale:null,
	messages: []
};



const LocaleReducer = (state = initialState, action) => {
	let newState;

	switch (action.type) {

		case LANGUAGE_PACK_GET:
			newState = Object.assign({}, state, { isFetching: true });
			return newState;

		case LANGUAGE_PACK_GOT:
			newState = Object.assign({}, state, {
				isFetching: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				locale: action.locale,
				messages: action.messages

			});
			return newState;


		default:
			return state;
	}
}

export default LocaleReducer;
