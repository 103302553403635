import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Base from './containers/Base';
import getStore from '../ExpressoStore';


const store = getStore();

export default class Expresso extends Component {
  render() {
    return (
      <Provider store={store}>
        <Base />
      </Provider>
    )
  }
}
