import { signIn, signOut, getData, synchronizeData, saveUser, deleteUser, isLoggedIn } from '../../api';
import {
	USER_SIGN_IN,
	USER_SIGNED_IN,
	USER_IS_LOGGED_IN,
	USER_IS_KNOW,
	USER_AUTH_ERROR,
	USER_SIGN_OUT,
	USER_SIGNED_OUT,
	USER_DO_SAVE,
	USER_SAVED,
	USER_DO_DELETE,
	USER_DELETED,
	USER_GET_DATA,
	USER_GOT_DATA,
	USER_DO_SYNC,
	USER_SYNC_DATA,
	PERIOD_CHANGED
} from '../../AppEvents';


/*
 * Login user
 */
function user_sign_in(data) {
	return {
		type: USER_SIGN_IN,
		data
	}
}

function user_signed_in(response) {
	if( parseInt(response.status, 10) === 401 ) {
		return {
			type: USER_AUTH_ERROR,
			receivedAt: Date.now()
		}
	}

	return {
		type: USER_SIGNED_IN,
		user: response.user,
		receivedAt: Date.now()
	}
}

export function doLogin(data) {
	return dispatch => {
		dispatch(user_sign_in(data));
		return signIn(data).then(json => dispatch(user_signed_in(json)));
	}
}



/*
 * Logout user
 */
function user_sign_out() {
	return {
		type: USER_SIGN_OUT,
	}
}

function user_signed_out(response) {
	return {
		type: USER_SIGNED_OUT,
	}
}

export function doLogout() {
	return dispatch => {
		dispatch(user_sign_out());
		return signOut().then(json => dispatch(user_signed_out(json)));
	}
}





/*
 * Test user
 */
function user_is_logged_in() {
	return {
		type: USER_IS_LOGGED_IN,
	}
}

function user_logged_in_status(response) {
	if( ! response.has_session ) {
		return {
			type: USER_AUTH_ERROR,
			receivedAt: Date.now()
		}
	}

	return {
		type: USER_IS_KNOW,
		receivedAt: Date.now()
	}
}

export function isSessionActive() {
	return dispatch => {
		dispatch(user_is_logged_in());
		return isLoggedIn().then(json => dispatch(user_logged_in_status(json)));
	}
}




/*
 * Saving user
 */
function user_save() {
	return {
		type: USER_DO_SAVE
	}
}

function user_saved(response) {
	const r = {
		type: USER_SAVED,
		code:parseInt(response.status, 10),
		user: response.user,
		receivedAt: Date.now()
	}

	r.message = r.status === 200 ? 'User was saved' : response.message;

	return r;
}


/*
 * Delete a user
 */
function user_delete(user) {
	return {
		type: USER_DO_DELETE,
		user
	}
}


function user_deleted(response) {
	const r = {
		type: USER_DELETED,
		id: parseInt(response.id, 10),
		code:parseInt(response.status, 10),
		receivedAt: Date.now()
	}

	r.message = r.status === 200 ? 'User was deleted' : response.message;
	return r;
}




/*
 * Getting data
 */
function user_get_data(year) {
	return {
		type: USER_GET_DATA,
		year: year
	}
}

function user_got_data(response) {
	if(! response ) {
		return { type: USER_AUTH_ERROR };
	}

	return {
		type: USER_GOT_DATA,
		code:parseInt(response.status, 10),
		data: response,
		receivedAt: Date.now()
	}
}

function doFetchData(year) {
	return (dispatch, getState) => {
		//	const state = getState();
		dispatch(user_get_data(year));
		return getData(year).then(json => dispatch(user_got_data(json)));
	}
}





/*
 * Sync data
 */
function user_get_sync(year, event_id) {
	return {
		type: USER_DO_SYNC,
		year: year,
		event_id: event_id
	}
}

function user_sync_data(response) {
	if( response.status === 401 ) {
		return {
			type: USER_AUTH_ERROR,
			receivedAt: Date.now()
		}
	}

	return {
		type: USER_SYNC_DATA,
		code:parseInt(response.status, 10),
		data: response,
		receivedAt: Date.now()
	}
}

function doSyncData(year, event_id) {
	return (dispatch, getState) => {
		//	const state = getState();
		dispatch(user_get_sync(year, event_id));
		return synchronizeData(event_id, year).then(json => dispatch(user_sync_data(json)));
	}
}




function period_change(period) {
	return {
		type: PERIOD_CHANGED,
		period: period
	}
}


export function doChangePeriod(period) {
	// update global state
	// then refetch data

	return (dispatch, getState) => {
		const state = getState();
		const oldPeriodYear = parseInt(state.data.activePeriod.slice(0,4), 10);
		dispatch(period_change(period));

		const year = parseInt(period.slice(0,4), 10);

		/*
		 * At the first time i thinked to append other years data to the existing
		 * ones but there will be a very large state...
		 * /
		if( state.data.loadedYears.indexOf(year) === -1 ) {
			return dispatch( doFetchData(year) );
		}
		*/

		if( oldPeriodYear !== year )
			return dispatch( doFetchData(year) );

	};

}



export function doSaveUser(user) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(user_save(user));
			return saveUser(user).then(json => dispatch(user_saved(json)));
		});
	}
}


export function doDeleteUser(user) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(user_delete(user));
			return deleteUser(user).then(json => dispatch(user_deleted(json)));
		});
	}
}



export function fetchData() {
	return (dispatch, getState) => {
		const state = getState();
		if((! state.data || state.data.didInvalidate === true) && ! state.data.isFetching ) {
			// Dispatch a thunk from thunk!
			const year = parseInt(state.data.activePeriod.slice(0,4), 10);
			return dispatch(doFetchData(year));
		}
		else {
			// Let the calling code know there's nothing to wait for.
			return Promise.resolve()
		}
	}
}



export function syncData() {
	return (dispatch, getState) => {
		const state = getState();
		// Only sync for multiusers app
		if(state.data.users.length > 1 && ! state.data.isFetching ) {
			const year = parseInt(state.data.activePeriod.slice(0,4), 10);
			const eventId = parseInt(state.data.last_event_id, 10);
			return dispatch(doSyncData(year, eventId));
		}
		else {
			// Let the calling code know there's nothing to wait for.
			return Promise.resolve()
		}
	}
}
