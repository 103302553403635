import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataList from '../../../components/containers/DataList';
import getVisibleTransactions from '../filters/VisibleTransactions';
import Transaction from './Transaction';
import I18n from '../../../locales/I18n';

class TransactionsList extends React.Component {
	render() {
		const { transactions, isFetching } = this.props;
		if( isFetching )
			return (<div className="message-empty">{ I18n.t('Please wait while loading...') }</div>)

		return (
            <DataList
                data={ transactions }
                keyExtractor={ (item) => item.id }
                renderItem={ ({ item }) => <Transaction id={ item.id } transaction={ item } /> }
            />
		);
	};
}

TransactionsList.propTypes = {
	transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
	isFetching: PropTypes.bool.isRequired,
	client: PropTypes.number,
}



const mapStateToProps = (state, ownProps) => {
	const { isFetching, user } = state.data || { isFetching: true, user: null }

	let transactions = getVisibleTransactions(state.data, ownProps.bankAccount, ownProps.client || null);
	return {
		user,
		transactions,
		isFetching,
	}
}

export default connect(mapStateToProps)(TransactionsList);
