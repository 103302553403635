import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Currency from '../../../components/common/Currency';
import I18n from '../../../locales/I18n';

class TransactionsTotalAmount extends React.Component {

	render() {
		return (
			<div className="section-total-amount">
				<div className="label">{ I18n.t('Balance') }:</div>
				<div className="amount taxexcl">
					<Currency amount={ this.props.balance } />
				</div>
			</div>
		)
	};
};

TransactionsTotalAmount.propTypes = {
	balance: PropTypes.number.isRequired
};


const mapStateToProps = (state, ownProps) => {
	const { company } = state.data || { company: {} };

	const accounts =
		ownProps.bankAccount ?
		company.bank_accounts.filter(a => a.id === ownProps.bankAccount) :
		company.bank_accounts;

	let balance = 0;
	accounts.forEach(a => {
		const b = a.status ? parseFloat(a.status.balance) : 0;
		balance += b;
	});

	return { balance }
};

export default connect(mapStateToProps)(TransactionsTotalAmount);
