import escapeStringForRegex from '../../../libs/EscapeStringForRegex';

const matchTerms = (terms, transaction, data) => {
	if(terms === false)
		return true; // no current search

	const regExp = new RegExp('\\W'+ escapeStringForRegex(terms), 'gi');
	const client = data.clients.find(c => parseInt(c.id, 10) === parseInt(transaction.client_id, 10));
	const amount = transaction.amount > 0 ? transaction.amount : transaction.amount * -1;
	const searchInTerms = [' ', transaction.structured_message, transaction.message, transaction.category, transaction.bankaccount_name, transaction.bankaccount_iban, amount];
	if(client)
		searchInTerms.push(client.name, client.short_name);

	if( transaction.category === 'INCOME' ) {
		const invoice = data.invoices.find(i => parseInt(i.id, 10) === parseInt(transaction.document_id, 10) && i.type === 'INVOICE' );
		if( invoice ) {
			searchInTerms.push(invoice.iid);
			invoice.items.forEach(item => {
				searchInTerms.push(item.label, item.details);
			});
		}
	}

	if( transaction.category === 'OUTCOME' ) {
		const expense = data.expenses.find(e => parseInt(e.id, 10) === parseInt(transaction.document_id, 10) && e.type === 'INVOICE' );
		if( expense )
			searchInTerms.push(expense.label, expense.details);
	}

	searchInTerms.push(' ');

	return searchInTerms.join(' ').match(regExp) !== null;
}



const getVisibleTransactions = (state, bankAccount = null, client = null) => {
	if( state.transactions.length === 0 ) {
		return [];
	}
	return state.transactions.filter(t => {
		const isClient = client ? parseInt(client.id, 10) === parseInt(t.client_id, 10) : true;
		const isAccount = bankAccount ? bankAccount === parseInt(t.bank_account_id, 10) : true;
		return t.validated && isClient && isAccount && matchTerms(state.searchTerms, t, state);
	});
};


export default getVisibleTransactions;
