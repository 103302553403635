import {
	USER_SIGN_IN,
	USER_SIGNED_IN,
	USER_AUTH_ERROR,
	USER_SIGN_OUT,
	USER_SIGNED_OUT,
	USER_SAVED,
} from '../AppEvents';

import Cookie from '../libs/Cookie';
import config from '../config';

/*
 * Note --
 * Reducer function take initial state as its first parameter
 */

const initialState = {
	isFetching: false,
	didInvalidate: true,
	lastUpdated: null,
	authError: false,
	user: JSON.parse(sessionStorage.getItem('expressousr'))
};


function UserReducer(state = initialState, action) {
	let newState;

	switch (action.type) {
		case USER_SIGN_IN:
			newState = Object.assign({}, state, { isFetching: true });
			return newState;

		case USER_SIGNED_IN:
			newState = Object.assign({}, state, {
				isFetching: false,
				didInvalidate: false,
				user: action.user,
				authError: false,
				lastUpdated: action.receivedAt
			});

			sessionStorage.setItem('expressousr', JSON.stringify(action.user));
			return newState;


		case USER_SIGN_OUT:
			newState = Object.assign({}, state, {
				isFetching: true,
				didInvalidate: false,
			});
			return newState;


		case USER_SIGNED_OUT:
			newState = Object.assign({}, state, {
				isFetching: false,
				didInvalidate: true,
				lastUpdated: action.receivedAt,
				authError: false,
				user: {}
			});

			Cookie.remove(config.cookieName);
			sessionStorage.removeItem('expressousr');
			const baseUrl = document.location.href.split('?')[0];
			document.location.href = baseUrl+'?loggedout=true'; // make an hard redirect in order to empty state
			return newState;

		case USER_AUTH_ERROR:
			//console.log('USER_AUTH_ERROR');
			newState = Object.assign({}, state, { lastUpdated: action.receivedAt, authError: true, user: {} });

			Cookie.remove(config.cookieName);
			sessionStorage.removeItem('expressousr');
			return newState;


		case USER_SAVED:
			if( action.status !== 200 || parseInt(action.user.id, 10) !== parseInt(state.user.id, 10) )
				return state;

			newState = Object.assign({}, state, {
				lastUpdated: action.receivedAt,
				authError: false,
				user: action.user
			});

			return newState;

		default:
			return state
	}
}



export default UserReducer;
