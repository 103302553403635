import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from './Header';
import Company from '../../modules/clients/components/Company';
import { UserRole, userRoleIs } from '../../modules/users/UsersConsts';

class CompanyPane extends React.Component {

	render() {
		const { user } = this.props;
		if(! userRoleIs(user, [UserRole.MANAGER]))
			return null;

		return (
			<Fragment>
				<Header />
				<Route exact path="/company" component={Company} />
			</Fragment>
		)
	}
}

CompanyPane.propTypes = {
	user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
	const { user } = state.data;
	return { user }
}

const mapDispatchToProps = (dispatch) => {
	return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPane);
