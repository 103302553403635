import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataList from '../../../components/containers/DataList';

import Job from './Job';
import getVisibleJobs from '../filters/VisibleJobs';
import { JobStatus } from '../JobsConsts';
import I18n from '../../../locales/I18n';
import EditJob from './EditJob';
import { openOverlay } from '../../../UIActions';
import { doInvoiceJob } from '../JobsActions';


class JobsList extends React.Component {

	constructor(props) {
		super(props);
		this.state = { selected: [] };
	}

	createJob = e => {
		e.preventDefault();
		this.props.createJobForm({ client_id: this.props.client });
	}


	invoiceSelection = () => {
		// selection is in the state
		this.props.createInvoice(this.state.selected);
	}

	invoiceCompleted = () => {
		// build array with completed id
		const selection = this.props.jobs
							.filter(job => { return job.status === 'CLOSED' })
							.map(job => job.id);

		if(! selection.length ) {
			alert( I18n.t("Sorry, there's nothing to invoice"));
			return;
		}

		this.props.createInvoice(selection);
	}


	onJobSelect = input => {
		const selected = this.state.selected;
		const val = input.value;
		const index = selected.findIndex( key => parseInt(val, 10) === key );

		if( input.checked && index === -1 )
			selected.push(parseInt(val, 10));

		else if( index > -1 && ! input.checked )
			selected.splice(index, 1);

		this.setState({ selected: selected });
	}


	componentDidUpdate = (prevProps) => {
        if( prevProps !== this.props ) {
		    this.setState({ selected: [] });
        }
	}

	render() {
        const _this = this;
		const { jobs, isFetching } = this.props;
		let dataListOptions = '';

		if( isFetching )
			return <div className="message-empty">{ I18n.t('Please wait while loading...') }</div>



		if( jobs.length === 0 )
			return (
				<div className="message-empty">{ I18n.t('No document found here.') } <button className="link-like" onClick={this.createJob}>{ I18n.t('Create new ?') }</button></div>
			);

		if(this.props.client) {
			// show add link

			// has closed jobs ?
			const closedJobs = jobs.filter(j => j.status === JobStatus.CLOSED);
			let invoiceLink = '';

			if( closedJobs.length > 0 ) {
				invoiceLink = this.state.selected.length ?
								<button className="link-like" onClick={ this.invoiceSelection }>{ I18n.t('Invoice selection') }</button> :
								<button className="link-like" onClick={ this.invoiceCompleted }>{ I18n.t('Invoice completed') }</button>;
			}

			dataListOptions = (
				<div className="data-list-options">
					<button className="link-like" onClick={this.createJob}>{ I18n.t('Create new ?') }</button>
					{ invoiceLink }
				</div>
			);
		}

        const renderItem = ({ item }) => {
            return (
                <Job
                    id={ item.id }
                    job={ item }
                    isChildJob={ false }
                    onSelect={ _this.onJobSelect }
                    selected={ _this.state.selected.find(j => j === item.id) > -1 }
                />
            );
        }

		return (
			<div>
				{ dataListOptions }

                <DataList
                    data={ jobs }
                    keyExtractor={ (item) => item.id }
                    renderItem={ renderItem }
                />
			</div>
		);
	};
};

JobsList.propTypes = {
	jobs: PropTypes.arrayOf(PropTypes.object).isRequired,
	isFetching: PropTypes.bool.isRequired,
	type: PropTypes.string,
	client: PropTypes.number
};



const mapStateToProps = (state, ownProps) => {
	const { data } = state;
	const { isFetching, user } = data || { isFetching: true, user: null }

	const type = ownProps.type || null;
	const client = ownProps.client || null;

	return {
		user,
		jobs: getVisibleJobs(data, type, client, data.searchTerms),
		isFetching
	}
}


const mapDispatchToProps = (dispatch) => {
	return {
		createJobForm: (job) => {
			const title = I18n.t('Create job');
			const module = <EditJob job={job} />;
			dispatch(openOverlay(title, module))
		},

		createInvoice: (jobsIds) => {
			dispatch(doInvoiceJob(jobsIds));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsList);
