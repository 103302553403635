import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CheckBox from '../../../components/forms/CheckBox';
import I18n from '../../../locales/I18n';
import { doCreateJobFromQuote } from '../InvoicesActions';
import { closeOverlay, removeOverlayBodyClass } from '../../../UIActions';



class ConvertQuote extends React.Component {

	constructor(props) {
		super(props);

		const selection = [];
		this.props.quote.items.forEach(item => {
			selection.push(item.id);
		});
		this.state = { selection };
	}



	componentWillReceiveProps(nextProps) {
		const { lastAction } = nextProps;

		if( lastAction.type === 'JOB_FROM_QUOTE_CREATED' && lastAction.success ) {
			removeOverlayBodyClass();

			const timer = setTimeout(() => {
				this.props.doClose();
				clearTimeout(timer);
			}, 500);
		}
	}


	onCheck = input => {
		//e.preventDefault();

		let { selection } = this.state;
		const value = parseInt(input.value, 10);

		if( input.checked ) {
			selection.push(value);
		}
		else {
			selection = selection.filter(id => id !== value );
		}
		this.setState({ selection });
	}



	save = e => {
		e.preventDefault();

		if( this.props.isSaving )
			return;

		const id = document.getElementById('quote-id').value;
		const quoteData = new FormData( document.getElementById('quote-convert') );
		this.props.onSave(id, quoteData);

		//then close overlay and redirect to jobs pane (if success !!!)
	}



	renderItem = (i, item) => {
		const checked = this.state.selection.includes(item.id);
		return (
			<div className="input checkbox" key={ `quote-item-${item.id}-${new Date().getTime() }` }>
				<CheckBox
					label={ item.label }
					name="items[]"
					value={ item.id }
					hiddenField={ false }
					id={`quote-item-${item.id}`}
					checked={ checked }
					onChange={ this.onCheck }
				/>
			</div>
		);
	}



	renderSubmit = () => {
		if( this.props.isSaving )
			return <button type="submit" className="is-saving">{ I18n.t('Saving...') }</button>

		return <button type="submit">{ I18n.t('Save') }</button>
	}




	render() {
		const quote = this.props.quote;
		if( ! quote.items || quote.items.length === 0 )
			return null;


		let itemsForm = [];
		quote.items.forEach((item, i) => {
			itemsForm.push( this.renderItem(i, item) );
		});


		return (
			<div className="invoices form">
				<form method="post" acceptCharset="utf-8" id="quote-convert" onSubmit={this.save}>
					<fieldset>
						{ itemsForm }
					</fieldset>

					<div className="form-controls">
						<input type="hidden" name="id" id="quote-id" value={quote.id} readOnly />
						{ this.renderSubmit() }
					</div>
				</form>
			</div>
		);
	}
};

ConvertQuote.propTypes = {
	tax_rates: PropTypes.arrayOf(PropTypes.object).isRequired,
	quote: PropTypes.object,
	lastAction: PropTypes.object.isRequired,
	isSaving: PropTypes.bool.isRequired
};



const mapStateToProps = (state, ownProps) => {
	const { tax_rates, lastAction, isSaving } = state.data || { tax_rates: [], lastAction: {}, isSaving: false };
	return {
		tax_rates,
		lastAction,
		isSaving
	};
}


const mapDispatchToProps = (dispatch) => {
	return {
		onSave: (id, data) => {
			dispatch(doCreateJobFromQuote(id, data))
		},

		doClose: () => {
			dispatch(closeOverlay());
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ConvertQuote);
