import GlyphIcon from './Glyphicon';


const FabButton = ({ onClick = null, icon = 'plus', type = 'button', className = '', ...attrs }) => {

	const _onClick = event => {
		onClick && onClick(event);
	}

	const _className = ['btn-fab'];
	if( className ) {
		_className.push(className);
	}

	return (
		<button
			className={ _className.join(' ') }
			onClick={ _onClick }
			type={ type }
			{ ...attrs }
		>
			<GlyphIcon icon={ icon } />
		</button>
	);
}

export default FabButton;
