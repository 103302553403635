import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getVisibleExpenses from '../filters/VisibleExpenses';
import Currency from '../../../components/common/Currency';
import I18n from '../../../locales/I18n';



class ExpensesTotalAmount extends React.Component {

	constructor(props) {
		super(props);
		this.state = { mode: 'VATEXCL' }
	}

	switch = event => {
		event.preventDefault();
		let state = this.state;
		state.mode = state.mode === 'VATEXCL' ? 'VATINCL' : 'VATEXCL';
		this.setState(state);
	}


	render() {
		const amount = this.state.mode === 'VATEXCL' ? this.props.amount : this.props.amountWithTaxes;
		const vatStatus = this.state.mode === 'VATEXCL' ? I18n.t('Excl. VAT') : I18n.t('Inc. VAT');

		if( this.props.amount === 0 )
			return null;

		return (
			<div className="section-total-amount can-switch" onClick={this.switch}>
				<div className="label">{ I18n.t('Total') } <span className="vat-info">{ vatStatus }</span></div>
				<div className="amount">
					<Currency amount={amount} mode={this.state.mode} />
				</div>
			</div>
		)
	};
};

ExpensesTotalAmount.propTypes = {
	type: PropTypes.string.isRequired,
	amount: PropTypes.number.isRequired,
	amountWithTaxes: PropTypes.number.isRequired,
	period: PropTypes.array.isRequired,
};


const mapStateToProps = (state, ownProps) => {
	const { data } = state;
	const { isFetching, transactions } = data || { isFetching: true, transactions: [] }

	const expenses = getVisibleExpenses(data, ownProps.type, ownProps.period, ownProps.view, null);

	let amount = 0;
	let amountWithTaxes = 0;

	if(! isFetching ) {
		Object(expenses).forEach(invoice => {
			/*
			 * v0.6.5
			 * Find existing transactions assigned to this document
			 */
			let invoiceTransationAmount = 0;
			if( invoice.status === 'OPEN' ) {
				const invoiceTransactions = transactions.filter(t => parseInt(t.document_id, 10) === parseInt(invoice.id, 10) && t.type === 'OUT' && (t.category === 'INCOME' || t.category === 'FEE'));
				if( invoiceTransactions.length >  0 )
					invoiceTransactions.forEach(t => { invoiceTransationAmount += parseFloat(t.amount) });
			}

			amount += parseFloat(invoice.amount) - invoiceTransationAmount;
			amountWithTaxes += parseFloat(invoice.amount_with_taxes) - invoiceTransationAmount;
		});
	}

	return {
		amount,
		amountWithTaxes
	}
}

export default connect(mapStateToProps)(ExpensesTotalAmount)
