import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditBankAccount from './EditBankAccount';
import I18n from '../../../locales/I18n';
import Glyphicon from '../../../components/common/Glyphicon';
import { doDeleteBankAccount, doMakeDefaultAccount } from '../BankAccountsActions';
import { openOverlay } from '../../../UIActions';


class BankAccount extends React.Component {

	setDefault = event => {
		event.preventDefault();
		const id = parseInt(event.target.getAttribute('data-account-id'), 10);
		this.props.onSetDefault(id);
	}


	editForm = event => {
		event.preventDefault();
		this.props.onEditForm(this.props.account);
	}


	delete = event => {
		event.preventDefault();
		if( window.confirm( I18n.t('Are you sure?')) ) {
			const id = parseInt(event.target.getAttribute('data-account-id'), 10);
			this.props.onDelete(id);
		}
	}

	renderMakeDefaultButton = () => {
		const { account, countAccounts } = this.props;

		if( countAccounts < 2 )
			return null;

		if( account.is_default )
			return <Glyphicon icon="star" className="circle-icon small green" tagName="a" />

		return <Glyphicon icon="star" className="circle-icon small red-hover" data-account-id={ account.id } onClick={this.setDefault} tagName="a" />
	}



	render() {
		const account = this.props.account;


		return (
			<fieldset>
				<div className="input text">
					<label htmlFor="account-name">{ I18n.t('Name') }</label>
					<div>{ account.account_name }</div>
				</div>


				<div className="input text">
					<label htmlFor="bank">{ I18n.t('Bank') }</label>
					<div>{ account.bank || 'NA' }</div>
				</div>

				<div className="input text">
					<label htmlFor="iban">{ I18n.t('IBAN') }</label>
					<div>{ account.formatted_iban }</div>
				</div>

				<div className="input text">
					<label htmlFor="bic">{ I18n.t('BIC') }</label>
					<div>{ account.bic }</div>
				</div>



				<div className="form-item-option">
					<Glyphicon icon="edit" className="circle-icon small red-hover" data-account-id={ account.id } onClick={this.editForm} tagName="a" />
					{ this.renderMakeDefaultButton(account) }
					<Glyphicon icon="remove" className="circle-icon small red-hover" data-account-id={ account.id } onClick={this.delete} tagName="a" />
				</div>
			</fieldset>
		)
	}
};

BankAccount.propTypes = {
	client: PropTypes.number
};



const mapStateToProps = (state, ownProps) => {
	return {}
}


const mapDispatchToProps = (dispatch) => {
	return {
		onSetDefault: (account_id) => {
			dispatch(doMakeDefaultAccount(account_id));
		},

		onDelete: (account_id) => {
			dispatch(doDeleteBankAccount(account_id));
		},

		onEditForm: (account) => {
			dispatch(openOverlay( I18n.t('Edit account'), <EditBankAccount account={ account } />));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccount);
