import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from './Header';
import Transactions from '../../modules/transactions/components/Transactions';
import { UserRole, userRoleIs } from '../../modules/users/UsersConsts';

class TransactionsPane extends React.Component {

	render() {
		const { user } = this.props;
		if(! userRoleIs(user, [UserRole.MANAGER, UserRole.ACCOUNTANT]))
			return null;

		return (
			<Fragment>
				<Header />
				<Route exact path="/transactions" component={ () => <Transactions /> } />
			</Fragment>
		)
	}
}


TransactionsPane.propTypes = {
	user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
	const { user } = state.data;
	return { user }
}

const mapDispatchToProps = (dispatch) => {
	return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPane);
