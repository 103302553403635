import I18n from '../../locales/I18n';

export const UserRole = {
	SU: 'SU',
	MANAGER: 'MANAGER',
	ACCOUNTANT: 'ACCOUNTANT',
	ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
	EMPLOYEE: 'EMPLOYEE',
};


export const userRoleIs = (user, role) => {
	if( user.user_role.label === 'SU' )
		return true;

	if( typeof(role) === 'string' )
		return user.user_role.label === role;

	return role.indexOf(user.user_role.label) > -1;
}


export const userRoleIsMin = (user, role) => {

}



export const getUserRoleLabel = role => {
	switch( role ) {
		case 'SU':
			return I18n.t('SuperMario');

		case 'MANAGER':
			return I18n.t('Admin/Manager');

		case 'ACCOUNTANT':
			return I18n.t('Accountant');

		case 'ACCOUNT_MANAGER':
			return I18n.t('Account Manager');

		case 'EMPLOYEE':
		default:
			return I18n.t('Employee');
	}
}
