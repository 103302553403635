import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import I18n from '../../locales/I18n';
import { UserRole, userRoleIs } from '../../modules/users/UsersConsts';
import { JobStatus } from '../../modules/jobs/JobsConsts';
import { ExpenseStatus, ExpenseType } from '../../modules/expenses/ExpensesConsts';
import { InvoiceStatus, InvoiceType } from '../../modules/invoices/InvoicesConsts';

class PrimaryNav extends React.Component {

	getJobsBadge = () => {
		if( ! this.props.jobs.length )
			return null;

		return (
			<span className="badge">{ this.props.jobs.length }</span>
		);
	}

	getInvoicesBadge = () => {
		if( ! this.props.invoices.length )
			return null;

		return (
			<span className="badge">{ this.props.invoices.length }</span>
		);
	}

	getExpensesBadge = () => {
		if( ! this.props.expenses.length )
			return null;

		return (
			<span className="badge">{ this.props.expenses.length }</span>
		);
	}

	getTransactionsBadge = () => {
		if( ! this.props.transactions.length )
			return null;

		return (
			<span className="badge">{ this.props.transactions.length }</span>
		);
	}


	closeResponsiveMenu = event => {
		document.body.classList.remove('show-sidebar');
	}


	renderNavItem = (which, allowedRoles) => {
		if(! userRoleIs(this.props.user, allowedRoles))
			return;

		switch(which) {
			case 'DASHBOARD':
				return <li><NavLink to="/" activeClassName="active" onClick={ this.closeResponsiveMenu } exact>{ I18n.t('Dashboard') }</NavLink></li>
			case 'JOBS':
				const url = userRoleIs(this.props.user, [UserRole.MANAGER, UserRole.ACCOUNTANT]) ? '/jobs' : '/';
				return <li><NavLink to={ url } activeClassName="active" onClick={ this.closeResponsiveMenu }>{ I18n.t('Jobs') } { this.getJobsBadge() }</NavLink></li>;
			case 'INCOMES':
				return <li><NavLink to="/incomes" activeClassName="active" onClick={ this.closeResponsiveMenu }>{ I18n.t('Incomes') } { this.getInvoicesBadge() }</NavLink></li>;
			case 'OUTCOMES':
				return <li><NavLink to="/outcomes" activeClassName="active" onClick={ this.closeResponsiveMenu }>{ I18n.t('Outcomes') } { this.getExpensesBadge() }</NavLink></li>;
			case 'TRANSACTIONS':
				return <li><NavLink to="/transactions" activeClassName="active" onClick={ this.closeResponsiveMenu }>{ I18n.t('Transactions') } { this.getTransactionsBadge() }</NavLink></li>;
			default:
				return;
		}
	}


	render() {
		return (
			<menu className="menu-segment">
				<ul>
					{ this.renderNavItem('DASHBOARD', [UserRole.MANAGER, UserRole.ACCOUNTANT]) }
					{ this.renderNavItem('JOBS', [UserRole.MANAGER, UserRole.ACCOUNTANT, UserRole.ACCOUNT_MANAGER, UserRole.EMPLOYEE]) }
					{ this.renderNavItem('INCOMES', [UserRole.MANAGER, UserRole.ACCOUNTANT, UserRole.ACCOUNT_MANAGER]) }
					{ this.renderNavItem('OUTCOMES', [UserRole.MANAGER, UserRole.ACCOUNTANT]) }
					{ this.renderNavItem('TRANSACTIONS', [UserRole.MANAGER, UserRole.ACCOUNTANT]) }
				</ul>
			</menu>
		)
	}
};

PrimaryNav.propTypes = {
	jobs: PropTypes.arrayOf(PropTypes.object).isRequired,
	invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
	expenses: PropTypes.arrayOf(PropTypes.object).isRequired,
	transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
	user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
	const { jobs, invoices, expenses, transactions, user } = state.data || { jobs: [], invoices: [], expenses: [], transactions: [], user: false };

	const activePeriod = state.data.activePeriod.toString();
	const year = activePeriod.slice(0, 4);
	const period = activePeriod.indexOf('|') > -1 ? activePeriod.split('|') : [`${year}-01-01`, `${year}-12-31`];

	const isManager = userRoleIs(user, [UserRole.MANAGER, UserRole.ACCOUNTANT]);

	return {
		user,
		jobs: isManager ? jobs.filter(j => j.status === JobStatus.CLOSED && j.is_recurrent === false ) : jobs.filter(j => j.status === JobStatus.OPEN && j.is_recurrent === false ),

		invoices: invoices.filter(i => {
			const date_memo = i.date_memo;
			const isPeriod = period ? date_memo >= period[0] && date_memo <= period[1] : true;
			return i.status === InvoiceStatus.OPEN && i.type === InvoiceType.INVOICE && isPeriod
		}),

		expenses: expenses.filter(e => {
			const date_memo = e.date_memo;
			const isPeriod = period ? date_memo >= period[0] && date_memo <= period[1] : true;
			return e.status === ExpenseStatus.OPEN && e.type === ExpenseType.INVOICE && isPeriod
		}),

		transactions: transactions.filter(t => t.validated === false),
	};
}



export default connect(mapStateToProps)(PrimaryNav);
