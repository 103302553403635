import React from 'react';
import PropTypes from 'prop-types';
import Glyphicon from './Glyphicon';

export default class ContextMenu extends React.Component {

	constructor(props) {
		super(props);
		this.activeClassName = 'context-menu-shown';
		this.setActions(props);
	}

	componentDidUpdate() {
		this.setActions(this.props);
	}

	setActions = props => {
		const items = props.items;
		this.actions = [];
		items.forEach(item => {
			this.actions.push(item.action);
		});
	}


	onSelect = event => {
		const linkEl = event.target;
		const wrapper = linkEl.closest('.context-menu');
		const key = linkEl.getAttribute('data-key');

		if( this.actions[key] instanceof Function )
			this.actions[key](event);

		wrapper.classList.remove(this.activeClassName);
	}


	toggle = event => {
		event.preventDefault();
		const menuWrapper = event.target.closest('.context-menu');
		if( ! menuWrapper.classList.contains(this.activeClassName) ) {
			const activeContextMenus = document.querySelectorAll(`.${this.activeClassName}`);
			if(activeContextMenus.length > 0) {
				activeContextMenus.forEach( acm => {
					acm.classList.remove(this.activeClassName);
				});
			}
		}
		menuWrapper.classList.toggle(this.activeClassName);
	}


	render() {
		const items = this.props.items;

		if( items.length === 0 )
			return null;

		const itemsList = Object
			.keys(items)
			.map(key => (<li key={`context-menu-item-${key}`}><button data-key={ key } onClick={ this.onSelect }><Glyphicon icon={ items[key].icon } /> { items[key].label }</button></li>));

		return (
			<div className="context-menu">
				<button className="context-menu-toggle context-menu-toggle-button" onClick={ this.toggle }>
					<Glyphicon icon="option-horizontal" />
				</button>
				<ul className="context-menu-list">
					{ itemsList }
				</ul>
			</div>
		)
	}
};


ContextMenu.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object)
};
