import { saveExpense, deleteExpense } from '../../api';

import {
	EXPENSE_DO_SAVE,
	EXPENSE_SAVED,
	EXPENSE_DO_DELETE,
	EXPENSE_DELETED
} from '../../AppEvents';



function expense_save(expense) {
	/*
	 * Expense can be partial here but not when saving data !!!
     */
	return {
		type: EXPENSE_DO_SAVE,
		expense
	}
}


function expense_saved(response) {
	const r = {
		type: EXPENSE_SAVED,
		status: parseInt(response.status, 10),
		expense: response.expense,
		client_status: response.client_status,
		receivedAt: Date.now()
	}

	r.message = r.status === 200 ? 'Expense was saved' : response.message;
	return r;
}



function expense_delete(expense) {
	return {
		type: EXPENSE_DO_DELETE,
		expense
	}
}


function expense_deleted(response) {
	const r = {
		type: EXPENSE_DELETED,
		status: parseInt(response.status, 10),
		id: parseInt(response.id, 10),
		client_status: response.client_status,
		receivedAt: Date.now()
	}

	r.message = r.status === 200 ? 'Expense was deleted' : response.message;
	return r;
}







export function doSaveExpense(expense) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(expense_save(expense));
			// close overlay here?
			return saveExpense(expense).then(json => dispatch(expense_saved(json)));
		});
	}
}


export function doDeleteExpense(expense) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(expense_delete(expense));
			return deleteExpense(expense.id).then(json => dispatch(expense_deleted(json)));
		});
	}
}
