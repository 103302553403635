import escapeStringForRegex from '../../../libs/EscapeStringForRegex';

const matchTerms = (terms, inv, clients) => {
	if(terms === false)
		return true; // no current search

	const regExp = new RegExp('\\W'+ escapeStringForRegex(terms), 'gi');

	const searchInTerms = [' ', inv.label, inv.details];
	const client = clients.find(c => parseInt(c.id, 10) === parseInt(inv.client_id, 10));
	if(client)
		searchInTerms.push(client.name, client.short_name);

	searchInTerms.push(' ');
	return searchInTerms.join(' ').match(regExp) !== null;
}


const getVisibleExpenses = (state, type = null, period = null, status = null, client = null) => {
	if( state.expenses.length === 0 )
		return [];

	if( type === null && period === null && status === null && client === null && state.searchTerms === false )
		return state.expenses;

	let invoices = [];

	state.expenses.forEach(inv => {
		const dateMemo = inv.date_memo.slice(0,10);

		const isType = type && inv.type === type;
		const isPeriod = period ? dateMemo >= period[0] && dateMemo <= period[1] : true;
		const isOpen = status && status === 'open' ? inv.status === 'OPEN' : true;
		const isClosed = status && status === 'closed' ? inv.status === 'CLOSED' : true;
		const isClient = client ? client === parseInt(inv.client_id, 10) : true;

		if( isType && isPeriod && isOpen && isClosed && isClient && matchTerms(state.searchTerms, inv, state.clients))
			invoices.push(inv);
	});


	return invoices;
};


export default getVisibleExpenses;
