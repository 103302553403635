import React from 'react';
import PropTypes from 'prop-types';


class CheckBox extends React.Component {
	constructor(props) {
		super(props);

		this.selectorClassName = 'custom-checkbox';
		this.className = 'checked';

		//const isChecked = props.checked ? props.checked : false;
		this.state = { checked: props.checked }
	}


	updateState(event) {
		if(! event.target )
			return;

		const input = event.target;
		this.setState({ checked: input.checked });

		if(this.props.onChange)
			this.props.onChange(input);
	}



	render() {
		const { name, label, ...attrs } = this.props || { name: '' };
		const hiddenField = this.props.hasOwnProperty('hiddenField') ? this.props.hiddenField : true;

		const key = attrs.id ? attrs.id : `cbx-${name}-${ new Date().getTime() }`;
		let classNames = [this.selectorClassName];

		if( this.state.checked ) {
			classNames.push( this.className );
			attrs.defaultChecked = 'checked';
		}

		delete attrs.checked;
		delete attrs.onChange;
		delete attrs.id;
		delete attrs.hiddenField;


		let checkboxHtml;
		let hiddenFieldHtml;

		if( hiddenField ) {
			checkboxHtml = <input id={key} type="checkbox" onChange={ this.updateState.bind(this) } {...attrs} />
			hiddenFieldHtml = <input type="hidden" id={`${key}-hidden`} name={name} value={ this.state.checked ? '1' : '0' } />;
		}
		else {
			checkboxHtml = <input id={key} name={name} type="checkbox" onChange={ this.updateState.bind(this) } {...attrs} />
			hiddenFieldHtml = '';
		}

		return (
			<label htmlFor={key}>
				<span className={ classNames.join(' ') }>
					{ checkboxHtml }
					{ hiddenFieldHtml }
				</span>
				{ label }
			</label>
		);
	}
};

CheckBox.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	hiddenField: PropTypes.bool
};


export default CheckBox;
