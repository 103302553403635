import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import I18n from '../../locales/I18n';
import Select from 'react-select';
import { userRoleIs } from '../../modules/users/UsersConsts';


const styles = {
	multiValue: (base, state) => {
		return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
	},
	multiValueLabel: (base, state) => {
		return state.data.isFixed ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base;
	},
	multiValueRemove: (base, state) => {
		return state.data.isFixed ? { ...base, display: 'none' } : base;
	}
}

const orderOptions = values => {
	return values.filter(v => v.isFixed).concat(values.filter((v) => !v.isFixed));
};


class UsersSelect extends React.Component {


	constructor(props) {
		super(props);
		const value = this.parseValueProp(props.value);
		this.handleChange = this.handleChange.bind(this);
		this.state = { value: orderOptions(value)  };
	}


	componentWillReceiveProps(props) {
		const value = this.parseValueProp(props.value);
		this.setState({value: orderOptions(value)});
	}


	parseValueProp = value => {
		const parsedValue = [];
		value.forEach(userData => {
			const user = this.props.users.find(u => parseInt(u.value, 10) === parseInt(userData.id, 10));
			if( user ) {
				const isFixed = userData.fixed || parseInt(this.props.user.id, 10) === parseInt(userData.id, 10);
				parsedValue.push({ value: user.value, label: user.label, isFixed });
			}
		});
		return parsedValue;
	}


	getRemovedValue = newValue => {
		return this.state.value.find(val => ! newValue.find(v => v.value === val.value));
	}


	handleChange = (value, { action, removedValue }) => {
		switch(action) {
			case 'remove-value':
			case 'pop-value':
				const rValue = this.getRemovedValue(value);
				if(rValue && rValue.isFixed)
					return;
	        	break;

			case 'clear':
				value = this.state.value.filter(v => v.isFixed);
	        	break;

			default:
				break;
		}

		value = orderOptions(value);
		this.setState({ value });

		if( this.props.onChange )
			this.props.onChange(value);
	}


	render() {
		const { name, id, label } = this.props || { name: 'users', id: 'users-selector', label: I18n.t('Users') };
		return (
			<div className="input select">
				<label htmlFor={ id }>{ label || I18n.t('Users') }</label>
				<Select
					value={this.state.value}
					isMulti
					styles={styles}
					isClearable={this.state.value.some(v => !v.isFixed)}
					id={ id }
					name={ name }
					className='basic-multi-select'
					classNamePrefix="select"
					onChange={this.handleChange}
					options={this.props.users}
				  />
			</div>
		);
	}
}


UsersSelect.propTypes = {
	users: PropTypes.arrayOf(PropTypes.object).isRequired,
	user: PropTypes.object.isRequired,
	value: PropTypes.arrayOf(PropTypes.object),
	roles: PropTypes.arrayOf(PropTypes.string),
	name: PropTypes.string,
	label: PropTypes.string,
	id: PropTypes.string,
	onChange: PropTypes.func,
};


const mapStateToProps = (state, ownProps) => {
	// find settings and company
	const { users, user } = state.data;
	const options = ownProps.roles ? users.filter(u => userRoleIs(u, ownProps.roles) && u.active) : [];

	const _users = [];
	options.forEach(u => {
		_users.push({ value: u.id, label: u.fullname });
	});

	return {
		user,
		users: _users,
	};
}


const mapDispatchToProps = dispatch => {
	return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersSelect);
