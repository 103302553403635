import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Header from './Header';
import Clients from '../../modules/clients/components/Clients';
import Invoices from '../../modules/invoices/components/Invoices';
import { UserRole, userRoleIs } from '../../modules/users/UsersConsts';

class IncomesPane extends React.Component {


	renderClients = () => {
		const { match } = this.props;
		return <Clients type="clients" match={match} />;
	}

	renderInvoices = () => {
		const { match } = this.props;
		return <Invoices type="INVOICE" match={match} />;
	}

	renderQuotes = () => {
		const { match } = this.props;
		return <Invoices type="QUOTE" match={match} />;
	}

	renderCreditNotes = () => {
		const { match } = this.props;
		return <Invoices type="CREDIT_NOTE" match={match} />;
	}


	render() {
		const { match, user } = this.props;
		if( userRoleIs(user, [UserRole.MANAGER, UserRole.ACCOUNTANT]) ) {
			return (
				<Fragment>
					<Header />
					<Route exact path={`${match.url}`} component={ this.renderInvoices } />
					<Route path={`${match.url}/clients`} component={ this.renderClients } />
					<Route path={`${match.url}/invoices`} component={ this.renderInvoices } />
					<Route path={`${match.url}/quotes`} component={ this.renderQuotes } />
					<Route path={`${match.url}/credit-notes`} component={ this.renderCreditNotes } />
				</Fragment>
			)
		}

		if( userRoleIs(user, UserRole.ACCOUNT_MANAGER) ) {
			return (
				<Fragment>
					<Header />
					<Invoices type="QUOTE" match={match} />
				</Fragment>
			)
		}

		return null;
	}
}

IncomesPane.propTypes = {
	user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
	const { user } = state.data;
	return { user }
}

const mapDispatchToProps = (dispatch) => {
	return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomesPane);
