import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux'
import Toggle from '../../../components/forms/Toggle';
import SectionHeader from '../../../components/common/SectionHeader';
import FabButton from '../../../components/common/FabButton';
import InvoicesTotalAmount from './InvoicesTotalAmount';
import InvoicesList from './InvoicesList';
import CreditNotesList from './CreditNotesList';
import QuotesList from './QuotesList';
import EditInvoice from './EditInvoice';
import { InvoiceType } from '../InvoicesConsts';
import { openOverlay } from '../../../UIActions';

import I18n from '../../../locales/I18n';


const Invoices = ({ type }) => {

	const propsFromStore = useSelector( selectData );
	const { period } = propsFromStore;
	const dispatch = useDispatch();
	const [ _view, setView ] = useState('all');


	const renderList = () => {
		switch(type) {
			case InvoiceType.QUOTE:
				return <QuotesList view={ _view } period={ period } />;

			case InvoiceType.CREDIT_NOTE:
				return <CreditNotesList period={ period } />;

			case InvoiceType.INVOICE:
			default:
				return <InvoicesList view={ _view } period={ period } />;
		}
	}


	const renderCreateButton = () => {

		const _getTitle = () => {
			if( type === InvoiceType.QUOTE ) {
				return I18n.t('Add quote');
			}

			if( type === InvoiceType.CREDIT_NOTE ) {
				return I18n.t('Add credit note');
			}

			return null;
		}

		const buttonTitle = _getTitle();
		if( buttonTitle === null ) {
			return null;
		}

		const handleClick = event => {
			event.preventDefault();
			const title = event.currentTarget.getAttribute('title');
			const module = <EditInvoice invoice={ { type } } />;
			dispatch(openOverlay( title, module ));

		};

		return (
			<FabButton
				onClick={ handleClick }
				title={ buttonTitle }
			/>
		)
	}


	const renderFilters = () => {
		if( type === InvoiceType.CREDIT_NOTE ) {
			return '';
		}

		const handleToggleView = () => {
			setView( _view === 'open' ? 'all' : 'open' );
		}

		return  (
			<ul className="actions-filters">
				<li>
					<Toggle
						name="show-state"
						labelRight={ type === InvoiceType.INVOICE ? I18n.t('Unpaid only') : I18n.t('Open only') }
						checked={ _view === 'open' }
						onToggle={ handleToggleView }
					/>
				</li>
			</ul>
		);
	}



	const getTitle = () => {
		switch(type) {
			case InvoiceType.INVOICE:
			default:
				return I18n.t('Invoices');

			case InvoiceType.CREDIT_NOTE:
				return I18n.t('Credit notes');

			case InvoiceType.QUOTE:
				return I18n.t('Quotes');
		}
	}



	return (
		<main id="main">
			<SectionHeader type="incomes" title={ getTitle() } />

			<div className="action-bar">
				{ renderFilters() }
				{ type !== InvoiceType.QUOTE && <InvoicesTotalAmount type={ type } view={ _view } period={ period } /> }
			</div>

			<div id="data" className="nano">
				<div className="nano-content">
					{ renderList() }
				</div>
			</div>

			{ renderCreateButton() }
		</main>
	);
};

Invoices.propTypes = {
	type: PropTypes.string.isRequired,
};

const selectData = (store) => {
	const activePeriod = store.data.activePeriod.toString();
	const year = activePeriod.slice(0, 4);
	const periodLimits = activePeriod.indexOf('|') > -1 ? activePeriod.split('|') : [`${year}-01-01`, `${year}-12-31`];
	return { period: periodLimits }
}

export default Invoices;
