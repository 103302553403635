import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { closeDocumentViewer } from '../../UIActions';


class DocumentViewer extends React.Component {


	close = (e) => {
		e.preventDefault();
		this.props.onClose();
	}


	renderOptions = () => {
		const options = this.props.options;
		const html = Object
						.keys(options)
						.map( key => {
									if( options[key].url )
										return <a key={key} href={ options[key].url }>{options[key].label}</a>;

									if( options[key].action )
										return <button key={key} onClick={ options[key].action }>{options[key].label}</button>;

									return null;
								}
							);

		return (
			<div id="document-viewer-controls">{ html }</div>
		)
	}


	render() {
		const { url } = this.props;

		if(! url )
			return null;

		return (
			<div id="document-viewer">
				<div id="document-viewer-clicker" onClick={ this.close } />

				<div id="document-viewer-header">
					{ this.renderOptions() }
					<button onClick={ this.close } id="document-viewer-close" />
				</div>

				<div id="document-viewer-holder">
					<iframe title="document viewer" src={ url } width="100%" height="100%" frameBorder="0" scrolling="auto" />
				</div>
			</div>
		);
	}
};

DocumentViewer.propTypes = {
	document_url: PropTypes.object,
	options: PropTypes.array.isRequired
};

const mapStateToProps = (state, ownProps) => {
	const { url, options } = state.ui.docViewer || { url: null, options: [] };

	return {
		url,
		options
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		onClose: () => {
			dispatch(closeDocumentViewer());
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentViewer);
