import { jobSessionAdd, jobSessionComplete, jobSessionDelete, jobSessionSave } from '../../api';

import {
	SESSION_DO_ADD,
	SESSION_ADDED,
	SESSION_DO_SAVE,
	SESSION_SAVED,
	SESSION_DO_COMPLETE,
	SESSION_COMPLETED,
	SESSION_DO_DELETE,
	SESSION_DELETED
} from '../../AppEvents';



function session_add(job_id) {
	return {
		type: SESSION_DO_ADD,
		job_id
	}
}


function session_added(response) {
	const r = {
		type: SESSION_ADDED,
		status: parseInt(response.status, 10),
		session: response.session,
		receivedAt: Date.now()
	}

	r.message = r.status === 200 ? 'Session was added' : response.message;
	return r;
}


function session_save(session_id, data) {
	return {
		type: SESSION_DO_SAVE,
		session_id,
		data
	}
}


function session_saved(response) {
	const r = {
		type: SESSION_SAVED,
		status: parseInt(response.status, 10),
		session: response.session,
		receivedAt: Date.now()
	}

	r.message = r.status === 200 ? 'Session was saved' : response.message;
	return r;
}


function session_complete(session) {
	return {
		type: SESSION_DO_COMPLETE,
		session
	}
}


function session_completed(response) {
	const r = {
		type: SESSION_COMPLETED,
		status: parseInt(response.status, 10),
		session: response.session,
		job: response.job,
		receivedAt: Date.now()
	}
	r.message = r.status === 200 ? 'Session was saved' : response.message;
	return r;
}





function session_delete(session_id) {
	return {
		type: SESSION_DO_DELETE,
		session_id
	}
}


function session_deleted(response) {
	const r = {
		type: SESSION_DELETED,
		status: parseInt(response.status, 10),
		id: response.id,
		job: response.job,
		receivedAt: Date.now()
	}

	r.message = r.status === 200 ? 'Session was deleted' : response.message;
	return r;
}



export function doAddJobSession(job_id) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(session_add(job_id));
			return jobSessionAdd(job_id).then(json => dispatch(session_added(json)));
		});
	}
}

export function doSaveJobSession(session_id, data) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(session_save(session_id, data));
			return jobSessionSave(session_id, data).then(json => dispatch(session_saved(json)));
		});
	}
}


export function doCompleteJobSession(session_id) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(session_complete(session_id));
			return jobSessionComplete(session_id).then(json => dispatch(session_completed(json)));
		});
	}
}


export function doDeleteJobSession(session_id) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(session_delete(session_id));
			return jobSessionDelete(session_id).then(json => dispatch(session_deleted(json)));
		});
	}
}
