import { saveClient, saveClientUsers } from '../../api';

import {
	CLIENT_DO_SAVE,
	CLIENT_SAVED,
	CLIENT_USERS_DO_SAVE,
	CLIENT_USERS_SAVED
} from '../../AppEvents';


function client_save(client) {
	return {
		type: CLIENT_DO_SAVE,
		client
	}
}


function client_saved(response) {
	const r = {
		type: CLIENT_SAVED,
		status: parseInt(response.status, 10),
		client: response.client,
		receivedAt: Date.now()
	};
	r.message = r.status === 200 ? 'Client was saved' : response.message;

	return r;
}


function client_users_save(client, users) {
	return {
		type: CLIENT_USERS_DO_SAVE,
		client,
		users
	}
}


function client_users_saved(response) {
	const r = {
		type: CLIENT_USERS_SAVED,
		status: parseInt(response.status, 10),
		client: response.client,
		receivedAt: Date.now()
	}

	r.message = r.status === 200 ? 'Client users were saved' : response.message;
	return r;
}



export function doSaveClient(client) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(client_save(client));
			return saveClient(client).then(json => dispatch(client_saved(json)));
		});
	}
}


export function doSaveClientUsers(client, users) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(client_users_save(client, users));
			return saveClientUsers(client.id, users).then(json => dispatch(client_users_saved(json)));
		});
	}
}
