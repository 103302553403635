import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

import DateTime from '../../../components/common/DateTime';
import Currency from '../../../components/common/Currency';
import CheckBox from '../../../components/forms/CheckBox';
import ItemOptions from '../../../components/common/ItemOptions';
import JobSessions from '../../job_sessions/components/JobSessions';
import Avatar from '../../users/components/Avatar';
import Glyphicon from '../../../components/common/Glyphicon';
import { doSaveJob, doDeleteJob, doInvoiceJob } from '../JobsActions';
import { JobStatus, JobType } from '../JobsConsts';
import { doAddJobSession } from '../../job_sessions/JobSessionsActions';
import { UserRole, userRoleIs } from '../../users/UsersConsts';

import I18n from '../../../locales/I18n';
import EditJob from './EditJob';
import EditJobUsers from './EditJobUsers';
import { openOverlay } from '../../../UIActions';


/*
 * v7.0.0
 * Sessions are always activated but they are only informative when Job.type !== HOURLY
 * Except when Job is a recurrent one
 * The amount of time for a job is calculated from the sessions ONLY when Job.type === HOURLY
 */

class Job extends React.Component {

	constructor(props) {
    	super(props);
    	this.state = {
            quantity: this.getQuantity(props),
            updatingQuantity: false
        };
    }

	componentDidUpdate(prevProps) {
       if( this.props !== prevProps ) {
           if(! this.state.updatingQuantity ) {
               this.setState({ quantity: this.getQuantity(this.props) });
           }
       }
    }

    componentDidMount() {
		this.saveQuantityAfterMs = 1000;
		this.quantityStep = .5;
		this.timer = null;
		this.setState({ quantity: this.getQuantity(this.props), childrenShown: false });
	}



	getQuantity = (p) => {
		let q = 0;
		if( p.job.type === JobType.FLAT_RATE ) {
			p.childJobs.forEach(cj => {
				cj.sessions.forEach(s => { q += parseFloat(s.time_amount); });
			});
			return q;
		}

		if( p.job.type !== JobType.HOURLY )
			return p.job.quantity;

		p.sessions.forEach(s => { q += parseFloat(s.time_amount); });

		return q;
	}

	editForm = e => {
		e.preventDefault();
		const { job, user } = this.props;

		if( job.status !== JobStatus.INVOICED && userRoleIs(user, [UserRole.MANAGER, UserRole.ACCOUNT_MANAGER]) )
			this.props.getForm(this.props.job);
	}


	addSubJobForm = e => {
		e.preventDefault();
		const { job, user } = this.props;

		if( job.status !== JobStatus.INVOICED && userRoleIs(user, [UserRole.MANAGER, UserRole.ACCOUNT_MANAGER]) )
			this.props.getForm({ parent_job_id: job.id, client_id: job.client_id });
	}


	usersForm = e => {
		e.preventDefault();
		this.props.getUsersForm(this.props.job);
	}


	onSelect = input => {
		input.closest('.item').classList.toggle('selected');

		if( this.props.onSelect )
			this.props.onSelect(input);
	}


	onReopen = event => {
		event.preventDefault();

		if(! JobStatus.CLOSED )
			return;

		const status = JobStatus.OPEN;

		const job = new FormData();
		job.append('id', this.props.job.id);
		job.append('status', status);

		this.props.onSave(job);
	};



	updateStatus = event => {
		event.preventDefault();
		let status;

		switch(this.props.job.status) {
			case JobStatus.OPEN:
				status = JobStatus.CLOSED;
				break;

			case JobStatus.CLOSED:
				status = JobStatus.INVOICED;
				break;

			default:
				status = this.props.job.status
		}

		const job = new FormData();
		job.append('id', this.props.job.id);
		job.append('status', status);

		this.props.onSave(job);
	};



	onQuantityChange = event => {
		event.preventDefault();

		const newQuantity = this.state.quantity + this.quantityStep;
		this.setState({ updatingQuantity: true, quantity: newQuantity })

		if( this.timer !== null )
			clearTimeout(this.timer);

		const job = new FormData();
		job.append('id', this.props.job.id);
		job.append('quantity', newQuantity);

		this.timer = setTimeout( () => {
			this.props.onSave(job);
			this.setState({ updatingQuantity: false });
		}, this.saveQuantityAfterMs);
	};


	onInvoice = event => {
		event.preventDefault();
		this.props.createInvoice([ this.props.job.id ]);
	}


	onDelete = event => {
		event.preventDefault();

		if( window.confirm( I18n.t('Are you sure?')) )
			this.props.onDelete({ id: this.props.job.id });
	};


	addJobSession = event => {
		this.props.onAddJobSession(this.props.job.id);
	};


	showJobSessions = event => {
		// open overlay...
		this.props.viewJobSessions(this.props.job.id);
	}


	getItemOptions = () => {
		const { job, hasActiveSession, sessions, user } = this.props;
		const options = [];

		const isManager = userRoleIs(user, [UserRole.MANAGER]);
		const jobOwner = job.job_users.find(ju => ju.owner);
		const isJobOwner = jobOwner ? userRoleIs(user, [UserRole.ACCOUNT_MANAGER]) && parseInt(user.id, 10) === parseInt(jobOwner.user_id, 10) : false;


		if( ! job.is_recurrent ) {
			// if there's no active session now !!!
			if( job.status === JobStatus.OPEN && ! hasActiveSession && job.type !== JobType.FLAT_RATE ) {
				options.push({
					label: I18n.t('Start session'),
					action: this.addJobSession,
					icon: 'record'
				});
			}

			// if there's session already...
			if(sessions.length > 0) {
				options.push({
					label: I18n.t('View sessions'),
					action: this.showJobSessions,
					icon: 'time'
				});
			}
		}

		const isPartOfFlatRate = this.props.parentJob && this.props.parentJob.type === JobType.FLAT_RATE;

		if( job.status === JobStatus.OPEN && ! job.is_recurrent && (isManager || isJobOwner) && job.type !== JobType.FLAT_RATE && ! isPartOfFlatRate ) {
			options.push({
				label: I18n.t('Mark as complete'),
				action: this.updateStatus,
				icon: 'ok'
			});
		}



		if( job.status === JobStatus.OPEN && (isManager || isJobOwner) ) {

			if(! job.parent_job_id ) {
				options.push({
					label: I18n.t('Add a child job'),
					action: this.addSubJobForm,
					icon: 'plus'
				});
			}

			options.push({
				label: I18n.t('Edit'),
				action: this.editForm,
				icon: 'edit'
			});

			if( isManager || isJobOwner ) {
				options.push({
					label: I18n.t('Manage users'),
					action: this.usersForm,
					icon: 'user'
				});
			}
		}

		if( job.status === JobStatus.CLOSED && isManager ) {
			options.push({
				label: I18n.t('Edit'),
				action: this.editForm,
				icon: 'edit'
			});
		}

		if( job.status === JobStatus.CLOSED && ! job.is_recurrent ) {
		 	if( isManager ) {
				options.push({
					label: I18n.t('Reopen this job'),
					action: this.onReopen,
					icon: 'repeat'
				});
			}

			if( userRoleIs(user, [UserRole.MANAGER, UserRole.ACCOUNTANT]) && job.invoiceable ) {
				options.push({
					label: I18n.t('Invoice'),
					action: this.onInvoice,
					icon: 'flash'
				});
			}
		}

		if( job.status !== JobStatus.INVOICED && isManager ) {
			options.push({
				label: I18n.t('Delete'),
				action: this.onDelete,
				icon: 'trash',
				className: 'action-delete'
			});
		}

		return options;
	}


	renderQuantityUpdater = () => {
		if( this.props.job.type === JobType.FIX || this.props.job.status !== JobStatus.OPEN )
			return (
				<div className="quantity">
					<div className="quantity-num">{ this.state.quantity }</div>
				</div>
			);

		return (
			<div className="quantity">
				<div className="quantity-num">{this.state.quantity}</div>
				<button className="quantity-updater icon circle-icon" onClick={ this.onQuantityChange }>
					<Glyphicon icon="plus" />
				</button>
			</div>
		);
	}


	renderAmountFields = () => {
		const { job, user } = this.props;
		const amount = job.type === JobType.HOURLY && job.status === JobStatus.OPEN ? this.state.quantity * job.fee : job.amount;


		if( job.type === JobType.FLAT_RATE ) {
			const { childJobs } = this.props;

			let quantityDone = 0;
			childJobs.forEach(cj => {
				// session amount addition (child job is obligatory HOURLY)
				cj.sessions.forEach(s => { quantityDone += parseFloat(s.time_amount); });
			})
			return (
				<div className="item-col-amount">
					<span className="number amount">{ quantityDone }</span> /
					<span className="number">{ job.quantity }</span>{ I18n.t('h') }
				</div>
			);
		}

		const isPartOfFlatRate = this.props.parentJob && this.props.parentJob.type === JobType.FLAT_RATE;

		if( userRoleIs(user, [UserRole.MANAGER]) && ! isPartOfFlatRate) {
			return (
				<React.Fragment>
					<div className="item-job-quantity">
						<div className="fee"><span className="number">{job.fee}</span></div>
						{ this.renderQuantityUpdater() }
					</div>

					<div className="item-col-amount col-amount-taxexcl">
						<div className="amount">
							<Currency amount={ amount } />
						</div>
					</div>
				</React.Fragment>
			);
		}

		if( userRoleIs(user, [UserRole.ACCOUNTANT]) && ! isPartOfFlatRate) {
			return (
				<React.Fragment>
					<div className="item-col-amount col-amount-taxexcl">
						<div className="amount">
							<Currency amount={ amount } />
						</div>
					</div>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<div className="item-job-quantity">
					{ this.renderQuantityUpdater() }
				</div>
			</React.Fragment>
		);
	}


	renderCheckboxSelect = () => {
		const { job, selected, user } = this.props;

		if(! userRoleIs(user, [UserRole.MANAGER, UserRole.ACCOUNTANT]) )
			return;

		if( job.status === JobStatus.CLOSED )
			return <CheckBox label='' name={`chk_job_${job.id}`} value={job.id} id={`chk-job-${job.id}`} onChange={ this.onSelect } checked={ selected } />;

		return null;
	}



	renderDate = () => {
		const job = this.props.job;
		if(! job.is_recurrent )
			return null;

		return (
			<div className="item-col-date">
				<DateTime date={job.date_memo} format="dd mmm yyyy" />
			</div>
		);
	}



	renderUsers = () => {
		const { user, users, job } = this.props;
		const jobUsers = [];

		job.job_users.forEach(ju => {
			const jobUser = users.find(u => parseInt(u.id, 10) === parseInt(ju.user_id, 10));
			if(! jobUser)
				return;

			let avatarTitle = parseInt(user.id, 10) === parseInt(jobUser.id, 10) ? I18n.t('You') : jobUser.fullname;
			if( ju.owner )
				avatarTitle += ' ('+ I18n.t('Owner') +')';

			const userAvatar = <Avatar key={ `job${job.id}-user${ju.user_id}` } user={ jobUser } title={ avatarTitle } size={ 'small' } />

			if( ju.owner )
				return jobUsers.unshift(userAvatar);

			return jobUsers.push(userAvatar);
		});

		return jobUsers;
	}



	renderClient = () => {
		const { user, client, isChildJob } = this.props;
		if( isChildJob )
			return;

		if( userRoleIs(user, [UserRole.MANAGER]) ) {
			return (
				<div className="item-client">
					<Link to={`/incomes/clients/view/${client.id}`}>{client.short_name}</Link>
				</div>
			);
		}

		return (
			<div className="item-client">
				<span className="link-like">{client.short_name}</span>
			</div>
		);
	}


	renderTitle = () => {
		const { job, user } = this.props;

		if( job.status === JobStatus.OPEN && userRoleIs(user, [UserRole.MANAGER, UserRole.ACCOUNT_MANAGER]) ) {
			return (
				<div className="item-title">
					<button className="link-like" onClick={ this.editForm }>{job.label}</button>
				</div>
			);
		}

		return (
			<div className="item-title">
				<span className="link-like">{job.label}</span>
			</div>
		);
	}

	hasChildren() {
		return this.props.hasOwnProperty('childJobs') && Array.isArray(this.props.childJobs) && this.props.childJobs.length > 0;
	}

	toggleChildJobs = e => {
		if( e.currentTarget.tagName === 'a' || e.currentTarget.tagName === 'button')
			e.preventDefault();

		if(! this.hasChildren() )
			return;

		this.setState({ childrenShown: ! this.state.childrenShown });
	}


	renderChildren() {
		if(! this.hasChildren() || ! this.state.childrenShown )
			return;

		return (
			<ul className="data-list-children">
				{ this.props.childJobs.map(job => <ChildJob key={`data-item-job-${job.id}`} id={job.id} job={job} isChildJob={ true } parentJob={ this.props.job } />) }
			</ul>
		);
	}


	render() {
		const { job } = this.props;

		if( job.status === JobStatus.INVOICED || (job.parent_job_id && ! this.props.isChildJob) )
			return null;

		return (
			<li className="data-list-item">
				<div className={`item job job-${job.id} ${job.status.toLowerCase()} ${job.due_class}`} onClick={ this.toggleChildJobs }>
					<div className="item-check">
						{ this.renderCheckboxSelect() }
					</div>

					<div className="item-main-col">
						{ this.renderClient() }
						{ this.renderTitle() }
					</div>

					<div className="item-col-users">
						{ this.renderUsers() }
					</div>

					{ this.renderDate() }
					{ this.renderAmountFields() }

					<ItemOptions options={ this.getItemOptions() } />
				</div>

				{ this.renderChildren() }
			</li>
		)
	}
};

Job.propTypes = {
	job: PropTypes.object.isRequired,
	client: PropTypes.object.isRequired,
	onSave: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	selected: PropTypes.bool,
	isChildJob: PropTypes.bool.isRequired,
	hasActiveSession: PropTypes.bool.isRequired,
	sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
	childJobs: PropTypes.arrayOf(PropTypes.object),
	parentJob: PropTypes.object,
	user: PropTypes.object.isRequired,
	users: PropTypes.arrayOf(PropTypes.object).isRequired
};


const mapStateToProps = (state, ownProps) => {
	const { clients, searchTerms, job_sessions, user, users, jobs } = state.data || { clients: [], searchTerms: false, job_sessions: [], user: {}, jobs: [] };
	const client = clients.find(c => parseInt(c.id, 10) === parseInt(ownProps.job.client_id, 10)) || {};
	const sessions = ownProps.job.type === JobType.HOURLY ? job_sessions.filter(s => parseInt(s.job_id, 10) === parseInt(ownProps.job.id, 10) && s.completed === true ) : [];
	const hasActiveSession = job_sessions.find(s => s.completed === false && s.user_id === user.id ) ? true : false;

	const childJobs = ownProps.isChildJob ? null : jobs.filter(j => j.parent_job_id && parseInt(ownProps.job.id, 10) === parseInt(j.parent_job_id, 10) );
	if( childJobs && ownProps.job.type === JobType.FLAT_RATE ) {
		childJobs.forEach((cj, index) => {
			childJobs[index].sessions = cj.type === JobType.HOURLY ? job_sessions.filter(s => parseInt(s.job_id, 10) === parseInt(cj.id, 10) && s.completed === true ) : [];
		});
	}

	return { client, searchTerms, user, users, hasActiveSession, sessions, childJobs };
}


const mapDispatchToProps = (dispatch) => {
	return {
		getForm: (job) => {
			const title = I18n.t('Edit job');
			const module = <EditJob job={job} />;
			dispatch(openOverlay( title, module ));
		},

		getUsersForm: (job) => {
			const title = I18n.t('Job users');
			const module = <EditJobUsers job={job} />;
			dispatch(openOverlay( title, module ));
		},

		onSave: (job) => {
			dispatch(doSaveJob(job))
		},

		onDelete: (job) => {
			dispatch(doDeleteJob(job));
		},

		createInvoice: (jobsIds) => {
			dispatch(doInvoiceJob(jobsIds));
		},

		onAddJobSession: jobId => {
			dispatch(doAddJobSession(jobId));
		},

		viewJobSessions: jobId => {
			const title = I18n.t('Job sessions');
			const module = <JobSessions job_id={jobId} />;
			dispatch(openOverlay( title, module ));
		}
	}
}


export default connect(mapStateToProps,mapDispatchToProps)(Job);
const ChildJob = connect(mapStateToProps,mapDispatchToProps)(Job);
