import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CountrySelect from '../../../components/forms/CountrySelect';
import CheckBox from '../../../components/forms/CheckBox';
import I18n from '../../../locales/I18n';
import { getCompanyByVatID } from '../../../api';
import { doSaveClient } from '../ClientsActions';
import { openOverlay, closeOverlay, removeOverlayBodyClass } from '../../../UIActions';
import { CLIENT_SAVED } from '../../../AppEvents';



class EditClient extends React.Component {

	constructor(props) {
		super(props);

		this.screens = {
			VAT_SEARCH: 'vatSearch',
			EDIT_FORM: 'editForm'
		};

		let client = props.client;
		if( ! client.is_client && ! client.is_provider ) {
			client.is_client = true;
			client.is_provider = false;
		}

		if( ! client.id ) {
			client = Object.assign({}, client, { is_self: false, invoice_by_email: true, vat_liable: false, country_id: 'BE' });
		}

		this.state = {
			client: client,
			countriesAreLoaded: false,
			screen: props.client.id ? this.screens.EDIT_FORM : this.screens.VAT_SEARCH,
		};
	}


    componentDidUpdate(prevProps) {
        const { lastAction, client } = this.props;

        if( client !== prevProps.client ) {
            const updatedClient = Object.assign({}, this.state.client, client);
            this.setState({ client: updatedClient });
        }

        if( lastAction.type === CLIENT_SAVED && lastAction.success ) {

            if( this.props.nextView ) {
                this.props.doNextView(this.props.nextTitle, this.props.nextView);
                return;
            }

            removeOverlayBodyClass();

            const timer = setTimeout(() => {
                this.props.doClose();
                clearTimeout(timer);
            }, 500);
        }
    }




	findClient = event => {
		event.preventDefault();
		const accessKey = this.props.settings.apilayer_access_key;

		if(! accessKey ) {
			alert( I18n.t("It seems you didn't configured you ApiLayer access key...") );
			this.showEditForm();
			return;
		}

		const vatNumber = document.getElementById('vat-id-search').value;
		const clientRequest = getCompanyByVatID(accessKey, vatNumber).then(r => {
			if( r.status !== 200 ) {
				alert( r.message );
				return;
			}

			const response = r.response;
			if( response.format_valid ) {
				const client = {
					name: 	response.company_name,
					address:response.company_address,
					country_id: response.country_code,
					vat_id: response.country_code + response.vat_number
				};
				this.setState({ client: client });
				this.showEditForm();
			}
			return response;
		});

		return clientRequest;
	}


	showVatSearch = () => {
		this.setState({ screen: this.screens.VAT_SEARCH });
	}

	showEditForm = () => {
		this.setState({ screen: this.screens.EDIT_FORM });
	}


	updateCountry = (element) => {
        if( this.state.client.country_id !== element.value ) {
		    const client = Object.assign({}, this.state.client, { country_id: element.value, country_name: element.label });
		    this.setState({ client });
        }
		return element.value;
	}

	storeName = event => {
		event.preventDefault();
		const client = Object.assign({}, this.state.client, { name: event.target.value });
		this.setState({ client });
	}

	/*
 	 * When user saves an item ...
	 */
	save = e => {
		e.preventDefault();
		if( this.props.isSaving )
			return;

		const formData = new FormData(document.getElementById('client-edit'));
		/*
		 * ReactSelect no compatible for 2.0-alpha (for now?)
		 * So I add manually the right value
		 */
		formData.append('country_id', this.state.client.country_id);

		this.props.onSave(formData);
	}


	renderActiveCheckbox = () => {
		const client = this.state.client;

		if( client.is_self )
			return;

		return (
			<div className="input checkbox">
				<CheckBox label={I18n.t('Show in active list?')} name="active" defaultValue="1" id="client-is-active" checked={ client.active } />
			</div>
		);
	}


	renderTypeSelector = () => {
		const client = this.state.client;

		if( client.is_self )
			return;

		return (
			<div className="fields-group">
				<div className="input checkbox">
					<CheckBox label={I18n.t('Client')} name="is_client" defaultValue="1" id="client-is-client" checked={ client.is_client } />
				</div>

				<div className="input checkbox">
					<CheckBox label={I18n.t('Provider')} name="is_provider" defaultValue="1" id="client-is-provider" checked={ client.is_provider } />
				</div>
			</div>
		);
	}


	renderHourlyFee = () => {
		const client = this.state.client;

		return (
			<div className="input number">
				<label htmlFor="client-hourly-fee">{ I18n.t('Hourly fee') }</label>
				<input type="number" name="hourly_fee" className="small" style={{ width:'60px' }} step="any" id="client-hourly-fee" defaultValue={client.hourly_fee || this.props.company.hourly_fee} required /> { this.props.settings.currency }
			</div>
		)
	}


	renderVatID = () => {
		const client = this.state.client;

		return (
			<div className="input text">
				<label htmlFor="client-vat-id">{ I18n.t('VAT ID') }</label>
				<input type="text" name="vat_id" maxLength="60" id="client-vat-id" defaultValue={client.vat_id || ''} pattern="^[A-Z]{2}[0-9 ]+$" />
			</div>
		)
	}


	renderVatOptions = () => {
		const client = this.state.client;

		return (
			<div className="fields-group">
				<div className="input checkbox">
					<CheckBox label={I18n.t('VAT liable')} name="vat_liable" defaultValue="1" id="client-vat-liable" checked={ client.vat_liable } />
				</div>

				<div className="input checkbox">
					<CheckBox label={I18n.t('IntraCom VAT')} name="intracom_vat" defaultValue="1" id="client-intracom-vat" checked={ client.intracom_vat } />
				</div>
			</div>
		)
	}


	renderInvoicingInfo = () => {
		const client = this.state.client;

		if( client.is_self )
			return (
				<fieldset>
					<div className="fields-group">
						{ this.renderHourlyFee() }

						<div className="input checkbox">
							<CheckBox label={I18n.t('VAT liable')} name="vat_liable" defaultValue="1" id="client-vat-liable" checked={ client.vat_liable } />
						</div>
					</div>

					{ this.renderVatID() }
				</fieldset>
			);

		if( client.is_provider && ! client.is_client )
			return (
				<fieldset>
					{ this.renderHourlyFee() }
					{ this.renderVatID() }
					{ this.renderVatOptions() }
				</fieldset>
			)

		return (
			<fieldset>
				<div className="fields-group">
					{ this.renderHourlyFee() }

					<div className="input checkbox">
						<CheckBox label={I18n.t('Invoice by e-mail')} name="invoice_by_email" defaultValue="1" id="invoice-by-email" checked={ client.invoice_by_email } />
					</div>
				</div>

				{ this.renderVatID() }
				{ this.renderVatOptions() }
			</fieldset>
		);
	}



	checkValidity = event => {
		event.target.checkValidity();
	}


	renderSubmit = () => {
		if( this.props.isSaving )
			return <button type="submit" className="is-saving">{ I18n.t('Saving...') }</button>

		return <button type="submit">{ I18n.t('Save') }</button>
	}


	render() {
		const { client, screen } = this.state;

		if( screen === this.screens.VAT_SEARCH ) {
			return (
				<div className="clients form" key="client-form-vat-search">
					<form id="vat-search-form" method="post" acceptCharset="utf-8" onSubmit={this.findClient}>
						<fieldset>
							<div className="input text">
								<label htmlFor="vat-id-search">{ I18n.t('Search client') }</label>
								<input type="text" maxLength="40" id="vat-id-search" placeholder={ I18n.t('Enter VAT N°')} />
							</div>
						</fieldset>

						<div className="form-controls">
							<button type="submit">{ I18n.t('Search') }</button>
							<span className="form-controls-or">
								<span> or </span>
								<button className="link-like" onClick={ this.showEditForm }>{ I18n.t('Manual set-up') }</button>
							</span>
						</div>
					</form>
				</div>
			)
		}

		return (
			<div className="clients form" key="client-form-details">
				<form method="post" id="client-edit" acceptCharset="utf-8" onSubmit={this.save}>

					<fieldset>
						<div className="input text">
							<label htmlFor="client-name">{ I18n.t('Name') }</label>
							<input type="text" name="name" maxLength="60" id="client-name" defaultValue={client.name} onBlur={ this.storeName.bind(this) } required />
						</div>

						<div className="input text">
							<label htmlFor="client-shortname">{ I18n.t('Shortname') }</label>
							<input type="text" name="shortname" maxLength="40" id="client-shortname" defaultValue={client.shortname || ''} />
						</div>

						<div className="input text">
							<label htmlFor="client-name-comp">{ I18n.t('Name (compl.)') }</label>
							<input type="text" name="name_comp" maxLength="100" id="client-name-comp" defaultValue={client.name_comp || ''} />
						</div>

						{ this.renderActiveCheckbox() }

						{ this.renderTypeSelector() }
					</fieldset>

					{ this.renderInvoicingInfo() }


					<fieldset>
						<div className="input text">
							<label htmlFor="client-address">{ I18n.t('Address') }</label>
							<textarea name="address" maxLength="255" id="client-address" defaultValue={client.address || ''} />
						</div>

						<div className="input text">
							<label htmlFor="client-postcode">{ I18n.t('Postcode') }</label>
							<input type="text" name="postcode" maxLength="30" id="client-postcode" defaultValue={client.postcode || ''} />
						</div>

						<div className="input text">
							<label htmlFor="client-town">{ I18n.t('Town') }</label>
							<input type="text" name="town" maxLength="60" id="client-town" defaultValue={client.town || ''} />
						</div>

						<CountrySelect name="country_id" value={ client.country_id } label={ client.country_name } onChange={this.updateCountry.bind(this)} />

						<div className="input text">
							<label htmlFor="client-info-comp">{ I18n.t('Info compl.') }</label>
							<textarea type="text" name="comp_info" maxLength="255" id="client-info-comp" defaultValue={client.comp_info || ''} />
						</div>
					</fieldset>

					<fieldset>
						<div className="input text">
							<label htmlFor="client-contact-name">{ I18n.t('Contact') }</label>
							<input type="text" name="contact_name" maxLength="60" id="client-contact-name" defaultValue={client.contact_name || ''} />
						</div>

						<div className="input text">
							<label htmlFor="client-contact-email">{ I18n.t('E-mail') }</label>
							<input type="email" name="contact_email" maxLength="60" id="client-contact-email" defaultValue={client.contact_email || ''} />
						</div>

						<div className="input text">
							<label htmlFor="client-invoice-email">{ I18n.t('E-mail invoice to') }</label>
							<input type="email" name="invoice_email" maxLength="60" id="client-invoice-email" defaultValue={client.invoice_email || ''} />
						</div>

						<div className="input text">
							<label htmlFor="client-contact-phone">{ I18n.t('Phone') }</label>
							<input type="text" name="contact_phone" maxLength="60" id="client-contact-phone" defaultValue={client.contact_phone || ''} />
						</div>
					</fieldset>


					<div className="form-controls">
						<input type="hidden" name="id" value={client.id} readOnly />
						{ this.renderSubmit() }
					</div>

				</form>
			</div>
		);
	}
};


EditClient.propTypes = {
	client: PropTypes.object,
	company: PropTypes.object,
	settings: PropTypes.object,
	nextTitle: PropTypes.string,
	nextView: PropTypes.element,
	lastAction: PropTypes.object.isRequired,
	isSaving: PropTypes.bool.isRequired
};



const mapStateToProps = (state, ownProps) => {
	// find settings and company
	const { lastAction, companySettings, company, isSaving } = state.data;

	return {
		settings: companySettings,
		company,
		lastAction,
		isSaving
	};
}


const mapDispatchToProps = (dispatch) => {
	return {
		onSave: (client) => {
			dispatch(doSaveClient(client));
		},

		doNextView: (title, module) => {
			dispatch(openOverlay(title, module));
		},

		doClose: () => {
			dispatch(closeOverlay());
		}
	}
}



export default connect(mapStateToProps, mapDispatchToProps)(EditClient);
