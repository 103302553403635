import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Glyphicon from '../../../components/common/Glyphicon';
import ClientSelect from '../../../components/forms/ClientSelect';
import AddClientButton from '../../../components/forms/AddClientButton';
import TaxRateSelect from '../../../components/forms/TaxRateSelect';
import CheckBox from '../../../components/forms/CheckBox';
import DateSelect from '../../../components/forms/DateSelect';
import I18n from '../../../locales/I18n';
import { closeOverlay, removeOverlayBodyClass } from '../../../UIActions';
import { doSaveInvoice } from '../InvoicesActions';
import { INVOICE_SAVED } from '../../../AppEvents';
import { InvoiceStatus } from '../InvoicesConsts';


class EditInvoice extends React.Component {

	/*
	 * When modifiyng the item, just update local state.
	 * The global state is only updated when saving data ***
	 */
	constructor(props) {
		super(props);

		this.type = props.invoice.type;
		this.state = { invoice: props.invoice };

		this.statuses = [
			{ label: I18n.t('Draft'), value: InvoiceStatus.DRAFT },
			{ label: I18n.t('Open'), value: InvoiceStatus.OPEN },
			{ label: I18n.t('Closed'), value: InvoiceStatus.CLOSED }
		];
	}

    componentDidUpdate() {
		/*
		 * Not for invoice ...
		 */
		const { lastAction } = this.props;

		if( lastAction.type === INVOICE_SAVED && lastAction.success ) {
			removeOverlayBodyClass();

			const timer = setTimeout(() => {
				this.props.doClose();
				clearTimeout(timer);
			}, 500);
		}


	}



	/*
 	 * When user adds an item ...
	 */
	addItem = (e) => {
		// add an item in local state...
		let invoice = this.state.invoice;

		// add a new item into this.state.invoice.items
		const ccItem = invoice.items.slice(-1)[0];
		let newItem = {};

		for( let prop in ccItem ) {
			if( ['document_id', 'document_type', 'tax_rate_id', 'percent_margin'].indexOf(prop) === -1 )
				newItem[prop] = '';
			else
				newItem[prop] = ccItem[prop];
		}

		invoice.items.push(newItem);

		this.setState({ invoice: invoice });
	}


	/*
 	 * When user removes an item ...
	 */
	removeItem = event => {
		event.preventDefault();

		if( window.confirm( I18n.t('Are you sure?') )) {
			let invoice = this.state.invoice;
			const itemID = parseInt(event.target.getAttribute('data-item'), 10);


			if( invoice.items[itemID] )
				invoice.items[itemID] = null;

			this.setState({ invoice: invoice });
		}
	}


	changeClient = select => {
		const invoice = Object.assign({}, this.state.invoice, { client_id: select.value });

		const taxRateId = this.clientDefaultTaxRate(invoice.client_id);

		if( taxRateId !== parseInt(invoice.tax_rate_id, 10) && this.type === 'QUOTE' ) {
			// update all items default vat rate;
			const items = invoice.items.map( item => {
				item.tax_rate_id = taxRateId;
				return item;
			})
			invoice.items = items;
		}
		this.setState({ invoice });
	}



	clientDefaultTaxRate = client_id => {
		const client = this.props.clients.find(c => parseInt(c.id, 10) === parseInt(client_id, 10)) || {};
		return client.intracom_vat ? 4 : 2;
	}


	/*
 	 * When user saves an item ...
	 */
	save = event => {
		event.preventDefault();

		if( this.props.isSaving )
			return;

		const formData = new FormData(document.getElementById('invoice-edit'));
		formData.append('type', this.type);
		if(! formData.get('client_id'))
			formData.append('client_id', this.state.invoice.client_id);

		this.props.onSave(formData);
	}



	/*
 	 * This is for inline margin calculation in form, only used for quotes
	 */
	calcMargin = (e) => {
		e.preventDefault();
		const holder = e.target.parentNode.parentNode;

		const buyPriceInput = holder.querySelector('input[name*=buy_price]');
		const marginInput = holder.querySelector('input[name*=percent_margin]');
		const feeInput = holder.querySelector('input[name*=fee]');

		const _buyPrice = parseFloat(buyPriceInput.value);
		const _margin = parseFloat(marginInput.value);
		const _fee = parseFloat(feeInput.value);

		// calculate margin
		if( e.target.name.indexOf('[fee]') > -1 )
			marginInput.value = 100 - ((_buyPrice / _fee) * 100);

		else
			feeInput.value = (_buyPrice + (_buyPrice / (100/_margin))).toFixed(2);
	}


	quoteSingleJobChange = input => {
		//alert('change');
	}


	/*
	 * following methods are made
	 * To generate the sub-forms
	 */
	renderItemForm = (i, item) => {
		if(item === null)
			return;

		const key = `inv-item-${i}`;

		if( this.type === 'QUOTE' ) {
			return (
				<fieldset id={key} key={key}>
					<legend className="small">{ I18n.r('Item #{0}', i + 1) }</legend>

					<div className="input text">
						<label htmlFor={`items-${i}-label`}>{ I18n.t('Label') }</label>
						<input type="text" name={`items[${i}][label]`} maxLength="255" id={`items-${i}-label`} defaultValue={item.label} required />
					</div>

					<div className="input textarea">
						<label htmlFor={`items-${i}-details`}>{ I18n.t('Details') }</label>
						<textarea name={`items[${i}][details]`} id={`items-${i}-details`} rows="8" defaultValue={item.details} />
					</div>

					<div className="fields-group fields-group-quote-amount">
						<div className="input number item-buy-price">
							<label htmlFor={`items-${i}-buy-price`}>{ I18n.t('Buy Price') }</label>
							<input type="number" name={`items[${i}][buy_price]`} className="margin-calc" step="any" id={`items-${i}-buy-price`} defaultValue={item.buy_price} onChange={this.calcMargin} required />
						</div>

						<div className="input number item-margin">
							<label htmlFor={`items-${i}-percent-margin`}>{ I18n.t('Margin') }</label>
							<input type="number" name={`items[${i}][percent_margin]`} className="margin-calc" id={`items-${i}-percent-margin`} defaultValue={item.percent_margin} onChange={this.calcMargin} step="1" min="0" />
							%
						</div>

						<div className="input number item-total">
							<label htmlFor={`items-${i}-fee`}>{ I18n.t('Total') }</label>
							<input type="number" name={`items[${i}][fee]`} step="any" className="margin-calc" id={`items-${i}-fee`} defaultValue={item.fee} onChange={this.calcMargin} required />
						</div>
					</div>

					<input type="hidden" name={`items[${i}][quantity]`} value="1" readOnly />

					<div className="input select">
						<label htmlFor={`items-${i}-tax-rate-id`}>{ I18n.t('VAT rate') }</label>
						<TaxRateSelect name={`items[${i}][tax_rate_id]`} id={`items-${i}-tax-rate-id`} value={item.tax_rate_id || 2} />
					</div>

					<div className="form-item-option">
						<Glyphicon icon="remove" className="circle-icon small red-hover" onClick={this.removeItem} data-item={i} tagName="a" />
					</div>

					<input type="hidden" name={`items[${i}][id]`} value={item.id} readOnly />
				</fieldset>
			);
		}


		if( this.type === 'INVOICE' ) {
			return (
				<fieldset id={key} key={key}>
					<legend className="small">{ I18n.r('Item #{0}', i + 1) }</legend>

					<div className="input text">
						<label htmlFor={`items-${i}-label`}>{ I18n.t('Label') }</label>
						<input type="text" name={`items[${i}][label]`} maxLength="255" id={`items-${i}-label`} defaultValue={item.label} required />
					</div>

					<div className="fields-group fields-group-cn-amount">
						<div className="input number item-total">
							<label htmlFor={`items-${i}-fee`}>{ I18n.t('Amount') }</label>
							<input type="number" name={`items[${i}][fee]`} step="any" id={`items-${i}-fee`} defaultValue={item.fee} required />
						</div>
					</div>

					<input type="hidden" name={`items[${i}][quantity]`} value="1" readOnly />

					<div className="input select">
						<label htmlFor={`items-${i}-tax-rate-id`}>{ I18n.t('VAT rate') }</label>
						<TaxRateSelect name={`items[${i}][tax_rate_id]`} id={`items-${i}-tax-rate-id`} value={item.tax_rate_id || 2} />
					</div>

					<div className="form-item-option">
						<Glyphicon icon="remove" className="circle-icon small red-hover" onClick={this.removeItem} data-item={i} tagName="a" />
					</div>

					<input type="hidden" name={`items[${i}][id]`} value={item.id} readOnly />
				</fieldset>
			);
		}


		return (
			<fieldset id={key} key={key}>
				<legend className="small">{ I18n.r('Item #{0}', i + 1) }</legend>

				<div className="input text">
					<label htmlFor={`items-${i}-label`}>{ I18n.t('Label') }</label>
					<input type="text" name={`items[${i}][label]`} maxLength="255" id={`items-${i}-label`} defaultValue={item.label} required />
				</div>

				<div className="input textarea">
					<label htmlFor={`items-${i}-details`}>{ I18n.t('Details') }</label>
					<textarea name={`items[${i}][details]`} id={`items-${i}-details`} rows="8" defaultValue={item.details} />
				</div>

				<div className="fields-group fields-group-cn-amount">
					<div className="input number item-total">
						<label htmlFor={`items-${i}-fee`}>{ I18n.t('Amount') }</label>
						<input type="number" name={`items[${i}][fee]`} step="any" id={`items-${i}-fee`} defaultValue={item.fee} required />
					</div>
				</div>

				<input type="hidden" name={`items[${i}][quantity]`} value="1" readOnly />

				<div className="input select">
					<label htmlFor={`items-${i}-tax-rate-id`}>{ I18n.t('VAT rate') }</label>
					<TaxRateSelect name={`items[${i}][tax_rate_id]`} id={`items-${i}-tax-rate-id`} value={item.tax_rate_id || 2} />
				</div>

				<div className="form-item-option">
					<Glyphicon icon="remove" className="circle-icon small red-hover" onClick={this.removeItem} data-item={i} tagName="a" />
				</div>

				<input type="hidden" name={`items[${i}][id]`} value={item.id} readOnly />
			</fieldset>
		);
	};



	renderQuoteFields = () => {
		if( this.type !== 'QUOTE' )
			return;

		const invoice = this.state.invoice;

		return (
			<div>
				<div className="input text">
					<label htmlFor="quote-title">{ I18n.t('Title') }</label>
					<input type="text" name="quote_title" maxLength="140" id="quote-title" defaultValue={invoice.quote_title} required />
				</div>

				<div className="input text">
					<label htmlFor="quote-memo">{ I18n.t('Memo') }</label>
					<input type="text" name="quote_memo" maxLength="75" id="quote-memo" defaultValue={invoice.quote_memo} />
				</div>

				<div className="input checkbox">
					<CheckBox label={I18n.t('Single job')} name="quote_single_job" value="1" id="quote-single-job" onChange={ this.quoteSingleJobChange } checked={ invoice.quote_single_job } />
				</div>
			</div>
		);
	}


	renderDateMemo = () => {
		const invoice = this.state.invoice;
		if(! invoice.date_memo )
			return null;

		return (
			<div className="input date required">
				<label>{ I18n.t('Date') }</label>
				<DateSelect name="date_memo" defaultValue={invoice.date_memo} />
			</div>
		)
	}


	renderSubmit = () => {
		if( this.props.isSaving )
			return <button type="submit" className="is-saving">{ I18n.t('Saving...') }</button>

		return <button type="submit">{ I18n.t('Save') }</button>
	}

	render() {
		const invoice = this.state.invoice;

		if( ! invoice.items || invoice.items.length === 0 ) {
			invoice.items = [
				{
					document_type: this.type,
					tax_rate_id: 2,
					percent_margin: 15
				}
			];
		}

		let itemsForm = [];
		invoice.items.forEach((item, i) => {
			itemsForm.push( this.renderItemForm(i, item) );
		});


		return (
			<div className="invoices form">
				<form method="post" acceptCharset="utf-8" id="invoice-edit" onSubmit={this.save}>
					<fieldset>
						<div className="input select">
							<label htmlFor="client-id">{ I18n.t('Client') }</label>
							<AddClientButton type="client" />
							<ClientSelect type="client" id="client-id" name="client_id" value={invoice.client_id} onChange={ this.changeClient } />
						</div>

						{ this.renderDateMemo() }

						{ this.renderQuoteFields() }
					</fieldset>


					<div id="items-form-holder">
						{ itemsForm }

						<div className="items-controls">
							<button className="link-like" id="add-document-item" onClick={this.addItem}>
								<Glyphicon icon="plus" className="circle-icon small" />
								{ I18n.t('Add an item') }
							</button>
						</div>
					</div>

					<div className="form-controls">
						<input type="hidden" name="id" value={ invoice.id || undefined } readOnly />
						{ this.renderSubmit() }
					</div>
				</form>
			</div>
		);
	}
};

EditInvoice.propTypes = {
	tax_rates: PropTypes.arrayOf(PropTypes.object).isRequired,
	clients: PropTypes.arrayOf(PropTypes.object).isRequired,
	invoice: PropTypes.object,
	lastAction: PropTypes.object.isRequired,
	isSaving: PropTypes.bool.isRequired
};



const mapStateToProps = (state, ownProps) => {
	const { tax_rates, invoices, lastAction, isSaving } = state.data || { tax_rates: [], invoices: [], lastAction: {}, isSaving: false };
	const invoice = ownProps.invoice.id ? invoices.find( q => parseInt(q.id, 10) === parseInt(ownProps.invoice.id, 10) ) : ownProps.invoice;
	const countItems = invoice.item ? invoice.items.length : 0;
	const clients = state.data.clients || [];

	if( lastAction.type === INVOICE_SAVED && lastAction.status < 400 ) {
		if(! invoice.hasOwnProperty('id') ) {
			// RELOAD WITH NEW ID !!!
		}
	}

	return {
		tax_rates,
		clients,
		invoice,
		countItems,
		lastAction,
		isSaving
	};
}


function mapDispatchToProps(dispatch) {
	return {
		onSave: (invoice) => {
			dispatch(doSaveInvoice(invoice));
		},

		doClose: () => {
			dispatch(closeOverlay());
		}
		/*
		doEdit: (invoice) => {
			const module = <EditInvoice invoice={invoice} />;
			dispatch(openOverlay( title, module ));
		}
		*/
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditInvoice);
