import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Glyphicon from '../../../components/common/Glyphicon';
import Currency from '../../../components/common/Currency';
import I18n from '../../../locales/I18n';

const ClientButton = ({ client }) => {

	const renderBalance = () => {
		if( client.status.balance === 0 ) {
			return;
		}

		const balanceClassName = ['meta-value'];
		if( client.status.balance > 0 ) {
			balanceClassName.push('warning');
		}
		return (
			<span className="meta balance">
				<Glyphicon icon="time" title={ I18n.t('Due') } />
				<span className={ balanceClassName.join(' ') }>
					<Currency amount={client.status.balance} />
				</span>
			</span>
		);
	}

	return (
		<li className={`data-list-item icon-arrow-right client client-${client.id}`} id={`data-list-item-${client.id}`}>
			<Link to={`/incomes/clients/view/${client.id}`}>
				<h2 className="item-title">{client.name}</h2>
				<div className="item-meta">
					<span className="meta turnover">

						<Glyphicon icon="download" title={ I18n.t('Invoiced') } />
						<span className="meta-value">
							<Currency amount={client.status.invoiced} />
						</span>
					</span>

					{ renderBalance() }
				</div>
			</Link>
		</li>
	);
}

ClientButton.propTypes = {
	client: PropTypes.object.isRequired,
};

export default ClientButton;
