import React from 'react';
import { connect } from 'react-redux';
//import logo from '../../../assets/img/expresso.svg';
import logo from '../../../assets/img/logo-256x256.png';
import { doLogin } from '../UsersActions';
import Cookie from '../../../libs/Cookie';
import CheckBox from '../../../components/forms/CheckBox';
import I18n from '../../../locales/I18n';

import config from '../../../config';


class Login extends React.Component {

	componentDidMount() {
		// Dear browser, autocomplete is fucking OFF...
		document.getElementById('email').value = '';
		document.getElementById('password').value = '';

		const userCookie = Cookie.read(config.cookieName);
		if( userCookie !== null ) {
			const values = {};
			userCookie.split('&').forEach(v => {
				const param = v.split('=');
				values[param[0]] = param[1];
			});

			const data = new FormData();
			data.append('email', values.email);
			data.append('password', values.password);
			data.append('remember', 1);
			this.props.doLogin(data);
		}
	};

	login = e => {
		e.preventDefault();
		const data = new FormData(document.getElementById('login-frm'));
		this.props.doLogin(data);
	}


	flash = () => {
		if( this.props.user.authError !== false ) {
			Cookie.remove(config.cookieName);
			return <div className="auth-message-error">{ I18n.t('Oops. Forgot your password?') }</div>
		}
	}

	render() {
		return (
			<div id="login-form">

				<main id="login" className="form" role="main">
					<div className="logo-container">
						<img src={ logo } className="logo" alt="Expresso" />
						Expresso
					</div>

					{ this.flash() }

					<form method="post" acceptCharset="utf-8" id="login-frm" onSubmit={ this.login } autoComplete="off">
						<div className="input text">
							<label htmlFor="email">{ I18n.t('E-mail address') }</label>
							<input type="email" name="email" placeholder={ I18n.t('E-mail address') } autoComplete="off" autoCorrect="off" autoCapitalize="off" id="email" />
						</div>

						<div className="input text">
							<label htmlFor="password">{ I18n.t('Password') }</label>
							<input type="password" name="password" className="textbox" placeholder={ I18n.t('Password') } id="password" />
						</div>

						<div className="input checkbox">
							<CheckBox name="remember" value="1" defaultChecked id="remember" label={ I18n.t('Remember me') } />
						</div>

						<div className="submit">
							<button type="submit">{ I18n.t('Start making coffee') }</button>
						</div>
					</form>
				</main>
			</div>
		);
	}
}


const mapStateToProps = (state, ownProps) => {
	const { user } = state;
	return {
		user,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		doLogin: data => {
			if( data.get('remember') )
				Cookie.write(config.cookieName, `email=${ data.get('email') }&password=${ data.get('password')}`, 30);
			else
				Cookie.remove(config.cookieName);

			dispatch(doLogin(data));
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
