import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Glyphicon from '../common/Glyphicon';
import EditClient from '../../modules/clients/components/EditClient';

import I18n from '../../locales/I18n';
import { openOverlay } from '../../UIActions';
import { UserRole, userRoleIs } from '../../modules/users/UsersConsts';


class AddClientButton extends React.Component {
	/*
	constructor(props) {
		super(props);
		const value = props.value ? props.value : null;
		const client = value ? props.clients.find(c => c.id === parseInt(value, 10)) : null;
		const initialInputValue = client ? client.short_name : '';
		this.state = ({ value, initialInputValue, inputValue: initialInputValue });
	}
	*/


	onAdd = event => {
		let type = this.props.type || null;
		if( type !== 'client' && type !== 'provider' )
			type = 'client';

		this.props.onAdd(type, this.props.title, this.props.module);
	};


	render() {
		const isManager = userRoleIs(this.props.user, [UserRole.MANAGER]);
		if(! isManager)
			return null;

		return (
			<Glyphicon icon="plus" tagName="a" className="green circle-icon add-new-client" title={ I18n.t('Add new') } onClick={this.onAdd} />
		);
	}
}



AddClientButton.propTypes = {
	type: PropTypes.string,
	user: PropTypes.object.isRequired,
	module: PropTypes.element,
	title: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
	const { user } = state.data || { user: {} };
	const { module, title } = state.ui.overlay || { module: null, title: null };
	return {
		module,
		title,
		user
	};
}


const mapDispatchToProps = (dispatch) => {
	return {
		onAdd: (type, nextTitle, nextView) => {
			const title = type === 'client' ? I18n.t('Add client') : I18n.t('Add provider');
			const defaults = type === 'client' ? { is_client: true } : { is_provider: true };
			const module = <EditClient client={ defaults } nextView={nextView} nextTitle={nextTitle} />;
			const onClose = () => {
				dispatch(openOverlay( nextTitle, nextView ));
			}
			dispatch(openOverlay( title, module, onClose ));
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(AddClientButton);
