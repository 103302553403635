import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Glyphicon from '../../../components/common/Glyphicon';
import SelectBox from '../../../components/forms/SelectBox';
import DateTime from '../../../components/common/DateTime';
import Currency from '../../../components/common/Currency';
import I18n from '../../../locales/I18n';
import config from '../../../config';
import accounting from 'accounting-js';
import { amountInCents } from '../../../libs/Number';
import { getBankAccountLabel } from '../../bank_accounts/BankAccountsHelpers';
import { TransactionCategories, TransactionTypes, transactionsTypesWithDoc } from '../TransactionConsts';
import { InvoiceType, InvoiceStatus } from '../../invoices/InvoicesConsts';
import { ExpenseType, ExpenseStatus } from '../../expenses/ExpensesConsts';

import { doValidateTransaction } from '../TransactionsActions';

class TransactionResume extends React.Component {

	constructor(props) {
		super(props);

		const t = props.transaction;
		if(! t.category)
			t.category = Object.keys(props.categories[props.transaction.type])[0];

		t.referenced_transaction_id = null;
		this.state = t;
	}


	changeCategory = event => {

		let s = {
			category: event.target.value
		};

		// if category has client, set first as default
		// in order to load document list
		// If we dont do that and theres only one document in list,
		// we'll never see it because of the list is loaded
		// with the onchange callback on client select.
		// So if only one client, never callback...
		const clientsList = this.buildClientsList(this.state.type, s.category);
		if( clientsList.length > 0 ) {
			s.client_id = clientsList[0].value;
		}

		this.setState(s);
	}


	changeClient = event => {
		this.setState({ client_id: event.target.value });
	}


	changeDocument = () => {
		//event.preventDefault();

		const documentId = parseInt(document.getElementById(`transaction-${this.state.id}-document`).value, 10);
		const clientIdField = document.getElementById(`transaction-${this.state.id}-client`);

		if(! documentId) {
			clientIdField.value = '';
			this.setState({ client_id: null });
			return;
		}

		const { type, category } = this.state;
		const documents = (type === TransactionTypes.IN && category !== TransactionCategories.CREDIT_NOTE) || (type === TransactionTypes.OUT && category === TransactionCategories.CREDIT_NOTE) ? this.props.invoices : this.props.expenses;
		const doc = documents.find(d => d.id === documentId );
		if( doc ) {
            if( doc.client_id ) {
			   this.setState({ document_id: doc.id, client_id: doc.client_id });
               return;
            }
			this.setState({ document_id: doc.id });
			return;
		}

		clientIdField.value = '';
		this.setState({ client_id: null });
		return;
	}



	changeReferencedTransaction = event => {
		this.setState({ referenced_transaction_id: event.target.value });
	}




	validateTransaction = event => {
		const frm = event.target.closest('form');
		const data = new FormData(frm);

		if(! data.get('id')) {
			return;
		}
		this.props.onValidate(data);
	}




	hasDocument = category => {
		return transactionsTypesWithDoc.indexOf(category) > -1;
	}





	getDocumentExistingTransactionsAmount = (transactionType, transactionCategory, documentId) => {
		const transactions = this.props.transactions.filter(t => {
			return t.validated && t.type === transactionType && t.category === transactionCategory && parseInt(t.document_id, 10) === parseInt(documentId, 10);
		});
		if( transactions.length === 0 )
			return 0;

		let amount = 0;
		transactions.forEach(t => { amount += parseFloat(t.amount) });

		return parseFloat(amount);
	}


	getDocumentType = () => {
		const transaction = this.state;
		if( transaction.category === TransactionCategories.OUTCOME )
			return ExpenseType.INVOICE;

		if( transaction.category === TransactionCategories.INCOME )
			return InvoiceType.INVOICE;

		return transaction.category;
	}


	getDocumentsList = () => {
		const transaction = this.state;
		const type = this.getDocumentType();

		const properties = {
			INV: {
				key: 'invoices',
				prop: 'iid',
				closedStatus: InvoiceStatus.CLOSED,
			},
			EXP: {
				key: 'expenses',
				prop: 'label',
				closedStatus: ExpenseStatus.CLOSED,
			}
		};

		const props =
			(transaction.type === TransactionTypes.IN && transaction.category !== TransactionCategories.CREDIT_NOTE) ||
			(transaction.type === TransactionTypes.OUT && transaction.category === TransactionCategories.CREDIT_NOTE) ?
			properties.INV :
			properties.EXP;

		const documents = [];

		this.props[props.key].forEach(d => {
			if(d.status === props.closedStatus || d.type !== type) {
				return;
			}

			const isClient =
				transaction.client_id && type !== TransactionCategories.FEE ?
				parseInt(transaction.client_id, 10) === parseInt(d.client_id, 10) :
				true;

			if(! isClient) {
				return;
			}

			const amountLeft = d.amount_with_taxes - this.getDocumentExistingTransactionsAmount(transaction.type, transaction.category, d.id);
			if( amountLeft.toString() === this.state.amount.toString() ) {
				documents.push({
					label: `${d[props.prop]} –  ${d.amount_with_taxes}`,
					value: d.id,
				});
				return;
			}

			const balance = accounting.formatMoney(amountLeft, this.props.currencyFormat);
			const balanceInfo =
				amountLeft < d.amount_with_taxes ?
				`Balance: ${balance} of ${accounting.formatMoney(d.amount_with_taxes, this.props.currencyFormat)}` :
				balance;

			documents.push({
				label: `${d[props.prop]} –  ${balanceInfo}`,
				value: d.id
			});
		});

		if(! documents.length) {
			documents.push({ value: '', label: I18n.t('No match') });
		}
		return documents;
	}



	getReferencedTransactions = () => {
		const transaction = this.state;

		const refsTra = this.props.referenced_transactions;

		if( refsTra === 0 ) {
			return [];
		}
		const types =
			transaction.type === TransactionTypes.IN ?
			[TransactionCategories.INCOME, TransactionCategories.INVEST] :
			[TransactionCategories.OUTCOME, TransactionCategories.TAX];

		const trAmount = amountInCents( transaction.amount < 0 ? transaction.amount * -1 : transaction.amount );

		// if amount is provided in transaction, reduce to
		const references = refsTra.filter(ref => {
			const isAmount = ref.amount ? trAmount === amountInCents(ref.amount) : true;
			return types.indexOf(ref.type) > -1 && isAmount;
		});

		if( references.length === 0 ) {
			return [];
		}

		return references.sort((a, b) => {
			const aAmount = a.amount ? amountInCents(a.amount) : null;
			const bAmount = b.amount ? amountInCents(b.amount) : null;
			if( aAmount && bAmount && aAmount === bAmount ) {
				return 0;
			}
			return aAmount && aAmount === trAmount ? -1 : 1;
		})
			.map(ref => ({
				label: ref.name,
				value: ref.id,
			}));
	}


	getClientsList = () => {
		const transaction = this.state;
		if(! transaction.category )
			return [];

		return this.buildClientsList(transaction.type, transaction.category);
	}



	buildClientsList = (type, category) => {
		const clientIds = [];
		if( category === TransactionCategories.FEE || ! this.hasDocument(category) ) {
			return [];
		}

		if( type === TransactionTypes.IN ) {
			this.props.invoices.forEach(i => {
				if( i.type === InvoiceType.INVOICE && i.status !== InvoiceStatus.CLOSED ) {
					clientIds.push(i.client_id);
				}
			});
		}
		else {
			this.props.expenses.forEach(e => {
				if( e.type === ExpenseType.INVOICE && e.status !== ExpenseStatus.CLOSED ) {
					clientIds.push(e.client_id);
				}
			});
		}

		const clientPropFilter = type === TransactionTypes.IN ? 'is_client' : 'is_provider';

		return this.props.clients
			.filter(c => c[clientPropFilter] && clientIds.indexOf(c.id) > -1)
			.map(c => ({
				value: c.id,
				label: c.short_name
			}));
	}


	_buildInputId = name => {
		const transaction = this.state;
		console.log(transaction);
		return `transaction-${transaction.id}-${name}`;
	}


	renderClientSelect = () => {
		const clientsList = this.getClientsList();
		if( clientsList.length === 0 ) {
			return '';
		}

		const id = this._buildInputId('client');
		const transaction = this.state;
		const clientInputLabel = transaction.type === TransactionTypes.IN ? I18n.t('Client') : I18n.t('Provider');

		return (
			<div className="input select">
				<label htmlFor={ id }>{ clientInputLabel }</label>
				<SelectBox
					name="client_id"
					id={ id }
					value={ this.state.client_id || '' }
					options={ clientsList }
					onChange={ this.changeClient }
				/>
			</div>
		);
	}



	renderDocumentSelect = () => {
		const transaction = this.state;
		if(! transaction.category ) {
			return '';
		}

		if( transaction.category === TransactionCategories.REFERENCED_TRANSACTION ) {
			let references = this.getReferencedTransactions();
			if( references.length === 0 ) {
				return '';
			}

			const refId = this._buildInputId('referenced-transaction');

			return (
				<div className="input select">
					<label htmlFor={ refId }>{ I18n.t('Ref.') }</label>
					<SelectBox
						name="referenced_transaction_id"
						id={ refId }
						value={ this.state.referenced_transaction_id || '' }
						options={ references }
						onChange={ this.changeReferencedTransaction }
					/>
				</div>
			);
		}

		if( this.hasDocument(transaction.category) ) {
			const docId = this._buildInputId('document');

			return (
				<div className="input select">
					<label htmlFor={ docId }>{ I18n.t('Document') }</label>
					<SelectBox
						name="document_id"
						id={ docId }
						value={ this.state.document_id || '' }
						options={ this.getDocumentsList() }
						onChange={ this.changeDocument }
					/>
				</div>
			);
		}

		return '';
	}



	renderCompanyBankAccountSelect = () => {
		const transaction = this.state;
		const account = this.props.bankAccounts.find(a => a.id === transaction.bank_account_id);
		if( account ) {
			return (
				<div className="fields-group">
					<div className="input text">
						<span className="label-like">{ I18n.t('Paid on') }</span>
						<span className="input-like">{ getBankAccountLabel(account) }</span>
					</div>
				</div>
			);
		}

		return '';
	}





	renderThirdPartyBankAccount = () => {
		const transaction = this.state;

		if(! transaction.bankaccount_iban) {
			const id = this._buildInputId('iban');
			return (
				<div className="input text">
					<label htmlFor={ id }>{ I18n.t('Account') }</label>
					<input type="text" name="bankaccount_iban" id={ id } defaultValue="" />
				</div>
			);
		}


		if( transaction.bankaccount_name )
			return (
				<div className="input text">
					<span className="label-like">{ I18n.t('Account') }</span>
					<span className="input-like">{transaction.iban} {transaction.bankaccount_name}</span>
				</div>
			);

		return '';
	}





	renderMessage = () => {
		const transaction = this.state;

		if(transaction.message)
			return (
				<div className="input text">
					<span className="label-like">{ I18n.t('Message') }</span>
					<span className="input-like">{transaction.message}</span>
				</div>
			);

		if( transaction.structured_message )
			return (
				<div className="input text">
					<span className="label-like">{ I18n.t('Msg (struct)') }</span>
					<span className="input-like">{transaction.struct_msg}</span>
				</div>
			);

		const id = this._buildInputId('message');
		return  (
			<div className="input textarea">
				<label htmlFor={ id }>{ I18n.t('Message') }</label>
				<textarea name="message" id={ id } defaultValue="" />
			</div>
		);
	}






	render() {
		const transaction = this.state;

		const categoriesOptions = Object
			.keys(this.props.categories[transaction.type])
			.map( key => ({
					label: this.props.categories[transaction.type][key],
					value: key
				}));

		return (
			<form key={`transaction-${transaction.id}`} method="post" acceptCharset="utf-8" className={`transaction-${transaction.id}-form`}>
				<input
					type="hidden"
					name="id"
					value={transaction.id}
					readOnly
				/>
				<input
					type="hidden"
					name="client_id"
					id={ this._buildInputId('client-hidden') }
					value={ this.state.client_id || '' }
					readOnly
				/>
				<input type="hidden" name="validated" value={true} readOnly />

				<fieldset>
					<div className="fields-group">
						<div className="input date">
							<span className="label-like">{ I18n.t('Date') }</span>
							<span className="input-like">
								<DateTime date={transaction.date_memo} format="dd/mm/yyyy" />
							</span>
						</div>

						<div className="input number">
							<span className="label-like">{ I18n.t('Amount') }</span>
							<span className="input-like">
								<Currency amount={ transaction.amount } />
							</span>
						</div>
					</div>

					{ this.renderCompanyBankAccountSelect() }

					<div className="fields-group">
						<div className="input select">
							<label htmlFor={ this._buildInputId('category') }>{ I18n.t('Category') }</label>
							<SelectBox
								name="category"
								id={ this._buildInputId('category') }
								value={ this.state.category || '' }
								options={ categoriesOptions }
								onChange={ this.changeCategory }
							/>
						</div>

						{ this.renderClientSelect() }
					</div>

					{ this.renderDocumentSelect() }

					{ this.renderThirdPartyBankAccount() }

					{ this.renderMessage() }

					<div className="form-item-option">
						<Glyphicon
							icon="ok"
							className="circle-icon small green-hover"
							onClick={this.validateTransaction}
							tagName="a"
						/>
					</div>
				</fieldset>
			</form>
		);
	}
};





TransactionResume.propTypes = {
	currencyFormat: PropTypes.object.isRequired,
	bankAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
	transaction: PropTypes.object.isRequired,
	categories: PropTypes.object.isRequired,
	transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
	expenses: PropTypes.arrayOf(PropTypes.object).isRequired,
	invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
	clients: PropTypes.arrayOf(PropTypes.object).isRequired,
	referenced_transactions: PropTypes.arrayOf(PropTypes.object),
	settings: PropTypes.object.isRequired
};



const mapStateToProps = (state, ownProps) => {
	const { transactions, transaction_categories, expenses, invoices, clients, companySettings, referenced_transactions, company } = state.data || { clients: [], expenses: [], invoice: [], referenced_transactions:[], transaction_categories: {}, companySettings: {}, company: {} };
	const bankAccounts = company.bank_accounts || [];


	let currencyFormat = config.format.currency;
	if( companySettings && companySettings.currency_info.symbol )
		currencyFormat.symbol = companySettings.currency_info.symbol

	return {
		currencyFormat,
		transactions,
		bankAccounts,
		categories: transaction_categories,
		expenses,
		invoices,
		clients,
		referenced_transactions,
		settings: companySettings,
	};
}


const mapDispatchToProps = (dispatch) => {
	return {
		onValidate: (transaction) => {
			dispatch(doValidateTransaction(transaction));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionResume);
