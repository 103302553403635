import {
	CLIENT_SAVED,
	JOB_SAVED,
	JOB_DELETED,
	JOBS_INVOICED,
	INVOICE_SAVED,
	INVOICE_DELETED,
	INVOICE_SENT,
	JOB_FROM_QUOTE_CREATED,
	EXPENSE_SAVED,
	EXPENSE_DELETED,
	TRANSACTION_SAVED,
	TRANSACTION_DELETED,
	USER_SAVED,
	USER_DELETED,
	BANKACCOUNT_SAVED,
	BANKACCOUNT_DELETED,
	SETTINGS_SAVED,
	OVERLAY_OPEN,
	OVERLAY_CLOSE,
	DOCVIEWER_OPEN,
	DOCVIEWER_CLOSE,
	CODA_IMPORTED,
	FLASH_MESSAGE_HIDE
} from '../AppEvents';


const initialState = {
	overlay: {
		title:null,
		module:null,
		onClose:null
	},
	docViewer: {
		url: null,
		options: []
	},
	flashMessage: null
};



const UIReducer = (state = initialState, action) => {
	let newState = Object.assign( {}, state, {} );

	const actionsDone = [
		CLIENT_SAVED,
		JOB_SAVED,
		JOB_DELETED,
		JOBS_INVOICED,
		INVOICE_SAVED,
		INVOICE_DELETED,
		INVOICE_SENT,
		JOB_FROM_QUOTE_CREATED,
		EXPENSE_SAVED,
		EXPENSE_DELETED,
		TRANSACTION_SAVED,
		TRANSACTION_DELETED,
		USER_SAVED,
		USER_DELETED,
		BANKACCOUNT_SAVED,
		BANKACCOUNT_DELETED,
		SETTINGS_SAVED,
		CODA_IMPORTED
	];

	if( actionsDone.indexOf(action.type) !== -1 ) {
		const flashMessageObject = { action: action };
		flashMessageObject.type = action.status < 400 ? 'success' : 'error';
		if( action.status < 400 )
			flashMessageObject.message = action.message;

		newState = Object.assign({}, newState, { flashMessage: flashMessageObject });
	}

	switch (action.type) {
		case OVERLAY_OPEN:
			newState = Object.assign({}, newState, {
				overlay: {
					title: action.title,
					module: action.module,
					onClose: action.onClose
				}
			});
			return newState;

		case OVERLAY_CLOSE:
			newState = Object.assign({}, newState, {
				overlay: {
					title: null,
					module: null,
					onClose: null
				}
			});
			return newState;


		case DOCVIEWER_OPEN:
			newState = Object.assign({}, newState, {
				docViewer: {
					url: action.url,
					options: action.options,
				}
			});
			return newState;

		case DOCVIEWER_CLOSE:
			newState = Object.assign({}, newState, {
				docViewer: {
					url: null,
					options: []
				}
			});
			return newState;

		case FLASH_MESSAGE_HIDE:
			return Object.assign({}, newState, { flashMessage: null });

		default:
			return newState;
	}
}

export default UIReducer;
