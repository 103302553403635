import { combineReducers } from 'redux'

import DataReducer from './DataReducer';
import UserReducer from './UserReducer';
import LocaleReducer from './LocaleReducer';
import UIReducer from './UIReducer';


const AppReducer = combineReducers({
	user: UserReducer,
	data: DataReducer,
	ui: UIReducer,
	locale: LocaleReducer
})

export default AppReducer;
