import React from 'react';
import { connect } from 'react-redux';
import BankStatementImporter from '../../../components/forms/BankStatementImporter';
import ValidateTransaction from './ValidateTransaction';
import { doImportCoda } from '../TransactionsActions';



class ImportTransaction extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			mustValidate: false
		}
	}


	/*
 	 * When user saves transaction ...
	 */
	onFileDrop = (acceptedFiles, rejectedFiles) => {
		this.props.onSendFiles(acceptedFiles);
		// When re-render after refreshed state with imported transactions, render <ValidateTransaction />
		this.setState({ mustValidate: true });
	}


	componentDidUpdate(prevProps, prevState) {
		if( this.state.mustValidate && (this.props.updated >  prevProps.updated) ) {
			if( this.props.waitingTransactions.length === 0 )
				this.setState({ mustValidate: false });
		}
	}


	render() {
		if( this.state.mustValidate )
			return <ValidateTransaction />

		return (
			<div data-updated={this.props.updated} className="transaction form">
				<BankStatementImporter id="dropzone-transactions" onDrop={ this.onFileDrop.bind(this) } />
			</div>
		);
	}
}



const mapStateToProps = (state, ownProps) => {
	const { lastUpdated, transactions } = state.data || { lastUpdated: false, transactions: [] };
	const waitingTransactions = transactions.filter(tr => ! tr.validated);
	return { updated: lastUpdated, waitingTransactions };
}


const mapDispatchToProps = (dispatch) => {
	return {
		onSendFiles: (files) => {
			const importData = new FormData();
			files.forEach(file => {
				importData.append('files[]', file, file.name);
			})

			dispatch(doImportCoda(importData));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportTransaction);
