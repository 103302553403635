import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from '../../locales/I18n';
import Glyphicon from './Glyphicon';
import DateJS from '../../libs/DateJS';
import { doChangePeriod } from '../../modules/users/UsersActions';



class PeriodSwitcher extends React.Component {


	changePeriod = event => {
		this.props.changePeriod(event.target.value);
	}

	getYears = () => {
		const currentYear = new Date().getFullYear();
		const { startYear } = this.props;
		const totalYears = currentYear - startYear;

		if( totalYears === 0 )
			return [ currentYear ];

		const years = [];
		for( let y = startYear; y <= currentYear; y ++ )
			years.push(y);

		return years;
	}


	buildSubOptions = (year) => {
		const quarters = [
			{ start: '01-01', end: '03-31', label: I18n.t('Q1') },
			{ start: '04-01', end: '06-30', label: I18n.t('Q2') },
			{ start: '07-01', end: '09-30', label: I18n.t('Q3') },
			{ start: '10-01', end: '12-31', label: I18n.t('Q4') }
		];

		const date = DateJS.today();
		const mmdd = `${date.m}-${date.d}`;

		const options = quarters.map( q => {
			if( year.toString() === date.y && mmdd < q.start )
				return '';

			const value = `${year}-${q.start}|${year}-${q.end}`;
			const label = `${year} - ${q.label}`;
			return <option key={ value } value={ value }>{ label }</option>;
		})

		return options;
	}

	buildOptions = () => {
		const years = this.getYears();

		const options = years.map(y => {
			const optionsGroup = this.buildSubOptions(y)
			return (
				<Fragment key={y}>
					<option value={y}>{y}</option>
					<optgroup label={`${y} ${ I18n.t('quarters') }`}>
						{ optionsGroup }
					</optgroup>
				</Fragment>
			)
		});

		return options;
	}


	render() {
		if(! this.props.componentActivated)
			return null;

		return (
			<div className="period-swicher">
				<select name="year" className="switcher" value={ this.props.activePeriod } onChange={this.changePeriod}>
					{ this.buildOptions() }
				</select>

				<Glyphicon icon="chevron-down" />
			</div>
		);
	};
};

PeriodSwitcher.propTypes = {
	startYear: PropTypes.number.isRequired,
	activeYear: PropTypes.number.isRequired,
	componentActivated: PropTypes.bool.isRequired
};


const mapStateToProps = (state, ownProps) => {
	const startYear = state.data.startYear || new Date().getFullYear();
	const activePeriod = state.data.activePeriod || new Date().getFullYear();
	const activeYear = typeof(activePeriod) === 'string' ? parseInt(activePeriod.slice(0,4), 10) : activePeriod;
	const componentActivated = state.data.user.user_role.level <= state.data.userRoleLevels.ACCOUNTANT || false;

	return {
		startYear,
		activePeriod,
		activeYear,
		componentActivated
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		changePeriod: (period) => {
			dispatch(doChangePeriod(period));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PeriodSwitcher);
