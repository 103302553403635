import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import DateTime from '../../../components/common/DateTime';
import Currency from '../../../components/common/Currency';
import ItemOptions from '../../../components/common/ItemOptions';
import I18n from '../../../locales/I18n';
import EditExpense from './EditExpense';
import { openOverlay, openDocumentViewer } from '../../../UIActions';
import { doSaveExpense, doDeleteExpense } from '../ExpensesActions';

class CreditNote extends React.Component {


	editForm = event => {
		event.preventDefault();
		const invoice = { id: this.props.cn.id };
		this.props.getForm(I18n.t('Edit credit note'), invoice);
	}


	doDuplicate = () => {
		const invoice = Object.assign({}, this.props.cn, { id: '', date_memo: null, attached_file: '', status: 'OPEN' });
		this.props.getForm(I18n.t('Add credit note'), invoice);
	}


	markAsClosed = event => {
		event.preventDefault();
		this.closeExpense(false);
	}
	/*
	markAsPaid = event => {
		event.preventDefault();
		this.closeExpense(true);
	}
	*/
	closeExpense = updateClientStatus => {
		const invoice = this.props.cn;
		if( invoice.status === 'CLOSED' )
			return null;

		const expenseForm = new FormData();
		expenseForm.append('id', invoice.id);
		expenseForm.append('action', 'updateStatus');
		expenseForm.append('status', 'CLOSED');

		if( updateClientStatus )
			expenseForm.append('paid', '1');

		this.props.onStatusChange(expenseForm);
	}


	viewDocument = event => {
		event.preventDefault();

		const cn = this.props.cn;
		const options = [
			{
				label: I18n.t('Download'),
				url: cn.download_url
			}
		];

		this.props.viewDocument(cn.view_url, options);
	}


	doDelete = event => {
		event.preventDefault();
		this.props.onDelete(this.props.cn)
	}


	getItemOptions = () => {
		const cn = this.props.cn;
		const options = [];

		if(cn.view_url) {
			options.push({
				label: I18n.t('View'),
				action: this.viewDocument,
				icon: 'open'
			});
		}

		options.push({
			label: I18n.t('Duplicate'),
			action: this.doDuplicate,
			icon: 'duplicate'
		});

		if( cn.status !== 'CLOSED' ) {
			options.push({
				label: I18n.t('Edit'),
				action: this.editForm,
				icon: 'edit'
			});
		}
		else if(! cn.attached_file) {
			options.push({
				label: I18n.t('Add file'),
				action: this.editForm,
				icon: 'open'
			});
		}

		if( cn.status !== 'CLOSED' ) {
			options.push({
				label: I18n.t('Delete'),
				action: this.doDelete,
				icon: 'trash'
			});
		}

		return options;
	}



	render() {
		const { cn, client } = this.props;

		const classNames = ['item', 'credit-note', `credit-note-${cn.id}`];
		if(cn.status === 'OPEN')
			classNames.push('open');

		return (
			<li className="data-list-item">
				<div className={ classNames.join(' ') } id={`invoice-${cn.id}`} data-id={cn.id}>
					<div className="item-main-col">
						<div className="item-title">
							<button className="link-like" onClick={ this.viewDocument }>{cn.label}</button>
						</div>

						<div className="itemclient">
							<Link to={`/outcomes/providers/view/${client.id}`}>{client.short_name}</Link>
						</div>
					</div>

					<div className="item-col-date">
						<DateTime date={cn.date_memo} format="dd mmm yyyy" />
					</div>

					<div className="item-col-amount col-amount-taxexcl">
						<div className="amount">
							<Currency amount={cn.amount} />
						</div>
					</div>

					<div className="item-col-amount col-amount-taxincl">
						<div className="amount">
							<Currency amount={cn.amount_with_taxes} />
						</div>
					</div>

					<ItemOptions options={ this.getItemOptions() } />
				</div>
			</li>
		)
	}
};

CreditNote.propTypes = {
	cn: PropTypes.object.isRequired,
	client: PropTypes.object.isRequired,
	onStatusChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	getForm: PropTypes.func.isRequired,
	viewDocument: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
	const { clients, searchTerms } = state.data || { clients: [], searchTerms: false };
	const client = clients.find(c => parseInt(c.id, 10) === parseInt(ownProps.cn.client_id, 10)) || {}
	return { client, searchTerms }
}


const mapDispatchToProps = (dispatch) => {
	return {
		onStatusChange: (expense) => {
			dispatch(doSaveExpense(expense))
		},

		onDelete: (expense) => {
			dispatch(doDeleteExpense(expense))
		},

		getForm: (title, expense) => {
			const module = <EditExpense expense={expense} />;
			dispatch(openOverlay(title, module ));
		},

		viewDocument: (url, options) => {
			dispatch(openDocumentViewer(url, options));
		}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(CreditNote);
