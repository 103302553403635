
import { saveTransaction, deleteTransaction, validateTransaction, importCoda } from '../../api';
import I18n from '../../locales/I18n';

import {
	TRANSACTION_DO_SAVE,
	TRANSACTION_SAVED,
	TRANSACTION_DO_DELETE,
	TRANSACTION_DELETED,
	TRANSACTION_DO_VALIDATE,
	TRANSACTION_VALIDATED,
	CODA_DO_IMPORT,
	CODA_IMPORTED
} from '../../AppEvents';




function transaction_save(transaction) {
	/*
	 * Transaction can be partial here but not when saving data !!!
     */
	return {
		type: TRANSACTION_DO_SAVE,
		transaction
	}
}


function transaction_saved(response) {
	const r = {
		type: TRANSACTION_SAVED,
		status: parseInt(response.status, 10),
		transaction: response.transaction,
		bank_account_statuses: response.bank_account_statuses,
		receivedAt: Date.now()
	}

	r.message = r.status === 200 ? I18n.t('Transaction was saved') : response.message;
	return r;
}



function transaction_delete(transaction) {
	return {
		type: TRANSACTION_DO_DELETE,
		transaction
	}
}


function transaction_deleted(response) {
	const r = {
		type: TRANSACTION_DELETED,
		status: parseInt(response.status, 10),
		id: parseInt(response.id, 10),
		bank_account_statuses: response.bank_account_statuses,
		receivedAt: Date.now()
	}

	r.message = r.status === 200 ? I18n.t('Transaction was deleted') : response.message;
	return r;
}


function transaction_validate(transaction) {
	return {
		type: TRANSACTION_DO_VALIDATE,
		transaction
	}
}


function transaction_validated(response) {
	return {
		type: TRANSACTION_VALIDATED,
		status: parseInt(response.status, 10),
		data: response,
		receivedAt: Date.now()
	}
}


function coda_import(files) {
	return {
		type: CODA_DO_IMPORT,
		files
	}
}


function coda_imported(response) {
	return {
		type: CODA_IMPORTED,
		status: parseInt(response.status, 10),
		flash: response.flash,
		transactions: response.transactions,
		bank_account_statuses: response.bank_account_statuses,
		receivedAt: Date.now(),
		message: response.transactions.length ? I18n.t('Transaction(s) imported') : I18n.t('Nothing to import')
	};
}


export function doSaveTransaction(transaction) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(transaction_save(transaction));
			return saveTransaction(transaction).then(json => dispatch(transaction_saved(json)));
		});
	}
}


export function doValidateTransaction(transaction) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(transaction_validate(transaction));
			return validateTransaction(transaction).then(json => dispatch(transaction_validated(json)));
		});
	}
}


export function doDeleteTransaction(transaction) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(transaction_delete(transaction));
			return deleteTransaction(transaction).then(json => dispatch(transaction_deleted(json)));
		});
	}
}


export function doImportCoda(files) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(coda_import(files));
			return importCoda(files).then(json => {
				const d = dispatch(coda_imported(json));
				//dispatch(openOverlay(I18n.t('Validate transactions'), <ValidateTransactions />))
				return d;
			});
		});
	}
}
