import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BankAccount from './BankAccount';
import EditBankAccount from './EditBankAccount';
import I18n from '../../../locales/I18n';
import { openOverlay } from '../../../UIActions';


class BankAccounts extends React.Component {

	addAccount = event => {
		event.preventDefault();
		this.props.onEditForm({ client_id: this.props.client });
	}

	render() {
		const accounts = this.props.accounts;
		if(! accounts.length )
			return (<div className="message-empty">{ I18n.t("There's no saved account...") }</div>)

		const accountsList = Object
				.keys(accounts)
				.map(key => (<BankAccount account={ accounts[key] } forceDefault={ accounts.length < 2 } key={`bankaccount_${accounts[key].id}`} />) );

		return (
			<div className="bank-accounts form">
				{ accountsList }

				<div className="form-controls">
					<button type="submit" onClick={ this.addAccount }>{ I18n.t('Add account') }</button>
				</div>
			</div>
		);
	}
};

BankAccounts.propTypes = {
	client: PropTypes.number
};



const mapStateToProps = (state, ownProps) => {
	if( parseInt(ownProps.client, 10) === parseInt(state.data.company.id, 10) )
		return {
			accounts: state.data.company.bank_accounts
		};

	const client = state.data.clients.find(c => c.id === parseInt(ownProps.client, 10));
	if( client )
		return { accounts: client.bank_accounts };

	return { accounts: {} }
}


const mapDispatchToProps = (dispatch) => {
	return {
		onEditForm: (account) => {
			dispatch(openOverlay( I18n.t('Add account'), <EditBankAccount account={ account } />));
		}
	}
}



export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts);
