import {
	JOB_DO_SAVE,
	JOB_SAVED,
	JOB_DELETED,
	JOBS_INVOICED,
	JOB_USERS_SAVED,
	JOB_USER_DELETED,
	JOB_OWNER_SAVED,
	INVOICE_DO_SAVE,
	INVOICE_SAVED,
	INVOICE_DELETED,
	INVOICE_CREDIT_NOTE_CREATED,
	JOB_FROM_QUOTE_CREATE,
	JOB_FROM_QUOTE_CREATED,
	SESSION_ADDED,
	SESSION_SAVED,
	SESSION_COMPLETED,
	SESSION_DELETED,
	EXPENSE_DO_SAVE,
	EXPENSE_SAVED,
	EXPENSE_DELETED,
	CLIENT_DO_SAVE,
	CLIENT_SAVED,
	CLIENT_USERS_SAVED,
	BANKACCOUNT_DO_SAVE,
	BANKACCOUNT_SAVED,
	BANKACCOUNT_DELETED,
	BANKACCOUNT_MADE_DEFAULT,
	TRANSACTION_DO_SAVE,
	TRANSACTION_SAVED,
	CODA_IMPORTED,
	TRANSACTION_VALIDATED,
	USER_GOT_DATA,
	USER_GET_DATA,
	USER_SYNC_DATA,
	PERIOD_CHANGED,
	USER_DO_SAVE,
	USER_SAVED,
	USER_DELETED,
	USER_SIGNED_OUT,
	SETTINGS_DO_SAVE,
	SETTINGS_SAVED,
	DO_SEARCH
} from '../AppEvents';

/*
 * Note --
 * Reducer function take initial state as its first parameter
 */

const initialState = {
	isFetching: false,
	isSaving: false,
	didInvalidate: true,
	lastUpdated: null,
	startYear: (new Date().getFullYear()),
	activePeriod: (new Date().getFullYear()).toString(),
	loadedYears: [],
	lastAction: { type: null, status: undefined, success: null },
	user: {},
	users: [],
	userRoles: [],
	userRoleLevels: {},
	company: {},
	clients: [],
	invoices: [],
	jobs: [],
	job_sessions: [],
	jobs_statuses: [],
	expenses: [],
	tax_rates: [],
	transactions: [],
	transaction_categories: {},
	referenced_transactions: [],
	companySettings: {},
	stats: {},
	searchTerms: false,
	last_event_id: 0
};




function DataReducer(state = initialState, action) {
	const lastAction = {
		type: action.type,
		status: action.status,
		success: action.status >= 200 && action.status < 400
	};

	let newState = Object.assign({}, state, { isSaving: false, lastAction: lastAction });
	let index;
	let updateObject;


	switch (action.type) {
		case CLIENT_DO_SAVE:
		case JOB_DO_SAVE:
		case INVOICE_DO_SAVE:
		case EXPENSE_DO_SAVE:
		case TRANSACTION_DO_SAVE:
		case USER_DO_SAVE:
		case BANKACCOUNT_DO_SAVE:
		case SETTINGS_DO_SAVE:
		case JOB_FROM_QUOTE_CREATE:
			newState = Object.assign({}, newState, { isSaving: true });
			return newState;



		case DO_SEARCH:
			newState = Object.assign({}, newState, { searchTerms: action.terms });
			return newState;

		case USER_SIGNED_OUT:
			newState = initialState;
			return Object.assign({}, state, { lastAction: lastAction} );

		case USER_GET_DATA:
			newState = Object.assign({}, newState, { isFetching: true });
			return newState;

		case USER_GOT_DATA:
			if( action.status >= 400 ) {
				newState = Object.assign({}, newState, { isFetching: false });
				return newState;
			}

			if( action.data.status === 403 ) {
				newState = Object.assign({}, newState, { isFetching: false });
				return newState;
			}

			const loadedYears = newState.loadedYears.concat([ parseInt(action.data.year,10) ]);

			let userRoleLevels = {};
			action.data.userRoles.forEach(role => {
				userRoleLevels[role.label] = role.level;
			});

			let updatedDataObject = {
				isFetching: false,
				didInvalidate: false,
				startYear: parseInt(action.data.startYear, 10),
				loadedYears:loadedYears,
				user: action.data.user,
				users: action.data.users,
				company: action.data.company,
				clients: action.data.clients,
				jobs: action.data.jobs,
				job_sessions: action.data.job_sessions,
				jobs_statuses: action.data.jobs_statuses,
				tax_rates: action.data.tax_rates,
				companySettings: action.data.companySettings,
				userRoles: action.data.userRoles,
				userRoleLevels: userRoleLevels,
				lastUpdated: action.receivedAt,
				last_event_id: action.data.last_event_id
			};

			if( action.data.invoices )
				updatedDataObject.invoices = action.data.invoices;

			if( action.data.user.user_role.level <= userRoleLevels.ACCOUNTANT ) {
				updatedDataObject.expenses = action.data.expenses;
				updatedDataObject.transaction_categories = action.data.transaction_categories;
				updatedDataObject.referenced_transactions = action.data.referenced_transactions;
				updatedDataObject.transactions = action.data.transactions;
				updatedDataObject.stats = action.data.stats;
			}

			newState = Object.assign({}, newState, updatedDataObject);

			return newState;



		case USER_SYNC_DATA:
			if( action.data.status === 403 )
				return newState;

			// update stats
			if( action.data.hasOwnProperty('stats') ) {
				let updatedObject = {
					stats: Object.assign({}, newState.stats, action.data['stats'])
				};
				newState = Object.assign({}, newState, updatedObject);
			}

			const possibleUpdatedObjectKeys = ['user', 'last_event_id', 'company', 'transaction_categories', 'companySettings'];
			possibleUpdatedObjectKeys.forEach(key => {
				if( action.data.hasOwnProperty(key) ) {
					let updatedObject = {};
					updatedObject[key] = action.data[key];
					newState = Object.assign({}, newState, updatedObject);
				}
			});


			const possibleUpdatedArrayKeys = ['jobs', 'job_sessions', 'clients', 'users', 'invoices', 'expenses', 'transactions', 'referenced_transactions', 'tax_rates'];
			possibleUpdatedArrayKeys.forEach(key => {
				if( action.data.hasOwnProperty(key) ) {
					let updatedObject = {}
					updatedObject[key] = mergeData(newState[key], action.data[key]);
					newState = Object.assign({}, newState, updatedObject);
				}
			});

			const possibleDeletedKey = ['clients', 'jobs', 'job_sessions', 'tax_rates', 'users', 'invoices', 'expenses', 'transactions', 'referenced_transactions'];
			possibleDeletedKey.forEach(key => {
				const deletedKey = `${key}_deleted`;
				if( action.data.hasOwnProperty(deletedKey) ) {
					let updatedObject = {}
					updatedObject[key] = deleteFromCollection(newState[key], action.data[deletedKey]);
					newState = Object.assign({}, newState, updatedObject);
				}
			});

			return newState;


		case PERIOD_CHANGED:
			const period = action.period;
			newState = Object.assign({}, newState, { activePeriod: period });

			return newState;


		/*
	     * Client was saved
		 */
		case CLIENT_SAVED:
		case CLIENT_USERS_SAVED:
			if( action.status >= 400 )
				return newState;

			const updatedClients = mergeData(newState.clients, action.client);
			updatedClients.sort((a, b) => {
				if(a.name.toLowerCase() > b.name.toLowerCase())
					return 1;

				if(a.name.toLowerCase() < b.name.toLowerCase())
					return -1;

				return 0;
			});

			updateObject = {
				isFetching: false,
				isSaving: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				clients: updatedClients
			};
			return Object.assign({}, newState, updateObject);





		/*
		 * Account was saved
		 */
		case BANKACCOUNT_SAVED:
			if( action.status >= 400 )
				return newState;

			//
			// If depends of current company
			if( parseInt(newState.company.id,10) === parseInt(action.account.client_id, 10) ) {
				let company = newState.company;
				company.bank_accounts = mergeData(company.bank_accounts, action.account);

				newState =  Object.assign({}, newState, {
					isFetching: false,
					didInvalidate: false,
					lastUpdated: action.receivedAt,
					company: company,
					isSaving: false
				});

				return newState;
			}

			// find index
			index = newState.clients.findIndex(c => c.id === action.account.client_id);
			if( index > -1 ) {
				const client = Object.assign({}, newState.clients[index], {});
				client.bank_accounts = mergeData(client.bank_accounts, action.account);

				if( action.account.is_default ) {
					client.iban = action.account.formatted_iban;
					client.bic = action.account.bic;
				}

				const updatedClients4ba = mergeData(newState.clients, client);
				updatedClients4ba.sort((a, b) => {
					if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
					if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
					return 0;
				});

				newState =  Object.assign({}, newState, {
					isFetching: false,
					didInvalidate: false,
					lastUpdated: action.receivedAt,
					clients: updatedClients4ba,
					isSaving: false
				});
			}

			return newState





		/*
	     * Account was deleted
		 * Or Made default
		 */
		case BANKACCOUNT_DELETED:
		case BANKACCOUNT_MADE_DEFAULT:
			if( action.status >= 400 )
				return newState;

			const bankAccountMadeDefaultState = {
				isFetching: false,
				isSaving: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt
			};

			// this is current company
			if( parseInt(newState.company.id,10) === parseInt(action.client_id, 10) ) {
				let company = newState.company;
				company.bank_accounts = action.accounts;
				bankAccountMadeDefaultState.company = company;
				return Object.assign({}, newState, bankAccountMadeDefaultState);
			}

			// find index
			index = newState.clients.findIndex(c => c.id === action.client_id);
			if( index > -1 ) {
				const clients = newState.clients.concat([]); // new array
				clients[index].bank_accounts = action.accounts;
				bankAccountMadeDefaultState.clients = clients;
			}

			return Object.assign({}, newState, bankAccountMadeDefaultState);


		/*
	     * Job was saved
		 */
		case JOB_SAVED:
			if( action.status >= 400 )
				return newState;

			// Remove all job_sessions of this job since they're all in the response data
			let sessions = newState.job_sessions.filter(s => parseInt(s.job_id, 10) !== parseInt(action.job.id, 10));

			newState =  Object.assign({}, newState, {
				isFetching: false,
				isSaving: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				jobs: mergeData(newState.jobs, action.job),
				job_sessions: mergeData(sessions, action.sessions)
			});

			return newState;



		/*
	     * Job was deleted
		 */
		case JOB_DELETED:
			if( action.status >= 400 )
				return newState;

			newState =  Object.assign({}, newState, {
				isFetching: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				jobs: newState.jobs.filter(job => {
						return parseInt(job.id, 10) !== parseInt(action.id, 10);
					}),
				job_sessions: newState.job_sessions.filter(s => {
						return parseInt(s.job_id, 10) !== parseInt(action.id, 10);
					})
			});

			return newState;




		/*
	     * Jobs was invoiced
		 */
		case JOBS_INVOICED:
			if( action.status >= 400 )
				return newState;

			newState =  Object.assign({}, newState, {
				isFetching: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				invoices: mergeData(newState.invoices, action.invoices),
				jobs: mergeData(newState.jobs, action.jobs)
			});

			return newState;




		/*
	     * Job users were updated
		 */
		case JOB_USERS_SAVED:
		case JOB_USER_DELETED:
		case JOB_OWNER_SAVED:
			if( action.status >= 400 )
				return newState;

			newState =  Object.assign({}, newState, {
				isFetching: false,
				isSaving: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				jobs: mergeData(newState.jobs, action.job),
			});

			return newState;






		/*
	     * Work session was started
		 */
		case SESSION_ADDED:
		case SESSION_SAVED:
			if( action.status >= 400 )
				return newState;

			newState = Object.assign({}, newState, {
				isFetching: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				job_sessions: mergeData(newState.job_sessions, action.session)
			});

			return newState;


		/*
	     * Work session was completed
		 */
		case SESSION_COMPLETED:
			if( action.status >= 400 )
				return newState;

			newState = Object.assign({}, newState, {
				isFetching: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				job_sessions: mergeData(newState.job_sessions, action.session),
				jobs: mergeData(newState.jobs, action.job)
			});

			return newState;


		/*
	     * Work session was cancelled
		 */
		case SESSION_DELETED:
			if( action.status >= 400 )
				return newState;

			newState = Object.assign({}, newState, {
				isFetching: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				jobs: mergeData(newState.jobs, action.job),
				job_sessions: newState.job_sessions.filter(s => {
					return parseInt(s.id, 10) !== parseInt(action.id, 10);
				}),
			});

			return newState;






		/*
		 * Invoice was saved
		 */
		case INVOICE_SAVED:
			if( action.status >= 400 )
				return newState;

 			newState =  Object.assign({}, newState, {
 				isFetching: false,
				isSaving: false,
 				didInvalidate: false,
 				lastUpdated: action.receivedAt,
 				invoices: mergeData(newState.invoices, action.invoice)
 			});

			/* Update client status */
			if( action.client_status ) {
				let client = newState.clients.find(c => parseInt(c.id, 10) === parseInt(action.client_status.client_id, 10));
				if(client) {
					client.status = action.client_status;
					newState.clients = mergeData(newState.clients, client);
				}
			}

 			return newState;


		/*
		 * Credit note was created from an existing invoice: a new document is created (credit_note) and another is modified (invoice, closed).
		 */
		case INVOICE_CREDIT_NOTE_CREATED:
			if( action.status >= 400 )
				return newState;

			newState =  Object.assign({}, newState, {
 				isFetching: false,
				isSaving: false,
 				didInvalidate: false,
 				lastUpdated: action.receivedAt,
 				invoices: mergeData(newState.invoices, action.invoice)
 			});
			newState.invoices.push(action.credit_note);
 			return newState;



		/*
	     * Invoice was deleted (OK)
		 */
		case INVOICE_DELETED:
			if( action.status >= 400 )
				return newState;

			newState =  Object.assign({}, newState, {
				isFetching: false,
				isSaving: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				invoices: newState.invoices.filter(invoice => {
					return parseInt(invoice.id, 10) !== parseInt(action.id, 10);
				})
			});

			/* Update client status */
			if( action.client_status ) {
				let client = newState.clients.find(c => parseInt(c.id, 10) === parseInt(action.client_status.client_id, 10));
				if(client) {
					client.status = action.client_status;
					newState.clients = mergeData(newState.clients, client);
				}
			}

			return newState;



		/*
		 * Quote was converted
		 */
		 case JOB_FROM_QUOTE_CREATED:
		 	if( action.status >= 400 )
			 	return newState;

			/*
			the received object should be in this form:
			action.quote
			action.jobs = [] of job
			*/

			const updatedJobs = newState.jobs;
			action.jobs.forEach( job => {
				updatedJobs.unshift(job);
			});

			newState =  Object.assign({}, newState, {
 				isFetching: false,
 				didInvalidate: false,
 				lastUpdated: action.receivedAt,
				isSaving:false,
 				invoices: mergeData(newState.invoices, action.quote),
				jobs: updatedJobs
 			});

			return newState;




		/*
	     * Expense was saved
		 */
		case EXPENSE_SAVED:
			if( action.status >= 400 )
				return newState;

            let expenses = mergeData(newState.expenses, action.expense).sort((a, b) => {
                if( a.date_memo === b.date_memo ) {
                    return 0;
                }
                return a.date_memo > b.date_memo ? -1 : 1;
            });


			newState =  Object.assign({}, newState, {
				isFetching: false,
				isSaving: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				expenses: expenses
			});

			/* Update client status */
			if( action.client_status ) {
				let client = newState.clients.find(c => parseInt(c.id, 10) === parseInt(action.client_status.client_id, 10));
				if(client) {
					client.status = action.client_status;
					newState.clients = mergeData(newState.clients, client);
				}
			}

			return newState;



		/*
	     * Expense was deleted
		 */
		case EXPENSE_DELETED:
			if( action.status >= 400 )
				return newState;

			newState =  Object.assign({}, newState, {
				isFetching: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				expenses: newState.expenses.filter(exp => {
					return parseInt(exp.id, 10) !== parseInt(action.id, 10);
				})
			});

			/* Update client status */
			if( action.client_status ) {
				let client = newState.clients.find(c => parseInt(c.id, 10) === parseInt(action.client_status.client_id, 10));
				if(client) {
					client.status = action.client_status;
					newState.clients = mergeData(newState.clients, client);
				}
			}

			return newState;



		/*
		 * Transactions was imported
		 */
		case CODA_IMPORTED:
			if( action.status >= 400 )
				return newState;

			const transactions = newState.transactions.concat(action.transactions);

			// sort transactions by date memo DESC
			transactions.sort((a, b) => {
				if(a.date_memo < b.date_memo)
					return 1;

				if(a.date_memo > b.date_memo)
					return -1;

				return 0;
			});

			// then insert them in front of newState.data.transactions
			newState =  Object.assign({}, newState, {
 				isFetching: false,
 				didInvalidate: false,
 				lastUpdated: action.receivedAt,
 				transactions: transactions,
 			});

			/* Update bankaccounts */
			if( action.bank_account_statuses ) {
				newState.company = Object.assign({}, newState.company, {
					bank_accounts: updateCompanyBankAccountStatus(newState.company, action.bank_account_statuses)
				});
			}

			return newState;


		/*
	     * Job was saved
		 */
		case TRANSACTION_SAVED:
			if( action.status >= 400 )
				return newState;

			newState =  Object.assign({}, newState, {
				isFetching: false,
				isSaving: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				transactions: mergeData(newState.transactions, action.transaction)
			});

			/* Update bankaccounts */
			if( action.bank_account_statuses ) {
				newState.company = Object.assign({}, newState.company, {
					bank_accounts: updateCompanyBankAccountStatus(newState.company, action.bank_account_statuses)
				});
			}

			return newState;


		/*
		 * Transactions was validated
		 */
		case TRANSACTION_VALIDATED:
			if( action.status >= 400 )
				return newState;

			updateObject = {
				isFetching: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				transactions: mergeData(newState.transactions, action.data.transaction)
			};

			// In case of API created new transaction (see comments in TransactionsController::validate)
			if( action.data.transaction_new ) {
				updateObject.transactions = mergeData(updateObject.transactions, action.data.transaction_new);
			}

			if( action.data.expense )
				updateObject.expenses = mergeData(newState.expenses, action.data.expense)

			if( action.data.invoice )
				updateObject.invoices = mergeData(newState.invoices, action.data.invoice)

			if( action.data.client_status ) {
				let client = newState.clients.find(c => parseInt(c.id, 10) === parseInt(action.data.client_status.client_id, 10));
				if(client) {
					client.status = action.data.client_status;
					newState.clients = mergeData(newState.clients, client);
				}
			}

			if( action.data.bankAccount ) {
				const updatedClients = newState.clients.concat([]);
				const foundClientIndex = updatedClients.findIndex(c => c.id === action.data.bankAccount.client_id);

				if( foundClientIndex > -1 ) {
					updatedClients[foundClientIndex].bank_accounts = mergeData(updatedClients[foundClientIndex].bank_accounts, action.data.bankAccount);
					updateObject.clients = updatedClients;
				}
			}

			return Object.assign({}, newState, updateObject);




		/*
	     * User was saved
		 */
		case USER_SAVED:
			if( action.status >= 400 )
				return newState;

			let newUsersState = {
				isFetching: false,
				isSaving: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				users: mergeData(newState.users, action.user)
			};

			/* if self, update loggedinuser too */
			if( parseInt(action.user.id, 10) === parseInt(newState.user.id, 10) ) {
				newUsersState.user = action.user;
			}

			return Object.assign({}, newState, newUsersState);



		/*
	     * Expense was deleted
		 */
		case USER_DELETED:
			if( action.status >= 400 )
				return newState;

			newState =  Object.assign({}, newState, {
				isFetching: false,
				didInvalidate: false,
				lastUpdated: action.receivedAt,
				users: newState.users.filter(usr => {
					return parseInt(usr.id, 10) !== parseInt(action.id, 10);
				})
			});

			return newState;


		/*
		 * Settings saved
		 */
		case SETTINGS_SAVED:
			if( action.status >= 400 )
				return newState;

			return Object.assign({}, newState, { companySettings: action.settings, isSaving: false });

		/*
	     * Do nothing !
		 */
		default:
			return newState
	}
}



/*
 * After transaction(s) import OR transaction saved,
 * the balance of specified accound may have changed.
 * Update them
*/
function updateCompanyBankAccountStatus(company, statuses) {
	const bankAccounts = [];
	company.bank_accounts.forEach(a => {
		const status = statuses.find(s => parseInt(a.id, 10) === parseInt(s.bank_account_id, 10) );
		if( status)
			a.status = status;
		return bankAccounts.push(a);
	});
	return bankAccounts;
};








const deleteFromCollection = (originalItems, idToRemove) => {
	const data = originalItems.concat([]); // create new array
	if(! Array.isArray(idToRemove) )
		idToRemove = [ idToRemove ];

	return data.filter(item => {
		return idToRemove.indexOf(item.id) === -1;
	});
}



const mergeData = (originalItems, updatedItems) => {
	const data = originalItems.concat([]); // create new array

	if(! Array.isArray(updatedItems) )
		updatedItems = [ updatedItems ];

	updatedItems.forEach( updated => {
		const index = data.findIndex( original => parseInt(original.id, 10) === parseInt(updated.id, 10) );

		if( index === -1 )
			data.unshift(updated);
		else
			data[index] = updated;
	});

	return data;
}




export default DataReducer;
