import React, { Fragment } from 'react';
import Header from './Header';
import Dashboard from '../../modules/dashboard/components/Dashboard';


class DashboardPane extends React.Component {

	render() {
		return (
			<Fragment>
				<Header />
				<div className="overlay"></div>
				<Dashboard />
			</Fragment>
		)
	}

}

export default DashboardPane;
