/* BankAccounts events */
export const BANKACCOUNT_DO_SAVE = 'BANKACCOUNT_DO_SAVE';
export const BANKACCOUNT_SAVED = 'BANKACCOUNT_SAVED';
export const BANKACCOUNT_DO_DELETE = 'BANKACCOUNT_DO_DELETE';
export const BANKACCOUNT_DELETED = 'BANKACCOUNT_DELETED';
export const BANKACCOUNT_MAKE_DEFAULT = 'BANKACCOUNT_MAKE_DEFAULT';
export const BANKACCOUNT_MADE_DEFAULT = 'BANKACCOUNT_MADE_DEFAULT';

/* Clients events */
export const CLIENT_DO_SAVE = 'CLIENT_DO_SAVE';
export const CLIENT_SAVED = 'CLIENT_SAVED';
export const CLIENT_USERS_DO_SAVE = 'CLIENT_USERS_DO_SAVE';
export const CLIENT_USERS_SAVED = 'CLIENT_USERS_SAVED';

/* Expenses events */
export const EXPENSE_DO_SAVE = 'EXPENSE_DO_SAVE';
export const EXPENSE_SAVED = 'EXPENSE_SAVED';
export const EXPENSE_DO_DELETE = 'EXPENSE_DO_DELETE';
export const EXPENSE_DELETED = 'EXPENSE_DELETED';

/* Invoices events */
export const INVOICE_DO_SAVE = 'INVOICE_DO_SAVE';
export const INVOICE_SAVED = 'INVOICE_SAVED';
export const INVOICE_DO_DELETE = 'INVOICE_DO_DELETE';
export const INVOICE_DELETED = 'INVOICE_DELETED';
export const JOB_FROM_QUOTE_CREATE = 'JOB_FROM_QUOTE_CREATE';
export const JOB_FROM_QUOTE_CREATED = 'JOB_FROM_QUOTE_CREATED';
export const INVOICE_DO_SEND = 'INVOICE_DO_SEND';
export const INVOICE_SENT = 'INVOICE_SENT';
export const INVOICE_CREDIT_NOTE_CREATE = 'INVOICE_CREDIT_NOTE_CREATE';
export const INVOICE_CREDIT_NOTE_CREATED = 'INVOICE_CREDIT_NOTE_CREATED';

/* Jobs events */
export const JOB_DO_SAVE 		= 'JOB_DO_SAVE';
export const JOB_SAVED 			= 'JOB_SAVED';
export const JOB_DO_DELETE 		= 'JOB_DO_DELETE';
export const JOB_DELETED 		= 'JOB_DELETED';
export const JOBS_DO_INVOICE 	= 'JOB_DO_INVOICE';
export const JOBS_INVOICED 		= 'JOB_INVOICED';

export const JOB_USERS_DO_SAVE	= 'JOB_USERS_DO_SAVE';
export const JOB_USERS_SAVED 	= 'JOB_USERS_SAVED';
export const JOB_OWNER_DO_SAVE	= 'JOB_OWNER_DO_SAVE';
export const JOB_OWNER_SAVED	= 'JOB_OWNER_SAVED';
export const JOB_USER_DO_DELETE = 'JOB_USER_DO_DELETE';
export const JOB_USER_DELETED 	= 'JOB_USER_DELETED';


/* Sessions events */
export const SESSION_DO_ADD = 'SESSION_DO_ADD';
export const SESSION_ADDED = 'SESSION_ADDED';
export const SESSION_DO_SAVE = 'SESSION_DO_SAVE';
export const SESSION_SAVED = 'SESSION_SAVED';
export const SESSION_DO_COMPLETE = 'SESSION_DO_COMPLETE';
export const SESSION_COMPLETED = 'SESSION_COMPLETED';
export const SESSION_DO_DELETE = 'SESSION_DO_DELETE';
export const SESSION_DELETED = 'SESSION_DELETED';

/* Locale events */
export const LANGUAGE_PACK_GET = 'LANGUAGE_PACK_GET';
export const LANGUAGE_PACK_GOT = 'LANGUAGE_PACK_GOT';
export const LANGUAGE_SWITCH = 'LANGUAGE_SWITCH';

/* Settings events */
export const SETTINGS_DO_SAVE = 'SETTINGS_DO_SAVE';
export const SETTINGS_SAVED = 'SETTINGS_SAVED';

/* Transactions events */
export const TRANSACTION_DO_SAVE = 'TRANSACTION_DO_SAVE';
export const TRANSACTION_SAVED = 'TRANSACTION_SAVED';
export const TRANSACTION_DO_DELETE = 'TRANSACTION_DO_DELETE';
export const TRANSACTION_DELETED = 'TRANSACTION_DELETED';
export const TRANSACTION_DO_VALIDATE = 'TRANSACTION_DO_VALIDATE';
export const TRANSACTION_VALIDATED = 'TRANSACTION_VALIDATED';
export const CODA_DO_IMPORT = 'CODA_DO_IMPORT';
export const CODA_IMPORTED = 'CODA_IMPORTED';

/* Users events */
export const USER_SIGN_IN = 'USER_SIGN_IN';
export const USER_SIGNED_IN = 'USER_SIGNED_IN';
export const USER_AUTH_ERROR = 'USER_AUTH_ERROR';
export const USER_SIGN_OUT = 'USER_SIGN_OUT';
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT';
export const USER_DO_SAVE = 'USER_DO_SAVE';
export const USER_SAVED = 'USER_SAVED';
export const USER_DO_DELETE = 'USER_DO_DELETE';
export const USER_DELETED = 'USER_DELETED';

export const USER_GET_DATA = 'USER_GET_DATA';
export const USER_GOT_DATA = 'USER_GOT_DATA';
export const USER_DO_SYNC = 'USER_DO_SYNC';
export const USER_SYNC_DATA = 'USER_SYNC_DATA';

export const USER_IS_LOGGED_IN = 'USER_IS_LOGGED_IN';
export const USER_IS_KNOW = 'USER_IS_KNOW';


/* UI events */
export const OVERLAY_OPEN = 'OVERLAY_OPEN';
export const OVERLAY_CLOSE = 'OVERLAY_CLOSE';
export const DOCVIEWER_OPEN = 'DOCUMENT_VIEWER_OPEN';
export const DOCVIEWER_CLOSE = 'DOCUMENT_VIEWER_CLOSE';
export const FLASH_MESSAGE_HIDE = 'FLASH_MESSAGE_HIDE';
export const DO_SEARCH = 'DO_SEARCH';
export const PERIOD_CHANGED = 'PERIOD_CHANGED';
