import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';
import config from './config';
import AppReducer from './reducers/AppReducer';


function configureStore(preloadedState) {

	let appliedMiddlewares;
	if( config.debug ) {
		const loggerMiddleware = createLogger();
		appliedMiddlewares = applyMiddleware(thunkMiddleware, loggerMiddleware);
	}
	else
		appliedMiddlewares = applyMiddleware(thunkMiddleware)

	return createStore(
		AppReducer,
		preloadedState,
		appliedMiddlewares
	);
}


const store = configureStore();

export default function getStore() {
	return store;
}
