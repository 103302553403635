import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Glyphicon from './Glyphicon';
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { hideFlashMessage } from '../../UIActions';

class FlashMessage extends React.Component {


	constructor(props) {
		super(props);
		this.timer = null;
	}

	componentWillUnmount() {
		this.stopTimer();
	}

	close = event => {
		event.preventDefault();
		this.doClose();
	}

	doClose = () => {
		const { dispatch } = this.props;
		dispatch(hideFlashMessage());
		this.stopTimer();
	}

	stopTimer = () => {
		if( this.timer )
			clearTimeout(this.timer);
	}

	renderMessage = () => {
		const message = this.props.message;
		if(! message)
			return null;

		const type = this.props.type ? this.props.type : 'notice';

		this.stopTimer();
		this.timer = setTimeout(this.doClose, 6000);

		return (
            <CSSTransition
                classNames="flash-message"
                timeout={{ enter: 500, exit: 300 }}
            >
    			<div className={`message ${type}`}>
    				<div className="message-text">{message}</div>
    				<Glyphicon icon="remove" tagName="a" onClick={ this.close } className="message-close" />
    			</div>
            </CSSTransition>
		)
	}

	render() {
        return (
            <TransitionGroup>
                { this.renderMessage() }
            </TransitionGroup>
        );
	};
}

FlashMessage.propTypes = {
	type:	PropTypes.string,
	message: PropTypes.node
};

const mapStateToProps = (state, ownProps) => {
	return { }
}

export default connect(mapStateToProps)(FlashMessage);
