import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectBox from '../../../components/forms/SelectBox';
import I18n from '../../../locales/I18n';
import { closeOverlay, removeOverlayBodyClass } from '../../../UIActions';
import { doSaveUser } from '../UsersActions';
import { getUserRoleLabel } from '../UsersConsts';
import { USER_SAVED } from '../../../AppEvents';


class EditUser extends React.Component {

	/*
	 * When modifiyng the item, just update local state.
	 * The global state is only updated when saving data ***
	 */
	constructor(props) {
		super(props);
		const user = props.user ? props.user : {};
		const type = ! user.id ? 'settings' : (props.type ? props.type : 'details');
		this.state = { user, type, wrongPassword: false };
	}


	componentDidUpdate() {
        const { lastAction } = this.props;
		const type = ! this.props.user.id ? 'settings' : (this.props.type ? this.props.type : 'details');
		this.setState({ user: props.user, type: type });

		if( lastAction.type === USER_SAVED && lastAction.success ) {
			removeOverlayBodyClass();

			const timer = setTimeout(() => {
				this.props.doClose();
				clearTimeout(timer);
			}, 500);
		}
	}


	/*
 	 * When user saves job ...
	 */
	save = event => {
		event.preventDefault();

		if( this.state.wrongPassword )
			return;

		if( this.props.isSaving )
			return;

		const userData = new FormData(document.getElementById('user-edit'));
		this.props.onSave(userData);
	}



	changeRole = event => {
		event.preventDefault();
		let state = this.state;
		state.user.user_role_id = event.target.value;
		this.setState(state);
	}


	checkPasswords = event => {
		const pw1 = document.getElementById('password').value;
		const pw2 = document.getElementById('password2').value;
		const wrongPassword = ( pw1 && pw2 && (pw1 !== pw2) );
		this.setState({ wrongPassword: wrongPassword });
	}

	renderDetailsForm = () => {
		const user = this.state.user;

		if( this.state.type && this.state.type === 'password' ) {
			return (
				<fieldset>
					<div className="input text">
						<span className="label-like">{ I18n.t('User') }</span>
						<span className="input-like">{ user.fullname }</span>
					</div>
				</fieldset>
			);
		}

		return (
			<fieldset>
				<div className="input text">
					<label htmlFor="fname">{ I18n.t('Firsname') }</label>
					<input type="text" name="fname" maxLength="140" id="fname" defaultValue={ user.fname || '' } autoComplete="off" autoCorrect="off" spellCheck="false" required />
				</div>

				<div className="input text">
					<label htmlFor="lname">{ I18n.t('Lastname') }</label>
					<input type="text" name="lname" maxLength="140" id="lname" defaultValue={ user.lname || '' } autoComplete="off" autoCorrect="off" spellCheck="false" required />
				</div>

				<div className="input email">
					<label htmlFor="email">{ I18n.t('E-mail address') }</label>
					<input type="email" name="email" maxLength="140" id="email" defaultValue={ user.email || '' } autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" required />
				</div>

				{ this.renderRolesSelector() }
			</fieldset>
		)
	}



	renderPasswordForm = () => {
		if(! this.state.type || this.state.type === 'details' )
			return null;

		const wrongPasswordMessage = this.state.wrongPassword ? <p style={{color: 'red'}}>{ I18n.t('Oops! Passwords do not match.') }</p> : '';
		return (
			<fieldset>
				<div className="input password">
					<label htmlFor="password">{ I18n.t('Password') }</label>
					<input type="password" name="password" maxLength="140" id="password" onBlur={ this.checkPasswords } autoComplete="new-password" autoCorrect="off" autoCapitalize="off" spellCheck="false" required />
				</div>

				<div className="input password">
					<label htmlFor="password2">{ I18n.t('Repeat password') }</label>
					<input type="password" name="password2" maxLength="140" id="password2" onBlur={ this.checkPasswords } autoComplete="new-password" autoCorrect="off" autoCapitalize="off" spellCheck="false" required />
				</div>

				{ wrongPasswordMessage }
			</fieldset>
		);
	}





	renderRolesSelector = () => {
		if( this.props.isSelf )
			return null; // user cant change its role himself

		const defaultRoleId = 5;
		const userRoles = this.props.userRoles.filter(role => role.level >= this.props.currentUser.user_role.level).map(role => {
			return {
				label: getUserRoleLabel(role.label),
				value: role.id
			};
		});

		return (
			<div className="input select" id="level-selector" style={ {display:'block'} }>
				<label htmlFor="user_role_id">{ I18n.t('Role') }</label>
				<SelectBox name="user_role_id" className="small" id="level" value={ this.state.user.user_role_id || defaultRoleId } options={ userRoles } onChange={ this.changeRole }  />
			</div>
		)
	}

	renderSubmit = () => {
		if( this.props.isSaving )
			return <button type="submit" className="is-saving">{ I18n.t('Saving...') }</button>

		return <button type="submit">{ I18n.t('Save') }</button>
	}

	render() {
		const user = this.state.user;

		return (
			<div className="users form">
				<form method="post" acceptCharset="utf-8" id="user-edit" onSubmit={this.save}>
					{ this.renderDetailsForm() }
					{ this.renderPasswordForm() }

					<div className="form-controls">
						<input type="hidden" name="id" value={user.id} readOnly />
						{ this.renderSubmit() }
					</div>
				</form>
			</div>
		);
	}
};

EditUser.propTypes = {
	user: PropTypes.object.isRequired,
	currentUser: PropTypes.object.isRequired,
	isSelf: PropTypes.bool.isRequired,
	lastAction: PropTypes.object.isRequired,
	type: PropTypes.string,
	userRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
	isSaving: PropTypes.bool.isRequired
};



const mapStateToProps = (state, ownProps) => {
	const { user, userRoles, lastAction, isSaving } = state.data || { user: {}, userRoles: [], lastAction: {}, isSaving: false };
	const isSelf = user.id === ownProps.user.id;
	return { isSelf, lastAction, userRoles, isSaving, currentUser: user };
}


const mapDispatchToProps = (dispatch) => {
	return {
		onSave: (user) => {
			dispatch(doSaveUser(user));
		},

		doClose: () => {
			dispatch(closeOverlay());
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
