import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import DateTime from '../../../components/common/DateTime';
import Currency from '../../../components/common/Currency';
import ItemOptions from '../../../components/common/ItemOptions';
import I18n from '../../../locales/I18n';

import EditInvoice from './EditInvoice';
import { openOverlay, openDocumentViewer } from '../../../UIActions';
import { doSaveInvoice, doDeleteInvoice, doSendInvoice, doCreditNoteInvoice } from '../InvoicesActions';
import { InvoiceStatus, InvoiceType } from '../InvoicesConsts';

class Invoice extends React.Component {


	constructor(props) {
		super(props);

		//this.mode = 'NORMAL';
		this.mode = 'ADVANCED';
	}


	editForm = (e) => {
		e.preventDefault();
		const invoice = { id: this.props.invoice.id };
		this.props.getForm(invoice);
	}


	viewDocument = (e) => {
		e.preventDefault();

		const invoice = this.props.invoice;
		const byEmail = this.props.client.invoice_by_email;

		const options = [
			{
				label: I18n.t('Download'),
				url: invoice.pdf
			},
			{
				label: I18n.t('Print'),
				url: invoice.print_version
			}
		];

		if( byEmail && invoice.status ===  InvoiceStatus.OPEN ) {
			options.push(
				{
					label: I18n.t('Send by e-mail'),
					action: this.sendInvoice
				}
			)
		}

		this.props.viewDocument(invoice.html, options);
	}


	updateStatus = event => {
		const invoice = this.props.invoice;
		if( invoice.status ===  InvoiceStatus.CLOSED )
			return null;

		const status = invoice.status ===  InvoiceStatus.DRAFT ?  InvoiceStatus.OPEN :  InvoiceStatus.CLOSED;
		const invoiceForm = new FormData();

		invoiceForm.append('id', invoice.id);
		invoiceForm.append('action', 'updateStatus');
		invoiceForm.append('status', status);

		this.props.onStatusChange(invoiceForm);
	}


	doDelete = () => {
		if( window.confirm( I18n.t('Are you sure?')) )
			this.props.onDelete({ id: this.props.invoice.id });
	}



	sendInvoice = e => {
		e.preventDefault();
		e.target.style.display = 'none';
		const invoice = this.props.invoice;
		this.props.onSendInvoice(invoice);
	}

	makeCreditNote = e => {
		e.preventDefault();
		this.props.onMakeCreditNote(this.props.invoice.id);
	}

	renderTransactionsDetails = () => {
		const { invoice, transactions } = this.props;
		if( invoice.status ===  InvoiceStatus.CLOSED )
			return '';

		if( transactions.length === 0 )
			return '';

		let amount = 0;
		transactions.forEach(t => { amount += parseFloat(t.amount) });

		return <span className="invoice-transactions-details">
			<span className="invoice-transactions-details-paid">{ I18n.t('Already paid:') } <strong><Currency amount={amount} /></strong></span>
			<span className="invoice-transactions-details-balance">{ I18n.t('Balance:') } <strong className="invoice-balance"><Currency amount={ parseFloat(invoice.amount_with_taxes) - amount } /></strong></span>
		</span>
	}



	getItemOptions = () => {
		const { invoice } = this.props;

		const options = [
			{
				label: I18n.t('View'),
				action: this.viewDocument,
				icon: 'open'
			}
		];

		if( invoice.status !==  InvoiceStatus.CLOSED ) {
			options.push({
				label: (invoice.status ===  InvoiceStatus.DRAFT) ? I18n.t('Mark as open') : I18n.t('Manually mark as paid'),
				action: this.updateStatus,
				icon: 'ok'
			});
		}


		if( invoice.status ===  InvoiceStatus.DRAFT || (invoice.status ===  InvoiceStatus.OPEN && this.mode === 'ADVANCED') ) {
			options.push({
				label: I18n.t('Edit'),
				action: this.editForm,
				icon: 'edit'
			});

			options.push({
				label: I18n.t('Delete'),
				action: this.doDelete,
				icon: 'trash'
			});
		}

		if( invoice.status === InvoiceStatus.OPEN && invoice.type === InvoiceType.INVOICE ) {
			options.push({
				label: I18n.t('Create credit note'),
				action: this.makeCreditNote,
				icon: 'piggy-bank'
			});
		}

		if( invoice.status !== 'DRAFT' ) {
			options.push({
				label: I18n.t('Download'),
				actionUrl:  invoice.pdf,
				icon: 'download'
			});
		}

		return options;
	}




	render() {
		const { invoice, client } = this.props;
		const invoiceId = invoice.iid || '{DRAFT}';

		const classNames = ['item', 'invoice', `invoice-${invoice.id}`, `${invoice.status.toLowerCase()}` ];
		if( invoice.is_late )
			classNames.push('late');

		return (
			<li className="data-list-item">
				<div className={ classNames.join(' ') } id={`invoice-${invoice.id}`} data-id={invoice.id}>
					<div className="item-main-col">
						<div className="item-client">
							<Link to={`/incomes/clients/view/${client.id}`}>{client.short_name}</Link>
						</div>

						<div className="item-title">
							<button className="link-like" onClick={ this.viewDocument }>{invoiceId}</button>
							{ this.renderTransactionsDetails() }
						</div>
					</div>

					<div className="item-col-date">
						<DateTime date={invoice.date_memo} format="dd mmm yyyy" />
					</div>

					<div className="item-col-amount col-amount-taxexcl">
						<div className="amount">
							<Currency amount={invoice.amount} />
						</div>
					</div>

					<div className="item-col-amount col-amount-taxincl">
						<div className="amount">
							<Currency amount={invoice.amount_with_taxes} />
						</div>
					</div>

					<ItemOptions options={ this.getItemOptions() } />
				</div>
			</li>
		)
	}
};

Invoice.propTypes = {
	invoice: PropTypes.object.isRequired,
	client: PropTypes.object.isRequired,
	transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
	onStatusChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	getForm: PropTypes.func.isRequired,
	viewDocument: PropTypes.func.isRequired,
	onSendInvoice: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
	const { clients, searchTerms, transactions } = state.data || { clients: [], searchTerms: false, transactions: [] };
	const client = clients.find(c => parseInt(c.id, 10) === parseInt(ownProps.invoice.client_id, 10)) || {}
	const invoiceTransactions = transactions.filter(t => t.validated && parseInt(t.document_id, 10) === parseInt(ownProps.invoice.id, 10) && t.type === 'IN' && t.category === 'INCOME');
	return { client, searchTerms, transactions: invoiceTransactions };
}


const mapDispatchToProps = (dispatch) => {
	return {
		viewDocument: (url, options) => {
			dispatch(openDocumentViewer(url, options));
		},

		getForm: (invoice) => {
			const title = I18n.t('Edit invoice');
			const module = <EditInvoice invoice={invoice} />;
			dispatch(openOverlay( title, module ));
		},

		onStatusChange: (invoice) => {
			dispatch(doSaveInvoice(invoice))
		},

		onDelete: (invoice) => {
			dispatch(doDeleteInvoice(invoice))
		},

		onSendInvoice: (invoice) => {
			dispatch(doSendInvoice(invoice));
		},

		onMakeCreditNote: (invoiceId) => {
			dispatch(doCreditNoteInvoice(invoiceId));
		}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Invoice);
