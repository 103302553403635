import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { userRoleIs, UserRole } from '../../modules/users/UsersConsts';

class ClientSelect extends Component {

	constructor(props) {
		super(props);
		const value = props.value ? props.value : null;
		const client = value ? props.clients.find(c => c.id === parseInt(value, 10)) : null;
		const initialInputValue = client ? client.short_name : '';
		this.state = ({ value, initialInputValue, inputValue: initialInputValue });
	}

	handleInputChange = (inputValue) => {
		//const inputValue = newValue.replace(/\W/g, '');
		this.setState({ inputValue });
		return inputValue;
	};

	handleChange = (element) => {
		if(! element )
			return;

		if(this.props.onChange)
			this.props.onChange(element);
	}

	render() {
		const { clients, name, ...attrs } = this.props || { clients: [], name: '' };
		const options = Object(clients).map(c => {
			return {
				label: c.short_name,
				value: c.id
			}
		});

		if(attrs.onChange)
			delete attrs.onChange;

		if(attrs.value)
			delete attrs.value;

		return (
			<Select
				name={ name }
				id="client-id"
				options={options}
				defaultOptions
				inputValue={this.state.inputValue}
				onInputChange={this.handleInputChange}
				onChange={this.handleChange}
			/>
		);
	}
}



ClientSelect.propTypes = {
	clients: PropTypes.arrayOf(PropTypes.object).isRequired,
	name: PropTypes.string.isRequired,
	type: PropTypes.string,
	showSelf: PropTypes.bool
};


const mapStateToProps = (state, ownProps) => {
	const { user, clients } = state.data || { user: {}, clients: [] };

	const filteredClients = clients.filter(client => {
		if(ownProps.type && ownProps.type === 'provider')
			return (client.is_provider && userRoleIs(user, [UserRole.MANAGER, UserRole.ACCOUNTANT]));

		if( userRoleIs(user, [UserRole.ACCOUNT_MANAGER]))
			return client.client_users.find(cu => parseInt(cu.user_id, 10) === parseInt(user.id, 10));

		return client.is_client;
	});

	if( ownProps.hasOwnProperty('showSelf') && ownProps.showSelf && state.data.company )
		filteredClients.push(state.data.company);


	return {
		clients: filteredClients
	}
}


export default connect(mapStateToProps)(ClientSelect);
