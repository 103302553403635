import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SectionHeader from '../../../components/common/SectionHeader';
import ContextMenu from '../../../components/common/ContextMenu';
import Currency from '../../../components/common/Currency';
import Glyphicon from '../../../components/common/Glyphicon';
import EditClient from './EditClient';
import BankAccounts from '../../bank_accounts/components/BankAccounts';
import UsersList from '../../users/components/UsersList';
import EditSettings from '../../settings/components/EditSettings';

import I18n from '../../../locales/I18n';
import { openOverlay } from '../../../UIActions';



class Company extends React.Component {

	editCompanyForm = e => {
		e.preventDefault();
		this.props.getEditForm(this.props.company);
	}

	editSettings = e => {
		e.preventDefault();
		this.props.editSettingsForm();
	}

	addUser = e => {
		e.preventDefault();
	}

	manageBankAccounts = e => {
		e.preventDefault();
		const { company } = this.props;
		this.props.bankAccountManager(company);
	}


	renderHeader = () => {
		const { company } = this.props;

		const contextMenu = [
			{
				action: this.editCompanyForm,
				icon: 'edit',
				label: I18n.t('Edit')
			},

			{
				action: this.editSettings,
				icon: 'cog',
				label: I18n.t('Edit settings')
			},

			{
				action: this.addUser,
				icon: 'plus',
				label: I18n.t('Add user')
			}
		];


		let IBANData = '';
		if( company.iban ) {
			IBANData = (
					<p>
						<span className="client-data">
							<span className="client-data-label"><Glyphicon icon="credit-card" /></span>
							<span className="client-data-value">{company.iban}</span>
							<button onClick={ this.manageBankAccounts } className="microbutton">{ I18n.t('Manage') }</button>
						</span>
					</p>
				)
			}


		return (
			<header className="client-header data-main-header">
				<h2>
					{company.name}

					<ContextMenu items={ contextMenu } />
				</h2>


				<div className="section-details">
					<div className="invoice-info">
						<p>
							<span className="client-data">
								<span className="client-data-label"><Glyphicon icon="envelope" /></span>
								<span className="client-data-value">{company.address_formatted}</span>
							</span>
						</p>

						<p>
							<span className="client-data">
								<span className="client-data-label">{ I18n.t('VAT') }:</span>
								<span className="client-data-value">{company.vat_num}</span>
							</span>
						</p>
						<p>
							<span className="client-data">
								<span className="client-data-label">{ I18n.t('Hourly fee') }:</span>
								<span className="client-data-value">
									<Currency amount={ company.hourly_fee } />
								</span>
							</span>
						</p>
					</div>

					<div className="contact-info">
						<p>
							<span className="client-data">
								<span className="client-data-label"><Glyphicon icon="user" /></span>
								<span className="client-data-value">
									<strong>{company.contact_name}</strong><br />
									<a href={`mailto:${company.contact_email}`}>{company.contact_email}</a><br />
									<a href={`tel:${company.contact_phone}`}>{company.contact_phone}</a>
								</span>
							</span>
						</p>

						{ IBANData }
					</div>
				</div>
			</header>
		)
	}



	renderUsers = () => {
		return (
			<div className="nano expresso-users" style={{ minHeight: '400px' }}>
				<div className="nano-content">
					<h3 className="h3">{ I18n.t('Team') }</h3>
					<UsersList />
				</div>
			</div>
		)
	}

	render() {
		const { isFetching } = this.props;

		if( isFetching )
			return <div className="message-empty">{ I18n.t('Please wait while loading...') }</div>


		return (
			<main id="main">
				<SectionHeader type="company" title={ I18n.t('Company settings') } />

				<section className="company" id="client">
					{ this.renderHeader() }

					{ this.renderUsers() }
				</section>
			</main>
		)
	}
};


Company.propTypes = {
	isFetching: PropTypes.bool.isRequired,
	company: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	//user: PropTypes.object.isRequired
};


const mapStateToProps = (state, ownProps) => {
	const { company, isFetching, companySettings } = state.data || { company: {}, companySettings: {}, isFetching: true };

	return {
		company,
		settings: companySettings,
		isFetching
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		getEditForm: (client) => {
			const title = I18n.t('Company details');
			const module = <EditClient client={client} />;
			dispatch(openOverlay( title, module ));
		},

		bankAccountManager: (client) => {
			dispatch(openOverlay( I18n.t('Bank accounts'), <BankAccounts client={client.id} /> ));
		},

		editSettingsForm: () => {
			dispatch(openOverlay( I18n.t('Edit settings'), <EditSettings /> ));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Company);
