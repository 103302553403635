import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import JobSession from './JobSession';
import { UserRole, userRoleIs } from '../../users/UsersConsts';
//import I18n from '../../../locales/I18n';



class JobSessions extends React.Component {


	render() {
		const { job_sessions, user, job } = this.props;
		const isEmployee = userRoleIs(user, [UserRole.EMPLOYEE]);
		const jobOwner = job.job_users.find(ju => ju.owner);
		const isJobOwner = jobOwner ? parseInt(user.id, 10) === parseInt(jobOwner.user_id, 10) : false;

		let itemsList = [];
		job_sessions.forEach((session, i) => {
			if(! isEmployee || isJobOwner || parseInt(user.id, 10) === parseInt(session.user_id, 10) )
				itemsList.push( <JobSession session={session} key={`job-session-${session.id}`} /> );
		});

		return (
			<ul className="job-sessions-list">
				{ itemsList }
			</ul>
		)
	}
};

JobSessions.propTypes = {
	job: PropTypes.object.isRequired,
	job_sessions: PropTypes.arrayOf(PropTypes.object)
};


const mapStateToProps = (state, ownProps) => {
	const { job_sessions, jobs, user } = state.data || { job_sessions: [], jobs: [], user: {} };

	const sessions = job_sessions.filter(s => parseInt(ownProps.job_id, 10) === parseInt(s.job_id, 10) && s.completed === true );
	sessions.sort((a, b) => {
		if(a.created_at < b.created_at)
			return 1;

		if(a.created_at > b.created_at)
			return -1;

		return 0;
	});

	const job = jobs.find(j => parseInt(j.id, 10) === parseInt(ownProps.job_id, 10) );
	return { job_sessions: sessions, job, user };
}


const mapDispatchToProps = (dispatch) => {
	return {}
}

export default connect(mapStateToProps,mapDispatchToProps)(JobSessions);
