import React from 'react';


class NotFoundException extends React.Component {

  render() {
    return (
      <div className="exception-message not-found"></div>
    );
  }
}

export default NotFoundException;
