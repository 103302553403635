import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataList from '../../../components/containers/DataList';
import getVisibleIncomes from '../filters/VisibleIncomes';
import Invoice from './Invoice';
import I18n from '../../../locales/I18n';

class InvoicesList extends React.Component {

	render() {
		const { isFetching, invoices } = this.props;

		if( isFetching )
			return <div className="message-empty">{ I18n.t('Please wait while loading...') }</div>

		if( ! invoices.length )
			return <div className="message-empty">{ I18n.t('No document found') }</div>

        return <DataList
                    data={ invoices }
                    keyExtractor={ (item) => item.id }
                    renderItem={ ({ item }) => <Invoice invoice={ item } /> }
                />;
	}
};

InvoicesList.propTypes = {
	invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
	client: PropTypes.number,
	period: PropTypes.array,
	view: PropTypes.string
};


const mapStateToProps = (state, ownProps) => {
	const { isFetching } = state.data || { isFetching: true }

	if( isFetching || state.data.invoices.length === 0 )
		return { invoices: [], isFetching };

	return {
		invoices: getVisibleIncomes(state.data, 'INVOICE', ownProps.period, ownProps.view, ownProps.client),
		isFetching
	};
}

export default connect(mapStateToProps)(InvoicesList);
