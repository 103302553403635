
export default class Cookie {
	static write = (key, data, days) => {
		let expires;
		if (days) {
			let date = new Date();
			date.setTime(date.getTime()+(days*24*60*60*1000));
			expires = "; expires="+date.toGMTString();
		}
		else
			expires = '';

		document.cookie = key +'='+ data + expires +'; path=/';
	}

	static read = key => {
		var keyEQ = key + '=';
		var ca = document.cookie.split(';');
		for(let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ')
				c = c.substring(1,c.length);

			if (c.indexOf(keyEQ) === 0)
				return c.substring(keyEQ.length,c.length);
		}
		return null;
	}

	static remove = key => {
		Cookie.write(key, '', -1);
	}
}
