import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataList from '../../../components/containers/DataList';
import getVisibleIncomes from '../filters/VisibleIncomes';
import CreditNote from './CreditNote';
import I18n from '../../../locales/I18n';
import EditInvoice from './EditInvoice';
import { openOverlay } from '../../../UIActions';


class CreditNotesList extends React.Component {


	createCreditNote = e => {
		e.preventDefault();
		const title = I18n.t('Create credit note');
		this.props.createInvoiceForm(title, { type: 'CREDIT_NOTE', client_id: this.props.client });
	}


	render() {
		const { isFetching, credit_notes } = this.props;
		let dataListOptions = '';

		if( isFetching )
			return <div className="message-empty">{ I18n.t('Please wait while loading...') }</div>

		if( ! credit_notes.length )
			return <div className="message-empty">{ I18n.t('No document found') }. <button className="link-like" onClick={this.createCreditNote}>{ I18n.t('Create new ?') }</button></div>

		if(this.props.client) {
			// show add link
			dataListOptions = (
				<div className="data-list-options">
					<button className="link-like" onClick={this.createCreditNote}>{ I18n.t('Create new ?') }</button>
				</div>
			);
		}

		return (
			<div>
				{ dataListOptions }
                <DataList
                    data={ credit_notes }
                    keyExtractor={ (item) => item.id }
                    renderItem={ ({ item }) => <CreditNote cn={ item } /> }
                />
			</div>
		);
	}
};

CreditNotesList.propTypes = {
	credit_notes: PropTypes.arrayOf(PropTypes.object).isRequired,
	client: PropTypes.number
};


const mapStateToProps = (state, ownProps) => {
	const { isFetching } = state.data || { isFetching: true }

	if( isFetching || state.data.invoices.length === 0 )
		return { credit_notes: [], isFetching };

	return {
		credit_notes: getVisibleIncomes(state.data, 'CREDIT_NOTE', ownProps.period, null, ownProps.client),
		isFetching
	};
}


const mapDispatchToProps = (dispatch) => {
	return {
		createInvoiceForm: (title, invoice) => {
			const module = <EditInvoice invoice={invoice} />;
			dispatch(openOverlay( title, module ));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditNotesList);
