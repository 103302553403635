import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import DateTime from '../../../components/common/DateTime';
import Glyphicon from '../../../components/common/Glyphicon';
import TimeFormatter from '../../../libs/TimeFormatter';
import I18n from '../../../locales/I18n';
import Avatar from '../../users/components/Avatar';
import { doSaveJobSession, doDeleteJobSession } from '../JobSessionsActions';
import { JobStatus } from '../../jobs/JobsConsts';


class JobSession extends React.Component {

	constructor(props) {
		super(props);
		this.state = { mode: 'READ' };
	}

	getNotes = () => {
		if( this.props.session.notes )
			return <span className="session-notes-from-user">{ this.props.session.notes }</span>;

		return <span className="session-notes-empty">{ I18n.t('No notes for this session') }</span>;
	}

	edit = event => {
		event.preventDefault();
		if( this.props.isEditable )
			this.setState({ mode: 'EDIT' });
	}


	saveSession = event => {
		event.preventDefault();
		const id = this.props.session.id;
		const data = new FormData( document.getElementById(`session-${id}-form`) );
		this.props.onSave( id, data );
		this.setState({ mode: 'READ' });
	}


	deleteSession = event => {
		event.preventDefault();
		if( window.confirm( I18n.t('Are you sure?') ) )
			this.props.onDelete(this.props.session);
	}

	renderMeta = () => {
		const { session } = this.props;

		const timeFormatter = new TimeFormatter(session.time_amount * 3600); // in seconds

		if( session.type === 'AUTO' ) {
			const timeElapsed = Math.round( (Date.parse(session.finished_at) -  Date.parse(session.started_at)) / 1000 );
			const realTimeFormatter = new TimeFormatter(timeElapsed);

			return (
				<Fragment>
					<div className="job-session-time job-session-total">
						<strong>{ timeFormatter.hmm( I18n.t('h') ) } </strong>
						({ realTimeFormatter.hmm( I18n.t('h') ) })
					</div>
					<div className="job-session-time job-session-start-at">
						<span className="label">{ I18n.t('Start') }:</span>
						<DateTime date={session.started_at} format="dd mmm yyyy – HH:MM" />
					</div>
					<div className="job-session-time job-session-finished-at">
						<span className="label">{ I18n.t('End') }:</span>
						<DateTime date={session.finished_at} format="dd mmm yyyy – HH:MM" />
					</div>
				</Fragment>
			);
		}


		return (
			<Fragment>
				<div className="job-session-time job-session-total">
					<strong>{ timeFormatter.hmm( I18n.t('h') ) }</strong>
				</div>

				<div className="job-session-time job-session-start-at">
					<span className="label">{ I18n.t('On') }: </span>
					<DateTime date={session.created_at} format="dd mmm yyyy – HH:MM" />
				</div>

				<div className="job-session-time">
					<span className="label">{ I18n.t('Manual') }</span>
				</div>
			</Fragment>
		);
	}

	render() {
		const { session, user, isEditable } = this.props;

		if( this.state.mode === 'EDIT' && isEditable ) {
			return (
				<li className="job-session-item job-session-form form">
					<div className="job-session-meta">
						{ this.renderMeta() }
					</div>
					<form key={`session-${session.id}`} method="post" acceptCharset="utf-8" id={`session-${session.id}-form`}>
						<input type="hidden" name="id" value={session.id} readOnly />
						<fieldset>
							<div className="input number">
								<label htmlFor={`session-${session.id}-time-amount`}>{ I18n.t('Time (hours)') }</label>
								<input type="number" name="time_amount" id={`session-${session.id}-time-amount`} step=".5" defaultValue={session.time_amount} />
							</div>

							<div className="input text">
								<label htmlFor={`session-${session.id}-notes`}>{ I18n.t('Notes') }</label>
								<textarea id={`session-${session.id}-notes`} name="notes" defaultValue={session.notes} />
							</div>

							<div className="form-item-option">
								<Glyphicon icon="ok" className="circle-icon small green-hover" onClick={this.saveSession} tagName="a" />
							</div>
						</fieldset>
					</form>
				</li>
			);
		}

		const sessionOptions =
			isEditable ? (
				<div className="job-session-options">
					<button className="link-like" onClick={ this.edit }>{ I18n.t('Edit') }</button>
					<button className="link-like delete-link" onClick={ this.deleteSession }>{ I18n.t('Delete') }</button>
				</div>
			) : null;

		return (
			<li className="job-session-item" id={`job-session-${session.id}`}>
				<div className="job-session-meta">
					<div className="job-session-time job-session-user" style={{flex:0, paddingRight: '5px'}} >
						<Avatar user={ user } size="small" />
					</div>
					{ this.renderMeta() }
				</div>
				<div className="job-session-notes">{ this.getNotes() }</div>
				{ sessionOptions }
			</li>
		);
	}
};

JobSession.propTypes = {
	user: PropTypes.object.isRequired,
	job: PropTypes.object.isRequired,
	session: PropTypes.object.isRequired,
	isEditable: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => {
	const { users, jobs } = state.data || { users: [], jobs: [] };
	const user = users.find(u => parseInt(ownProps.session.user_id, 10) === parseInt(u.id, 10));
	const job = jobs.find(j => parseInt(ownProps.session.job_id, 10) === parseInt(j.id, 10));
	const isEditable = job.status === JobStatus.OPEN;

	return { user, job, isEditable };
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSave: (session_id, data) => {
			dispatch(doSaveJobSession(session_id, data));
		},

		onDelete: (session) => {
			dispatch(doDeleteJobSession(session.id));
		},
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(JobSession);
