import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataList from '../../../components/containers/DataList';
import getVisibleExpenses from '../filters/VisibleExpenses';
import Fee from './Fee';
import EditExpense from './EditExpense';
import { openOverlay } from '../../../UIActions';
import I18n from '../../../locales/I18n';

class FeesList extends React.Component {

	addExpense = event => {
		event.preventDefault();
		const title = I18n.t('Add fee');
		this.props.createExpenseForm(title, { type: 'FEE' });
	}

	render() {
		const { isFetching, fees } = this.props;

		if( isFetching )
			return <div className="message-empty">{ I18n.t('Please wait while loading...') }</div>

		if( ! fees.length )
			return <div className="message-empty">{ I18n.t('No document found') }. <button className="link-like" onClick={this.addExpense}>{ I18n.t('Add new ?') }</button></div>


		return (
            <DataList
                data={ fees }
                keyExtractor={ (item) => item.id }
                renderItem={ ({ item }) => <Fee fee={ item } /> }
            />
		);
	}
};

FeesList.propTypes = {
	fees: PropTypes.arrayOf(PropTypes.object).isRequired,
	view: PropTypes.string
};


const mapStateToProps = (state, ownProps) => {
	const { isFetching } = state.data || { isFetching: true }

	if( isFetching || state.data.expenses.length === 0 )
		return { fees: [], isFetching };

	return {
		fees: getVisibleExpenses(state.data, 'FEE', ownProps.period, ownProps.view, ownProps.client),
		isFetching
	};
}


const mapDispatchToProps = (dispatch) => {
	return {
		createExpenseForm: (title, expense) => {
			const module = <EditExpense expense={expense} />;
			dispatch(openOverlay( title, module ));
		}
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(FeesList);
