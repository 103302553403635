import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Glyphicon from '../common/Glyphicon';
import { closeOverlay, addOverlayBodyClass, removeOverlayBodyClass } from '../../UIActions';

class Overlay extends React.Component {

	constructor(props) {
        super(props);

		const _this = this;
		document.body.onkeyup = function(e) {
			if( (e.key && e.key === 'Escape') || (e.keyCode && e.keyCode === 27) ) {
				_this.close(e);
			}
		}
	}


	componentDidUpdate(prevProps, prevState) {
		if(this.props.module) {
			const timer = setTimeout(() => {
				addOverlayBodyClass();
				clearTimeout(timer);
			},50);
		}

	}

	close = event => {
		event.preventDefault();

		if( this.props.onClose ) {
			this.props.onClose();
			return;
		}

		removeOverlayBodyClass();

		const timer = setTimeout(() => {
			this.props.onCloseOverlay();
			clearTimeout(timer);
		}, 500);

	}



	render() {
		const { module, title } = this.props;
		if(! module )
			return null;

		return (
			<Fragment>
				<div id="overlay-wrapper">
					<div className="header">
						<h1 className="page-title">
							<Glyphicon icon="chevron-left" className="circle-icon" tagName="a" onClick={this.close} />
							{title}
						</h1>
						{
							/*<p>From <a href="#">You</a> to <a href="#">Scott Waite</a>, started on <a href="#">March 2, 2014</a> at 2:14 pm est.</p> */
						}
					</div>

					<div id="overlay-nano-wrapper" className="nano">
						<div className="nano-content">
							{module}
						</div>
					</div>
				</div>

				<div id="overlay-click-stopper" onClick={ this.close } />
			</Fragment>
		);
	}
}



Overlay.propTypes = {
	onClose: PropTypes.func
};


const mapStateToProps = (state, ownProps) => {
	const { module, title, onClose } = state.ui.overlay || { module: null, title: null, onClose: null };

	return {
		module,
		title,
		onClose
	};
}




const mapDispatchToProps = (dispatch) => {
	return {
		onCloseOverlay: () => {
			dispatch(closeOverlay());
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);
