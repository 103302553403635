import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import DateTime from '../../../components/common/DateTime';
import Currency from '../../../components/common/Currency';
import ItemOptions from '../../../components/common/ItemOptions';
import I18n from '../../../locales/I18n';
import EditExpense from './EditExpense';
import { openOverlay, openDocumentViewer } from '../../../UIActions';
import { doSaveExpense, doDeleteExpense } from '../ExpensesActions';

class Invoice extends React.Component {

	editForm = event => {
		event.preventDefault();
		const expense = { id: this.props.invoice.id };
		this.props.getForm(I18n.t('Edit expense'), expense);
	}


	doDelete = event => {
		event.preventDefault();
		this.props.onDelete(this.props.invoice)
	}


	viewDocument = event => {
		event.preventDefault();

		const invoice = this.props.invoice;
		const options = [
			{
				label: I18n.t('Download'),
				url: invoice.download_url
			}
		];

		this.props.viewDocument(invoice.view_url, options);
	}



	markAsClosed = event => {
		event.preventDefault();
		this.closeExpense(false);
	}

	markAsPaid = event => {
		event.preventDefault();
		this.closeExpense(true);
	}


	closeExpense = updateClientStatus => {
		const invoice = this.props.invoice;
		if( invoice.status === 'CLOSED' )
			return null;

		const expenseForm = new FormData();
		expenseForm.append('id', invoice.id);
		expenseForm.append('action', 'updateStatus');
		expenseForm.append('status', 'CLOSED');

		if( updateClientStatus )
			expenseForm.append('paid', '1');

		this.props.onStatusChange(expenseForm);
	}


	doDuplicate = () => {
		const invoice = Object.assign({}, this.props.invoice, { id: '', date_memo: null, attached_file: '', status: 'OPEN' });
		this.props.getForm(I18n.t('Add expense'), invoice);
	}


	renderTransactionsDetails = () => {
		const { invoice, transactions } = this.props;
		if( invoice.status === 'CLOSED' )
			return '';

		if( transactions.length === 0 )
			return '';

		let amount = 0;
		transactions.forEach(t => {
			let invAmount = parseFloat(t.amount);
			if( invAmount < 0 )
				invAmount *= -1;
			amount += invAmount
		});

		return <span className="invoice-transactions-details">
			<span className="invoice-transactions-details-paid">{ I18n.t('Already paid:') } <strong><Currency amount={amount} /></strong></span>
			<span className="invoice-transactions-details-balance">{ I18n.t('Balance:') } <strong className="invoice-balance"><Currency amount={ parseFloat(invoice.amount_with_taxes) - amount } /></strong></span>
		</span>
	}



	getItemOptions = () => {
		const { invoice } = this.props;

		const options = [];

		if(invoice.view_url) {
			options.push({
				label: I18n.t('View'),
				action: this.viewDocument,
				icon: 'open'
			});
		}


		options.push({
			label: I18n.t('Duplicate'),
			action: this.doDuplicate,
			icon: 'duplicate'
		});

		if( invoice.status !== 'CLOSED' ) {
			options.push({
				label: I18n.t('Edit'),
				action: this.editForm,
				icon: 'edit'
			});
		}
		else if(! invoice.attached_file) {
			options.push({
				label: I18n.t('Add file'),
				action: this.editForm,
				icon: 'open'
			});
		}

		if( invoice.status !== 'CLOSED' ) {
			options.push({
				label: I18n.t('Close invoice'),
				action: this.markAsClosed,
				icon: 'flash'
			});

			options.push({
				label: I18n.t('Paid with credit card'),
				action: this.markAsPaid,
				icon: 'credit-card'
			});

			options.push({
				label: I18n.t('Delete'),
				action: this.doDelete,
				icon: 'trash'
			});
		}

		return options;
	}



	render() {
		const { invoice, provider } = this.props;

		const classNames = ['item', 'expense', `expense-${invoice.id}`];
		if(invoice.status === 'OPEN')
			classNames.push('open');

		if( invoice.is_late )
			classNames.push('late');

		return (
			<li className="data-list-item">
				<div className={ classNames.join(' ') } id={`expense-${invoice.id}`} data-id={invoice.id}>
					<div className="item-main-col">
						<div className="item-client">
							<Link to={`/outcomes/providers/view/${provider.id}`}>{provider.short_name}</Link>
						</div>

						<div className="item-title">
							<button className="link-like" onClick={ this.viewDocument }>{invoice.label}</button>
							{ this.renderTransactionsDetails() }
						</div>
					</div>

					<div className="item-col-date">
						<DateTime date={invoice.date_memo} format="dd mmm yyyy" />
					</div>

					<div className="item-col-amount col-amount-taxexcl">
						<div className="amount">
							<Currency amount={invoice.amount} />
						</div>
					</div>

					<div className="item-col-amount col-amount-taxincl">
						<div className="amount">
							<Currency amount={invoice.amount_with_taxes} />
						</div>
					</div>

					<ItemOptions options={ this.getItemOptions() } />
				</div>
			</li>
		)
	}
};

Invoice.propTypes = {
	invoice: PropTypes.object.isRequired,
	provider: PropTypes.object.isRequired,
	transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
	onStatusChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	getForm: PropTypes.func.isRequired,
	viewDocument: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
	const { clients, searchTerms, transactions } = state.data || { clients: [], searchTerms: false, transactions: [] };
	const provider = clients.find(c => parseInt(c.id, 10) === parseInt(ownProps.invoice.client_id, 10)) || {}
	const invoiceTransactions = transactions.filter(t => parseInt(t.document_id, 10) === parseInt(ownProps.invoice.id, 10) && t.type === 'OUT' && t.category === 'OUTCOME' && t.validated);
	return { provider, searchTerms, transactions: invoiceTransactions };
}


const mapDispatchToProps = (dispatch) => {
	return {
		onStatusChange: (expense) => {
			dispatch(doSaveExpense(expense))
		},

		onDelete: (expense) => {
			dispatch(doDeleteExpense(expense))
		},

		getForm: (title, expense) => {
			const module = <EditExpense expense={expense} />;
			dispatch(openOverlay(title, module));
		},

		viewDocument: (url, options) => {
			dispatch(openDocumentViewer(url, options));
		}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Invoice);
