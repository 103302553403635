import { saveSettings } from '../../api';

import {
	SETTINGS_DO_SAVE,
	SETTINGS_SAVED
} from '../../AppEvents';



function settings_save(settings) {
	return {
		type: SETTINGS_DO_SAVE,
		settings
	}
}


function settings_saved(response) {
	const r = {
		type: SETTINGS_SAVED,
		status: parseInt(response.status, 10),
		settings: response.settings,
		receivedAt: Date.now()
	}

	r.message = r.status === 200 ? 'Settings was saved' : response.message;
	return r;
}


export function doSaveSettings(settings) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(settings_save(settings));
			return saveSettings(settings).then(json => dispatch(settings_saved(json)));
		});
	}
}
