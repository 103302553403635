import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import TimeFormatter from '../../../libs/TimeFormatter';
import Moveable from '../../../libs/Moveable';
import { doCompleteJobSession, doDeleteJobSession, doSaveJobSession } from '../JobSessionsActions';
import I18n from '../../../locales/I18n';



class ActiveJobSession extends React.Component {



	constructor(props) {
		super(props);
		this.timer = null;
		this.moveable = null;
		this.startDate = null;
		this.refresh();
	}

	componentDidUpdate() {
		this.refresh();
	}

	refresh = () => {
		if(this.props.job_session)
			this.startDate = Date.parse(this.props.job_session.started_at);
	}

	onComplete = () => {
		// save endtime of session, mark it as closed, add equiv time to job (Math.ceil to upper 30')
		this.props.onComplete(this.props.job_session);
	}


	onCancel = () => {
		if(! window.confirm(I18n.t('Are you sure?')))
			return;

		clearInterval(this.timer);
		this.props.onCancel(this.props.job_session);
	}





	editNotes = event => {
		this.openNotesForm();
	}


	saveNotesChange = event => {
		this.closeNotesForm();
		const textarea = document.getElementById('session-notes');
		if(textarea.value !== this.props.job_session.notes)
			this.saveNotes();
	}

	cancelNotesChange = event => {
		this.closeNotesForm();
		const textarea = document.getElementById('session-notes');
		if(textarea.value !== this.props.job_session.notes)
			textarea.value = this.props.job_session.notes;
	}


	onChangeNotes = event => {
		this.closeNotesForm();
		if(event.target.value === this.props.job_session.notes)
			return;
		this.saveNotes();
	}


	openNotesForm = () => {
		document.getElementById('session-notes-holder').classList.add('on-edit');
		document.getElementById('session-notes').focus();
	}

	closeNotesForm = () => {
		document.getElementById('session-notes-holder').classList.remove('on-edit');
	}

	saveNotes = () => {
		const id = this.props.job_session.id;
		const data = new FormData();
		data.append('id', id);
		data.append('notes', document.getElementById('session-notes').value);
		this.props.onSave( id, data );
	}





	updateChrono = () => {
		var timeElapsed = document.getElementById('time-elapsed');
		if( timeElapsed )
			timeElapsed.innerText = this.getCounter();
	}

	getCounter = () => {
		if(! this.startDate)
			return '00:00:00';

		const timeElapsed = Math.round( (new Date().getTime() - this.startDate) / 1000 );
		const timeFormatter = new TimeFormatter(timeElapsed);
		return timeFormatter.hhmmss( I18n.t(':') );
	}





	render() {
		const { job_session, job } = this.props;

		if( ! job_session ) {
			clearInterval(this.timer);
			this.timer = null;
			this.moveable = null;
			this.startDate = null;
			return null;
		}

		this.timer = setInterval(this.updateChrono, 1000);
		const editNotesLabel = job_session.notes ? I18n.t('Edit notes') : I18n.t('Add notes');

		if(! this.moveable) {
			const timeout = setTimeout( () => {
				this.moveable = new Moveable(document.getElementById('job-session-moveable'));
				clearTimeout(timeout);
			}, 500 );
		}

		return (
			<div className="job-session-overlay" id="job-session-moveable">
				<div className="session-job-title">{job.label}</div>
				<div className="session-duration">
					<span id="time-elapsed">{ this.getCounter() }</span>
				</div>
				<div className="session-notes" id="session-notes-holder">
					<textarea id="session-notes" onBlur={ this.onChangeNotes } defaultValue={ job_session.notes } />
				</div>
				<div className="session-controls">
					<button onClick={this.onComplete} className="link-like session-control session-save session-control-primary">{ I18n.t('OK') }</button>
					<button onClick={this.editNotes} className="link-like session-control session-edit-note session-control-secondary">{ editNotesLabel }</button>
					<button onClick={this.onCancel} className="link-like session-control session-cancel session-control-secondary">{ I18n.t('Cancel') }</button>
					<button onClick={this.saveNotesChange} className="link-like session-notes-control session-notes-save session-control-primary">{ I18n.t('Save') }</button>
					<button onClick={this.cancelNotesChange} className="link-like session-notes-control session-notes-cancel session-control-secondary">{ I18n.t('Cancel') }</button>
				</div>
			</div>
		)
	}
};

ActiveJobSession.propTypes = {
	job: PropTypes.object
};


const mapStateToProps = (state, ownProps) => {
	const { job_sessions, jobs, user } = state.data || { job_sessions: [], jobs: [], user: {} };
	const job_session = job_sessions.find(s => s.completed === false && s.user_id === user.id );
	const job = job_session ? jobs.find(j => j.id === job_session.job_id) : {};
	return { job_session, job }
}


const mapDispatchToProps = (dispatch) => {
	return {
		onComplete: (session) => {
			dispatch(doCompleteJobSession(session.id))
		},

		onCancel: (session) => {
			dispatch(doDeleteJobSession(session.id));
		},

		onSave: (session_id, data) => {
			dispatch(doSaveJobSession(session_id, data));
		}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(ActiveJobSession);
