import config from '../config';
import { getLanguageFile } from '../api';
//import po2json from 'po2json';
import {
	LANGUAGE_PACK_GET,
	LANGUAGE_PACK_GOT,
	LANGUAGE_SWITCH
} from '../AppEvents';



function language_get() {
	return {
		type: LANGUAGE_PACK_GET,
		locale: 'fr_FR'
	}
}


function language_got(obj) {
	const messages = [];
	for(let i in obj) {
		if( obj[i][1] ) {
			messages.push({ msgid: i, msgstr: obj[i] });
		}
	}

	return {
		type: LANGUAGE_PACK_GOT,
		locale: 'fr_FR',
		messages: messages,
		receivedAt: Date.now()
	}
}



function language_switch() {
	return {
		type: LANGUAGE_SWITCH,
		didInvalidate: true,
		locale: 'fr_FR'
	}
}



function doFetchLocale() {
	return dispatch => {
		dispatch(language_get());
		//console.log(config);
		return getLanguageFile(config.locale).then(blob => {
			//console.log(blob);
			dispatch(language_got(blob));
		});
	}
}

export function switchLanguage() {
	return (dispatch, getState) => {
		return dispatch(language_switch());
	}
}


export function fetchLocale() {
	// Note that the function also receives getState()
	// which lets you choose what to dispatch next.
	// This is useful for avoiding a network request if
	// a cached value is already available.

	return (dispatch, getState) => {
		const state = getState();
		if(! state.locale || ! state.locale.isFetching || state.locale.didInvalidate !== false ) {
			// Dispatch a thunk from thunk!
			return dispatch(doFetchLocale());
		}

		// Let the calling code know there's nothing to wait for.
		return Promise.resolve();
	}
}
