import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone'
import Glyphicon from '../../../components/common/Glyphicon';
import DateTime from '../../../components/common/DateTime';
import Currency from '../../../components/common/Currency';
import ClientSelect from '../../../components/forms/ClientSelect';
import AddClientButton from '../../../components/forms/AddClientButton';
import DateSelect from '../../../components/forms/DateSelect';
import TaxRateSelect from '../../../components/forms/TaxRateSelect';
import I18n from '../../../locales/I18n';
import { openDocumentViewer, closeOverlay, removeOverlayBodyClass } from '../../../UIActions';
import { doSaveExpense } from '../ExpensesActions';
import { sendExpenseFile, deleteExpenseFile } from '../../../api';
import { EXPENSE_SAVED } from '../../../AppEvents';
import { ExpenseStatus, ExpenseType } from '../ExpensesConsts';


class EditExpense extends React.Component {

	constructor(props) {
		super(props);

		this.type = props.expense.type;

		this.types = {
			INVOICE: I18n.t('Invoice'),
			CREDIT_NOTE: I18n.t('Credit note'),
			FEE: I18n.t('Fee')
		};

		this.state = {
			file: props.expense.attached_file,
			filename: props.expense.filename,
			expense: props.expense
		};
	}


	componentDidUpdate() {
        const { lastAction } = this.props;

		if( lastAction.type === EXPENSE_SAVED && lastAction.success ) {
			removeOverlayBodyClass();

			const timer = setTimeout(() => {
				this.props.doClose();
				clearTimeout(timer);
			}, 500);
		}
	}



	/*
 	 * When user adds an subtotal ...
	 */
	addSubtotal = event => {
		event.preventDefault();

		// add an item in local state...
		let expense = this.state.expense;

		// add a new subtotal into this.state.expense.subtotals
		const ccSubtotal = expense.subtotals.slice(-1)[0];
		let newSubtotal = {};

		for(let prop in ccSubtotal) {
			newSubtotal[prop] = '';
		}

		expense.subtotals.push(newSubtotal);
		this.setState({ expense: expense });
	}



	/*
 	 * When user removes an item ...
	 */
	removeSubtotal = event => {
		event.preventDefault();

		if( window.confirm( I18n.t('Are you sure?') )) {
			let expense = this.state.expense;
			const itemID = event.target.getAttribute('data-item');
			if( expense.subtotals[itemID] ) {
				expense.subtotals[itemID] = null;
				//expense.subtotals.splice(itemID, 1);
			}
			this.setState({ expense: expense });
		}
	}


	/*
 	 * When user saves expense ...
	 */
	save = event => {
		event.preventDefault();

		if( this.props.isSaving ) {
			return;
		}
		if( this.props.expense.status === ExpenseStatus.CLOSED ) {
			return;
		}
		const expense = new FormData(document.getElementById('expense-edit'));
		if(! expense.get('client_id')) {
			const client_id = this.props.expense.client_id || 0;
			expense.append('client_id', client_id);
		}

		this.props.onSave(expense);
	}


	viewDocument = event => {
		event.preventDefault();

		const expense = this.props.expense;
		if(! expense.id || ! expense.attached_file)
			return;

		const options = [
			{
				label: I18n.t('Download'),
				url: expense.download_url
			}
		];

		this.props.viewDocument(expense.view_url, options);
	}


	removeFile = event => {
		event.preventDefault();
		if( this.state.file && window.confirm( I18n.t('Are you sure?')) ) {
			this.setState({ file: null });
			deleteExpenseFile(this.props.expense.id).then(json => this.fileRemoved(json))
		}
	}


	fileRemoved = response => {
		if( response.status !== 200 ) {
			// do nothing
			return;
		}
	}


	onDropFile = (acceptedFile, rejectedFiles) => {
		document.getElementById('dropzone-expense').classList.add('loading');

		const expense = this.props.expense;
		const fileData = new FormData();

		acceptedFile.forEach(file => {
			fileData.append('file', file, file.name);
		});

		if(this.props.expense.id)
			fileData.append('id', this.props.expense.id);

		if( expense.status === ExpenseStatus.CLOSED )
			fileData.append('save_expense', true);

		sendExpenseFile(fileData).then(json => this.fileAdded(json));
	}


	fileAdded = response => {
		if( response.status >= 400 ) {
			alert(response.errorMessage);
			return;
		}

		this.setState({ file: response.file, filename: response.filename });
	}



	changeProvider = event => {
		const expense = Object.assign({}, this.state.expense, { client_id: event.value });
		this.setState({ expense });
		return event.value;
	};


	/*
	 * Calc Amount with VAT
	 */
	calcTotalAmount  = event => {
		//event.preventDefault();
		let holder = event.target.parentNode.parentNode;
		if(holder.classList.contains('input')) {
			holder = holder.parentNode;
		}
		const index = holder.dataset.index;

		const amountWithTaxesInput = holder.querySelector('input[name*=amount_with_taxes]');
		const amountInput = holder.querySelector('input[name*=amount]');
		const taxRateInput = holder.querySelector('select[name*=tax_rate_id]');
		const amountValue = parseFloat(amountInput.value);
		const taxRateId = parseInt(taxRateInput.value, 10);
		const taxRate = this.props.tax_rates.find(r => parseInt(r.id, 10) === taxRateId);
		const taxRateFactor = (taxRate.rate / 100) + 1;
		//const amountWithTaxesValue = (amountValue * taxRateFactor).toFixed(2);
		const amountWithTaxesCents = Math.round( ((amountValue * taxRateFactor) * 100).toFixed(4) );
		const amountWithTaxesValue = (amountWithTaxesCents / 100);

		amountWithTaxesInput.value = amountWithTaxesValue;

		let expense = this.state.expense;
		expense.subtotals[index].amount = amountValue;
		expense.subtotals[index].tax_rate_id = taxRateId;
		expense.subtotals[index].amount_with_taxes = parseFloat(amountWithTaxesValue);

		this.setState({expense})
	}


	/*
	 * When manually fixing total amount
	 */
	updateAmountWithTaxes = event => {
		let holder = event.target.parentNode.parentNode;
		if(holder.classList.contains('input')) {
			holder = holder.parentNode;
		}
		const index = holder.dataset.index;

		let expense = this.state.expense;
		expense.subtotals[index].amount_with_taxes = parseFloat(event.target.value);

		this.setState({expense})
	}




	renderProviderSelect = () => {
		const { expense } = this.state;
		const { clients } = this.props;

		if( expense.type === ExpenseType.FEE ) {
			return null;
		}

		if(expense.status === ExpenseStatus.CLOSED ) {
			const client = clients.find(c => expense.client_id === c.id);

			return (
				<div className="input select">
					<span className="label-like">{ I18n.t('Provider') }</span>
					<span className="input-like">{ client.name }</span>
				</div>
			);
		}

		return (
			<div className="input select">
				<label htmlFor="client-id">{ I18n.t('Provider') }</label>
				<AddClientButton type="provider" />
				<ClientSelect type="provider" id="client-id" name="client_id" value={ expense.client_id } onChange={ this.changeProvider } />
			</div>
		);

	}



	renderDate = () => {
		const { expense } = this.state;

		const dateTime =
			expense.status === ExpenseStatus.CLOSED ?
			<span className="input-like"><DateTime date={expense.date_memo} format='dd/mm/yyyy' /></span> :
			<DateSelect name="date_memo" defaultValue={expense.date_memo} /> ;

		return (
			<div className="input date required">
				<label>{ I18n.t('Date') }</label>
				{ dateTime }
			</div>
		);
	}



	renderLabel = () => {
		const { expense } = this.state;

		const field =
			expense.status === ExpenseStatus.CLOSED ?
			<span className="input-like">{ expense.label }</span> :
			<input type="text" name="label" maxLength="140" id="label" defaultValue={expense.label} required />

		return (
			<div className="input text">
				<label htmlFor="label">{ I18n.t('Label') }</label>
				{ field }
			</div>
		);
	}


	renderDetails = () => {
		const { expense } = this.state;
		if( expense.status === ExpenseStatus.CLOSED ) {
			return null;
		}
		return (
			<div className="input text">
				<label htmlFor="label">{ I18n.t('Details') }</label>
				<textarea name="details" id="details" defaultValue={expense.details} />
			</div>
		);
	}





	renderFile = () => {
		if( this.state.file ) {
			return (
				<fieldset>
					<div className="input file">
						<span className="input-like">
							{ I18n.t('Current file') }:
							<button className="link-like" onClick={ this.viewDocument }>{ this.state.filename }</button>
						</span>
						<button className="microbutton" onClick={ this.removeFile }>{ I18n.t('Remove') }</button>
					</div>
				</fieldset>
			);
		}

		return (
			<fieldset className="dropzone-fs">
				<div className="input file">
					<Dropzone id="dropzone-expense" onDrop={this.onDropFile.bind(this)} className="dropzone dropzone-mini" multiple={false}>
						<div className="dropzone-instructions">
							<p>{ I18n.t('Drop your file here') }</p>
						</div>
					</Dropzone>
				</div>
			</fieldset>
		);
	}



	renderSubmit = () => {
		if( this.state.expense.status === ExpenseStatus.CLOSED ) {
			return null;
		}

		if( this.props.isSaving ) {
			return <button type="submit" className="is-saving">{ I18n.t('Saving...') }</button>
		}
		return <button type="submit">{ I18n.t('Save') }</button>
	}





	/*
	 * following methods are made
	 * To generate the sub-forms
	 */
	renderSubtotalForm = (i, subtotal) => {
		if( subtotal === null ) {
			return;
		}
		const key = `exp-subtotal-${i}`;
		let defaultTaxRateId = 2;
		if( this.state.expense.client_id ) {
			const provider = this.props.clients.find(c => parseInt(c.id, 10) === parseInt(this.state.expense.client_id, 10));
			if( provider.intracom_vat ) {
				defaultTaxRateId = 4;
			}
		}


		return (
			<fieldset id={key} key={key}>
				<legend className="small">{ I18n.r('Subtotal #{0}', i + 1) }</legend>

				<div className="input text">
					<label htmlFor={`subtotals-${i}-label`}>{ I18n.t('Label') }</label>
					<input
						type="text"
						name={`subtotals[${i}][label]`}
						maxLength="255" id={`subtotals-${i}-label`}
						defaultValue={subtotal.label || ''}
					/>
				</div>

				<div className="fields-group fields-group-exp-subtotal" data-index={i}>
					<div className="input number item-amount">
						<label htmlFor={`subtotals-${i}-amount`}>{ I18n.t('Amount') }</label>
						<input
							type="number"
							name={`subtotals[${i}][amount]`}
							step="any"
							id={`subtotals-${i}-amount`}
							defaultValue={subtotal.amount || ''}
							required
							onChange={this.calcTotalAmount}
						/>
					</div>

					<div className="input select no-label">
						<TaxRateSelect
							name={`subtotals[${i}][tax_rate_id]`}
							id={`subtotals-${i}-tax-rate-id`}
							value={subtotal.tax_rate_id || defaultTaxRateId}
							onChange={this.calcTotalAmount}
						/>
					</div>

					<div className="input number item-amount">
						<label htmlFor={`subtotals-${i}-amount-with-taxes`}>{ I18n.t('Full amount') }</label>
						<input
							type="number"
							name={`subtotals[${i}][amount_with_taxes]`}
							step="any" id={`subtotals-${i}-amount-with-taxes`}
							defaultValue={subtotal.amount_with_taxes || ''}
							onChange={this.updateAmountWithTaxes}
							required
						/>
					</div>
				</div>

				<div className="form-item-option">
					<Glyphicon
						icon="remove"
						className="circle-icon small red-hover"
						onClick={this.removeSubtotal}
						data-item={i}
						tagName="a"
					/>
				</div>

				<input type="hidden" name={`subtotals[${i}][id]`} value={subtotal.id} readOnly />
			</fieldset>
		);
	};





	render() {
		const { expense } = this.state;
		const { settings } = this.props;

		if( ! expense.subtotals || expense.subtotals.length === 0 ) {
			expense.subtotals = [{ label: '' }];
		}

		let amount = 0;
		let amountWithTaxes = 0;

		expense.subtotals.forEach(s => {
			if( s === null ) {
				return;
			}
			if( s.amount ) {
				amount += Math.round(s.amount * 100);
				amountWithTaxes += Math.round(s.amount_with_taxes * 100);
			}
		});

		amount /= 100;
		amountWithTaxes /= 100;


		let subtotalsForm = [];
		expense.subtotals.forEach((subtotal, i) => {
			subtotalsForm.push( this.renderSubtotalForm(i, subtotal) );
		});

		return (
			<div className="expenses form">
				<form method="post" acceptCharset="utf-8" id="expense-edit" onSubmit={this.save}>
					<fieldset>
						{ this.renderProviderSelect() }
						{ this.renderDate() }
						{ this.renderLabel() }
						{ this.renderDetails() }
					</fieldset>

					<div id="items-form-holder">
						{ subtotalsForm }

						<div className="items-controls">
							<button className="link-like" id="add-document-subtotal" onClick={this.addSubtotal}>
								<Glyphicon icon="plus" className="circle-icon small" />
								{ I18n.t('Add subtotal') }
							</button>
						</div>
					</div>

					<fieldset>
						<div className="fields-group">
							<div className="input number">
								<span className="label-like">{ I18n.t('Amount') }</span>
								<span className="input-like"><Currency amount={amount} settings={settings} /></span>
							</div>

							<div className="input number">
								<span className="label-like">{ I18n.t('Full amount') }</span>
								<span className="input-like"><Currency amount={amountWithTaxes} settings={settings} /></span>
							</div>
						</div>
					</fieldset>

					{ this.renderFile() }

					<div className="form-controls">
						<input type="hidden" name="id" value={expense.id} readOnly />
						<input type="hidden" name="type" value={expense.type} readOnly />
						<input type="hidden" name="attached_file" value={this.state.file} readOnly />
						{ this.renderSubmit() }
					</div>
				</form>
			</div>
		);
	}
};



EditExpense.propTypes = {
	tax_rates: PropTypes.arrayOf(PropTypes.object).isRequired,
	expense: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	onSave: PropTypes.func.isRequired,
	viewDocument: PropTypes.func.isRequired,
	doClose: PropTypes.func.isRequired,
	lastAction: PropTypes.object.isRequired,
	isSaving: PropTypes.bool.isRequired
}



const mapStateToProps = (state, ownProps) => {
	const { tax_rates, expenses, clients, lastAction, companySettings, isSaving } = state.data || { tax_rates: [], expenses: [], clients: [], lastAction: {}, companySettings: {}, isSaving: false };
	const expense = ownProps.expense.id ? expenses.find(exp => exp.id === ownProps.expense.id ) : ownProps.expense;

	return {
		tax_rates,
		expense,
		settings: companySettings,
		clients,
		lastAction,
		isSaving
	};
}


const mapDispatchToProps = (dispatch) => {
	return {
		onSave: (expense) => {
			dispatch(doSaveExpense(expense));
		},

		viewDocument: (url, options) => {
			dispatch(openDocumentViewer(url, options));
		},

		doClose: () => {
			dispatch(closeOverlay());
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditExpense);
