
import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import I18n from '../../locales/I18n';



class BankStatementImporter extends React.Component {

	constructor(props) {
		super(props);


		const id = props.id ? props.id : 'bank-statements-dropzone';
		this.state = { id };
	}


	onDrop = (acceptedFiles, rejectedFiles) => {
		//console.log(acceptedFiles);
		/*
		acceptedFiles.forEach(file => {
	        const reader = new FileReader();
	        reader.onload = () => {
	            const fileAsBinaryString = reader.result;
	            // do whatever you want with the file content
				console.log(fileAsBinaryString);
	        };
	        reader.onabort = () => console.log('file reading was aborted');
	        reader.onerror = () => console.log('file reading has failed');

	        reader.readAsBinaryString(file);
	    });
		*/

		document.getElementById( this.state.id ).classList.add('loading');
		this.props.onDrop(acceptedFiles, rejectedFiles);
	}



	render() {

		return (
			<Dropzone id={ this.state.id } onDrop={ this.onDrop.bind(this) } className="dropzone">
				<div className="dropzone-instructions">
					<h3>{ I18n.t('Drop your file(s) here') }</h3>
					<p>{ I18n.t('Documents must be at CODA format (Belgian Bank Statement).') }</p>
				</div>
			</Dropzone>
		);
	}
};

BankStatementImporter.propTypes = {
	id: PropTypes.string,
	onDrop: PropTypes.func.isRequired
};


export default BankStatementImporter;
