
/*
 * @return int $cents
 */
export const amountInCents = amount => {
	if( isNaN(amount) ) {
		return 0;
	}
	return Math.round(amount * 100);
}
