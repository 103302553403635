
export default class Moveable {


	constructor(element, handleSelector) {
		this.pos1 = 0;
		this.pos2 = 0;
		this.pos3 = 0;
		this.pos4 = 0;
		this.element = element;
		this.initialize(handleSelector);
	}

	initialize = (handleSelector) => {
		if(! this.element)
			return;

		if( handleSelector ) {
			const handler = this.element.querySelector(handleSelector);
			if( handler ) {
				handler.onmousedown = this.startDrag;
				return;
			}
		}

		this.element.onmousedown = this.startDrag;
		return;

	}



	startDrag = event => {
		event = event || window.event;
	    // get the mouse cursor position at startup:
	    this.pos3 = event.clientX;
	    this.pos4 = event.clientY;
	    document.onmouseup = this.stopDrag;
	    // call a function whenever the cursor moves:
	    document.onmousemove = this.startMove;
	}


	startMove = event => {
		event = event || window.event;

		this.pos1 = this.pos3 - event.clientX;
	    this.pos2 = this.pos4 - event.clientY;
	    this.pos3 = event.clientX;
	    this.pos4 = event.clientY;

	    // set the element's new position:
	    this.element.style.top = `${ this.element.offsetTop - this.pos2 }px`;
	    this.element.style.left = `${ this.element.offsetLeft - this.pos1 }px`;
	}


	stopDrag = () => {
		/* stop moving when mouse button is released:*/
		document.onmouseup = null;
		document.onmousemove = null;
    }
}
