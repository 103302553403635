export const TransactionCategories = {
	REFERENCED_TRANSACTION: 'REF',
	TAX: 'TAX',
	SALARY: 'SALARY',
	INVEST: 'INVEST',
	REFUND: 'REFUND',
	INCOME: 'INCOME',
	OUTCOME: 'OUTCOME',
	FEE: 'FEE',
	CREDIT_NOTE: 'CREDIT_NOTE',
	OWN_TRANSFER: 'OWN_TRANSFER',
};


export const TransactionTypes = {
	IN: 'IN',
	OUT: 'OUT',
};

export const transactionsTypesWithDoc = [
	TransactionCategories.INCOME,
	TransactionCategories.OUTCOME,
	TransactionCategories.CREDIT_NOTE,
	TransactionCategories.FEE,
];
