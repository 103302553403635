import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import logo from '../../assets/img/logo-256x256.png';

import PrimaryNav from './PrimaryNav';
import I18n from '../../locales/I18n';

import Glyphicon from '../common/Glyphicon';
import PeriodSwitcher from '../common/PeriodSwitcher';
import EditJob from '../../modules/jobs/components/EditJob';
import EditInvoice from '../../modules/invoices/components/EditInvoice';
import EditClient from '../../modules/clients/components/EditClient';
import EditExpense from '../../modules/expenses/components/EditExpense';
import EditSettings from '../../modules/settings/components/EditSettings';
import { openOverlay } from '../../UIActions';
import { doLogout } from '../../modules/users/UsersActions';
import { UserRole, userRoleIs } from '../../modules/users/UsersConsts';

import packageJson from '../../../package.json';


const companyUrl = '/company';

class Header extends React.Component {


	createJob = event => {
		event.preventDefault();
		this.props.createJobForm();
	}

	createQuote = event => {
		event.preventDefault();
		const title = I18n.t('Create quote');
		this.props.createInvoiceForm(title, { type: 'QUOTE' });
	}

	/*
	createCreditNote = event => {
		event.preventDefault();
		const title = I18n.t('Create credit note');
		this.props.createInvoiceForm(title, { type: 'CREDIT_NOTE' });
	}
	*/


	createClient = event => {
		event.preventDefault();
		this.props.createClientForm({ is_client: true });
	}

	createProvider = event => {
		event.preventDefault();
		this.props.createClientForm({ is_provider: true });
	}

	createExpense = event => {
		event.preventDefault();
		const title = I18n.t('Add expense');
		this.props.createExpenseForm(title, { type: 'INVOICE' });
	}


	editSettings = event => {
		event.preventDefault();
		this.props.editSettingsForm();
	}

	editProfile = event => {
		event.preventDefault();
		alert('Edit Profile');
		//this.props.editSettingsForm();
	}

	logout = event => {
		event.preventDefault();
		this.props.doLogout();
	}

	closeResponsiveMenu = event => {
		document.body.classList.remove('show-sidebar');
	}


	renderIfAllowed = (key, allowedRoles) => {
		if(! userRoleIs(this.props.user, allowedRoles))
			return;

		switch(key) {
			case 'NEW_JOB':
				return <li><button onClick={ this.createJob }>{ I18n.t('New job') } <span className="icon">+</span></button></li>;
			case 'NEW_CLIENT':
				return <li><button onClick={ this.createClient }>{ I18n.t('Add client') } <span className="icon">+</span></button></li>;
			case 'NEW_QUOTE':
				return <li><button onClick={ this.createQuote }>{ I18n.t('New quote') } <span className="icon">+</span></button></li>;
			case 'NEW_EXPENSE':
				return <li><button onClick={ this.createExpense }>{ I18n.t('Add expense') } <span className="icon">+</span></button></li>;
			case 'NEW_PROVIDER':
				return <li><button onClick={ this.createProvider }>{ I18n.t('Add provider') } <span className="icon">+</span></button></li>;
			case 'COMPANY':
				return <li><NavLink to={ companyUrl } activeClassName="active" onClick={ this.closeResponsiveMenu }>{ I18n.t('Company') } <Glyphicon icon="home" /></NavLink></li>;
			case 'SETTINGS':
				return <li><button onClick={ this.editSettings }>{ I18n.t('Settings') } <Glyphicon icon="cog" /></button></li>;

			default:
				return;
		}
	}





	renderShortcuts = () => {
		const allowedRoles = [UserRole.MANAGER, UserRole.ACCOUNTANT, UserRole.ACCOUNT_MANAGER];
		if(! userRoleIs(this.props.user, allowedRoles))
			return;

		return (
			<React.Fragment>
				<div className="menu-segment">
					<ul className="shortcuts">
						<li className="title">{ I18n.t("Shortcuts") }</li>
						{ this.renderIfAllowed('NEW_JOB', [UserRole.MANAGER, UserRole.ACCOUNT_MANAGER]) }
						{ this.renderIfAllowed('NEW_CLIENT', [UserRole.MANAGER, UserRole.ACCOUNTANT]) }
						{ this.renderIfAllowed('NEW_QUOTE', [UserRole.MANAGER, UserRole.ACCOUNT_MANAGER]) }
						{ this.renderIfAllowed('NEW_EXPENSE', [UserRole.MANAGER, UserRole.ACCOUNTANT]) }
						{ this.renderIfAllowed('NEW_PROVIDER', [UserRole.MANAGER, UserRole.ACCOUNTANT]) }
					</ul>
				</div>

				<div className="separator"></div>
			</React.Fragment>
		)
	}






	renderSettings = () => {
		return (
			<div className="menu-segment">
				<ul className="settings">
					<li className="title">{ I18n.t('Settings') }</li>
					{ this.renderIfAllowed('COMPANY', [UserRole.MANAGER]) }
					{ this.renderIfAllowed('SETTINGS', [UserRole.MANAGER]) }
					<li><NavLink to="/profile" activeClassName="active" onClick={ this.closeResponsiveMenu }>{ I18n.t('My Profile') } <Glyphicon icon="user" /></NavLink></li>
					<li><button onClick={ this.logout }>{ I18n.t('Logout') } <Glyphicon icon="off" /></button></li>
				</ul>
			</div>
		)
	}






	render() {
		return (
			<header id="header" className="nano">
				<div className="nano-content">
					<div className="logo-container">
						<img src={ logo } className="logo" alt="Expresso" />
						Expresso
					</div>

					<PeriodSwitcher />

					<PrimaryNav />

					<div className="separator"></div>

					{ this.renderShortcuts() }

					{ this.renderSettings() }

					<div className="app-version"><small>© Expresso v{ packageJson.version } – tix02.be</small></div>
				</div>
			</header>


		);
	}
}


Header.propTypes = {
	user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
	const { user } = state.data;
	return { user }
}

const mapDispatchToProps = (dispatch) => {
	return {
		createJobForm: () => {
			const title = I18n.t('Create job');
			const module = <EditJob job={null} />;
			dispatch(openOverlay(title, module))
		},

		createClientForm: (options) => {
			const title = options.is_client ? I18n.t('Add client') : I18n.t('Add provider');
			const module = <EditClient client={options} />;
			dispatch(openOverlay(title, module))
		},

		createInvoiceForm: (title, invoice) => {
			const module = <EditInvoice invoice={invoice} />;
			dispatch(openOverlay( title, module ));
		},

		createExpenseForm: (title, expense) => {
			const module = <EditExpense expense={expense} />;
			dispatch(openOverlay( title, module ));
		},

		editSettingsForm: () => {
			dispatch(openOverlay( I18n.t('Edit settings'), <EditSettings /> ));
		},

		doLogout: () => {
			dispatch(doLogout());
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
