import React from 'react';
import PropTypes from 'prop-types';

class SelectBox extends React.Component {
	getOptions = () => {
		const options = [];
		this.props.options.forEach(option => {
			options.push(<option key={`key-${option.value}-${new Date().getTime()}`} value={option.value}>{option.label}</option>);
		});
		return options;
	}

	render() {
		const { name, ...attrs } = this.props || { name: '' };
		delete attrs.options;

		return (
			<span className="select-box">
				<select name={name} {...attrs}>
					{ this.getOptions() }
				</select>
			</span>
		);
	}
};


SelectBox.propTypes = {
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	name: PropTypes.string.isRequired
}


export default SelectBox;
