import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TransactionResume from './TransactionResume';


class ValidateTransaction extends React.Component {
	render() {

		const account_id = this.props.bank_account_id ? parseInt(this.props.bank_account_id, 10) : null;

		const items = this.props.transactions
			.filter(t => account_id ? account_id === parseInt(t.bank_account_id, 10) : true )
			.map(t => <TransactionResume key={`transaction-resume-${t.id}`} transaction={t} />);

		return (
			<div className="transaction form">
				{ items }
			</div>
		);
	}
};

ValidateTransaction.propTypes = {
	transactions: PropTypes.arrayOf(PropTypes.object).isRequired
};


const mapStateToProps = (state, ownProps) => {
	const { transactions } = state.data || { transactions: [] };
	const waitingTransactions = transactions.filter(tr => ! tr.validated);

	return {
		transactions: waitingTransactions,
	};
}

export default connect(mapStateToProps)(ValidateTransaction);
