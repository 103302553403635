import { saveInvoice, deleteInvoice, createJobFromQuote, sendInvoice, createCreditNoteFromInvoice } from '../../api';

import {
	INVOICE_DO_SAVE,
	INVOICE_SAVED,
	INVOICE_DO_DELETE,
	INVOICE_DELETED,
	JOB_FROM_QUOTE_CREATE,
	JOB_FROM_QUOTE_CREATED,
	INVOICE_DO_SEND,
	INVOICE_SENT,
	INVOICE_CREDIT_NOTE_CREATE,
	INVOICE_CREDIT_NOTE_CREATED
} from '../../AppEvents';

/*
- Structure of response always be
{
	message: null || error str
	status: statusCode
	url: URL
	data: {
		user data to response
	}
}
*/


function invoice_save(invoice) {
	/*
	 * Invoice can be partial here but not when saving data !!!
     */
	return {
		type: INVOICE_DO_SAVE,
		invoice
	}
}


function invoice_saved(response) {
	const r = {
		type: INVOICE_SAVED,
		status: parseInt(response.status, 10),
		invoice: response.invoice,
		client_status: response.client_status,
		receivedAt: Date.now()
	};
	r.message = r.status === 200 ? 'Document saved.' : response.message;
	return r;
}



function invoice_delete(invoice) {
	return {
		type: INVOICE_DO_DELETE,
		invoice
	}
}


function invoice_deleted(response) {
	const r = {
		type: INVOICE_DELETED,
		status: parseInt(response.status, 10),
		id: parseInt(response.id, 10),
		client_status: response.client_status,
		receivedAt: Date.now()
	}
	r.message = r.status === 200 ? 'Document deleted.' : response.message;
	return r;
}


function job_from_quote_create(data) {
	return {
		type: JOB_FROM_QUOTE_CREATE,
		data
	}
}


function job_from_quote_created(response) {
	const r = {
		type: JOB_FROM_QUOTE_CREATED,
		status: parseInt(response.status, 10),
		quote: response.quote,
		jobs: response.jobs,
		receivedAt: Date.now()
	}
	r.message = r.status === 200 ? 'Job was converted.' : response.message;
	return r;
}


function invoice_send(invoice) {
	/*
	 * Invoice can be partial here but not when saving data !!!
     */
	return {
		type: INVOICE_DO_SEND,
		invoice
	}
}


function invoice_sent(response) {
	const r = {
		type: INVOICE_SENT,
		status: parseInt(response.status, 10),
		invoice: response.invoice,
		to: response.to,
		receivedAt: Date.now()
	}
	r.message = r.status === 200 ? r.flash : response.message;
	return r;
}




function invoice_to_creditnote_create(invoiceId) {
	return {
		type: INVOICE_CREDIT_NOTE_CREATE,
		invoiceId
	}
}

function invoice_to_creditnote_created(response) {
	const r = {
		type: INVOICE_CREDIT_NOTE_CREATED,
		status: parseInt(response.status, 10),
		invoice: response.invoice,
		credit_note: response.credit_note,
		receivedAt: Date.now()
	};
	r.message = r.status === 200 ? 'Credit note was created.' : response.message;
	return r;
}


export function doSaveInvoice(invoice) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(invoice_save(invoice));
			return saveInvoice(invoice).then(json => dispatch(invoice_saved(json)));
		});
	}
}


export function doDeleteInvoice(invoice) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(invoice_delete(invoice));
			return deleteInvoice(invoice).then(json => dispatch(invoice_deleted(json)));
		});
	}
}

export function doCreateJobFromQuote(id, data) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(job_from_quote_create(data));
			return createJobFromQuote(id, data).then(json => dispatch(job_from_quote_created(json)));
		});
	}
}

export function doSendInvoice(invoice) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(invoice_send(invoice));
			return sendInvoice(invoice).then(json => dispatch(invoice_sent(json)));
		});
	}
}


export function doCreditNoteInvoice(invoiceId) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(invoice_to_creditnote_create(invoiceId));
			return createCreditNoteFromInvoice(invoiceId).then(json => dispatch(invoice_to_creditnote_created(json)));
		});
	}
}
