import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux'
import Toggle from '../../../components/forms/Toggle';
import SectionHeader from '../../../components/common/SectionHeader';
import FabButton from '../../../components/common/FabButton';
import ExpensesTotalAmount from './ExpensesTotalAmount';
import EditExpense from './EditExpense';
import InvoicesList from './InvoicesList';
import CreditNotesList from './CreditNotesList';
import FeesList from './FeesList';
import { ExpenseType } from '../ExpensesConsts';
import { openOverlay } from '../../../UIActions';
import I18n from '../../../locales/I18n';



const Expenses = ({ type }) => {

	const propsFromStore = useSelector( selectData );
	const { period } = propsFromStore;
	const [ _view, setView ] = useState('all');
	const dispatch = useDispatch();


	const renderList = () => {
		switch(type) {
			case ExpenseType.FEE:
				return <FeesList view={ _view } period={ period } />;

			case ExpenseType.CREDIT_NOTE:
				return <CreditNotesList period={ period } />;

			case ExpenseType.INVOICE:
			default:
				return <InvoicesList view={ _view } period={ period } />;
		}
	}


	const renderCreateButton = () => {

		const handleClick = event => {
			event.preventDefault();
			const title = event.currentTarget.getAttribute('title');
			const module = <EditExpense expense={ { type } } />;
			dispatch(openOverlay( title, module ));
		};

		const _getTitle = () => {
			if( type === ExpenseType.FEE ) {
				return I18n.t('Add fee');
			}

			if( type === ExpenseType.CREDIT_NOTE ) {
				return I18n.t('Add credit note');
			}

			return I18n.t('Add expense');
		}

		return (
			<FabButton
				onClick={ handleClick }
				title={ _getTitle() }
			/>
		)
	}



	const renderFilters = () => {
		if( type === ExpenseType.CREDIT_NOTE ) {
			return '';
		}

		const handleToggleView = () => {
			setView( _view === 'open' ? 'all' : 'open' );
		}

		return  (
			<ul className="actions-filters">
				<li>
					<Toggle
						name="show-unpaid"
						labelRight={ I18n.t('Unpaid only') }
						checked={ _view === 'open' }
						onToggle={ handleToggleView }
					/>
				</li>
			</ul>
		);
	}



	const getTitle = () => {
		switch(type) {
			case ExpenseType.INVOICE:
			default:
				return I18n.t('Expenses');

			case ExpenseType.CREDIT_NOTE:
				return I18n.t('Credit notes');

			case ExpenseType.FEE:
				return I18n.t('Fees');
		}
	}

	return (
		<main id="main">
			<SectionHeader type="outcomes" title={ getTitle() } />

			<div className="action-bar">
				{ renderFilters() }
				<ExpensesTotalAmount type={ type } view={ _view } period={ period } />
			</div>

			<div id="data" className="nano">
				<div className="nano-content">
					{ renderList() }
				</div>
			</div>

			{ renderCreateButton() }
		</main>
	);
}


Expenses.propTypes = {
	type: PropTypes.string.isRequired,
};


const selectData = (store) => {
	const activePeriod = store.data.activePeriod.toString();
	const year = activePeriod.slice(0, 4);
	const periodLimits = activePeriod.indexOf('|') > -1 ? activePeriod.split('|') : [`${year}-01-01`, `${year}-12-31`];
	return { period: periodLimits }
}

export default Expenses;
