import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Currency from '../../../components/common/Currency';
import getVisibleJobs from '../filters/VisibleJobs';
import { JobType, JobStatus } from '../JobsConsts';
import I18n from '../../../locales/I18n';

class JobsTotalAmount extends React.Component {

	render() {
		return (
			<React.Fragment>
				<div className="label">{ I18n.t('Total') }:</div>
				<div className="amount taxexcl">
					<Currency amount={this.props.amount} />
				</div>
			</React.Fragment>
		)
	};
};

JobsTotalAmount.propTypes = {
	type: PropTypes.string.isRequired,
	amount: PropTypes.number.isRequired,
};


const mapStateToProps = (state, ownProps) => {
	const { data } = state;
	const { isFetching, job_sessions } = data || { isFetching: true, job_sessions: [] };
	const jobs = getVisibleJobs(data, ownProps.type, null, data.searchTerms);

	let amount = 0;

	if(! isFetching ) {
		Object(jobs).forEach(job => {
			if(! job.invoiceable )
				return;

			if( job.type === JobType.HOURLY && job.status === JobStatus.OPEN ) {
				const sessions = job_sessions.filter(s => parseInt(s.job_id, 10) === parseInt(job.id, 10) && s.completed === true );
				let timeAmount = 0;
				sessions.forEach(s => { timeAmount += parseFloat(s.time_amount); });
				amount += parseFloat(timeAmount * job.fee);
			}
			else
				amount += parseFloat(job.amount);
		});
	}

	return {
		amount
	}
}

export default connect(mapStateToProps)(JobsTotalAmount)
