import 'babel-polyfill';
import './assets/css/expresso.css';
//import "react-toggle-component/styles.css";
import React from 'react';
import ReactDOM from 'react-dom';
import Expresso from './components/Expresso';


const touchCssClass = 'ontouchstart' in document.documentElement ? 'is-touch' : 'no-touch';
document.querySelector('head').classList.add(touchCssClass);

/* Service Worker * /
/*
navigator.serviceWorker.register('data-sw.js', {
  scope: '.'
}).then(function(registration) {
  console.log('The service worker has been registered ', registration);
});
*/

ReactDOM.render(
  <Expresso />,
  document.getElementById('root')
);
