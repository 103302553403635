import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import SectionHeader from '../../../components/common/SectionHeader';
import FabButton from '../../../components/common/FabButton';
import Glyphicon from '../../../components/common/Glyphicon';
import JobsTotalAmount from './JobsTotalAmount';
import JobsList from './JobsList';
import EditJob from './EditJob';

import { UserRole, userRoleIs } from '../../users/UsersConsts';
import { openOverlay } from '../../../UIActions';
import { JobStatus, JobType } from '../JobsConsts';

import I18n from '../../../locales/I18n';



const Jobs = () => {

	const dispatch = useDispatch();
	const storeProps = useSelector( selectData );
	const { user } = storeProps;

	const defaultType = userRoleIs(user, [UserRole.ACCOUNTANT]) ? JobStatus.CLOSED : JobStatus.OPEN;
	const [type, setType] = useState(defaultType);


	const _showComplete = event => {
		event.preventDefault();
		setType(JobStatus.CLOSED);
	};

	const _showOpen = event => {
		event.preventDefault();
		setType(JobStatus.OPEN);
	};

	const _showRecurrent = event => {
		event.preventDefault();
		setType(JobType.RECURRENT);
	};

	const _showFlatRate = event => {
		event.preventDefault();
		setType(JobType.FLAT_RATE);
	};




	const renderCreateButton = () => {
		if(! userRoleIs(user, [UserRole.MANAGER, UserRole.ACCOUNT_MANAGER])) {
			return null;
		}

		const handleClick = (e) => {
			e.preventDefault();

			const title = I18n.t('Create job');
			const module = <EditJob job={ {} } />;
			dispatch( openOverlay( title, module ) );
		}

		return (
			<FabButton
				onClick={ handleClick }
				title={ I18n.t('Add new') }
			/>
		);
	}




	const filters = [];
	if( userRoleIs(user, [UserRole.MANAGER, UserRole.ACCOUNT_MANAGER, UserRole.EMPLOYEE]) ) {
		filters.push(
			<li key="filter-jobs-open" className={ type === JobStatus.OPEN ? 'active' : ''  }>
				<Glyphicon
					icon="play"
					tagName="a"
					className="circle-icon"
					title={ I18n.t('View open') }
					onClick={ _showOpen }
				/>
			</li>
		);
		filters.push(
			<li key="filter-jobs-flat-rate" className={ type === JobType.FLAT_RATE ? 'active' : ''  }>
				<Glyphicon
					icon="book"
					tagName="a"
					className="circle-icon"
					title={ I18n.t('Flat rate') }
					onClick={ _showFlatRate}
				/>
			</li>
		);
		filters.push(
			<li key="filter-jobs-closed" className={ type === JobStatus.CLOSED ? 'active' : ''  }>
				<Glyphicon
					icon="ok"
					tagName="a"
					className="circle-icon"
					title={ I18n.t('View complete') }
					onClick={ _showComplete}
				/>
			</li>
		);
	}

	if( userRoleIs(user, [UserRole.MANAGER]) ) {
		filters.push(
			<li key="filter-jobs-recurrents" className={ type === JobType.RECURRENT ? 'active' : ''  }>
				<Glyphicon
					icon="repeat"
					tagName="a"
					className="circle-icon"
					title={ I18n.t('View recurrents') }
					onClick={ _showRecurrent}
				/>
			</li>
		);
	}




	const jobsTotalAmount = userRoleIs(user, [UserRole.MANAGER, UserRole.ACCOUNTANT]) && type !== JobType.FLAT_RATE ?
		<JobsTotalAmount type={ type } /> :
		null;

	return (
		<main id="main">
			<SectionHeader type="incomes" title={ I18n.t('Jobs') } />

			<div className="action-bar">
				<ul className="actions-filters">{ filters }</ul>
				<div className="section-total-amount">{ jobsTotalAmount }</div>
			</div>

			<div id="data" className="nano">
				<div className="nano-content">
					<JobsList type={ type } />
				</div>
			</div>

			{ renderCreateButton() }
		</main>
	)

}


const selectData = (store) => {
	const { user } = store.data;
	return { user }
}


export default Jobs;
