import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DataList from '../../../components/containers/DataList';
import getVisibleClients from '../filters/VisibleClients';
import ClientButton from './ClientButton';
import ProviderButton from './ProviderButton';

const ClientsList = ({ type, showOnlyActive }) => {

	const propsFromStore = useSelector((store) => selectData(store, type, showOnlyActive));
	const { clients } = propsFromStore;

    const _renderItem = (item) => {
        if( type === 'providers' ) {
            return <ProviderButton provider={ item } />
        }
        return <ClientButton client={ item } />
    }

    return (
        <aside className="data-sidebar" id="clients-list">
            <div className="data-list-holder nano">
                <div className="nano-content">

                    <nav id="clients-nav" className="data-list-nav">
                        <DataList
                            className="data-list-menu"
                            data={ clients }
                            keyExtractor={ (item) => item.id }
                            renderItem={ ({ item }) => _renderItem(item) }
                        />
                    </nav>
                </div>
            </div>
        </aside>
    );
};


ClientsList.propTypes = {
	type: PropTypes.string.isRequired,
};


const selectData = (store, type, showOnlyActive) => {
	const clientsTypeKey = type === 'providers' ? 'is_provider' : 'is_client';
	return {
		clients: getVisibleClients(store.data, clientsTypeKey, showOnlyActive),
	}
}

export default ClientsList;
