import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import DateTime from '../../../components/common/DateTime';
import Currency from '../../../components/common/Currency';
import ItemOptions from '../../../components/common/ItemOptions';
import I18n from '../../../locales/I18n';

import EditExpense from './EditExpense';
import { openOverlay, openDocumentViewer } from '../../../UIActions';
import { doSaveExpense, doDeleteExpense } from '../ExpensesActions';


class Fee extends React.Component {


	editForm = event => {
		event.preventDefault();
		const invoice = { id: this.props.fee.id };
		this.props.getForm(I18n.t('Edit fee'), invoice);
	}


	viewDocument = event => {
		event.preventDefault();

		const fee = this.props.fee;
		const options = [
			{
				label: I18n.t('Download'),
				url: fee.download_url
			}
		];

		this.props.viewDocument(fee.view_url, options);
	}


	markAsPaid = event => {
		event.preventDefault();

		const invoice = this.props.fee;
		if( invoice.status === 'CLOSED' )
			return null;

		const expenseForm = new FormData();
		expenseForm.append('id', invoice.id);
		expenseForm.append('action', 'updateStatus');
		expenseForm.append('status', 'CLOSED');
		this.props.onStatusChange(expenseForm);
	}


	doDelete = event => {
		event.preventDefault();
		this.props.onDelete(this.props.fee)
	}


	doDuplicate = () => {
		const invoice = Object.assign({}, this.props.fee, { id: '', date_memo: null, attached_file: '', status: 'OPEN' });
		this.props.getForm(I18n.t('Add fee'), invoice);
	}


	getItemOptions = () => {
		const { fee } = this.props;

		const options = [];

		if(fee.view_url) {
			options.push({
				label: I18n.t('View'),
				action: this.viewDocument,
				icon: 'open'
			});
		}


		options.push({
			label: I18n.t('Duplicate'),
			action: this.doDuplicate,
			icon: 'duplicate'
		});

		if( fee.status !== 'CLOSED' ) {
			options.push({
				label: I18n.t('Edit'),
				action: this.editForm,
				icon: 'edit'
			});
		}
		else if(! fee.attached_file) {
			options.push({
				label: I18n.t('Add file'),
				action: this.editForm,
				icon: 'open'
			});
		}

		if( fee.status !== 'CLOSED' ) {
			options.push({
				label: I18n.t('Manually mark as paid'),
				action: this.markAsPaid,
				icon: 'flash'
			});

			options.push({
				label: I18n.t('Delete'),
				action: this.doDelete,
				icon: 'trash'
			});
		}

		return options;
	}


	render() {
		const { fee } = this.props;

		const classNames = ['item', 'fee', `fee-${fee.id}`];
		if(fee.status === 'OPEN')
			classNames.push('open');

		if( fee.is_late )
			classNames.push('late');

		return (
			<li className="data-list-item">
				<div className={ classNames.join(' ') } id={`expense-${fee.id}`} data-id={fee.id}>
					<div className="item-main-col">
						<div className="item-title">
							<button className="link-like" onClick={ this.viewDocument }>{fee.label}</button>
						</div>
					</div>

					<div className="item-col-date">
						<DateTime date={fee.date_memo} format="dd mmm yyyy" />
					</div>

					<div className="item-col-amount col-amount-taxexcl">
						<div className="amount">
							<Currency amount={fee.amount} />
						</div>
					</div>

					<div className="item-col-amount col-amount-taxincl">
						<div className="amount">
							<Currency amount={fee.amount_with_taxes} />
						</div>
					</div>

					<ItemOptions options={ this.getItemOptions() } />
				</div>
			</li>
		)
	}
};

Fee.propTypes = {
	fee: PropTypes.object.isRequired,
	provider: PropTypes.object.isRequired,
	onStatusChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	getForm: PropTypes.func.isRequired,
	viewDocument: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
	const { clients, searchTerms } = state.data || { clients: [], searchTerms: false };
	const provider = clients.find(c => parseInt(c.id, 10) === parseInt(ownProps.fee.client_id, 10)) || {}
	return { provider, searchTerms }
}

const mapDispatchToProps = (dispatch) => {
	return {
		onStatusChange: (expense) => {
			dispatch(doSaveExpense(expense))
		},

		onDelete: (expense) => {
			dispatch(doDeleteExpense(expense))
		},

		getForm: (title, expense) => {
			const module = <EditExpense expense={expense} />;
			dispatch(openOverlay(title, module ));
		},

		viewDocument: (url, options) => {
			dispatch(openDocumentViewer(url, options));
		}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Fee);
