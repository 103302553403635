import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ClientSelect from '../../../components/forms/ClientSelect';
import AddClientButton from '../../../components/forms/AddClientButton';
import SelectBox from '../../../components/forms/SelectBox';
import TaxRateSelect from '../../../components/forms/TaxRateSelect';
import DateSelect from '../../../components/forms/DateSelect';
import I18n from '../../../locales/I18n';
import { closeOverlay, removeOverlayBodyClass } from '../../../UIActions';
import { doSaveJob } from '../JobsActions';
import { JobStatus, JobType, /*JobStatusOptions, */JobTypeOptions, JobRecurrenceOptions } from '../JobsConsts';
import { UserRole, userRoleIs } from '../../users/UsersConsts';
import { JOB_SAVED } from '../../../AppEvents';


class EditJob extends React.Component {

	/*
	 * When modifiyng the item, just update local state.
	 * The global state is only updated when saving data ***
	 */
	constructor(props) {
		super(props);
		this.jobDefaults = this.jobDefaults.bind(this);
		this.state = { job: this.jobDefaults(props.job) };
	}


	componentDidUpdate() {
        const { lastAction } = this.props;
		// Don't remember Why this line below, but this is this line which causes the fees not working correctly ?
		//this.setState({ job: this.jobDefaults(props.job) });

		if( lastAction.type === JOB_SAVED && lastAction.success ) {
			removeOverlayBodyClass();

			const timer = setTimeout(() => {
				this.props.doClose();
				clearTimeout(timer);
			}, 500);
		}
	}



	jobDefaults = j => {
		let job = j ? j : { quantity: 1, status: JobStatus.OPEN, type: JobTypeOptions[0].value };

		if( job.type === JobType.HOURLY && job.status === JobStatus.OPEN )
			job.quantity = this.props.sessionsTimeAmount;

		if(! job.quantity)
			job.quantity = 0;

		if(! job.status)
			job.status = JobStatus.OPEN;

		if(! job.type)
			job.type = JobTypeOptions[0].value;

		if( job.client_id && ! job.fee )
			job.fee = this.clientDefaultHourlyFee(job.client_id);

		if( job.client_id && ! job.tax_rate_id )
			job.tax_rate_id = this.clientDefaultTaxRate(job.client_id);

		if( ! job.recurrence)
			job.recurrence = 0;

		if(! job.fee )
			job.fee = this.props.defaultFee;

		return job;
	}


	/*
 	 * When user saves job ...
	 */
	save = e => {
		e.preventDefault();

		if( this.props.isSaving )
			return;

		const job = new FormData(document.getElementById('job-edit'));
		job.append('status', this.state.job.status || JobStatus.OPEN);
		if(! job.get('client_id'))
			job.append('client_id', this.state.job.client_id);

		if(! job.get('is_recurrent'))
			job.is_recurrent = this.state.job.is_recurrent;

		this.props.onSave(job);
	}


	changeClient = event => {
		let updatedJob = {}
		updatedJob.client_id = event.value;

		if( this.state.job.type === JobType.HOURLY ) {
			const fee = this.clientDefaultHourlyFee(updatedJob.client_id);
			const feeField = document.getElementById('fee');
			if( feeField && parseFloat(fee) !== parseFloat(feeField.value) )
				updatedJob.fee = fee;
		}

		const taxRateId = this.clientDefaultTaxRate(updatedJob.client_id);
		updatedJob.tax_rate_id = taxRateId;

		const job = Object.assign({}, this.state.job, updatedJob);
		this.setState({ job });
	};


	changeType = event => {
		const job = Object.assign({}, this.state.job, { type: event.target.value });
		this.setState({ job });
	};

	changeTaxRate = event => {
		const job = Object.assign({}, this.state.job, { tax_rate_id: parseInt(event.target.value, 10) });
		this.setState({ job });
	};


	changeFee = event => {
		event.preventDefault();
		const job = Object.assign({}, this.state.job, { fee: event.target.value });
		this.setState({ job });
	};


	onRecurrenceChange = event => {
		event.preventDefault();
		const isRecurrent = parseInt(event.target.value, 10) !== 0;
		const job = Object.assign({}, this.state.job, { is_recurrent: isRecurrent, recurrence: event.target.value });
		this.setState({ job });
	}


	clientDefaultHourlyFee = client_id => {
		const client = this.props.clients.find(c => parseInt(c.id, 10) === parseInt(client_id, 10)) || {};
		return client.hourly_fee ? client.hourly_fee : this.props.defaultFee;
	}

	clientDefaultTaxRate = client_id => {
		const client = this.props.clients.find(c => parseInt(c.id, 10) === parseInt(client_id, 10)) || {};
		return client.intracom_vat ? 4 : 2;
	}


	renderClient = () => {
		const job = this.state.job;

		const isNew = ! job.hasOwnProperty('id');
		const isManager = userRoleIs(this.props.user, [UserRole.MANAGER]);
		const isAccountManager = userRoleIs(this.props.user, [UserRole.ACCOUNT_MANAGER]);

		if(! job.parent_job_id ) {
			if( isManager || (isAccountManager && isNew) ) {
				return (
					<div className="input select">
						<label htmlFor="client-id">{ I18n.t('Client') }</label>
						<AddClientButton type="client" />
						<ClientSelect type="client" showSelf={ true } id="client-id" name="client_id" value={job.client_id} onChange={this.changeClient} />
					</div>
				);
			}

			if( isNew )
				return;
		}


		const client = this.props.clients.find(c => parseInt(c.id, 10) === parseInt(job.client_id, 10) );
		if(! client)
			return;

		return (
			<div className="input select">
				<span className="label-like">{ I18n.t('Client') }</span>
				<span className="input-like">{ client.short_name }</span>
			</div>
		);
	}


	renderParentJob = () => {
		if(! this.props.parentJob )
			return;

		return (
			<div className="input select" id="parent-job-selector">
				<span className="label-like">{ I18n.t('Belongs to') }</span>
				<span className="input-like">{ this.props.parentJob.label }</span>
				<input type="hidden" name="parent_job_id" value={ this.props.job.parent_job_id } readOnly />
			</div>
		);
	};




	renderDateMemo = () => {
		let job = this.state.job;
		if(! job.date_memo ) {
			if(! job.is_recurrent )
				return null;

			const today = new Date().getFullYear();
			let month = today.getMonth();
			if( month < 10 )
				month = '0'+ month;

			let date = today.getMonth();
			if( date < 10 )
				date = '0'+ date;

			job.date_memo = [ today.getFullYear() , month, date ].join('-');
		}

		return (
			<div className="input date required">
				<label>{ I18n.t('Date') }</label>
				<DateSelect name="date_memo" defaultValue={job.date_memo} />
			</div>
		)
	}


	renderPriceFields = () => {
		const job = this.state.job;

        let quantityField = <input type="hidden" name="quantity" value={ job.quantity || 1 } />;

        if( job.type !== JobType.FIX && job.type !== JobType.FLAT_RATE  ) {
            const quantityLabel = (this.props.parentJob && this.props.parentJob.type === JobType.FLAT_RATE) || job.type === JobType.HOURLY ? I18n.t('Hours') : I18n.t('Quantity');
    		quantityField = (
    			<div className="input number">
    				<label htmlFor="quantity">{ quantityLabel }</label>
    				<input type="number" name="quantity" step=".5" id="quantity" defaultValue={ job.quantity || 1 } required />
    			</div>
    		);
        }

		if( this.props.parentJob && this.props.parentJob.type === JobType.FLAT_RATE )
			return quantityField;


		if( userRoleIs(this.props.user, [UserRole.MANAGER, UserRole.ACCOUNTANT]) ) {
			return (
				<div className="fields-group">
					<div className="input number">
						<label htmlFor="fee">{ I18n.t('Fee') }</label>
						<input type="number" name="fee" step="any" id="fee" value={ job.fee } onChange={ this.changeFee } required />
					</div>

					{ quantityField }
				</div>
			);
		}

		return quantityField;
	}


	renderTypeAndTaxRateField = () => {
		if( this.props.parentJob && this.props.parentJob.type === JobType.FLAT_RATE )
			return;

		return (
			<div className="fields-group">
				<div className="input select">
					<label htmlFor="type">{ I18n.t('Type') }</label>
					<SelectBox name="type" className="small" id="type" value={this.state.job.type} options={ JobTypeOptions } onChange={ this.changeType } />
				</div>

				<div className="input select">
					<label htmlFor="tax-rate-id">{ I18n.t('VAT rate') }</label>
					<TaxRateSelect name="tax_rate_id" id="tax-rate-id" value={this.state.job.tax_rate_id || 2} />
				</div>
			</div>
		);
	}


	renderRecurrenceField = () => {
		if( this.props.parentJob && this.props.parentJob.type === JobType.FLAT_RATE )
			return;

		return (
			<div className="input select" id="recurrence-selector" style={ {display:'block'} }>
				<label htmlFor="recurrence">{ I18n.t('Recurrence') }</label>
				<SelectBox name="recurrence" className="small" id="recurrence" value={this.state.job.recurrence || 0 } options={ JobRecurrenceOptions } onChange={ this.onRecurrenceChange }   />
			</div>
		);
	}


	renderSubmit = () => {
		if( this.props.isSaving )
			return <button type="submit" className="is-saving">{ I18n.t('Saving...') }</button>

		return <button type="submit">{ I18n.t('Save') }</button>
	}




	render() {
		const job = this.state.job;

		return (
			<div className="jobs form">
				<form method="post" acceptCharset="utf-8" id="job-edit" onSubmit={this.save}>
					<fieldset>
						{ this.renderClient() }
						{ this.renderParentJob() }
						{ this.renderDateMemo() }

						<div className="input text">
							<label htmlFor="label">{ I18n.t('Label') }</label>
							<input type="text" name="label" maxLength="140" id="label" defaultValue={job.label} required />
						</div>

						{ this.renderPriceFields() }
						{ this.renderTypeAndTaxRateField() }

						<div className="input text">
							<label htmlFor="memo">{ I18n.t('Memo') }</label>
							<textarea name="memo" maxLength="75" id="memo" defaultValue={job.memo || ''} />
						</div>

						{ this.renderRecurrenceField() }
					</fieldset>


					<div className="form-controls">
						<input type="hidden" name="id" value={job.id || ''} readOnly />
						{ this.renderSubmit() }
					</div>
				</form>
			</div>
		);
	}
};

EditJob.propTypes = {
	clients: PropTypes.arrayOf(PropTypes.object).isRequired,
	job: PropTypes.object,
	lastAction: PropTypes.object.isRequired,
	defaultFee: PropTypes.number,
	sessionsTimeAmount: PropTypes.number,
	isSaving: PropTypes.bool.isRequired
};



const mapStateToProps = (state, ownProps) => {
	const { clients, user, lastAction, isSaving, job_sessions, jobs } = state.data || { clients: [], user: {}, lastAction: {}, isSaving: false, job_sessions: [], jobs: [] };
	const company = clients.find(c => c.id === user.company_id && c.is_self);
	const defaultFee = company ? parseFloat(company.hourly_fee) : 50;
	const sessions = ownProps.job && ownProps.job.type === JobType.HOURLY ? job_sessions.filter(s => parseInt(s.job_id, 10) === parseInt(ownProps.job.id, 10) && s.completed === true ) : [];
	let sessionsTimeAmount = 0;
	sessions.forEach( s => { sessionsTimeAmount += parseFloat(s.time_amount); });



	const parentJob =
			ownProps.job && ownProps.job.parent_job_id ?
			jobs.find(j => parseInt(ownProps.job.parent_job_id, 10) === parseInt(j.id, 10)) :
			null;

	return {
		user,
		clients,
		defaultFee,
		sessionsTimeAmount,
		lastAction,
		isSaving,
		parentJob
	};
}


const mapDispatchToProps = (dispatch) => {
	return {
		onSave: (job) => {
			dispatch(doSaveJob(job));
		},

		doClose: () => {
			dispatch(closeOverlay());
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditJob);
