import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SelectBox from './SelectBox';


const TaxRateSelect = ({ name, options, id, value = null, onChange = null }) => {
    const defaultTaxRate = 2;
    const [stateValue, setStateValue] = useState( parseInt(value, 10) || defaultTaxRate );

    useEffect(() => {
        setStateValue(parseInt(value, 10));
    }, [value]);

    const _onChange = event => {
        setStateValue( parseInt(event.target.value, 10) );
        onChange && onChange(event);
    }

    return (
        <SelectBox
            name={ name }
            id={ id }
            value={ stateValue }
            options={ options }
            onChange={ _onChange }
            autoComplete="off"
        />
    );
}


TaxRateSelect.propTypes = {
	options: PropTypes.arrayOf(PropTypes.object).isRequired
}

const mapStateToProps = (state, ownProps) => {
	const tax_rates = (state.data.tax_rates || []).filter(r => r.active);

	const options = Object(tax_rates).map(rate => {
		return {
			label: rate.name,
			value: rate.id
		}
	});

	return { options };
}

export default connect(mapStateToProps)(TaxRateSelect);
