const getDomain = () => {
	const _default = 'https://expresso.tix02.be';

	switch(document.domain) {
		case 'localhost':
			// ! cd ~/Work/Sites/expresso.tix02.be
			// ! bin/cake server
			return 'http://localhost:8765';

		//case 'localhost': // fallback
		case 'expresso.tix02.local':
			return 'http://expresso.tix02.local';

		default:
			return _default;
	}
}

const config = {
	debug: false,

	app: {
		domain: 	getDomain(),
		path:		''
	},

	locale: 'fr_FR',

	cookieName: 'expresso_crdt',

	format: {
		currency: {
			symbol : '€',
			decimal : ',',
			thousand: ' ',
			precision : 2,
			format: "%v %s"
		}
	}
};

export default config;
