import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import accounting from 'accounting-js';
import config from '../../config';

class Currency extends React.Component {

	render() {
		const currency = accounting.formatMoney(this.props.amount, this.props.settings);

		return (
			<span className="currency">
				<span className="number" data-amount={this.props.amount}>{currency}</span>
			</span>
		);
	};
};

Currency.propTypes = {
	//amount: PropTypes.isRequired,
	settings: PropTypes.object
}


const mapStateToProps = (state, ownProps) => {
	const { isFetching, companySettings } = state.data || { isFetching: true, companySettings: {} };

	let settings = config.format.currency;
	if( companySettings && companySettings.currency_info.symbol )
		settings.symbol = companySettings.currency_info.symbol

	return {
		settings,
		isFetching
	}
}

export default connect(mapStateToProps)(Currency);
