import escapeStringForRegex from '../../../libs/EscapeStringForRegex';
import { JobStatus, JobType } from '../JobsConsts';

const matchTerms = (terms, job, clients) => {
	if(terms === false)
		return true; // no current search

	const regExp = new RegExp('\\W'+ escapeStringForRegex(terms), 'gi');
	const client = clients.find(c => parseInt(c.id, 10) === parseInt(job.client_id, 10));
	const searchInTerms = [' ', job.label];
	if(client)
		searchInTerms.push(client.name, client.short_name);

	searchInTerms.push(' ');
	return searchInTerms.join(' ').match(regExp) !== null;
}



const getVisibleJobs = (state, filter = null, client = null) => {
	if( state.jobs.length === 0 )
		return [];

	if( filter === null && client === null && state.searchTerms === false )
		return state.jobs;

	let filteredJobs = [];


	if( filter !== null ) {
		switch (filter) {
			case 'CLIENT':
				filteredJobs = state.jobs.filter(r => {
					return (r.status === JobStatus.OPEN || r.status === JobStatus.CLOSED) && r.is_recurrent === false && matchTerms(state.searchTerms, r, state.clients);
				});
				break;

			case JobStatus.OPEN:
			case JobStatus.CLOSED:
				filteredJobs = state.jobs.filter(r => {
					return r.status === filter && r.is_recurrent === false && r.type !== JobType.FLAT_RATE && matchTerms(state.searchTerms, r, state.clients);
				});
				break;

			case JobType.FLAT_RATE:
				filteredJobs = state.jobs.filter(r => {
					return r.status === JobStatus.OPEN && r.type === JobType.FLAT_RATE && r.is_recurrent === false && matchTerms(state.searchTerms, r, state.clients);
				});
				break;

			case 'RECURRENT':
				filteredJobs = state.jobs.filter(r => {
					return r.is_recurrent === true && matchTerms(state.searchTerms, r, state.clients);
				});

				// reverse sorting
				filteredJobs.sort((a, b) => {
					if( a.date_memo > b.date_memo ) return 1;
					if( a.date_memo < b.date_memo ) return -1;
					return 0;
				});
				break;

			default:
				filteredJobs = state.jobs.filter(r =>  matchTerms(state.searchTerms, r, state.clients));
		}
	}

	if( client !== null ) {
		filteredJobs = filteredJobs.filter(r => {
			return parseInt(r.client_id, 10) === client && matchTerms(state.searchTerms, r, state.clients);
		});
	}

	return filteredJobs;
};


export default getVisibleJobs;
