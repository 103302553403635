import { saveBankAccount, deleteBankAccount, makeDefaultBankAccount } from '../../api';

import {
	BANKACCOUNT_DO_SAVE,
	BANKACCOUNT_SAVED,
	BANKACCOUNT_DO_DELETE,
	BANKACCOUNT_DELETED,
	BANKACCOUNT_MAKE_DEFAULT,
	BANKACCOUNT_MADE_DEFAULT
} from '../../AppEvents';


function bankaccount_save(account) {
	return {
		type: BANKACCOUNT_DO_SAVE,
		account
	}
}

function bankaccount_saved(response) {
	const r = {
		type: BANKACCOUNT_SAVED,
		status: parseInt(response.status, 10),
		account: response.account,
		receivedAt: Date.now()
	}

	r.message = r.status === 200 ? 'Account was saved' : response.message;
	return r;
}


function bankaccount_delete(account_id) {
	return {
		type: BANKACCOUNT_DO_DELETE,
		account_id
	}
}


function bankaccount_deleted(response) {
	const r = {
		type: BANKACCOUNT_DELETED,
		status: parseInt(response.status, 10),
		client_id: parseInt(response.client_id, 10),
		accounts: response.accounts,
		receivedAt: Date.now()
	}

	r.message = r.status === 200 ? 'Account was deleted' : response.message;
	return r;
}


function bankaccount_make_default(account) {
	return {
		type: BANKACCOUNT_MAKE_DEFAULT,
		account
	}
}


function bankaccount_made_default(response) {
	return {
		type: BANKACCOUNT_MADE_DEFAULT,
		status: parseInt(response.status, 10),
		client_id: parseInt(response.client_id, 10),
		accounts: response.accounts,
		receivedAt: Date.now()
	}
}



export function doSaveBankAccount(account) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(bankaccount_save(account));
			return saveBankAccount(account).then(json => dispatch(bankaccount_saved(json)));
		});
	}
}


export function doDeleteBankAccount(account_id) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(bankaccount_delete(account_id));
			return deleteBankAccount(account_id).then(json => dispatch(bankaccount_deleted(json)));
		});
	}
}

export function doMakeDefaultAccount(account) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(bankaccount_make_default(account));
			return makeDefaultBankAccount(account).then(json => dispatch(bankaccount_made_default(json)));
		});
	}
}
