import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//import PropTypes from 'prop-types';
import DateSelect from '../../../components/forms/DateSelect';
import SelectBox from '../../../components/forms/SelectBox';
import AsyncSelect from 'react-select/async'
import Glyphicon from '../../../components/common/Glyphicon';
import I18n from '../../../locales/I18n';
import { closeOverlay, removeOverlayBodyClass } from '../../../UIActions';
import { doSaveTransaction } from '../TransactionsActions';
import { TRANSACTION_SAVED } from '../../../AppEvents';
import { searchBankAccount } from '../../../api';
import { getBankAccountLabel } from '../../bank_accounts/BankAccountsHelpers';






const AddTransaction = ({ bank_account_id }) => {

	const dispatch = useDispatch();

	const [ amount, setAmount ] = useState(null);
	const [ currentBankAccountId, setCurrentBankAccountId ] = useState(bank_account_id);
	const [ accountSelectable, setAccountSelectable ] = useState(false);
	const [ thirdPartyAccount, setThirdPartyAccount ] = useState({});

	const selectedProps = useSelector( selectData );
	const { lastAction, settings } = selectedProps;

	useEffect(() => {
		if( lastAction.type === TRANSACTION_SAVED && lastAction.success ) {
			removeOverlayBodyClass();

			const timer = setTimeout(() => {
				dispatch( closeOverlay() );
				clearTimeout(timer);
			}, 500);
		}
	}, [ lastAction ]);


	const _handleSave = (event) => {
		event.preventDefault();

		const { isSaving } = selectedProps;
		if( isSaving ) {
			return;
		}

		if( ! isValidAmount() ) {
			return false;
		}

		const transaction = new FormData(document.getElementById('transaction-add'));
		for(var pair of transaction.entries()) {
		   console.log(pair[0]+ ': '+ pair[1]);
		}
		//return;
		dispatch( doSaveTransaction(transaction) );
	}



	const _handleChangeAccount = (event) => {
		setCurrentBankAccountId(event.target.value),
		setAccountSelectable(false);
	}



	const _handleChangeAmount = (event) => {
		const amountValue = parseFloat(event.target.value);
		if(amountValue && isNaN(amountValue)) {
			alert(I18n.t('Please enter a number'));
			return;
		}

		if( amount !== amountValue ) {
			setAmount(amountValue);
		}
	}


	const isValidAmount = () => {
		return amount && (! isNaN(amount)) && amount !== 0;
	}

	const getBankAccount = (id) => {
		const { bankAccounts } = selectedProps;
		const bankAccount = bankAccounts.find(a => a.id === parseInt(id, 10));
		return bankAccount;
	}


	const getDefaultBankAccount = () => {
		const { bankAccounts } = selectedProps;
		const defaultBankAccount = bankAccounts.find(a => a.is_default);
		return defaultBankAccount || bankAccounts[0];
	}



	const _renderBankAccount = () => {
		const { bankAccounts } = selectedProps;
		const manyAccounts = bankAccounts.length > 1;

		if( accountSelectable && manyAccounts ) {
			const companyBankAccounts = bankAccounts.map( a => {
				return {
					value: a.id,
					label: getBankAccountLabel(a)
				};
			});

			return (
				<div className="input text">
					<label htmlFor="label">{ I18n.t('Bank Account') }</label>
					<SelectBox
						options={ companyBankAccounts }
						value={ currentBankAccountId || 0 }
						onChange={ _handleChangeAccount }
						name="bank_account_id"
					/>
				</div>

			);
		}

		let account;
		if( currentBankAccountId ) {
			account = getBankAccount(currentBankAccountId);
		}

		if(! account ) {
			account = getDefaultBankAccount();
		}

		return (
			<div className="input text">
				<span className="label-like">{ I18n.t('Bank Account') }</span>
				<span className="input-like">{ `${account.account_name} (${account.bank} ${account.formatted_iban})` }</span>
				<input type="hidden" name="bank_account_id" value={ account.id  } />
				{ manyAccounts && <Glyphicon
						icon="refresh"
						tagName="a"
						className="green circle-icon"
						title={ I18n.t('Change') }
						onClick={ () => setAccountSelectable(true) }
					/>}
			</div>
		);
	}



	const _handleThirdPartyIBANChange = (newValue) => {
		const value = newValue.value.split('||');
		setThirdPartyAccount({
			iban: value[1],
			name: value[0],
			bic: value[2],
			manualInput: false,
		});
	}


	const _renderThirdPartyAccount = () => {
		if(! isValidAmount() ) {
			return null;
		}

		if( thirdPartyAccount && (thirdPartyAccount.iban || thirdPartyAccount.manualInput) ) {

			return (
				<fieldset id="transaction-account">
					<legend className="small">{ I18n.t('Third Party') }</legend>
					<div className="input text">
						{ thirdPartyAccount.manualInput === false && thirdPartyAccount.iban ?
							(
								<>
									<span className="label-like">{ I18n.t('IBAN') }</span>
									<span className="input-like">{ thirdPartyAccount.iban }</span>
									<input
										type="hidden"
										name="bankaccount_iban"
										value={ thirdPartyAccount.iban }
									/>
								</>
							) : (
								<>
									<label htmlFor="bankaccount-iban">{ I18n.t('IBAN') }</label>
									<input
										type="text"
										name="bankaccount_iban"
										maxLength="40"
										id="bankaccount-iban"
										value={ thirdPartyAccount.iban || '' }
										onChange={ (event) => setThirdPartyAccount({ ...thirdPartyAccount, iban: event.target.value }) }
										style={{
											display:'inline-block',
											width: 'calc(100% - 40px)',
										}}
									/>
								</>
							)}

						<Glyphicon
							icon="refresh"
							tagName="a"
							className="green circle-icon"
							title={ I18n.t('Change') }
							onClick={ () => setThirdPartyAccount(null) }
						/>
					</div>

					<div className="input text">
						<label htmlFor="bankaccount-name">{ I18n.t('Name') }</label>
						{ thirdPartyAccount.manualInput === false && thirdPartyAccount.name ?
							(
								<>
									<span className="label-like">{ I18n.t('Name') }</span>
									<span className="input-like">{ thirdPartyAccount.name }</span>
									<input
										type="hidden"
										name="bankaccount_name"
										value={ thirdPartyAccount.name }
									/>
								</>
							) : (
								<>
									<label htmlFor="bankaccount-name">{ I18n.t('Name') }</label>
									<input
										type="text"
										name="bankaccount_name"
										maxLength="40"
										id="bankaccount-name"
										value={ thirdPartyAccount.name || '' }
										onChange={ (event) => setThirdPartyAccount({ ...thirdPartyAccount, name: event.target.value }) }
									/>
								</>
							)
						}
					</div>

					<div className="input text">
						{ thirdPartyAccount.manualInput === false && thirdPartyAccount.bic ?
							(
								<>
									<span className="label-like">{ I18n.t('BIC') }</span>
									<span className="input-like">{ thirdPartyAccount.bic }</span>
									<input
										type="hidden"
										name="bankaccount_bic"
										value={ thirdPartyAccount.bic }
									/>
								</>
							) : (
								<>
									<label htmlFor="bankaccount-bic">{ I18n.t('BIC') }</label>
									<input
										type="text"
										name="bankaccount_bic"
										maxLength="40"
										id="bankaccount-bic"
										value={ thirdPartyAccount.bic || '' }
										onChange={ (event) => setThirdPartyAccount({ ...thirdPartyAccount, bic: event.target.value }) }
									/>
								</>
							)
						}
					</div>
				</fieldset>
			);
		}


		const loadOptions = async (inputValue) => {
			return searchBankAccount(inputValue)
				.then(r => {
					return r.accounts.map(a => {
						return {
							value: `${a.account_name}||${a.formatted_iban}||${a.bic}`,
							label: getBankAccountLabel(a),
						}
					});
				});
		}

		return (
			<fieldset id="transaction-account">
				<legend className="small">{ I18n.t('Third Party') }</legend>
				<div className="input select">
					<label>{ I18n.t('IBAN') }</label>
					<AsyncSelect
						cacheOptions
						loadOptions={loadOptions}
						defaultOptions
						onChange={ _handleThirdPartyIBANChange }
						style={{
							container: () => ({
								display:'inline-block',
								width: 'calc(100% - 40px)',
							}),
						}}
					/>

					<Glyphicon
						icon="plus"
						tagName="a"
						className="green circle-icon add-new"
						title={ I18n.t('Add new') }
						onClick={ () => setThirdPartyAccount({ manualInput: true }) }
					/>
				</div>
			</fieldset>
		)
	}


	const _renderSubmit = () => {
		if(! isValidAmount() ) {
			return null;
		}

		const { isSaving } = selectedProps;
		if( isSaving ) {
			return <button type="submit" className="is-saving">{ I18n.t('Saving...') }</button>
		}

		return <button type="submit">{ I18n.t('Save') }</button>
	}


	return (
		<div className="transaction form">
			<form method="post" acceptCharset="utf-8" id="transaction-add" onSubmit={ _handleSave }>
				<fieldset>
					{ _renderBankAccount() }
				</fieldset>

				<fieldset>
					<div className="input number">
						<label htmlFor="amount">{ I18n.t('Amount') }</label>
						<input
							type="number"
							className="small"
							name="amount"
							step="any"
							id="transaction-amount"
							style={{width: '120px'}}
							defaultValue={ amount }
							onBlur={ _handleChangeAmount }
							required
						/>
						{ settings.currency_info.iso }
					</div>

					<div className="input date required">
						<label>{ I18n.t('Date') }</label>
						<DateSelect name="date_memo" />
					</div>

					<div className="input text">
						<label htmlFor="message">{ I18n.t('Message') }</label>
						<input
							type="text"
							name="message"
							maxLength="40"
							id="message"
						/>
					</div>

					<div className="input text">
						<label htmlFor="vcs">{ I18n.t('VCS') }</label>
						<input
							type="text"
							name="structured_message"
							maxLength="14"
							id="vcs"
						/>
					</div>

				</fieldset>

				{ _renderThirdPartyAccount() }

				<div className="form-controls">
					{ _renderSubmit() }
				</div>
			</form>
		</div>
	);
}






const selectData = (store) => {

	const {
		company,
		transaction_categories,
		expenses,
		invoices,
		clients,
		lastAction,
		companySettings,
		isSaving,
		referenced_transactions
	} = store.data || { 
		company: {},
		clients: [],
		expenses: [],
		invoice: [],
		transaction_categories: {},
		lastAction: {},
		companySettings: {},
		isSaving: false,
		referenced_transactions: []
	};

	const bankAccounts = company.bank_accounts || [];
	return {
		bankAccounts,
		categories: transaction_categories,
		referenced_transactions,
		expenses,
		invoices,
		clients,
		settings: companySettings,
		lastAction,
		isSaving
	};
}


/*
AddTransaction.propTypes = {
	bankAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
	transaction: PropTypes.object.isRequired,
	categories: PropTypes.object.isRequired,
	expenses: PropTypes.arrayOf(PropTypes.object).isRequired,
	invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
	clients: PropTypes.arrayOf(PropTypes.object).isRequired,
	settings: PropTypes.object.isRequired,
	lastAction: PropTypes.object.isRequired,
	isSaving: PropTypes.bool.isRequired
};
*/

export default AddTransaction;
