import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

import DateTime from '../../../components/common/DateTime';
import Currency from '../../../components/common/Currency';
import ItemOptions from '../../../components/common/ItemOptions';
import EditTransaction from './EditTransaction';

import I18n from '../../../locales/I18n';
import { doSaveTransaction, doDeleteTransaction } from '../TransactionsActions';
import { openOverlay, openDocumentViewer } from '../../../UIActions';



class Transaction extends React.Component {

	editForm = (e) => {
		e.preventDefault();
		this.props.getForm({ id: this.props.transaction.id });
	}


	onSave = event => {
		event.preventDefault();
		this.props.onSave({ id: this.props.transaction.id });
	};

	onDelete = event => {
		event.preventDefault();
		this.props.onDelete({ id: this.props.transaction.id });
	};


	viewDocument = event => {
		event.preventDefault();

		const { transaction, doc } = this.props;
		const documentUrl = transaction.type === 'IN' ? doc.html : doc.view_url;
		const options = [];

		if( transaction.type === 'IN' ) {
			options.push({
				label: I18n.t('Download'),
				url: doc.pdf
			});

			options.push({
				label: I18n.t('Print'),
				url: doc.print_version
			});
		}
		else {
			options.push({
				label: I18n.t('Download'),
				url: doc.download_url
			});
		}

		this.props.viewDocument(documentUrl, options);
	}





	getItemOptions = () => {
		const { transaction, doc } = this.props;

		const options = [];
		/*
			{
				label: I18n.t('Edit'),
				action: this.editForm,
				icon: 'edit'
			}
		];
		*/

		if(! transaction.validated ) {
			options.push({
				label: I18n.t('Validate'),
				action: this.onSave,
				icon: 'flash'
			})

			options.push({
				label: I18n.t('Delete'),
				action: this.onDelete,
				icon: 'trash'
			});
		}
		else if(doc) {
			if( transaction.type === 'IN' || (transaction.type === 'OUT' && doc.view_url) )
				options.push({
					label: I18n.t('View related document'),
					action: this.viewDocument,
					icon: 'open'
				});

		}

		return options;
	}




	render() {
		const { transaction, client } = this.props;

		let clientLink;

		if( client ) {
			const baseUrl = transaction.type === 'OUT' ? '/outcomes/providers' : '/incomes/clients';
			clientLink = <Link to={`${baseUrl}/view/${client.id}`}>{client.name}</Link>
		}
		else {
			clientLink = transaction.bankaccount_name;
		}

		const classNames = [
			'item',
			'transaction',
			`transaction-${transaction.id}`,
			`transaction-${transaction.type.toLowerCase()}`,
		];

		if( transaction.type === 'IN' ) {
			if( transaction.category === 'INCOME' ) {
				classNames.push('income');
			}
		}
		else {
			classNames.push(
				( transaction.category === 'TAX' || transaction.category === 'SALARY' ) ?
				transaction.category.toLowerCase() :
				'outcome'
			);
		}

		return (
			<li className="data-list-item">
				<div className={ classNames.join(' ') }>
					<div className="item-main-col">
						<div className="item-client">{ clientLink }</div>
						<div className="item-title">{transaction.message}</div>
					</div>

					<div className="item-col-date">
						<DateTime date={transaction.date_memo} format="dd mmm yyyy" />
					</div>

					<div className="item-col-category">{transaction.category}</div>

					<div className="item-col-amount col-amount-taxincl">
						<div className="amount">
							<Currency amount={transaction.amount} />
						</div>
					</div>

					<ItemOptions options={ this.getItemOptions() } />
				</div>
			</li>
		)
	}
};

Transaction.propTypes = {
	transaction: PropTypes.object.isRequired,
	client: PropTypes.object,
	doc: PropTypes.object,
	onSave: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired
};


const mapStateToProps = (state, ownProps) => {
	const { clients, searchTerms } = state.data || { clients: [], searchTerms: false };

	let doc;
	const client = clients.find(c => parseInt(c.id, 10) === parseInt(ownProps.transaction.client_id, 10));

	if(ownProps.transaction.document_id) {
		const documents = ownProps.transaction.type === 'IN' ? state.data.invoices : state.data.expenses;
		doc = documents.find(i => parseInt(i.id, 10) === parseInt(ownProps.transaction.document_id, 10));
	}

	return { client, doc, searchTerms }
}


const mapDispatchToProps = (dispatch) => {
	return {
		getForm: (transaction) => {
			const title = transaction.id ? I18n.t('Edit transaction') : I18n.t('Add transaction');
			const module = <EditTransaction transaction={transaction} />;
			dispatch(openOverlay( title, module ));
		},

		onSave: (transaction) => {
			dispatch(doSaveTransaction(transaction))
		},

		onDelete: (transaction) => {
			dispatch(doDeleteTransaction(transaction));
		},

		viewDocument: (url, options) => {
			dispatch(openDocumentViewer(url, options));
		}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Transaction);
