import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import Toggle from '../../../components/forms/Toggle';
import FabButton from '../../../components/common/FabButton';
import SectionHeader from '../../../components/common/SectionHeader';
import Client from './Client';
import Provider from './Provider';
import ClientsList from './ClientsList';
import EditClient from './EditClient';

import I18n from '../../../locales/I18n';
import { openOverlay } from '../../../UIActions';



const Clients = ({ match, type }) => {

	const [status, setStatus] = useState('ACTIVE');
	const dispatch = useDispatch();


	const handleAddClient = event => {
		event.preventDefault();

		const client = {
			is_client: false,
			is_provider: false
		};

		if( type === 'providers')
			client.is_provider = true;
		else
			client.is_client = true;

		const title = I18n.t('Add client');
		const module = <EditClient client={ client } />;
		dispatch(openOverlay( title, module ));
	}


	const handleToggleActiveMode = () => {
		setStatus(status === 'ACTIVE' ? 'ALL' : 'ACTIVE');
	}

	const renderHeader = () => {
		if( type === 'providers' ) {
			return <SectionHeader title={ I18n.t('Providers') } type="outcomes" />
		}
		return <SectionHeader title={ I18n.t('Clients') } type="incomes" />
	}

	return (
		<main id="main">
			{ renderHeader() }

			<div className="action-bar">
				<ul className="actions-filters">
					<li>
						<Toggle
							name="show-active"
							labelRight={ I18n.t('Active only') }
							checked={ status === 'ACTIVE' }
							onToggle={ handleToggleActiveMode }
						/>
					</li>
				</ul>

				<div>&nbsp;</div>
			</div>

			<div id="data" className="main-with-sidebar clients">
				<ClientsList type={ type } showOnlyActive={ status === 'ACTIVE' } />

				<div id="client" className="client-holder data-main nano">
					<div className="nano-content">
						<Route
							path={`${match.url}/${type}/view/:id`}
							component={ type === 'clients' ? Client : Provider }
						/>
					</div>
				</div>
			</div>

			<FabButton
				onClick={ handleAddClient }
				title={ I18n.t('Add new') }
			/>
		</main>
	)
};

Clients.propTypes = {
	type: PropTypes.string.isRequired
};

export default Clients;
