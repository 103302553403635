import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Header from './Header';
import Clients from '../../modules/clients/components/Clients';
import Expenses from '../../modules/expenses/components/Expenses';
import { UserRole, userRoleIs } from '../../modules/users/UsersConsts';

class OutcomesPane extends React.Component {

	renderProviders = () => {
		const { match } = this.props;
		return <Clients type="providers" match={match} />;
	}

	renderInvoices = () => {
		const { match } = this.props;
		return <Expenses type="INVOICE" match={match} />;
	}

	renderFees = () => {
		const { match } = this.props;
		return <Expenses type="FEE" match={match} />;
	}

	renderCreditNotes = () => {
		const { match } = this.props;
		return <Expenses type="CREDIT_NOTE" match={match} />;
	}


	render() {
		const { match, user } = this.props;
		if(! userRoleIs(user, [UserRole.MANAGER, UserRole.ACCOUNTANT]))
			return null;

		return (
			<Fragment>
				<Header />
				<Route exact path={`${match.url}`} component={this.renderInvoices} />
				<Route path={`${match.url}/providers`} component={this.renderProviders} />
				<Route path={`${match.url}/invoices`} component={this.renderInvoices} />
				<Route path={`${match.url}/fees`} component={this.renderFees} />
				<Route path={`${match.url}/credit-notes`} component={this.renderCreditNotes} />
			</Fragment>
		)
	}
}


OutcomesPane.propTypes = {
	user: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
	const { user } = state.data;
	return { user }
}

const mapDispatchToProps = (dispatch) => {
	return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OutcomesPane);
