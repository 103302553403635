import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from '../../../locales/I18n';
import SelectBox from '../../../components/forms/SelectBox';
import UsersSelect from '../../../components/forms/UsersSelect';
import { closeOverlay, removeOverlayBodyClass } from '../../../UIActions';
import { doSaveJobUsers, doSetJobOwner } from '../JobsActions';
import { UserRole, userRoleIs } from '../../users/UsersConsts';
import { JOB_USERS_SAVED, JOB_OWNER_SAVED } from '../../../AppEvents';



class EditJobUsers extends React.Component {

	/*
	 * When modifiyng the item, just update local state.
	 * The global state is only updated when saving data ***
	 */
	constructor(props) {
		super(props);

		const owner = props.job.job_users.find(ju => ju.owner);
		const ownerId = owner ? owner.user_id : 0;
		const usersListValue = [];

		props.job.job_users.forEach(ju => usersListValue.push({ id: ju.user_id, fixed: parseInt(ju.user_id, 10) === parseInt(ownerId, 10) }) );

		this.saveOwner = this.saveOwner.bind(this);
		this.onOwnerChange = this.onOwnerChange.bind(this);

		this.saveUsersList = this.saveUsersList.bind(this);
		this.onUsersListChange = this.onUsersListChange.bind(this);

		this.state = { usersListValue, ownerId, showSaveOwnerButton: false  };
	}


	componentWillReceiveProps(props) {
		const { lastAction } = props;

		let state =  { job: props.job };
		if( lastAction.type === JOB_OWNER_SAVED )
			state.showSaveOwnerButton = false;

		this.setState( Object.assign({}, this.state, state) );

		if( lastAction.type === JOB_USERS_SAVED && lastAction.success ) {
			removeOverlayBodyClass();

			const timer = setTimeout(() => {
				this.props.doClose();
				clearTimeout(timer);
			}, 500);
		}
	}


	/*
 	 * When user save owner ...
	 */
	saveOwner = e => {
		e.preventDefault();
		this.props.onSetOwner(this.props.job, this.state.ownerId);
	}

	onOwnerChange = event => {
		this.setState({ ownerId: event.target.value, showSaveOwnerButton: true });
	}


	/*
 	 * When user saves users ...
	 */
	saveUsersList = e => {
		e.preventDefault();
		if( this.props.isSaving )
			return;
		this.props.onSave(this.props.job, this.state.usersListValue);
	}

	onUsersListChange = value => {
		const usersListValue = [];
		value.forEach(v => usersListValue.push({ id: v.value, fixed: v.isFixed }) );
		this.setState( Object.assign({}, this.state, { usersListValue }) );
	}


	renderOwnerSelect = () => {
		const { user, job, users } = this.props;
		if(! userRoleIs(user, [UserRole.MANAGER]) )
			return;

		const allowedUsers = [];
		job.job_users.forEach(ju => {
			const jobUser = users.find(u => parseInt(u.id, 10) === parseInt(ju.user_id, 10) && u.active );
			if(! jobUser )
				return false;

			if(! userRoleIs(jobUser, [UserRole.MANAGER, UserRole.ACCOUNT_MANAGER]))
				return false;

			allowedUsers.push({ value: jobUser.id, label: jobUser.fullname });
			return true;
		})

		if( allowedUsers.length < 2 )
			return;

		// This line causes a PoEdit error
		// const saveButton = this.state.showSaveOwnerButton ? <button className="microbutton" onClick={ this.saveOwner }>{ I18n.t('Save') }</button> : '';

		let saveButton = '';
		if( this.state.showSaveOwnerButton )
			saveButton = <button className="microbutton" onClick={ this.saveOwner }>{ I18n.t('Save') }</button>;



		return (
			<fieldset>
				<div className="input text">
					<label htmlFor="owner">{ I18n.t('Owner') }</label>
					<SelectBox options={ allowedUsers } value={ this.state.ownerId } name="owner" id="owner" onChange={ this.onOwnerChange } />
				</div> { saveButton }
			</fieldset>
		)
	}




	renderSubmit = () => {
		if( this.props.isSaving )
			return <button type="submit" className="is-saving">{ I18n.t('Saving...') }</button>

		return <button type="submit">{ I18n.t('Save') }</button>
	}

	render() {
		const job = this.props.job;

		const usersListValue = [];
		this.props.job.job_users.forEach(ju => usersListValue.push({ id: ju.user_id, fixed: parseInt(ju.user_id, 10) === parseInt(this.state.ownerId, 10) }) );

		return (
			<div className="jobs form">
				<form method="post" acceptCharset="utf-8" id="job-users-edit" onSubmit={this.saveUsersList}>
					<fieldset>
						<div className="input text">
							<span className="label-like">{ I18n.t('Job') }</span>
							<span className="input-like">{job.label}</span>
						</div>
					</fieldset>


					{ this.renderOwnerSelect() }

					<fieldset>
						<UsersSelect
							id="job-users"
							name="job_users"
							value={ this.state.usersListValue }
							roles={[ UserRole.MANAGER, UserRole.ACCOUNT_MANAGER, UserRole.EMPLOYEE ]}
							onChange={ this.onUsersListChange }
						/>
					</fieldset>


					<div className="form-controls">
						<input type="hidden" name="id" value={job.id || ''} readOnly />
						{ this.renderSubmit() }
					</div>
				</form>
			</div>
		);
	}
};

EditJobUsers.propTypes = {
	users: PropTypes.arrayOf(PropTypes.object).isRequired,
	job: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	isSaving: PropTypes.bool.isRequired
};


const mapStateToProps = (state, ownProps) => {
	const { user, users, isSaving, lastAction } = state.data || { users: [], user: {}, lastAction: {}, isSaving: false };

	return {
		user,
		users,
		lastAction,
		isSaving
	};
}


const mapDispatchToProps = (dispatch) => {
	return {
		onSave: (job, users) => {
			const formData = new FormData();
			formData.append('job_id', job.id);
			users.forEach(u => {
				formData.append(`user[${u.id}][user_id]`, u.id);
				formData.append(`user[${u.id}][job_id]`, job.id);
			});
			dispatch(doSaveJobUsers(job, formData));
		},

		onSetOwner: (job, ownerId) => {
			const data = new FormData();
			data.append('job_id', job.id);
			data.append('owner_id', ownerId);
			dispatch(doSetJobOwner(job, data));
		},

		doClose: () => {
			dispatch(closeOverlay());
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditJobUsers);
