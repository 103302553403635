import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SectionHeader from '../../../components/common/SectionHeader';
import Glyphicon from '../../../components/common/Glyphicon';
import Avatar from './Avatar';
import EditUser from './EditUser';
import I18n from '../../../locales/I18n';
import { openOverlay } from '../../../UIActions';



class Profile extends React.Component {

	updatePicture = event => {
		event.preventDefault();
		this.props.getUploadForm(this.props.user);
	}

	updatePassword = event => {
		event.preventDefault();
		this.props.getEditForm(this.props.user);
	}

	render() {
		const { isFetching, user } = this.props;

		if( isFetching )
			return <div className="message-empty">{ I18n.t('Please wait while loading...') }</div>

		const gravatarPicture = user.is_gravatar_picture ? I18n.t('Picture grabbed from gravatar') : '';

		return (
			<main id="main">
				<SectionHeader type="profile" title={ I18n.t('Profile') } />

				<section className="user-profile" id="user-profile">
					<div className="user-picture">
						<Avatar user={ user } size="large" />
					</div>

					<div className="user-data">
						<h2>{ user.fullname }</h2>
						<h3>{ user.email }</h3>

						<div>{ gravatarPicture }</div>

						<div className="user-profile-actions">
							<button className="microbutton" onClick={ this.updatePicture }>
								<Glyphicon icon="camera" />
								{ I18n.t('Change picture') }
							</button>

							<button className="microbutton" onClick={ this.updatePassword }>
								<Glyphicon icon="lock" />
								{ I18n.t('Change password') }
							</button>
						</div>
					</div>
				</section>
			</main>
		)
	}
};


Profile.propTypes = {
	isFetching: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
};


const mapStateToProps = (state, ownProps) => {
	const { isFetching, user } = state.data || { user: {}, isFetching: true };

	return {
		user,
		isFetching
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		getEditForm: (user) => {
			const title = I18n.t('Change password');
			const module = <EditUser user={user} type="password" />;
			dispatch(openOverlay( title, module ));
		},

		getUploadForm: (user) => {

		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
