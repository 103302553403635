import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataList from '../../../components/containers/DataList';

import User from './User';
import I18n from '../../../locales/I18n';
import EditUser from './EditUser';
import { openOverlay } from '../../../UIActions';


class UsersList extends React.Component {


	addUser = e => {
		e.preventDefault();
		this.props.createUserForm();
	}



	render() {
		const { isFetching, users } = this.props;

		if( isFetching )
			return <div className="message-empty">{ I18n.t('Please wait while loading...') }</div>


		if( users.length === 0 )
			return (
				<div className="message-empty">{ I18n.t('No user found') } <button className="link-like" onClick={this.addUser}>{ I18n.t('Add new ?') }</button></div>
			);


		const dataListOptions = (
			<div className="data-list-options">
				<button className="link-like" onClick={this.addUser}>{ I18n.t('Add new ?') }</button>
			</div>
		);



		return (
			<div>
				{ dataListOptions }
                <DataList
                    data={ users }
                    keyExtractor={ (item) => item.id }
                    renderItem={ ({ item }) => <User user={ item } id={ item.id } /> }
                />
			</div>
		);
	};
};

UsersList.propTypes = {
	users: PropTypes.arrayOf(PropTypes.object).isRequired,
	isFetching: PropTypes.bool.isRequired,
	company: PropTypes.object,
	user: PropTypes.object
};



const mapStateToProps = (state, ownProps) => {
	const { isFetching, clients, user, users } = state.data || { isFetching: true, clients: [], user: null, users: [] }
	const company = clients.find(c => c.id === user.company_id && c.is_self);

	return {
		user,
		users,
		company,
		isFetching
	}
}


const mapDispatchToProps = (dispatch) => {
	return {
		createUserForm: () => {
			const title = I18n.t('Add user');
			const module = <EditUser user={ {} } />;
			dispatch(openOverlay(title, module))
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
