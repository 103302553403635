import React from 'react';
import PropTypes from 'prop-types';
import Glyphicon from './Glyphicon';

class ItemOptions extends React.Component {

	constructor(props) {
		super(props);
		this.activeClassName = 'context-menu-shown';
		this.setActions(props);
	}

	componentDidUpdate() {
		this.setActions(this.props);
	}

	setActions = props => {
		const items = props.options;
		this.actions = [];
		items.forEach(item => {
			this.actions.push(item.action);
		});
	}


	onSelect = event => {
		const linkEl = event.target;
		const wrapper = linkEl.closest('.item');
		const key = linkEl.getAttribute('data-key');

		if( this.actions[key] instanceof Function )
			this.actions[key](event);

		wrapper.classList.remove(this.activeClassName);
	}



	toggle = (e) => {
		e.preventDefault();
		const item = e.currentTarget.parentNode.parentNode;

		if( ! item.classList.contains(this.activeClassName) ) {
			const activeContextMenus = document.querySelectorAll(`.${this.activeClassName}`);
			if(activeContextMenus.length > 0) {
				activeContextMenus.forEach( acm => {
					acm.classList.remove(this.activeClassName);
				});
			}
		}
		item.classList.toggle(this.activeClassName);
	}


	render() {
		const items = this.props.options;

		if( items.length === 0 )
			return null;


		const itemsList = Object
			.keys(items)
			.map(key => {
				const classNames = ['menu-item'];
				if( items[key].className )
					classNames.push(items[key].className);

				if( items[key].hasOwnProperty('actionUrl') ) {
					return (
						<li key={`context-menu-item-${key}`} className={ classNames.join(' ') }>
							<a href={ items[key].actionUrl } data-key={key}>
								<Glyphicon icon={ items[key].icon } /> { items[key].label }
							</a>
						</li>
					);
				}
				return (
					<li key={`context-menu-item-${key}`} className={ classNames.join(' ') }>
						<button className="link-like" data-key={key} onClick={ this.onSelect }>
							<Glyphicon icon={ items[key].icon } /> { items[key].label }
						</button>
					</li>
				);
			});

		return (
			<div className="item-options">
				<button className="link-like context-menu-toggle-button item-options-toggle" onClick={this.toggle}><Glyphicon icon="option-horizontal" /></button>
				<ul className="item-options-list">
					{ itemsList }
				</ul>
			</div>
		);
	}
};

ItemOptions.propTypes = {
	options: PropTypes.arrayOf(PropTypes.object)
};

export default ItemOptions;
