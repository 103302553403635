import React from 'react';
import { connect } from 'react-redux';

import ReactChartkick, { LineChart, AreaChart, PieChart } from 'react-chartkick'
import Chart from 'chart.js'
import SelectBox from '../../../components/forms/SelectBox';
import SectionHeader from '../../../components/common/SectionHeader';
import Currency from '../../../components/common/Currency';
import Glyphicon from '../../../components/common/Glyphicon';
import { Link } from 'react-router-dom';
import I18n from '../../../locales/I18n';
import { UserRole, userRoleIs } from '../../users/UsersConsts';
import { getBankAccountLabel } from '../../bank_accounts/BankAccountsHelpers';

ReactChartkick.addAdapter(Chart);






class Dashboard extends React.Component {


	constructor(props) {
		super(props);

		const { company } = this.props;
		const defaultBankAccount = company.bank_accounts.find(a => a.is_default) || company.bank_accounts[0] || null;

		this.state = {
			jobSessionPeriod: 'day',
			jobSessionDate: new Date(),
			transactionPeriod: 'year',
			currentBankAccount: defaultBankAccount.id || null,
		};
	}


	handleSwitchJobSessionPeriod(event) {
		event.preventDefault();
		this.setState({ jobSessionPeriod: this.state.jobSessionPeriod === 'day' ? 'week' : 'day' });
	}


	handleSwitchTransactionsResumePeriod(event) {
		event.preventDefault();
		this.setState({ transactionPeriod: this.state.transactionPeriod === 'year' ? 'month' : 'year' });
	}

	handleSwitchBankAccount(event) {
		event.preventDefault();
		this.setState({ currentBankAccount: parseInt(event.target.value, 10) });
	}


	extractYearData = (which, period) => {
		if(! this.props.stats[which] ) {
			return false;
		}

		const activePeriod = parseInt(this.props.activePeriod.slice(0,4), 10);
		const data = {};

		Object.keys(this.props.stats[which]).map(key => {
			if( parseInt(key.slice(0, 4), 10) === period )
				data[`${activePeriod}-${key.slice(5)}`] = this.props.stats[which][key].toFixed(2);

			return 1;
		});

		return data;
	}




	dataForChart = which => {
		const stats = this.props.stats;
		if(! stats[which] )
			return false;

		const activePeriod = parseInt(this.props.activePeriod.slice(0,4), 10);
		const data = [];
		for( let year = activePeriod; year > activePeriod - 3; year-- )
			data.push({ name: year, data: this.extractYearData(which, year) });

		return data;
	}



	getInvoicedDetails = () => {
		const { invoices } = this.props;
		let amount = 0;
		const invs = invoices.filter(i => i.type === 'INVOICE' && i.status !== 'DRAFT');
		const docs = invoices.filter(i => i.type !== 'QUOTE' && i.status !== 'DRAFT');
		docs.forEach(i => {
			const am = i.type === 'CREDIT_NOTE' ? i.amount * -1 : i.amount;
			amount += am;
		});

		return {
			amount,
			invoices: invs.length
		};
	}


	getTurnoverByClient = () => {
		const invoices = this.props.invoices.filter(i => i.type !== 'QUOTE' && i.status !== 'DRAFT');

		let data = [];
		invoices.forEach( i => {
			if(! data[i.client_id] ) {
				const client = this.props.clients.find(c => parseInt(c.id, 10) === parseInt(i.client_id, 10));
				data[i.client_id] = [client.short_name, 0];
			}
			data[i.client_id][1] += (i.type === 'CREDIT_NOTE' ? i.amount * -1 : i.amount)
		});

		data = data.sort((a, b) => {
			if(a[1] > b[1]) return -1;
			if(a[1] < b[1]) return 1;
			return 0;
		})

		const max = 10;
		let current = 0;

		const values = [];
		let rest = 0;
		for(let i in data) {
			data[i][1] = Math.round(data[i][1] * 100) / 100;
			if( current < max )
				values.push(data[i]);
			else
				rest += data[i][1];
			current++;
		}
		values.push([ I18n.t('Other'), rest ]);

		return values;
	}


	getExpensedDetails = () => {
		const { expenses } = this.props;
		let amount = 0;
		const exps = expenses.filter(i => (i.type === 'INVOICE' || i.type === 'FEE'));
		expenses.forEach(i => {
			const am = i.type === 'CREDIT_NOTE' ? i.amount * -1 : i.amount;
			amount += am;
		});

		return {
			amount,
			expenses: exps.length
		};
	}


	getExpensesByProvider = () => {
		const expenses = this.props.expenses.filter(i => i.type !== 'FEE' && i.status !== 'DRAFT');

		let data = [];
		expenses.forEach( i => {
			if(! data[i.client_id] ) {
				const provider = this.props.clients.find(c => parseInt(c.id, 10) === parseInt(i.client_id, 10));
				data[i.client_id] = [provider.short_name, 0];
			}
			data[i.client_id][1] += (i.type === 'CREDIT_NOTE' ? i.amount * -1 : i.amount);
		});

		data = data.sort((a, b) => {
			if(a[1] > b[1]) return -1;
			if(a[1] < b[1]) return 1;
			return 0;
		})

		const max = 10;
		let current = 0;

		const values = [];
		let rest = 0;
		for(let i in data) {
			data[i][1] = Math.round(data[i][1] * 100) / 100;
			if( current < max )
				values.push(data[i]);
			else
				rest += data[i][1];
			current++;
		}
		values.push([ I18n.t('Other'), rest ]);

		return values;
	}


	getJobSessionsResume = () => {
		const { jobs, company } = this.props;

		let dateString;
		if( this.state.jobSessionPeriod === 'day' ) {
			dateString = this.state.jobSessionDate.toISOString().split('T')[0];
		}
		else {
			const oneWeekAgo = new Date();
			oneWeekAgo.setDate(-7);
			dateString = oneWeekAgo.toISOString().split('T')[0];
		}

		let sessionDetails = {
			time: 0,
			amount: 0,
			hourlyFee: 0,
			label: this.state.jobSessionPeriod === 'day' ? I18n.t('today') : I18n.t('this week')
		};


		this.props.job_sessions.forEach(s => {
			if(this.state.jobSessionPeriod === 'day' && s.created_at.slice(0, 10) !== dateString ) {
				return;
			}

			if(this.state.jobSessionPeriod === 'week' && s.created_at.slice(0, 10) < dateString ) {
				return;
			}

			sessionDetails.time += s.time_amount;
			const job = jobs.find(j => parseInt(j.id, 10) === parseInt(s.job_id, 10));
			const fee = job ? job.fee : company.hourly_fee;
			sessionDetails.amount += (s.time_amount * fee);
		});

		if(sessionDetails.amount > 0 && sessionDetails.time > 0 ) {
			sessionDetails.hourlyFee = sessionDetails.amount / sessionDetails.time;
		}

		const hours = parseInt(sessionDetails.time, 10);
		const floatVal = sessionDetails.time - hours;
		if(floatVal > 0 ) {
			const minutes = 60 * floatVal;
			sessionDetails.time = [hours, minutes].join(I18n.t('h'));
		}
		else {
			sessionDetails.time = `${sessionDetails.time}h00`;
		}

		sessionDetails.hourlyAverageLabel =
			sessionDetails.hourlyFee > 0 ?
			(<span>(<Currency amount={sessionDetails.hourlyFee} />/{I18n.t('h') })</span>) :
			'';

		return sessionDetails;
	}




	renderAccountsSelector = () => {
		const { company } = this.props;

		if( company.bank_accounts.length === 1 ) {
			const account = company.bank_accounts[0];
			return (<div className="microbutton">{ getBankAccountLabel(account) }</div>);
		}

		const options = company.bank_accounts.map(a => ({
			value: a.id,
			label: getBankAccountLabel(a),
		}));

		return (
			<div className="input select">
				<SelectBox
					name="current_bank_account"
					options={ options }
					onChange={ this.handleSwitchBankAccount.bind(this) }
					value={ this.state.currentBankAccount }
				/>
			</div>
		);
	}


	getBankAccountsStats = () => {
		const { company, transactionsResume, stats } = this.props;
		const account = company.bank_accounts.find(a => this.state.currentBankAccount === parseInt(a.id, 10));

		if(! account.status ) {
			return (
				<React.Fragment key={ account.id }>
					<div className="dashboard-chart transactions-chart dashboard-blocks">
						<div className="dashboard-block dashboard-block-12">
							<div className="dashboard-chart-header">
								<h3 className="h3">{ I18n.t('Cash Flow') }</h3>

								{ this.renderAccountsSelector() }
							</div>
						</div>
					</div>
				</React.Fragment>
			)
		}

		return (
			<React.Fragment key={ account.id }>
				<div className="dashboard-chart transactions-chart dashboard-blocks">
					<div className="dashboard-block dashboard-block-12">
						<div className="dashboard-chart-header">
							<h3 className="h3">{ I18n.t('Cash Flow') }</h3>

							{ this.renderAccountsSelector() }
						</div>

						<AreaChart
							data={ stats.bank_accounts[account.id] }
							suffix="€"
							thousands=" "
							decimal=","
							legend={false}
						/>
					</div>
				</div>

				<div className="dashboard-info dashboard-blocks">
					<div className="dashboard-block dashboard-block-3 dashboard-block-info">
						<Link to="/transactions">
							<Glyphicon icon="stats" />
							<div className="dashboard-amount">
								<Currency amount={account.status.balance} />
							</div>
							<p>{ I18n.t('Account balance') }</p>
						</Link>
					</div>

					<div className="dashboard-block dashboard-block-3 dashboard-block-info">
						<span onClick={ this.handleSwitchTransactionsResumePeriod.bind(this) }>
							<Glyphicon icon="transfer" />
							<div className="dashboard-amount">
								<Currency amount={transactionsResume[this.state.transactionPeriod].balance} />
							</div>
							<p>
								{ transactionsResume[this.state.transactionPeriod].moves }
								{ ' ' }
								{ this.state.transactionPeriod === 'year' ?
									I18n.t('transactions this year') :
									I18n.t('transactions this month')
								}
							</p>
						</span>
					</div>

					<div className="dashboard-block dashboard-block-3 dashboard-block-info">
						<span onClick={ this.handleSwitchTransactionsResumePeriod.bind(this) }>
							<Glyphicon icon="arrow-up" />
							<div className="dashboard-amount">
								<Currency amount={transactionsResume[this.state.transactionPeriod].IN} />
							</div>
							<p>
								{ this.state.transactionPeriod === 'year' ?
									I18n.t('incomes this year') :
									I18n.t('incomes this month')
								}
							</p>
						</span>
					</div>

					<div className="dashboard-block dashboard-block-3 dashboard-block-info">
						<span onClick={ this.handleSwitchTransactionsResumePeriod.bind(this) }>
							<Glyphicon icon="arrow-down" />
							<div className="dashboard-amount">
								<Currency amount={transactionsResume[this.state.transactionPeriod].OUT} />
							</div>
							<p>
								{ this.state.transactionPeriod === 'year' ?
									I18n.t('outcomes this year') :
									I18n.t('outcomes this month')
								}
							</p>
						</span>
					</div>
				</div>
			</React.Fragment>
		);
	}



	render() {
		if(! userRoleIs(this.props.user, [UserRole.MANAGER, UserRole.ACCOUNTANT, UserRole.SU, UserRole.EMPLOYEE, UserRole.ACCOUNT_MANAGER]) ) {
			return null;
		}

		if( userRoleIs(this.props.user, [UserRole.EMPLOYEE, UserRole.ACCOUNT_MANAGER ]) ) {
			return (
				<main id="main">
					<SectionHeader type="dashboard" title={ I18n.t('Dashboard') } />

					<div id="data" className="nano the-dashboard">
						<div className="nano-content">
							<section className="dashboard-section">Welcome, {this.props.user.fname}</section>
						</div>
					</div>
				</main>
			);
		}

		const { jobsDetails, invoicesResume, expensesResume } = this.props;
		const activePeriod = parseInt(this.props.activePeriod.slice(0,4), 10);

		const today = new Date();
		const months = today.getFullYear() === activePeriod ? today.getMonth() + 1 : 12;

		const invoicedDetails = this.getInvoicedDetails();
		const invoiceAverageAmount = invoicedDetails.invoices > 0 ? invoicedDetails.amount/invoicedDetails.invoices : 0;
		const montlyInvoicedAverage = invoicedDetails.amount/months;

		const expensedDetails = this.getExpensedDetails();
		const expenseAverageAmount = expensedDetails.expenses > 0 ? expensedDetails.amount/expensedDetails.expenses : 0;
		const montlyExpensedAverage = expensedDetails.amount/months;

		const sessions = this.getJobSessionsResume();

		return (
			<main id="main">
				<SectionHeader type="dashboard" title={ I18n.t('Dashboard') } />

				<div id="data" className="nano the-dashboard">
					<div className="nano-content">
						<section className="dashboard-section">
							<div className="dashboard-info dashboard-blocks">

								<div className="dashboard-block dashboard-block-3 dashboard-block-info">
									<Link to="/jobs">
										<Glyphicon icon="tasks" />
										<div className="dashboard-amount">
											<Currency amount={jobsDetails.open.amount} />
										</div>
										<p>{ jobsDetails.open.total } {I18n.t('open jobs')}</p>
									</Link>
								</div>

								<div className="dashboard-block dashboard-block-3 dashboard-block-info">
									<Link to="/jobs">
										<Glyphicon icon="bell" />
										<div className="dashboard-amount">
											<Currency amount={jobsDetails.closed.amount} />
										</div>
										<p>{ jobsDetails.closed.total } {I18n.t('jobs to invoice')}</p>
									</Link>
								</div>

								<div className="dashboard-block dashboard-block-3 dashboard-block-info">
									<Link to="/jobs">
										<Glyphicon icon="repeat" />
										<div className="dashboard-amount">
											<Currency amount={jobsDetails.recurrent.amount} />
										</div>
										<p>{ jobsDetails.recurrent.total } {I18n.t('recurrent jobs')}</p>
									</Link>
								</div>

								<div className="dashboard-block dashboard-block-3 dashboard-block-info">
									<span onClick={ this.handleSwitchJobSessionPeriod.bind(this) }>
										<Glyphicon icon="calendar" />
										<div className="dashboard-amount">
											<Currency amount={sessions.amount} />
										</div>
										<p>{ sessions.time } { sessions.label } { sessions.hourlyAverageLabel }</p>
									</span>
								</div>
							</div>
						</section>


						<section className="dashboard-section">
							<div className="dashboard-chart incomes-chart dashboard-blocks">
								<div className="dashboard-block dashboard-block-8">
									<div className="dashboard-chart-header">
										<h3 className="h3">{ I18n.t('Incomes') } { activePeriod }</h3>
									</div>

									<LineChart
										data={ this.dataForChart('incomes') }
										suffix="€"
										thousands=" "
										decimal=","
										legend="bottom"
										discrete={false}
									/>
								</div>

								<div className="dashboard-block dashboard-block-4">
									<div className="dashboard-chart-header">
										<h3 className="h3">{ I18n.t('Revenues by client') } { activePeriod }</h3>
									</div>

									<PieChart
										data={ this.getTurnoverByClient() }
										donut={true}
										suffix="€"
										thousands=" "
										decimal=","
										legend="bottom"
									/>
								</div>
							</div>

							<div className="dashboard-info dashboard-blocks">
								<div className="dashboard-block dashboard-block-3 dashboard-block-info">
									<Link to="/incomes">
										<Glyphicon icon="arrow-up" />
										<div className="dashboard-amount">
											<Currency amount={ invoicedDetails.amount } />
										</div>
										<p>{I18n.t('invoiced')}</p>
									</Link>
								</div>

								<div className="dashboard-block dashboard-block-3 dashboard-block-info">
									<Link to="/incomes">
										<Glyphicon icon="scale" />
										<div className="dashboard-amount">
											<Currency amount={ invoiceAverageAmount } />
										</div>
										<p>{I18n.t('average invoice')}</p>
									</Link>
								</div>

								<div className="dashboard-block dashboard-block-3 dashboard-block-info">
									<Link to="/incomes">
										<Glyphicon icon="stats" />
										<div className="dashboard-amount">
											<Currency amount={ montlyInvoicedAverage } />
										</div>
										<p>{I18n.t('invoiced average/month')}</p>
									</Link>
								</div>

								<div className="dashboard-block dashboard-block-3 dashboard-block-info">
									<Link to="/incomes">
										<Glyphicon icon="time" />
										<div className="dashboard-amount">
											<Currency amount={invoicesResume.amount} />
										</div>
										<p>{ invoicesResume.totalInvoices } {I18n.t('open invoices')}</p>
									</Link>
								</div>
							</div>
						</section>


						<section className="dashboard-section">
							<div className="dashboard-chart outcomes-chart dashboard-blocks">
								<div className="dashboard-block dashboard-block-8">
									<div className="dashboard-chart-header">
										<h3 className="h3">{ I18n.t('Outcomes') } { activePeriod }</h3>
									</div>
									<LineChart
										data={ this.dataForChart('outcomes') }
										suffix="€"
										thousands=" "
										decimal=","
										legend="bottom"
										discrete={false}
									/>
								</div>

								<div className="dashboard-block dashboard-block-4">
									<div className="dashboard-chart-header">
										<h3 className="h3">{ I18n.t('Expenses by provider') } { activePeriod }</h3>
									</div>
									<PieChart
										data={ this.getExpensesByProvider() }
										donut={true}
										suffix="€"
										thousands=" "
										decimal=","
										legend="bottom"
									/>
								</div>
							</div>

							<div className="dashboard-info dashboard-blocks">
								<div className="dashboard-block dashboard-block-3 dashboard-block-info">
									<Link to="/outcomes">
										<Glyphicon icon="arrow-down" />
										<div className="dashboard-amount">
											<Currency amount={ expensedDetails.amount } />
										</div>
										<p>{I18n.t('expensed')}</p>
									</Link>
								</div>

								<div className="dashboard-block dashboard-block-3 dashboard-block-info">
									<Link to="/outcomes">
										<Glyphicon icon="scale" />
										<div className="dashboard-amount">
											<Currency amount={ expenseAverageAmount } />
										</div>
										<p>{I18n.t('average expense')}</p>
									</Link>
								</div>

								<div className="dashboard-block dashboard-block-3 dashboard-block-info">
									<Link to="/outcomes">
										<Glyphicon icon="stats" />
										<div className="dashboard-amount">
											<Currency amount={ montlyExpensedAverage } />
										</div>
										<p>{I18n.t('expensed average/month')}</p>
									</Link>
								</div>

								<div className="dashboard-block dashboard-block-3 dashboard-block-info">
									<Link to="/outcomes">
										<Glyphicon icon="time" />
										<div className="dashboard-amount">
											<Currency amount={expensesResume.amount} />
										</div>
										<p>{ expensesResume.totalExpenses } {I18n.t('invoices to pay')}</p>
									</Link>
								</div>
							</div>
						</section>

						<section className="dashboard-section">
							{ this.getBankAccountsStats() }
						</section>
					</div>
				</div>
			</main>

		)
	}
}


const getIncomesUnpaidAmount = (invoices, transactions) => {
	let amount = 0;
	let transactionsAmount = 0;
	let totalInvoices = 0;

	invoices.forEach(inv => {
		if(! ( inv.type === 'INVOICE' && inv.status === 'OPEN'))
			return;

		totalInvoices++;
		transactionsAmount = 0;
		transactions.forEach(t => {
			if( parseInt(t.document_id, 10) === parseInt(inv.id, 10) && t.type === 'IN' && t.category === 'INCOME' )
				transactionsAmount += t.amount;
		});
		amount += inv.amount_with_taxes - transactionsAmount;
	});

	return { totalInvoices, amount };
}





const getOutcomesUnpaidAmount = (expenses, transactions) => {
	let amount = 0;
	let transactionsAmount = 0;
	let totalExpenses = 0;

	expenses.forEach(inv => {
		if(! ( inv.type === 'INVOICE' && inv.status === 'OPEN'))
			return;

		totalExpenses++;
		transactionsAmount = 0;
		transactions.forEach(t => {
			if( parseInt(t.document_id, 10) === parseInt(inv.id, 10) && t.type === 'OUT' && t.category === 'OUTCOME' )
				transactionsAmount += t.amount;
		});
		amount += inv.amount_with_taxes - transactionsAmount;
	});

	return { totalExpenses, amount };
}



const getJobsDetails = (jobs, job_sessions) => {
	const currentYear = new Date().getFullYear();

	let year = { total:0, amount: 0 };
	let open = { total:0, amount: 0 };
	let closed = { total:0, amount: 0 };
	let recurrent = { total:0, amount: 0 };

	jobs.forEach(job => {
		let jobAmount;
		if( job.type === 'HOURLY' && job.status === 'OPEN' ) {
			const sessions = job_sessions.filter(s => parseInt(s.job_id, 10) === parseInt(job.id, 10) && s.completed === true );
			let timeAmount = 0;
			sessions.forEach(s => { timeAmount += parseFloat(s.time_amount); });
			jobAmount = parseFloat(timeAmount * job.fee);
		}
		else
			jobAmount = job.amount;

		if( currentYear === new Date(job.date_memo).getFullYear() ) {
			year.total++;
			year.amount += jobAmount;
		}

		if( job.status === 'OPEN' && ! job.is_recurrent ) {
			open.total++;
			open.amount += jobAmount;
		}

		if( job.is_recurrent ) {
			recurrent.total++;
			recurrent.amount += jobAmount;
		}

		if(job.status === 'CLOSED') {
			closed.total++;
			closed.amount += jobAmount;
		}
	});

	return { year, open, closed, recurrent };
}



const mapStateToProps = (state, ownProps) => {
	//const { user } = state.user;
	const { company, activePeriod, jobs, job_sessions, user } = state.data;

	if(! userRoleIs(user, [UserRole.MANAGER, UserRole.ACCOUNTANT]) ) {
		return {
			user,
			company,
			jobs,
			job_sessions,
			activePeriod
		};
	}

	const { invoices, expenses, transactions, clients, stats } = state.data;

	const invoicesResume = getIncomesUnpaidAmount(invoices, transactions);
	const expensesResume = getOutcomesUnpaidAmount(expenses, transactions);
	const jobsDetails = getJobsDetails(jobs, job_sessions);


	const date = new Date();
	date.setDate(-30);
	const oneMonthAgo = date.toISOString().split('T')[0]

	let transactionsResume = {
		month: {
			IN: 0,
			OUT: 0,
			moves: 0,
			balance: 0,
		},

		year: {
			IN: 0,
			OUT: 0,
			moves: transactions.length,
			balance: 0,
		}
	};

	transactions.forEach(tr => {
		const amount = parseFloat(tr.amount);
		const inMonth = tr.date_memo.slice(0, 10) >= oneMonthAgo;
		if( inMonth )
			transactionsResume.month.moves ++;

		if( tr.type === 'IN' ) {
			transactionsResume.year.IN += amount;
			if( inMonth )
				transactionsResume.month.IN += amount;
		}
		else {
			transactionsResume.year.OUT -= amount;
			if( inMonth )
				transactionsResume.month.OUT -= amount;
		}
	});

	transactionsResume.month.balance = transactionsResume.month.IN - transactionsResume.month.OUT;
	transactionsResume.year.balance = transactionsResume.year.IN - transactionsResume.year.OUT;


	return {
		user,
		company,
		clients,
		invoices,
		expenses,
		jobs,
		jobsDetails,
		job_sessions,
		transactionsResume,
		expensesResume,
		invoicesResume,
		stats,
		activePeriod
	};
}

export default connect(mapStateToProps)(Dashboard);
