import React from 'react';
import PropTypes from 'prop-types';
import SelectBox from './SelectBox';
import dateFormat from 'dateformat';

class DateSelect extends React.Component {

	constructor(props) {
		super(props);
		this.dateFmt = 'yyyy/mm/dd';

		let value;
		if( this.props.defaultValue )
			value = this.props.defaultValue;
		else if( this.props.value )
			value = this.props.value;
		else
			value = new Date();

		const fmtValue = dateFormat(value, this.dateFmt).split('/');

		this.state = {
			value: {
				d: fmtValue[2],
				m: fmtValue[1],
				y: fmtValue[0]
			}
		};
	}

	updateState(e) {
		if(! e.target )
			return;

		const prop = e.target.getAttribute('data-prop');
		if( prop && this.state.value[prop] ) {
			let value = Object.assign({}, this.state.value, {});
			value[prop] = e.target.value;

			this.setState({value: value});
			if(this.props.onChange)
				this.props.onChange(e);
		}

		return;
	}


	render() {
		const { name, ...attrs } = this.props || { name: '' };
		const today = new Date();
		const currentYear = today.getFullYear();

		const value = this.state.value;

		let dateOptions = { days: [], months: [], years: [] };
		for(let d=1; d <= 31; d++) {
			let val = d < 10 ? `0${d}` : `${d}`;
			dateOptions.days.push({ label: val, value: val });
		}

		for(let m=1; m <= 12; m++) {
			let val = m < 10 ? `0${m}` : `${m}`;
			dateOptions.months.push({ label: val, value: val });
		}

		for(let y=(currentYear - 5); y <= (currentYear + 5); y++) {
			let val = `${y}`;
			dateOptions.years.push({ label: val, value: val });
		}

		/* defaultValue
		const dateFmt = 'yyyy/mm/dd';
		const defaultValue = attrs.defaultValue ? dateFormat(attrs.defaultValue, dateFmt).split('/') : dateFormat(today, dateFmt).split('/');
		const defaultValues = {
			d: defaultValue[2],
			m: defaultValue[1],
			y: defaultValue[0]
		};
		*/

		delete attrs.value;
		delete attrs.defaultValue;
		delete attrs.onChange;

		return (
			<span className="date-select">
				<SelectBox data-prop="d" name={`${name}[day]`} value={value.d} options={ dateOptions.days } onChange={ this.updateState.bind(this) } {...attrs} />
				<SelectBox data-prop="m" name={`${name}[month]`} value={value.m} options={ dateOptions.months } onChange={ this.updateState.bind(this) } {...attrs} />
				<SelectBox data-prop="y" name={`${name}[year]`} value={value.y} options={ dateOptions.years } onChange={ this.updateState.bind(this) } {...attrs} />
			</span>
		);
	}
};

DateSelect.propTypes = {
	name: PropTypes.string.isRequired
};


export default DateSelect;
