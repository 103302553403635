import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import InvoicesList from '../../expenses/components/InvoicesList';
import CreditNotesList from '../../expenses/components/CreditNotesList';
import ContextMenu from '../../../components/common/ContextMenu';
import Currency from '../../../components/common/Currency';
import Glyphicon from '../../../components/common/Glyphicon';
import EditClient from './EditClient';
import EditExpense from '../../expenses/components/EditExpense';
import I18n from '../../../locales/I18n';
import { openOverlay } from '../../../UIActions';





class Provider extends React.Component {

	constructor(props) {
		super(props);
		this.state = { activeTab: 'invoices' };
		this.bodyClassName = 'data-main-shown';
	}


	componentDidMount() {
		if(this.props.provider.id)
			return document.body.classList.add(this.bodyClassName);

		return document.body.classList.remove(this.bodyClassName);
	}

	componentWillUnmount() {
		return document.body.classList.remove(this.bodyClassName);
	}


	addProvider = () => {
		const title = I18n.t('Create provider');
		this.props.getForm(title, null);
	}

	editProvider = () => {
		const title = I18n.t('Edit provider');
		const { provider } = this.props;
		this.props.getForm(title, provider);
	}

	addInvoice = e => {
		e.preventDefault();
		const title = I18n.t('Add invoice');
		this.props.addExpenseForm(title, { type: 'INVOICE', client_id: this.props.provider.id });
	}

	addCreditNote = e => {
		e.preventDefault();
		const title = I18n.t('Add credit note');
		this.props.addExpenseForm(title, { type: 'CREDIT_NOTE', client_id: this.props.provider.id });
	}


	onTabClick = e => {
		e.preventDefault();
		const a = e.target;

		if(! a.getAttribute('data-tab'))
			return;

		const tab = a.getAttribute('data-tab');
		this.setState({ activeTab: tab });
	}

	getActiveClassName = tab => {
		return tab === this.state.activeTab ? 'tab active' : 'tab';
	}


	renderStatusExpensed = () => {
		const { provider } = this.props;

		if( provider.status.expensed === 0 )
			return null;

		return (
			<div className="client-status-info client-status-expensed">
				<h3>{ I18n.t('Expensed') }:</h3>
				<div className="amount">
					<Currency amount={ provider.status.expensed } />
				</div>
			</div>
		);
	}


	renderStatusDue = () => {
		const { provider } = this.props;

		if( provider.status.balance === 0 )
			return null;

		return (
			<div className="client-status-info client-status-due">
				<h3>{ I18n.t('Due') }:</h3>
				<div className="amount">
					<Currency amount={ provider.status.balance } />
				</div>
			</div>
		)
	}


	render() {
		const { provider } = this.props;

		if(! provider.id) {
			return (
				<div id="client-data">
					<div className="message-empty">
						<p>{ I18n.t('Pick a provider') } { I18n.t('or') } <button onClick={ this.addProvider } className="link-like">{ I18n.t('create a new one') }</button></p>
					</div>
				</div>
			);
		}



		const contextMenu = [
			{
				action: this.editProvider,
				icon: 'edit',
				label: I18n.t('Edit')
			},

			{
				action: this.addInvoice,
				icon: 'add',
				label: I18n.t('Add invoice')
			},

			{
				action: this.addCreditNote,
				icon: 'add',
				label: I18n.t('Add credit note')
			}
		];


		let dataList;
		switch( this.state.activeTab ) {
			case 'credit-notes':
				dataList = <div className="data-main-tab"><CreditNotesList client={provider.id} /></div>
				break;

			case 'invoices':
			default:
				dataList = <div className="data-main-tab"><InvoicesList client={provider.id} /></div>
		}


		let IBANData = '';
		/*
		if( provider.iban ) {
			IBANData = (
					<p>
						<span className="client-data">
							<span className="client-data-label"><Glyphicon icon="credit-card" /></span>
							<span className="client-data-value">{provider.iban}</span>
							<a onClick={ this.manageBankAccounts } className="microbutton">{ I18n.t('Manage') }</a>
						</span>
					</p>
				)
			}
		*/

		return (
			<section className="client">
				<header className="client-header data-main-header">
					<h2>
						{provider.name}

						<ContextMenu items={ contextMenu } />
					</h2>

					<div className="section-details">
						<div className="invoice-info">
							<p>
								<span className="client-data">
									<span className="client-data-label"><Glyphicon icon="envelope" /></span>
									<span className="client-data-value">{provider.address_formatted}</span>
								</span>
							</p>

							<p>
								<span className="client-data">
									<span className="client-data-label">{ I18n.t('VAT') }:</span>
									<span className="client-data-value">{provider.vat_num}</span>
								</span>
							</p>
						</div>

						<div className="contact-info">
							{ IBANData }
						</div>
					</div>

					<div className="client-status">
						{ this.renderStatusDue() }
						{ this.renderStatusExpensed() }
					</div>
				</header>

				<div className="data-main-related-data">
					<nav className="data-main-nav">
						<ul className="menu">
							<li><button onClick={ this.onTabClick } data-tab="invoices" className={ this.getActiveClassName('invoices') }>{ I18n.t('Invoices') }</button></li>
							<li><button onClick={ this.onTabClick } data-tab="credit-notes" className={ this.getActiveClassName('credit-notes') }>{ I18n.t('Credit notes') }</button></li>
						</ul>
					</nav>

					{ dataList }
				</div>
			</section>
		);
	}
};

Provider.propTypes = {
	provider: PropTypes.object.isRequired
};


const mapStateToProps = (state, ownProps) => {
	const { isFetching, clients } = state.data || { isFetching: true, clients: [] }

	if( isFetching || clients.length === 0 )
		return { provider: {}, isFetching };

	const provider = clients.find( c => parseInt(c.id, 10) === parseInt(ownProps.match.params.id, 10) );


	return {
		provider,
		isFetching
	}
}


const mapDispatchToProps = (dispatch) => {
	return {
		getForm: (title, client) => {
			const module = <EditClient client={client} />;
			dispatch(openOverlay( title, module ));
		},

		addExpenseForm: (title, expense) => {
			const module = <EditExpense expense={expense} />;
			dispatch(openOverlay( title, module ));
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(Provider);
