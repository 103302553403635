import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group'


const DataList = ({ className = 'data-list', data = [], keyExtractor = () => {}, renderItem }) => {
    return (
        <ul className={ className }>
            <TransitionGroup>
                { data.map((item, index) => {
                    return (
                        <CSSTransition
                            key={ keyExtractor(item) }
                            classNames="data-list-item"
                            timeout={{ enter:500, exit: 500 }}
                        >
                            { renderItem({ item, index }) }
                        </CSSTransition>
                    )
                }) }
            </TransitionGroup>
        </ul>
    );
};

export default DataList;
