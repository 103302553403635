import React from 'react';
import PropTypes from 'prop-types';
import { Toggle as ToggleCpt } from 'react-toggle-component';


const Toggle = ({ name, labelLeft = null, labelRight = null, checked = false, onToggle }) => {
    return (
        <label htmlFor={ name }>
            { labelLeft && labelLeft }
            <ToggleCpt
                name={ name }
                onToggle={ onToggle }
                checked={ checked }
                backgroundColor="white"
                leftBorderColor="#aaaaaa"
                rightBorderColor="#3af1ce"
                leftKnobColor="#aaaaaa"
                rightKnobColor="#3af1ce"
                width="32px"
                height="18px"
                knobWidth="10px"
                knobHeight="10px"
                knobGap="4px"

            />
            { labelRight && labelRight }
        </label>


    );
};


Toggle.propTypes = {
    name: PropTypes.string.isRequired,
    onToggle: PropTypes.func.isRequired,
    checked: PropTypes.bool,
};

export default Toggle;
