import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from '../../../locales/I18n';
import UsersSelect from '../../../components/forms/UsersSelect';
import { closeOverlay, removeOverlayBodyClass } from '../../../UIActions';
import { doSaveClientUsers } from '../ClientsActions';
import { UserRole } from '../../users/UsersConsts';
import { CLIENT_USERS_SAVED } from '../../../AppEvents';



class EditClientUsers extends React.Component {

	/*
	 * When modifiyng the item, just update local state.
	 * The global state is only updated when saving data ***
	 */
	constructor(props) {
		super(props);

		const usersListValue = [];
		props.client.client_users.forEach(cu => usersListValue.push({ id: cu.user_id }) );
		this.saveUsersList = this.saveUsersList.bind(this);
		this.onUsersListChange = this.onUsersListChange.bind(this);

		this.state = { usersListValue };
	}


	componentWillReceiveProps(props) {
		const { lastAction } = props;

		const usersListValue = [];
		props.client.client_users.forEach(cu => usersListValue.push({ id: cu.user_id }) );
		this.setState({ client: props.client, usersListValue });

		if( lastAction.type === CLIENT_USERS_SAVED && lastAction.success ) {
			removeOverlayBodyClass();

			const timer = setTimeout(() => {
				this.props.doClose();
				clearTimeout(timer);
			}, 500);
		}
	}



	/*
 	 * When user saves users ...
	 */
	saveUsersList = e => {
		e.preventDefault();
		if( this.props.isSaving )
			return;
		this.props.onSave(this.props.client, this.state.usersListValue);
	}


	onUsersListChange = value => {
		const usersListValue = [];
		value.forEach(v => usersListValue.push({ id: v.value }) );
		this.setState({ usersListValue });
	}



	renderSubmit = () => {
		if( this.props.isSaving )
			return <button type="submit" className="is-saving">{ I18n.t('Saving...') }</button>

		return <button type="submit">{ I18n.t('Save') }</button>
	}

	render() {
		const client = this.props.client;
		return (
			<div className="clients form">
				<form method="post" acceptCharset="utf-8" id="client-users-edit" onSubmit={this.saveUsersList}>
					<fieldset>
						<div className="input text">
							<span className="label-like">{ I18n.t('Client') }</span>
							<span className="input-like">{client.name}</span>
						</div>
					</fieldset>

					<fieldset>
						<UsersSelect
							id="client-users"
							name="client_users"
							value={ this.state.usersListValue }
							roles={[ UserRole.ACCOUNT_MANAGER ]}
							onChange={ this.onUsersListChange }
						/>
					</fieldset>


					<div className="form-controls">
						<input type="hidden" name="id" value={client.id || ''} readOnly />
						{ this.renderSubmit() }
					</div>
				</form>
			</div>
		);
	}
};

EditClientUsers.propTypes = {
	client: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	isSaving: PropTypes.bool.isRequired
};


const mapStateToProps = (state, ownProps) => {
	const { user, isSaving, lastAction } = state.data || { user: {}, lastAction: {}, isSaving: false };

	return {
		user,
		lastAction,
		isSaving
	};
}


const mapDispatchToProps = (dispatch) => {
	return {
		onSave: (client, users) => {
			const formData = new FormData();
			formData.append('client_id', client.id);
			users.forEach(u => {
				formData.append(`user[${u.id}][user_id]`, u.id);
				formData.append(`user[${u.id}][client_id]`, client.id);
			});
			dispatch(doSaveClientUsers(client, formData));
		},

		doClose: () => {
			dispatch(closeOverlay());
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditClientUsers);
