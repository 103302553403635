
export default class DateJS {
	static today = () => {
		const today = new Date();
		const monthInt = today.getMonth() + 1;
		const dayInt = today.getDate();

		return {
			y: today.getFullYear().toString(),
			m: monthInt > 9 ? monthInt.toString() : `0${monthInt.toString()}`,
			d: dayInt > 9 ? dayInt.toString() : `0${dayInt.toString()}`
		};
	}
}
