import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import JobsList from '../../jobs/components/JobsList';
import EditJob from '../../jobs/components/EditJob';
import InvoicesList from '../../invoices/components/InvoicesList';
import CreditNotesList from '../../invoices/components/CreditNotesList';
import QuotesList from '../../invoices/components/QuotesList';
import EditInvoice from '../../invoices/components/EditInvoice';
import Currency from '../../../components/common/Currency';
import Glyphicon from '../../../components/common/Glyphicon';
import ContextMenu from '../../../components/common/ContextMenu';
import EditClient from './EditClient';
import EditClientUsers from './EditClientUsers';
import BankAccounts from '../../bank_accounts/components/BankAccounts';
import I18n from '../../../locales/I18n';
import { UserRole, userRoleIs } from '../../users/UsersConsts';
import { openOverlay } from '../../../UIActions';



class Client extends React.Component {

	constructor(props) {
		super(props);
		this.state = { activeTab: 'jobs' };
		this.bodyClassName = 'data-main-shown';
	}

	componentDidMount() {
		if(this.props.client.id)
			return document.body.classList.add(this.bodyClassName);

		return document.body.classList.remove(this.bodyClassName);
	}

	componentWillUnmount() {
		return document.body.classList.remove(this.bodyClassName);
	}

	addClient = () => {
		const title = I18n.t('Create client');
		this.props.getForm(title, null);
	}

	editClient = () => {
		const title = I18n.t('Edit client');
		const { client } = this.props;
		this.props.getForm(title, client);
	}

	usersForm = e => {
		e.preventDefault();
		this.props.getUsersForm(this.props.client);
	}


	createJob = e => {
		e.preventDefault();
		this.props.createJobForm({ client_id: this.props.client.id });
	}

	createQuote = e => {
		e.preventDefault();
		const title = I18n.t('Create quote');
		this.props.createInvoiceForm(title, { type: 'QUOTE', client_id: this.props.client.id });
	}

	createCreditNote = e => {
		e.preventDefault();
		const title = I18n.t('Create credit note');
		this.props.createInvoiceForm(title, { type: 'CREDIT_NOTE', client_id: this.props.client.id });
	}


	manageBankAccounts = e => {
		e.preventDefault();
		const { client } = this.props;
		this.props.bankAccountManager(client);
	}



	onTabClick = e => {
		e.preventDefault();
		const a = e.target;

		if(! a.getAttribute('data-tab'))
			return;

		const tab = a.getAttribute('data-tab');
		this.setState({ activeTab: tab });
	}



	getActiveClassName = tab => {
		return tab === this.state.activeTab ? 'tab active' : 'tab';
	}



	renderStatusInvoiced = () => {
		const { client } = this.props;

		if( client.status.invoiced === 0 )
			return null;

		return (
			<div className="client-status-info client-status-invoiced">
				<h3>{ I18n.t('Invoiced') }:</h3>
				<div className="amount">
					<Currency amount={ client.status.invoiced } />
				</div>
			</div>
		);
	}



	renderStatusToInvoice = () => {
		const { readyToInvoice } = this.props;

		if( readyToInvoice === 0 )
			return null;

		return (
			<div className="client-status-info client-status-to-invoice">
				<h3>{ I18n.t('To invoice') }:</h3>
				<div className="amount">
					<Currency amount={ readyToInvoice } />
				</div>
			</div>
		);
	}


	renderStatusDue = () => {
		const { client } = this.props;

		if( client.status.balance === 0 )
			return null;

		return (
			<div className="client-status-info client-status-due">
				<h3>{ I18n.t('Due') }:</h3>
				<div className="amount">
					<Currency amount={ client.status.balance } />
				</div>
			</div>
		)
	}




	render() {
		const { client } = this.props;


		if(! client.id) {
			return (
				<div id="client-data">
					<div className="message-empty">
						<p>{ I18n.t('Pick a client') } { I18n.t('or') } <button className="link-like" onClick={ this.addClient }>{ I18n.t('create a new one') }</button></p>
					</div>
				</div>
			);
		}


		let dataList;
		switch( this.state.activeTab ) {
			case 'invoices':
				dataList = <div className="data-main-tab"><InvoicesList client={client.id} /></div>

				break;

			case 'quotes':
				dataList = <div className="data-main-tab"><QuotesList client={client.id} /></div>
				break;

			case 'credit-notes':
				dataList = <div className="data-main-tab"><CreditNotesList client={client.id} /></div>
				break;

			case 'jobs':
			default:
				dataList = <div className="data-main-tab"><JobsList type="CLIENT" client={client.id} /></div>
		}


		const contextMenu = [];
		contextMenu.push({
				action: this.editClient,
				icon: 'edit',
				label: I18n.t('Edit')
			});

		if( userRoleIs(this.props.user, [UserRole.MANAGER])) {
			contextMenu.push({
					action: this.usersForm,
					icon: 'user',
					label: I18n.t('Manage users')
				});

			contextMenu.push({
					action: this.createJob,
					icon: 'plus',
					label: I18n.t('Create job')
				});

			contextMenu.push({
					action: this.createQuote,
					icon: 'plus',
					label: I18n.t('Create quote')
				});
		}

		contextMenu.push({
				action: this.createCreditNote,
				icon: 'plus',
				label: I18n.t('Create credit note')
			});


		let IBANData = '';
		if( client.iban ) {
			IBANData = (
					<p>
						<span className="client-data">
							<span className="client-data-label"><Glyphicon icon="credit-card" /></span>
							<span className="client-data-value">{client.iban}</span>
							<button onClick={ this.manageBankAccounts } className="microbutton">{ I18n.t('Manage') }</button>
						</span>
					</p>
				)
			}

		return (
			<section className="client">
				<header className="client-header data-main-header">
					<h2>
						{client.name}

						<ContextMenu items={ contextMenu } />
					</h2>

					<div className="client-status">
						{ this.renderStatusToInvoice() }
						{ this.renderStatusDue() }
						{ this.renderStatusInvoiced() }
					</div>
					
					<div className="section-details">
						<div className="invoice-info">
							<p>
								<span className="client-data">
									<span className="client-data-label"><Glyphicon icon="envelope" /></span>
									<span className="client-data-value">{client.address_formatted}</span>
								</span>
							</p>

							<p>
								<span className="client-data">
									<span className="client-data-label">{ I18n.t('VAT') }:</span>
									<span className="client-data-value">{client.vat_num}</span>
								</span>
							</p>

							<p>
								<span className="client-data">
									<span className="client-data-label">{ I18n.t('Hourly fee') }:</span>
									<span className="client-data-value">
										<Currency amount={ client.hourly_fee } />
									</span>
								</span>
							</p>
						</div>

						<div className="contact-info">
							<p>
								<span className="client-data">
									<span className="client-data-label"><Glyphicon icon="user" /></span>
									<span className="client-data-value">
										<strong>{client.contact_name}</strong><br />
										<a href={`mailto:${client.contact_email}`}>{client.contact_email}</a><br />
										<a href={`tel:${client.contact_phone}`}>{client.contact_phone}</a>
									</span>
								</span>
							</p>

							{ IBANData }
						</div>
					</div>
				</header>

				<div className="data-main-related-data">
					<nav className="data-main-nav">
						<ul className="menu">
							<li><button onClick={ this.onTabClick } data-tab="jobs" className={ this.getActiveClassName('jobs') }>{ I18n.t('Jobs') }</button></li>
							<li><button onClick={ this.onTabClick } data-tab="invoices" className={ this.getActiveClassName('invoices') }>{ I18n.t('Invoices') }</button></li>
							<li><button onClick={ this.onTabClick } data-tab="quotes" className={ this.getActiveClassName('quotes') }>{ I18n.t('Quotes') }</button></li>
							<li><button onClick={ this.onTabClick } data-tab="credit-notes" className={ this.getActiveClassName('credit-notes') }>{ I18n.t('Credit notes') }</button></li>
						</ul>
					</nav>

					{ dataList }
				</div>
			</section>
		);
	}
};

Client.propTypes = {
	client: PropTypes.object.isRequired,
	readyToInvoice: PropTypes.number.isRequired,
	invoiced: PropTypes.number.isRequired
};

const mapStateToProps = (state, ownProps) => {
	const { isFetching, clients, jobs, invoices, user } = state.data || { isFetching: true, clients: [], jobs: [] , invoices: [], user: {} };

	if( isFetching || clients.length === 0 )
		return { client: {}, isFetching };

	const client = clients.find( c => parseInt(c.id, 10) === parseInt(ownProps.match.params.id, 10) );
	const closedJobs = jobs.filter(j => client.id === j.client_id && j.status === 'CLOSED');
	let readyToInvoice = 0;
	closedJobs.forEach(cj => {
		readyToInvoice += cj.amount;
	});


	let invoiced = 0;
	const clientInvoices = invoices.filter( i => client.id === i.client_id && i.type === 'INVOICE' );
	clientInvoices.forEach(i => {
		invoiced += i.amount_with_taxes;
	});

	return {
		client,
		isFetching,
		readyToInvoice,
		invoiced,
		user
	}
}



const mapDispatchToProps = (dispatch) => {
	return {
		getForm: (title, client) => {
			const module = <EditClient client={client} />;
			dispatch(openOverlay( title, module ));
		},

		getUsersForm: (client) => {
			const title = I18n.t('Client accounts');
			const module = <EditClientUsers client={client} />;
			dispatch(openOverlay( title, module ));
		},

		bankAccountManager: (client) => {
			dispatch(openOverlay( I18n.t('Bank accounts'), <BankAccounts client={client.id} /> ));
		},

		createInvoiceForm: (title, invoice) => {
			const module = <EditInvoice invoice={invoice} />;
			dispatch(openOverlay( title, module ));
		},

		createJobForm: (job) => {
			const title = I18n.t('Create job');
			const module = <EditJob job={job} />;
			dispatch(openOverlay(title, module))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Client);
