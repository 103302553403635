export const InvoiceStatus = {
	DRAFT: 'DRAFT',
	OPEN: 'OPEN',
	CLOSED: 'CLOSED',
	RAPPEL: 'RAPPEL'
};


export const InvoiceType = {
	INVOICE: 'INVOICE',
	QUOTE: 'QUOTE',
	CREDIT_NOTE: 'CREDIT_NOTE',
};


export const InvoicesDiscountTypes = {
	RELATIVE: 'REL',
	ABSOLUTE: 'ABS'
};
