import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import DateTime from '../../../components/common/DateTime';
import Currency from '../../../components/common/Currency';
import ItemOptions from '../../../components/common/ItemOptions';
import EditInvoice from './EditInvoice';
import ConvertQuote from './ConvertQuote';
import Avatar from '../../users/components/Avatar';
import I18n from '../../../locales/I18n';
import { openOverlay, openDocumentViewer } from '../../../UIActions';
import { doSaveInvoice, doDeleteInvoice, doCreateJobFromQuote } from '../InvoicesActions';
import { InvoiceStatus } from '../InvoicesConsts';
import { UserRole, userRoleIs } from '../../users/UsersConsts';


class Quote extends React.Component {

	constructor(props) {
		super(props);

		//this.mode = 'NORMAL';
		this.mode = 'ADVANCED';
	}

	editForm = (e) => {
		e.preventDefault();
		const quote = { id: this.props.quote.id };
		this.props.getForm(I18n.t('Edit quote'), quote);
	}


	updateStatus = event => {
		const invoice = this.props.quote;
		if( invoice.status ===  InvoiceStatus.CLOSED )
			return null;

		const status = invoice.status ===  InvoiceStatus.DRAFT ?  InvoiceStatus.OPEN :  InvoiceStatus.CLOSED;
		const invoiceForm = new FormData();

		invoiceForm.append('id', invoice.id);
		invoiceForm.append('action', 'updateStatus');
		invoiceForm.append('status', status);

		this.props.onStatusChange(invoiceForm);
	}


	doDuplicate = () => {
		const quote = Object.assign({}, this.props.quote, { id: null, date_memo: null });
		this.props.getForm(I18n.t('Create quote'), quote);
	}


	doConvert = () => {
		const quote = this.props.quote;

		if( quote.quote_single_job ) {
			const quoteData = new FormData();
			quoteData.append('id', quote.id);
			this.props.onConvert(quote.id, quoteData);
			// then redirect to jobs pane (if success)
			return;
		}

		// show a modal window with job list and checkboxes
		this.props.getMultiConvertForm(quote);
	}



	doDelete = () => {
		if( window.confirm( I18n.t('Are you sure?')) )
			this.props.onDelete({ id: this.props.quote.id });
	}


	viewDocument = (e) => {
		e.preventDefault();

		const invoice = this.props.quote;

		const options = [
			{
				label: I18n.t('Download'),
				url: invoice.pdf
			},
			{
				label: I18n.t('Print'),
				url: invoice.print_version
			}
		];

		this.props.viewDocument(invoice.html, options);
	}



	getItemOptions = () => {
		const invoice = this.props.quote;

		const options = [
			{
				label: I18n.t('View'),
				action: this.viewDocument,
				icon: 'open'
			}
		];

		if( invoice.status !==  InvoiceStatus.CLOSED && userRoleIs(this.props.user, [UserRole.MANAGER]) ) {
			options.push({
				label: (invoice.status ===  InvoiceStatus.DRAFT) ? I18n.t('Mark as open') : I18n.t('Close quote'),
				action: this.updateStatus,
				icon: 'ok'
			});
		}

		if( invoice.status ===  InvoiceStatus.DRAFT || (this.mode === 'ADVANCED' && userRoleIs(this.props.user, [UserRole.MANAGER])) ) {
			options.push({
				label: I18n.t('Edit'),
				action: this.editForm,
				icon: 'edit'
			});
		}

		if( invoice.status ===  InvoiceStatus.OPEN ) {
			options.push({
				label: I18n.t('Convert to job'),
				action: this.doConvert,
				icon: 'flash'
			});
		}


		if( invoice.status !==  InvoiceStatus.DRAFT ) {
			options.push({
				label: I18n.t('Duplicate'),
				action: this.doDuplicate,
				icon: 'duplicate'
			});
		}


		if( userRoleIs(this.props.user, [UserRole.MANAGER]) && (invoice.status ===  InvoiceStatus.DRAFT || (invoice.status ===  InvoiceStatus.OPEN && this.mode === 'ADVANCED')) ) {
			options.push({
				label: I18n.t('Delete'),
				action: this.doDelete,
				icon: 'trash'
			});
		}

		return options;
	}






	render() {
		const { quote, client } = this.props;
		const quoteId = quote.iid || '{DRAFT}';

		const classNames = ['item', 'invoice', 'quote', `quote-${quote.id}`, `${quote.status.toLowerCase()}` ];
		const creator = this.props.users.find(u => parseInt(u.id, 10) === parseInt(quote.created_by, 10));
		const avatar = creator ? <Avatar user={ creator } size="small" /> : '';

		return (
			<li className="data-list-item">
				<div className={ classNames.join(' ') } id={`invoice-${quote.id}`} data-id={quote.id}>
					<div className="item-main-col">
						<div className="item-client">
							<Link to={`/incomes/clients/view/${client.id}`}>{client.short_name}</Link>
						</div>

						<div className="item-title">
							<button className="link-like" onClick={ this.viewDocument }>{quoteId}: {quote.quote_title}</button>
						</div>
					</div>

					<div className="item-col-users">
						{ avatar }
					</div>

					<div className="item-col-date">
						<DateTime date={quote.date_memo} format="dd mmm yyyy" />
					</div>

					<div className="item-col-amount col-amount-taxexcl">
						<div className="amount">
							<Currency amount={quote.amount} />
						</div>
					</div>

					<ItemOptions options={ this.getItemOptions() } />
				</div>
			</li>
		)
	}
};

Quote.propTypes = {
	quote: PropTypes.object.isRequired,
	client: PropTypes.object.isRequired,
	onStatusChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	getForm: PropTypes.func.isRequired,
	viewDocument: PropTypes.func.isRequired,
	users: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapStateToProps = (state, ownProps) => {
	const { clients, searchTerms, users, user } = state.data || { clients: [], searchTerms: false, users: [] };
	const client = clients.find(c => parseInt(c.id, 10) === parseInt(ownProps.quote.client_id, 10)) || {}
	return { client, searchTerms, users, user }
}


const mapDispatchToProps = (dispatch) => {
	return {

		viewDocument: (url, options) => {
			dispatch(openDocumentViewer(url, options));
		},

		getForm: (title, quote) => {
			const module = <EditInvoice invoice={quote} />;
			dispatch(openOverlay( title, module ));
		},


		getMultiConvertForm: (quote) => {
			const title = I18n.t('Select item(s) to convert');
			const module = <ConvertQuote quote={quote} />;
			dispatch(openOverlay( title, module ));
		},

		onStatusChange: (quote) => {
			dispatch(doSaveInvoice(quote))
		},

		onDelete: (quote) => {
			dispatch(doDeleteInvoice(quote))
		},

		onConvert: (id, data) => {
			dispatch(doCreateJobFromQuote(id, data))
		}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Quote);
