import React, { Fragment } from 'react';
import Header from './Header';
import Jobs from '../../modules/jobs/components/Jobs';


class JobsPane extends React.Component {

	render() {
		return (
			<Fragment>
				<Header />
				<Jobs type="OPEN" />
			</Fragment>
		)
	}

}

export default JobsPane;
