import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataList from '../../../components/containers/DataList';
import getVisibleIncomes from '../filters/VisibleIncomes';
import Quote from './Quote';
import I18n from '../../../locales/I18n';
import EditInvoice from './EditInvoice';
import { openOverlay } from '../../../UIActions';


class QuotesList extends React.Component {

	createQuote = e => {
		e.preventDefault();
		const title = I18n.t('Create quote');
		this.props.createInvoiceForm(title, { type: 'QUOTE', client_id: this.props.client });
	}

	render() {

		const { isFetching, quotes } = this.props;
		let dataListOptions = '';

		if( isFetching )
			return <div className="message-empty">{ I18n.t('Please wait while loading...') }</div>

		if( ! quotes.length )
			return <div className="message-empty">{ I18n.t('No document found') }. <button className="link-like" onClick={this.createQuote}>{ I18n.t('Create new ?') }</button></div>

		if(this.props.client) {
			// show add link
			dataListOptions = (
				<div className="data-list-options">
					<button className="link-like" onClick={this.createQuote}>{ I18n.t('Create new ?') }</button>
				</div>
			);
		}


		return (
			<div>
				{ dataListOptions }
                <DataList
                    data={ quotes }
                    keyExtractor={ (item) => item.id }
                    renderItem={ ({ item }) => <Quote quote={ item } /> }
                />
			</div>
		);
	}
};

QuotesList.propTypes = {
	quotes: PropTypes.arrayOf(PropTypes.object).isRequired,
	client: PropTypes.number
	//quotes: PropTypes.object.isRequired,
	//creditNotes: PropTypes.object.isRequired,
};



const mapStateToProps = (state, ownProps) => {
	const { isFetching } = state.data || { isFetching: true }

	if( isFetching || state.data.invoices.length === 0 )
		return { quotes: [], isFetching };

	return {
		quotes: getVisibleIncomes(state.data, 'QUOTE', ownProps.period, ownProps.view, ownProps.client),
		isFetching
	};
}

/*
const mapStateToProps = (state, ownProps) => {
	const TYPE = 'QUOTE';
	const { data } = state;
	const { isFetching, invoices } = data || { isFetching: true, invoices: [] }

	if( isFetching || invoices.length === 0 )
		return { quotes: [], isFetching };

	return {
		quotes: invoices.filter(inv => {
			const isQuote = inv.type === TYPE;
			const date_memo = inv.date_memo.slice(0,10);
			const isPeriod = ownProps.period ? date_memo >= ownProps.period[0] && date_memo <= ownProps.period[1] : true;
			const isClient = ownProps.client ? ownProps.client === parseInt(inv.client_id, 10) : true;
			const isOpen = ownProps.view && ownProps.view === 'open' ? inv.status === 'OPEN' : true;
			const isClosed = ownProps.view && ownProps.view === 'closed' ? inv.status === 'CLOSED' : true;
			return isQuote && isPeriod && isClient && isOpen && isClosed;
		}),
		isFetching
	}
}
*/
const mapDispatchToProps = (dispatch) => {
	return {
		createInvoiceForm: (title, invoice) => {
			const module = <EditInvoice invoice={invoice} />;
			dispatch(openOverlay( title, module ));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotesList);
