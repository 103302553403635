import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import Header from './Header';
import Profile from '../../modules/users/components/Profile';

class ProfilePane extends React.Component {

	render() {
		return (
			<Fragment>
				<Header />
				<Route exact path="/profile" component={Profile} />
			</Fragment>
		)
	}
}

export default ProfilePane;
