import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Glyphicon from './Glyphicon';
import I18n from '../../locales/I18n';
import { doSearch } from '../../UIActions';

class SearchBox extends React.Component {

	constructor(props) {
		super(props);
		this.requestTimer = null;
		this.state = { terms: this.props.terms };
	};

	performSearch = event => {
		event.preventDefault();

		const terms = event.target.value || false;
		this.setState({ terms });

		this.requestTimer = setTimeout(() => {
			this.props.performSearch(terms);
			clearTimeout(this.requestTimer);
		},500);
	}


	render() {
		const currentValue = this.state.terms ? this.state.terms : '';
		return (
			<div className="search-box">
      			<input placeholder={ I18n.t('Search...') } name="search" value={ currentValue } onChange={ this.performSearch } />
				<Glyphicon icon="search" />
    		</div>
		);
	}
};

SearchBox.propTypes = {
	performSearch: PropTypes.func.isRequired
};


const mapStateToProps = (state, ownProps) => {
	return {
		terms: state.data.searchTerms
	};
}


const mapDispatchToProps = (dispatch) => {
	return {
		performSearch: terms => {
			dispatch(doSearch(terms));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
