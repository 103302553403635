import getStore from '../ExpressoStore';
const store = getStore();


class I18nTranslator {

	translate = (msgid) => {
		const state = store.getState();
		const message = state.locale.messages.find(m => m.msgid === msgid);
		return message ? message.msgstr : msgid;
	}
};



const _i18n = new I18nTranslator();

export default class I18n {
	static t = (msgid) => {
		return _i18n.translate(msgid);
	}

	static r = (msgid, ...props) => {
		const mask = /\{([0-9]+)\}/g;
		let msg = _i18n.translate(msgid);

		if( msg.match(mask) ) {
			let i = 0;
			let val;

			msg = msg.replace(mask, (match, p1) => {
				val = props[i];
				i++;
				return val;
			});
		}

		return msg;
	}
}
