export const ExpenseStatus = {
	OPEN: 'OPEN',
	CLOSED: 'CLOSED'
};


export const ExpenseType = {
	INVOICE: 'INVOICE',
	FEE: 'FEE',
	CREDIT_NOTE: 'CREDIT_NOTE',
};
