import I18n from '../../locales/I18n';

export const JobStatus = {
	OPEN: 'OPEN',
	CLOSED: 'CLOSED',
	INVOICED: 'INVOICED',
};


export const JobType = {
	FIX: 'FIX',
	HOURLY: 'HOURLY',
	ITEM: 'ITEM',
	FLAT_RATE: 'FLAT_RATE',
	RECURRENT: 'RECURRENT',
};

export const JobRecurrenceOptions = [
	{ label: I18n.t('Never'), value: '0' },
	{ label: I18n.t('Each year'), value: '1 year' },
	{ label: I18n.t('Each month'), value: '1 month' },
];

export const JobStatusOptions = [
	{ value: JobStatus.OPEN, label: I18n.t('Open') },
	{ value: JobStatus.CLOSED, label: I18n.t('Closed') },
	{ value: JobStatus.INVOICED, label: I18n.t('Invoiced') }
];

export const JobTypeOptions = [
	{ value: JobType.HOURLY, label: I18n.t('Hourly rate') },
	{ value: JobType.ITEM, label: I18n.t('Unit price') },
	{ value: JobType.FIX, label: I18n.t('Fixed price') },
	{ value: JobType.FLAT_RATE, label: I18n.t('Flat rate') }
];
