import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from '../../../locales/I18n';
import { closeOverlay, removeOverlayBodyClass } from '../../../UIActions';
import { doSaveBankAccount } from '../BankAccountsActions';
import { BANKACCOUNT_SAVED } from '../../../AppEvents';


class EditBankAccount extends React.Component {

	/*
	 * When modifiyng the item, just update local state.
	 * The global state is only updated when saving data ***
	 */
	constructor(props) {
		super(props);
		this.state = { account: props.account };
	}


	componentDidUpdate() {
		const { lastAction, account } = this.props;

		//this.setState({ account: account });

		if( lastAction.type === BANKACCOUNT_SAVED && lastAction.success ) {
			removeOverlayBodyClass();
			const timer = setTimeout(() => {
				this.props.doClose();
				clearTimeout(timer);
			}, 500);
		}
	}


	/*
 	 * When user saves account ...
	 */
	save = (e) => {
		e.preventDefault();
		if( this.props.isSaving )
			return;

		const account = new FormData(document.getElementById('account-edit'));
		this.props.onSave(account);
	}


	changeClient = event => {
		const account = Object.assign({}, this.state.account, { client_id: event.value });
		this.setState({ account });
		return event.value;
	};



	onChangedVoid = event => {

	}

	renderSubmit = () => {
		if( this.props.isSaving )
			return <button type="submit" className="is-saving">{ I18n.t('Saving...') }</button>

		return <button type="submit">{ I18n.t('Save') }</button>
	}

	render() {
		const account = this.state.account;


		return (
			<div className="accounts form">
				<form method="post" acceptCharset="utf-8" id="account-edit" onSubmit={this.save}>
					<fieldset>
						<div className="input text">
							<label htmlFor="bank">{ I18n.t('Bank') }</label>
							<input type="text" name="bank" maxLength="40" id="bank" defaultValue={account.bank} />
						</div>

						<div className="input text">
							<label htmlFor="account-name">{ I18n.t('Name') }</label>
							<input type="text" name="account_name" maxLength="100" id="account-name" defaultValue={account.account_name} required />
						</div>

						<div className="input text">
							<label htmlFor="iban">{ I18n.t('IBAN') }</label>
							<input type="text" name="iban" maxLength="60" id="iban" defaultValue={account.iban} required pattern="^[A-Z]{2}[0-9 ]+$" />
						</div>

						<div className="input text">
							<label htmlFor="bic">{ I18n.t('BIC') }</label>
							<input type="text" name="bic" maxLength="20" id="bic" defaultValue={account.bic} />
						</div>

					</fieldset>


					<div className="form-controls">
						<input type="hidden" name="id" value={account.id} readOnly />
						<input type="hidden" name="client_id" value={account.client_id} readOnly />
						{ this.renderSubmit() }
					</div>
				</form>
			</div>
		);
	}
};

EditBankAccount.propTypes = {
	clients: PropTypes.arrayOf(PropTypes.object).isRequired,
	tax_rates: PropTypes.arrayOf(PropTypes.object).isRequired,
	job: PropTypes.object,
	lastAction: PropTypes.object.isRequired,
	defaultFee: PropTypes.number,
	isSaving: PropTypes.bool.isRequired
};



const mapStateToProps = (state, ownProps) => {
	const { tax_rates, clients, user, lastAction, isSaving } = state.data || { tax_rates: [], clients: [], user: {}, lastAction: {}, isSaving: false };
	const company = clients.find(c => c.id === user.company_id && c.is_self);
	const defaultFee = company ? parseFloat(company.hourly_fee) : 50;

	return {
		tax_rates,
		clients,
		defaultFee,
		lastAction,
		isSaving
	};
}


const mapDispatchToProps = (dispatch) => {
	return {
		onSave: (account) => {
			dispatch(doSaveBankAccount(account));
		},

		doClose: () => {
			dispatch(closeOverlay());
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBankAccount);
