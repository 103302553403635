import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SectionHeader from '../../../components/common/SectionHeader';
import FabButton from '../../../components/common/FabButton';
import BankStatementImporter from '../../../components/forms/BankStatementImporter';
import TransactionsTotalAmount from './TransactionsTotalAmount';
import TransactionsList from './TransactionsList';
import AddTransaction from './AddTransaction';
import ImportTransaction from './ImportTransaction';
import SelectBox from '../../../components/forms/SelectBox';
import ValidateTransaction from './ValidateTransaction';
import I18n from '../../../locales/I18n';
import { openOverlay } from '../../../UIActions';
import { doImportCoda } from '../TransactionsActions';



class Transactions extends React.Component {

	constructor(props) {
		super(props);

		const defaultBankAccount = null;//props.bankAccounts.find(a => a.is_default);

		this.state = {
			afterUpload: false,
			currentBankAccountId: defaultBankAccount,
		};
	}

	componentDidMount() {
		const dropzone = document.getElementById('dropzone-transactions-main');
		//dropzone.style.display = 'none';

		if(dropzone) {
			document.getElementById('main').addEventListener('dragover',  event => {
				event.preventDefault();
				dropzone.classList.add('shown');
			}, false);

			dropzone.addEventListener('dragleave',  event => {
				event.preventDefault();
				dropzone.classList.remove('shown');
			}, false);

			document.addEventListener('drop', event => {
				// prevent default action (open as link for some elements)
				event.preventDefault();
				if(! event.target.classList.contains('dropzone') ) {
					dropzone.classList.remove('shown');
				}
			});
		}
	}


	componentDidUpdate(/*prevProps, prevState*/) {
		if( this.state.afterUpload ) {
			const total = this.props.waitingTransactions.length;
			//const prevTotal = prevProps.waitingTransactions.length;
			//console.log(total, prevTotal);

			if( total > 0 ) {
				this.props.validateForm({ bank_account_id: null });
			}

			const dropzone = document.getElementById('dropzone-transactions-main');
			dropzone.classList.remove('shown');
			dropzone.classList.remove('loading');

			this.setState({ afterUpload: false });
		}
	}

	addTransaction = event => {
		event.preventDefault();
		this.props.getForm({ bank_account_id: this.state.currentBankAccountId });
	};

	importTransaction = event => {
		event.preventDefault();
		this.props.importForm();
	};

	validateTransaction = event => {
		event.preventDefault();
		this.props.validateForm({ bank_account_id: this.state.currentBankAccountId });
	};

	onFileDrop = (acceptedFiles, rejectedFiles) => {
		this.props.onSendFiles(acceptedFiles);
		// When re-render after refreshed state with imported transactions, render <ValidateTransaction />
		this.setState({ afterUpload: true });
	}


	handleBankAccountChange = event => {
		const aid = event.target.value === '0' ? null : parseInt(event.target.value);
		this.setState({ currentBankAccountId: aid });
	}


	renderAccountsSelector = () => {
		if( this.props.bankAccounts.length < 2 ) {
			return null;
		}

		let options = [{
			value: 0,
			label: I18n.t('All Bank Accounts'),
		}];

		this.props.bankAccounts.forEach(a => {
			options.push({
				value: a.id,
				label: a.account_name,
			});
		});

		return (
			<li>
				<div className="input select">
				<SelectBox
					name="current_bank_account"
					options={ options }
					onChange={ this.handleBankAccountChange }
					value={ this.state.currentBankAccountId || 0 }
				/>
				</div>
			</li>
		);
	}



	/*
	 * Render badge with total waiting transactions, for current bank account only
	 */
	validateTransactionButton = () => {
		const bankAccount = this.state.currentBankAccountId;

		let total = this.props.waitingTransactions.length;
		if( total === 0 ) {
			return null;
		}

		if( bankAccount !== null ) {
			const wt =  this.props.waitingTransactions.filter(t => parseInt(t.bank_account_id, 10) === this.state.currentBankAccountId );
			total = wt.length;
			if( total === 0 ) {
				return null;
			}
		}

		return (
			<li>
				<button
					onClick={ this.validateTransaction }
					className="icon circle-icon transactions-counter"
					title={ I18n.t('Validate transactions') }
				>{ total }</button>
			</li>
		);
	}



	render() {
		const bankAccount = this.state.currentBankAccountId;

		return (
			<main id="main">
				<SectionHeader type="transactions" title={ I18n.t('Transactions') } />

				<div className="action-bar">
					<ul className="actions-filters">
						{ this.renderAccountsSelector() }
						{ this.validateTransactionButton() }
					</ul>

					<TransactionsTotalAmount bankAccount={ bankAccount } />
				</div>

				<div id="data" className="nano">
					<div className="nano-content">
						<TransactionsList bankAccount={ bankAccount } />
					</div>
				</div>

				<BankStatementImporter id="dropzone-transactions-main" onDrop={ this.onFileDrop.bind(this) } />

				<FabButton
					onClick={ this.addTransaction }
					title={ I18n.t('Add new') }
				/>

				<FabButton
					onClick={ this.importTransaction }
					title={ I18n.t('Import file') }
					icon="open"
					className="btn-fab-secondary"
				/>
			</main>
		)
	};
};

Transactions.propTypes = {
	waitingTransactions: PropTypes.arrayOf(PropTypes.object).isRequired,
	bankAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapStateToProps = (state, ownProps) => {
	const { transactions, company } = state.data || { transactions: [], company: {} }
	const bankAccounts = company.bank_accounts
	const waitingTransactions = transactions.filter(tr => ! tr.validated);
	return {
		waitingTransactions,
		bankAccounts
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		getForm: ({ bank_account_id }) => {
			const module = <AddTransaction bank_account_id={ bank_account_id } />;
			dispatch(openOverlay( I18n.t('Add transaction'), module ));
		},

		importForm: () => {
			dispatch(openOverlay( I18n.t('Import bank statement(s)'), <ImportTransaction />));
		},

		validateForm: ({ bank_account_id }) => {
			dispatch(openOverlay(I18n.t('Validate transactions'), <ValidateTransaction bank_account_id={ bank_account_id } />));
		},

		onSendFiles: (files) => {
			const importData = new FormData();
			files.forEach(file => {
				importData.append('files[]', file, file.name);
			})

			dispatch(doImportCoda(importData));
		}
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
