import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import I18n from '../../locales/I18n';
import { fetchData, syncData, isSessionActive } from '../../modules/users/UsersActions';
import { fetchLocale } from '../../locales/LocaleActions';
import Loader from '../common/Loader';
import FlashMessage from '../common/FlashMessage';
import Login from '../../modules/users/components/Login';
import DashboardPane from './DashboardPane';
import JobsPane from './JobsPane';
import IncomesPane from './IncomesPane';
import OutcomesPane from './OutcomesPane';
import TransactionsPane from './TransactionsPane';
import CompanyPane from './CompanyPane';
import ProfilePane from './ProfilePane';
import Overlay from './Overlay';
import DocumentViewer from './DocumentViewer';
import NotFoundException from '../exceptions/NotFoundException';
import ActiveJobSession from '../../modules/job_sessions/components/ActiveJobSession';
import { userRoleIs, UserRole } from '../../modules/users/UsersConsts';

import {
	CLIENT_SAVED,
	JOB_SAVED,
	JOB_DELETED,
	JOBS_INVOICED,
	INVOICE_SAVED,
	INVOICE_DELETED,
	INVOICE_SENT,
	JOB_FROM_QUOTE_CREATED,
	EXPENSE_SAVED,
	EXPENSE_DELETED,
	TRANSACTION_SAVED,
	TRANSACTION_DELETED,
	USER_SAVED,
	USER_DELETED,
	BANKACCOUNT_SAVED,
	BANKACCOUNT_DELETED,
	SETTINGS_SAVED
} from '../../AppEvents';



const MatchWithFade = ({ component:Component, ...rest }) => (
	<Route {...rest} render={(matchProps) => (
		<Component {...matchProps} />
	)}/>
);

const SECOND = 1000;
const MINUTE = SECOND * 60;


class Base extends React.Component {

	constructor(props) {
		super(props);

		const { dispatch } = props;

		this.syncTimer = null;

		/*try to sync data from distant DB each 30 seconds
		syncTimer = setInterval(() => {
			dispatch(syncData());
		}, 30 * SECOND);
		*/

		setInterval( () => {
			dispatch(isSessionActive());
		}, 20 * MINUTE);

		// or when window receive focus
		 window.addEventListener('focus', () => {
			 dispatch(isSessionActive());
		 });

		 // Inactive
		 //window.addEventListener('blur', stopTimer);
	}

	componentDidMount() {
		const { dispatch, user } = this.props;
		dispatch(fetchLocale());
		if( user.id ) {
			dispatch(fetchData());
			this.enableSync();
		}
		else
			this.disableSync();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { dispatch, user } = nextProps;
		if( user.id ) {
			dispatch(fetchData());
			this.enableSync();
		}
		else
			this.disableSync();
	}


	enableSync = () => {
		if(this.syncTimer)
			return;

		const { dispatch } = this.props;
		this.syncTimer = setInterval(() => {
			dispatch(syncData());
		}, 30 * SECOND);
	}

	disableSync = () => {
		if(! this.syncTimer)
			return;
		clearInterval(this.syncTimer);
		this.syncTimer = null;
	}



	getMessageForFlash = () => {
		const flashMessage = this.props.flashMessage;
		const action = flashMessage.action;

		if( flashMessage.message )
			return flashMessage.message;

		let message;
		switch( action.type ) {
			// Clients
			case CLIENT_SAVED:
				let clientLabel;
				// if is client
				if( action.client.is_client )
					clientLabel = I18n.t('Client');
				// or is company
				else if( action.client.is_self )
					clientLabel = I18n.t('Company info');
				// otherwise is provider
				else
					clientLabel = I18n.t('Provider');

				return I18n.r('{0} was saved', clientLabel);


			// Jobs
			case JOB_SAVED:
				return I18n.r('{0} was saved', I18n.t('Job'));

			case JOB_DELETED:
				return I18n.r('{0} was deleted', I18n.t('Job'));

			case JOBS_INVOICED:
				return (
					<React.Fragment>
						{I18n.t('Invoice was created')}
						<Link to="/incomes/invoices">{ I18n.t('Go to invoices') }</Link>
					</React.Fragment>
				);



			// Invoices
			case INVOICE_SAVED:
				if( action.invoice.type === 'CREDIT_NOTE' )
					return I18n.r('{0} was saved', I18n.t('Credit note'));

				if( action.invoice.type === 'QUOTE' )
					return I18n.r('{0} was saved', I18n.t('Quote'));

				return I18n.r('{0} was saved', I18n.t('Invoice'));

			case INVOICE_DELETED:
				return I18n.r('{0} was deleted', I18n.t('Document'));

			case INVOICE_SENT:
				return I18n.r('Invoice was sent to {0}', action.to);

			case JOB_FROM_QUOTE_CREATED:
				const jobs = action.jobs;
				if( jobs.length > 1 )
					message = I18n.r('{0} jobs were created', jobs.length);
				else
					message = I18n.t('Job was created');

				return (
					<span>
						{message} <Link to="/jobs">{ I18n.t('Go to jobs') }</Link>
					</span>
				);


			// Expenses
			case EXPENSE_SAVED:
				if( action.expense.type === 'CREDIT_NOTE' )
					return I18n.r('{0} was saved', I18n.t('Credit note'));

				if( action.expense.type === 'FEE' )
					return I18n.r('{0} was saved', I18n.t('Fee'));

				return I18n.r('{0} was saved', I18n.t('Invoice'));

			case EXPENSE_DELETED:
				return I18n.r('{0} was deleted', I18n.t('Document'));



			// Transactions
			case TRANSACTION_SAVED:
				return I18n.r('{0} was saved', I18n.t('Transaction'));

			case TRANSACTION_DELETED:
				return I18n.r('{0} was deleted', I18n.t('Transaction'));



			// Users
			case USER_SAVED:
				return I18n.r('{0} was saved', I18n.t('User'));

			case USER_DELETED:
				return I18n.r('{0} was deleted', I18n.t('User'));




			// Accounts
			case BANKACCOUNT_SAVED:
				return I18n.r('{0} was saved', I18n.t('Account'));

			case BANKACCOUNT_DELETED:
				return I18n.r('{0} was deleted', I18n.t('Account'));



			// Settings
			case SETTINGS_SAVED:
				return I18n.r('{0} were saved', I18n.t('Settings'));


			default:
				return null;
		}

	}


	renderFlash = () => {
		const flashMessage = this.props.flashMessage;
		if(! flashMessage)
			return <FlashMessage message={ null } />;

		const message = this.getMessageForFlash();
		if(! message )
			return <FlashMessage message={ null } />;

		return <FlashMessage type={ flashMessage.type } message={ message } />
	}



	renderRoutes = () => {
		const { dbUser } = this.props;
		if( userRoleIs(dbUser, [UserRole.MANAGER, UserRole.ACCOUNTANT] )) {
			return (
				<React.Fragment>
					<Route exact path="/" component={DashboardPane} />
					<MatchWithFade path="/jobs" component={JobsPane} />
					<MatchWithFade path="/incomes" component={IncomesPane} />
					<MatchWithFade path="/outcomes" component={OutcomesPane} />
					<MatchWithFade path="/transactions" component={TransactionsPane} />
					<MatchWithFade path="/company" component={CompanyPane} />
					<MatchWithFade path="/profile" component={ProfilePane} />
				</React.Fragment>
			);
		}

		if( userRoleIs(dbUser, [UserRole.ACCOUNT_MANAGER] )) {
			return (
				<React.Fragment>
					<Route exact path="/" component={JobsPane} />
					<MatchWithFade path="/incomes" component={IncomesPane} />
					<MatchWithFade path="/profile" component={ProfilePane} />
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<Route exact path="/" component={JobsPane} />
				<MatchWithFade path="/profile" component={ProfilePane} />
			</React.Fragment>
		);
	}


	render() {
		const { isFetching, user } = this.props;

		if(! user.id)
			return <Login />


		if( isFetching )
			return <Loader />


		return (
			<Router>
				<div id="wrapper">
					{ this.renderRoutes() }

					<Route component={NotFoundException}/>

					<Overlay />
					<DocumentViewer />
					<ActiveJobSession />

					{ this.renderFlash() }

				</div>
			</Router>
		);
	};

}


const mapStateToProps = (state, ownProps) => {
	let isFetching = state.data.isFetching || state.locale.isFetching || state.user.isFetching;
	if(! state.data.lastUpdated)
		isFetching = true;

	const { flashMessage } = state.ui || { flashMessage: null };
	const messages = state.locale.messages; // juste in case: we set this as a prop in order to re-render all when locale changes.
	const user = state.user.user || {};
	const dbUser = state.data.user || {};

	return {
		user,
		dbUser,
		messages,
		isFetching,
		flashMessage
	}
}


export default connect(mapStateToProps)(Base);
