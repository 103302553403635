import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import I18n from '../../locales/I18n';
import SearchBox from './SearchBox';
import Glyphicon from './Glyphicon';
import ImportTransaction from '../../modules/transactions/components/ImportTransaction';
import { openOverlay } from '../../UIActions';
import { UserRole, userRoleIs } from '../../modules/users/UsersConsts';



class SectionHeader extends React.Component {
	constructor(props) {
		super(props);
		this.navClassName = 'context-menu-shown';
	};


	getSectionNavItems = () => {
		switch(this.props.type) {
			case 'outcomes':
				return {
					providers: { label: I18n.t('Providers'), url: '/outcomes/providers' },
					invoices: { label: I18n.t('Expenses'), url: '/outcomes' },
					fees: { label: I18n.t('Fees'), url: '/outcomes/fees' },
					creditnotes: { label: I18n.t('Credit Notes'), url: '/outcomes/credit-notes' },
				};


			case 'transactions':
				return {
					transactions: { label: I18n.t('Transactions'), url: '/transactions' },
					import: { label: I18n.t('Import CODA'), action: this.importTransaction },
				};


			case 'incomes':
				if( userRoleIs(this.props.user, [UserRole.MANAGER, UserRole.ACCOUNTANT]) )
					return {
						jobs: { label: I18n.t('Jobs'), url: '/jobs' },
						clients: { label: I18n.t('Clients'), url: '/incomes/clients' },
						invoices: { label: I18n.t('Invoices'), url: '/incomes/invoices' },
						quotes: { label: I18n.t('Quotes'), url: '/incomes/quotes' },
						creditnotes: { label: I18n.t('Credit Notes'), url: '/incomes/credit-notes' },
					};

				if( userRoleIs(this.props.user, [UserRole.ACCOUNT_MANAGER]) )
					return {
						jobs: { label: I18n.t('Jobs'), url: '/' },
						quotes: { label: I18n.t('Quotes'), url: '/incomes/quotes' },
					};

				return {};

			default:
				return {};
		}
	}




	importTransaction = event => {
		event.preventDefault();
		this.props.importTransactionForm();
		this.closeNav();
	}


	toggleSidebar = event => {
		document.body.classList.toggle('show-sidebar');
	}


	closeNav = () => {
		const header = document.getElementById('section-header');
		const menuWrapper = header.getElementsByClassName('section-top-selector')[0];
		if(! menuWrapper)
			return;

		menuWrapper.classList.remove(this.navClassName);
	}



	toggleNav = event => {
		event.preventDefault();
		const pageTitle = event.target.closest('.page-title');
		const menuWrapper = pageTitle.getElementsByClassName('section-top-selector')[0];
		if(! menuWrapper)
			return;

		if( ! menuWrapper.classList.contains(this.navClassName) ) {
			const activeContextMenus = document.querySelectorAll(`.${this.navClassName}`);
			if(activeContextMenus.length > 0) {
				activeContextMenus.forEach( acm => {
					acm.classList.remove(this.navClassName);
				});
			}
		}

		menuWrapper.classList.toggle(this.navClassName);
	}



	renderSectionNav = () => {
		const items = this.getSectionNavItems();
		if(Object.keys(items).length === 0)
			return null;

		const itemsList = Object
			.keys(items)
			.map(key => {
				const label = items[key].label;
				const aEl = items[key].url ? <Link to={items[key].url}>{ label }</Link> : <button className="link-like" onClick={items[key].action}>{ label }</button>;
				return <li key={`section-nav-${key}`}>{ aEl }</li>
			});

		return (
			<div className="section-top-selector">
				<button className="link-like section-top-selector-toggle context-menu-toggle-button" onClick={ this.toggleNav }>
					<Glyphicon icon="option-horizontal" />
				</button>
				<ul className="section-top-selector-list">
					{ itemsList }
				</ul>
			</div>
		);
	};




	render() {
		const searchBox = ['dashboard', 'profile', 'company'].indexOf(this.props.type) > -1 ? '' : <SearchBox />

		return (
			<header className="header" id="section-header">
				{ searchBox }


				<div className="page-title">
					<button className="link-like sidebar-toggle-btn trigger-toggle-sidebar" onClick={ this.toggleSidebar }>
						<span className="line"></span>
						<span className="line"></span>
						<span className="line"></span>
						<span className="line line-angle1"></span>
						<span className="line line-angle2"></span>
					</button>

					<h1 onClick={ this.toggleNav }>{ this.props.title }</h1>

					{ this.renderSectionNav() }
				</div>
			</header>
		)
	}
};

SectionHeader.propTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	user: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
	const { user } = state.data;
	return { user };
}


const mapDispatchToProps = (dispatch) => {
	return {
		importTransactionForm: () => {
			dispatch(openOverlay( I18n.t('Import transaction'), <ImportTransaction />));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionHeader);
