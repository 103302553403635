import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../locales/I18n';
import AsyncSelect from 'react-select/async';

import { getCountry } from '../../api';





const CountrySelect = ({ name = 'country_id', value, label = null, onChange = null }) => {

    const [ inputValue, setInputValue ] = useState( label || '' );

    const _handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        return inputValue;
    };

    const _handleChange = ( element ) => {
        if(! onChange || ! element ) {
            return;
        }
        onChange(element);
    }

    const _loadOptions = (inputValue, callback) => {
        if(! inputValue ) {
            inputValue = 'Bel';
        }
        return getCountry(inputValue).then(json => json.countries);
    };

    return (
        <div className="input select">
            <label htmlFor="country-id">{ I18n.t('Country') }</label>
            <AsyncSelect
                name={ name }
                cacheOptions
                loadOptions={ _loadOptions }
                defaultOptions
                inputValue={ inputValue }
                onChange={ _handleChange }
                onInputChange={ _handleInputChange }
            />
        </div>
    );
}

export default CountrySelect;

/*
export default class CountrySelect extends Component {


	constructor(props) {
		super(props);

		const value = props.value ? props.value : null;
		const initialInputValue = props.label ? props.label : '';
		this.state = ({ value, initialInputValue, inputValue: initialInputValue });
	}

	handleInputChange = (newValue: string) => {
		const inputValue = newValue.replace(/\W/g, '');
		this.setState({ inputValue });
		return inputValue;
	};

	handleChange = (element) => {
		if(! element )
			return;

		if(this.props.onChange)
			this.props.onChange(element);
	}

	render() {
		const {name} = this.props || { name: 'country_id' }
		return (
			<div className="input select">
				<label htmlFor="country-id">{ I18n.t('Country') }</label>
				<AsyncSelect
					name={name}
					cacheOptions
					loadOptions={loadOptions}
					defaultOptions
					inputValue={this.state.inputValue}
					onChange={this.handleChange}
					onInputChange={this.handleInputChange}
				/>
			</div>
		);
	}
}


CountrySelect.propTypes = {
	value: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func
};
*/
