import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import DateTime from '../../../components/common/DateTime';
import Currency from '../../../components/common/Currency';
import ItemOptions from '../../../components/common/ItemOptions';
import EditInvoice from './EditInvoice';
import I18n from '../../../locales/I18n';
import { openOverlay, openDocumentViewer } from '../../../UIActions';
import { doSaveInvoice, doDeleteInvoice } from '../InvoicesActions';
import { InvoiceStatus } from '../InvoicesConsts';

class CreditNote extends React.Component {


	constructor(props) {
		super(props);

		//this.mode = 'NORMAL';
		this.mode = 'ADVANCED';
	}


	updateStatus = event => {
		const invoice = this.props.cn;
		if( invoice.status ===  InvoiceStatus.CLOSED )
			return null;

		const status = invoice.status ===  InvoiceStatus.DRAFT ?  InvoiceStatus.OPEN :  InvoiceStatus.CLOSED;
		const invoiceForm = new FormData();

		invoiceForm.append('id', invoice.id);
		invoiceForm.append('action', 'updateStatus');
		invoiceForm.append('status', status);

		this.props.onStatusChange(invoiceForm);
	}


	doDelete = () => {
		if( window.confirm( I18n.t('Are you sure?')) )
			this.props.onDelete({ id: this.props.cn.id });
	}

	editForm = (e) => {
		e.preventDefault();
		const cn = { id: this.props.cn.id };
		this.props.getForm(cn);
	}


	viewDocument = (e) => {
		e.preventDefault();

		const invoice = this.props.cn;
		const byEmail = this.props.client.invoice_by_email;

		const options = [
			{
				label: I18n.t('Download'),
				url: invoice.pdf
			},
			{
				label: I18n.t('Print'),
				url: invoice.print_version
			}
		];

		if( byEmail && ! invoice.status ===  InvoiceStatus.CLOSED ) {
			options.push(
				{
					label: I18n.t('Send by e-mail'),
					action: this.sendInvoice
				}
			)
		}

		this.props.viewDocument(invoice.html, options);
	}




	getItemOptions = () => {
		const invoice = this.props.cn;

		const options = [
			{
				label: I18n.t('View'),
				action: this.viewDocument,
				icon: 'open'
			}
		];

		if( invoice.status !==  InvoiceStatus.CLOSED ) {
			options.push({
				label: (invoice.status === 'DRAFT') ? I18n.t('Mark as open') : I18n.t('Close'),
				action: this.updateStatus,
				icon: 'ok'
			});
		}

		if( invoice.status ===  InvoiceStatus.DRAFT || (invoice.status ===  InvoiceStatus.OPEN && this.mode === 'ADVANCED') ) {
			options.push({
				label: I18n.t('Edit'),
				action: this.editForm,
				icon: 'edit'
			});

			options.push({
				label: I18n.t('Delete'),
				action: this.doDelete,
				icon: 'trash'
			});
		}

		return options;
	}




	render() {
		const { cn, client } = this.props;

		const cnId = cn.iid || '{DRAFT}';
		const classNames = ['item', 'invoice', 'credit_note', `credit_note-${cn.id}`, `${cn.status.toLowerCase()}` ];

		return (
			<li className="data-list-item">
				<div className={ classNames.join(' ') } id={`invoice-${cn.id}`} data-id={cn.id}>
					<div className="item-main-col">
						<div className="item-client">
							<Link to={`/incomes/clients/view/${client.id}`}>{client.short_name}</Link>
						</div>

						<div className="item-title">
							<button className="link-like" onClick={ this.viewDocument }>{cnId}</button>
						</div>
					</div>

					<div className="item-col-date">
						<DateTime date={cn.date_memo} format="dd mmm yyyy" />
					</div>

					<div className="item-col-amount col-amount-taxexcl">
						<div className="amount">
							<Currency amount={cn.amount} />
						</div>
					</div>

					<div className="item-col-amount col-amount-taxincl">
						<div className="amount">
							<Currency amount={cn.amount_with_taxes} />
						</div>
					</div>

					<ItemOptions options={ this.getItemOptions() } />
				</div>
			</li>
		)
	}
};

CreditNote.propTypes = {
	cn: PropTypes.object.isRequired,
	client: PropTypes.object.isRequired,
	onStatusChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	getForm: PropTypes.func.isRequired,
	viewDocument: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
	const { clients, searchTerms } = state.data || { clients: [], searchTerms: false };
	const client = clients.find(c => parseInt(c.id, 10) === parseInt(ownProps.cn.client_id, 10)) || {}
	return { client, searchTerms }
}


const mapDispatchToProps = (dispatch) => {
	return {
		viewDocument: (url, options) => {
			dispatch(openDocumentViewer(url, options));
		},

		getForm: (note) => {
			const title = I18n.t('Edit credit note');
			const module = <EditInvoice invoice={note} />;
			dispatch(openOverlay( title, module ));
		},

		onStatusChange: (note) => {
			dispatch(doSaveInvoice(note))
		},

		onDelete: (note) => {
			dispatch(doDeleteInvoice(note))
		}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(CreditNote);
