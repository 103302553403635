import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Glyphicon from '../../../components/common/Glyphicon';
import Currency from '../../../components/common/Currency';
import I18n from '../../../locales/I18n';


const ProviderButton = ({ provider }) => {
	const renderBalance = () => {
		if( provider.status.balance === 0 ) {
			return;
		}

		const balanceClassName = ['meta-value'];
		if( provider.status.balance < 0 ) {
			balanceClassName.push('warning');
		}

		return (
			<span className="meta balance">
				<Glyphicon icon="time" title={ I18n.t('Due') } />
				<span className={ balanceClassName.join(' ') }>
					<Currency amount={provider.status.balance} />
				</span>
			</span>
		);
	}


	return(
		<li className={`data-list-item icon-arrow-right provider provider-${provider.id}`} id={`data-list-item-${provider.id}`}>
			<Link to={`/outcomes/providers/view/${provider.id}`}>
				<h2 className="item-title">{provider.name}</h2>
				<div className="item-meta">
					<span className="meta turnover">
						<Glyphicon icon="upload" title={ I18n.t('Expensed') } />
						<span className="meta-value">
							<Currency amount={provider.status.expensed} />
						</span>
					</span>

					{ renderBalance() }
				</div>
			</Link>
		</li>
	);

};

ProviderButton.propTypes = {
	provider: PropTypes.object.isRequired,
};


export default ProviderButton;
