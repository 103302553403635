
import { saveJob, deleteJob, invoiceJobs, saveJobUsers, saveJobOwner } from '../../api';

import {
	JOB_DO_SAVE,
	JOB_SAVED,
	JOB_DO_DELETE,
	JOB_DELETED,
	JOBS_DO_INVOICE,
	JOBS_INVOICED,
	JOB_USERS_DO_SAVE,
	JOB_USERS_SAVED,
	JOB_OWNER_DO_SAVE,
	JOB_OWNER_SAVED
	//JOB_USER_DO_DELETE,
	//JOB_USER_DELETED
} from '../../AppEvents';



function job_save(job) {
	return {
		type: JOB_DO_SAVE,
		job
	}
}


function job_saved(response) {
	const r = {
		type: JOB_SAVED,
		status:response.status,
		job: response.job,
		sessions: response.sessions,
		receivedAt: Date.now()
	}

	r.message = 'Job was saved';// : response.message;
	return r;
}



function job_delete(job) {
	return {
		type: JOB_DO_DELETE,
		job
	}
}


function job_deleted(response) {
	const r = {
		type: JOB_DELETED,
		status: response.status,
		id: parseInt(response.id, 10),
		receivedAt: Date.now()
	}

	r.message = 'Job was deleted';// : response.message;
	return r;
}


function jobs_invoice(client_id, jobs) {
	return {
		type: JOBS_DO_INVOICE,
		client_id,
		jobs
	}
}


function jobs_invoiced(response) {
	return {
		type: JOBS_INVOICED,
		status: response.status,
		message: response.flash,
		jobs: response.jobs,
		invoices: response.invoices,
		receivedAt: Date.now()
	}
}




function job_users_save(job, users) {
	return {
		type: JOB_USERS_DO_SAVE,
		job,
		users
	}
}


function job_users_saved(response) {
	const r = {
		type: JOB_USERS_SAVED,
		status: response.status,
		job: response.job,
		receivedAt: Date.now()
	}

	r.message = 'Job users were saved';
	return r;
}


function job_owner_save(job, users) {
	return {
		type: JOB_OWNER_DO_SAVE,
		job,
		users
	}
}


function job_owner_saved(response) {
	const r = {
		type: JOB_OWNER_SAVED,
		status: response.status,
		job: response.job,
		receivedAt: Date.now()
	}

	r.message = 'Job owner was saved';
	return r;
}



/*
function job_user_delete(job, user) {
	return {
		type: JOB_USER_DO_DELETE,
		job,
		user
	}
}


function job_user_deleted(response) {
	const r = {
		type: JOB_USER_DELETED,
		job_id: parseInt(response.body.job_id, 10),
		user_id: parseInt(response.body.user_id, 10),
		code:parseInt(response.status, 10),
		receivedAt: Date.now()
	}

	r.message = r.status === 200 ? 'Job user was deleted' : response.message;
	return r;
}
*/



export function doSaveJob(job) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(job_save(job));
			return saveJob(job).then(json => dispatch(job_saved(json)));
		});
	}
}


export function doDeleteJob(job) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(job_delete(job));
			return deleteJob(job).then(json => dispatch(job_deleted(json)));
		});
	}
}


export function doInvoiceJob(jobsIds) {
	return (dispatch, getState) => {
		return dispatch(() => {
			const jobsData = new FormData();
			jobsIds.forEach(id => {
				jobsData.append('job_id[]', id);
			})
			dispatch(jobs_invoice(jobsIds));
			return invoiceJobs(jobsData).then(json => dispatch(jobs_invoiced(json)));
		});
	}
}


export function doSaveJobUsers(job, users) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(job_users_save(job, users));
			return saveJobUsers(job.id, users).then(json => dispatch(job_users_saved(json)));
		});
	}
}


export function doSetJobOwner(job, ownerId) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(job_owner_save(job, ownerId));
			return saveJobOwner(job.id, ownerId).then(json => dispatch(job_owner_saved(json)));
		});
	}
}


/*
export function doDeleteJobUser(job, user) {
	return (dispatch, getState) => {
		return dispatch(() => {
			dispatch(job_user_delete(job, user));
			return deleteJobUser(job.id, user).then(json => dispatch(job_user_deleted(json)));
		});
	}
}
*/
