export default class TimeFormatter {

	constructor(amountSeconds) {
		if(isNaN(amountSeconds))
			throw new Error('Missing amountSeconds');

		this.seconds = amountSeconds;
		this.objectTime = this.splitTime();
	}


	hhmmss = separator => {
		if(! separator)
			separator = ':';

		return [ this.objectTime.hours, this.objectTime.minutes, this.objectTime.seconds ].join(separator);
	}

	hmm = separator => {
		if(! separator)
			separator = 'h';

		return [ parseInt(this.objectTime.hours, 10), this.objectTime.minutes ].join(separator);
	}


	splitTime = () => {
		let seconds = this.seconds % 60;
		if( seconds < 10 )	seconds = '0'+ seconds;

		let hours = Math.floor(this.seconds/3600);
		if( hours < 10 ) hours = '0'+ hours;

		let minutes = Math.floor(this.seconds/60) - (parseInt(hours, 10) * 60);
		if( minutes < 10 )	minutes = '0'+ minutes;

		return {
			hours,
			minutes,
			seconds
		};
	}
}
